import { NgModule, Pipe, PipeTransform } from '@angular/core';

import {
    AestheticsModule as ReflexAestheticsModule,
    NavigationModule
} from '@smartsoftware/reflex-core';

import { TimeAgoPipe } from 'time-ago-pipe';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatSnackBarModule } from '@angular/material/snack-bar';

// TODO: Move this to Reflex's aesthetics module.
@Pipe({
    name: 'reflexTimeAgo',
    pure: false
})
export class ReflexTimeAgoPipe extends TimeAgoPipe implements PipeTransform {

    transform(value: Date | string | null | undefined): string {
        if (value === null || value === undefined) {
            return '';
        }
        let date = new Date(value);
        if (isNaN(date as unknown as number)) {
            return '';
        }
        let now = new Date();
        if (date <= now) {
            return super.transform(date.toString());
        }
        else {
            // all of the text strings coming out of TimeAgoPipe are all consistent
            // and end with ago. 
            // the logic is the same, just need to replace 'ago' with 'from now'
            let result = super.transform(date.toString());
            return result.replace(/ago$/, 'from now');
        }
    }
    
}

@NgModule({
    imports: [
        NavigationModule,
        ReflexAestheticsModule,

        MatDatepickerModule,
		MatRadioModule,
		MatCheckboxModule,
        MatNativeDateModule,
        MatCardModule,
        MatSnackBarModule
    ],
    declarations: [
        ReflexTimeAgoPipe
    ],
    exports: [
        ReflexAestheticsModule,
        NavigationModule,
        ReflexTimeAgoPipe,
        MatDatepickerModule,
		MatRadioModule,
		MatCheckboxModule,
        MatNativeDateModule,
        MatCardModule,
        MatSnackBarModule
    ]
})
export class AestheticsModule { }
