import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { UserProject } from '../models/userProject.model';

@Injectable()
export class UserProject_Service extends ResourceService<UserProject> {
    protected servicePath: string = '/UserProject';

    public ModelType = UserProject;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }
}
