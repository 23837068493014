import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { LegalConnect_Service, UserPermission_Service, SystemConfig_service} from "legalreflex-lib";
import { BreadCrumbService } from "../../../components/breadcrumb-bar/breadcrumb-service";
import { Title } from "@angular/platform-browser";

const DIGIT_REGEX = "^[0-9]*$";

@Component({
    templateUrl: './directEFile.html',
    styleUrls: ['./directEFile.scss']
})
export class DirectEFile implements OnInit{
    
    public isLoading = true;
    public isCanceled = false;
    public hasAttorneys = false;

    public loadingMessage = "Please wait while we log you into our eFiling Portal...";
    public showError = false;
    public errorMessage = "";

    private cookieURL?: string
    private forwardURL?: string;

    private routeState: RouteState;


    constructor (
        protected auth: OidcSecurityService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected userPermissionService: UserPermission_Service,
        protected legalConnectService: LegalConnect_Service,
        protected breadCrumbService: BreadCrumbService,
        protected fb: FormBuilder,
        public pageTitleService: Title,
        public sysConfig: SystemConfig_service
    ) { 

        this.pageTitleService.setTitle("FirstConnect - Direct eFile");
        this.routeState = this.router.getCurrentNavigation()?.extras.state as RouteState;
        
        this.breadCrumbService.breadcrumbs.next([
            { label: 'Orders', url: '/order'},
            { label: 'Direct eFile' }
        ]);

        this.breadCrumbService.actions.next([
            // { label: '', action: () => this.cancel(), isPrimary: false, icon: 'close'}
        ]);
    }

    public legalConnectFormGroup: FormGroup = this.fb.group({
        email: ['', Validators.required],
        fullName: ['', Validators.required],
        barId: ['', [Validators.required, Validators.pattern(DIGIT_REGEX)]],
        customerNumber: ['', Validators.required],
        companyNumber: ['', [Validators.required, Validators.pattern(DIGIT_REGEX) ]],
    });


    async ngOnInit() {
        this.isLoading = true;
        if(this.sysConfig.maintenanceRedirectCheck()) {
            window.localStorage.removeItem("loggedInUserPermissionNodes");
            this.auth.logoff();
        };
        // stop gap for when routeState is undefined
        if(!this.routeState)
            this.routeState = {clientMatterNumber: '', claimNumber: ''};
        
        this.auth.isAuthenticated$.subscribe((authenticated) => {
            if(!authenticated.isAuthenticated) return;

            this.userPermissionService.userSync.subscribe((userSynced) => {
                if(!userSynced) return;

                // check for LC attorneys
                if(this.userPermissionService.loggedInUser?.companyNumber 
                        && this.userPermissionService.loggedInUser.customerNumber) {
                    
                    this.legalConnectFormGroup.get('customerNumber')?.patchValue(this.userPermissionService.loggedInUser.customerNumber);
                    this.legalConnectFormGroup.get('companyNumber')?.patchValue(this.userPermissionService.loggedInUser.companyNumber);

                    this.legalConnectService.getLCAttorneys(
                        this.userPermissionService.loggedInUser.customerNumber,
                        this.userPermissionService.loggedInUser.companyNumber
                    ).subscribe(
                        (legalConnectAttorneyResponse) => {
                            this.hasAttorneys = (legalConnectAttorneyResponse?.length);
                            this.loginToLegalConnect();
                        }
                    );
                }
            });
        });
    }

    // public test() {
    //     // console.log('test the route data', this.routeState);
    //     const companyNumberControl = this.legalConnectFormGroup.get('companyNumber');
    //     const customerNumberControl = this.legalConnectFormGroup.get('customerNumber');

    //     console.log('company', companyNumberControl?.value);
    //     console.log('customer', customerNumberControl?.value);

    //     console.log('lc form errors', this.legalConnectFormGroup.errors);
    //     console.log('lc form valid', this.legalConnectFormGroup.valid);
    //     console.log('lc form', this.legalConnectFormGroup);
    // }

    public async save() {
        const fullNameControl = this.legalConnectFormGroup.get('fullName');
        const emailControl = this.legalConnectFormGroup.get('email');
        const barIdControl = this.legalConnectFormGroup.get('barId');
        const companyNumberControl = this.legalConnectFormGroup.get('companyNumber');
        const customerNumberControl = this.legalConnectFormGroup.get('customerNumber');

        // guard to make sure all the form controls are present
        if(!fullNameControl || !emailControl || !barIdControl || !companyNumberControl || !customerNumberControl) {
            console.error('Error: Attorney could not be created, missing form control');
            return false;
        }

        let attorney = await this.legalConnectService.createLCAttorney(
            fullNameControl.value, 
            emailControl.value, 
            barIdControl.value, 
            customerNumberControl.value, 
            companyNumberControl.value
        );

        if(!attorney.id) {
            console.error('Error: Attorney could not be created', attorney);
            return false;
        }

        // set has attorneys flag to true and continue to attempt to login.
        this.hasAttorneys = true;
        return await this.loginToLegalConnect();
    }

    public cancel() {
        this.isCanceled = true;
        this.router.navigate(['/']);
    }

    public manualRedirect() {
        if(!this.forwardURL) {
            console.error('Error: path not defined');
            return;
        }

        window.open(this.forwardURL, "_blank");
    }

    // empty callback function, required by LC API, not expected to be called
    private testCallback(cookieResponse: any) {
        console.log('cookie response', cookieResponse);
    }

    public async loginToLegalConnect () {
        
        this.isLoading = true;
        this.isCanceled = false;

        // check for necessary user data
        if(!this.userPermissionService.loggedInUser?.customerNumber 
                || !this.userPermissionService.loggedInUser.companyNumber
                || !this.userPermissionService.loggedInUser.email) {
            
            this.isLoading = false;
            return false;
        }
        
        let customer, user, login, cookie;
        
        if(this.hasAttorneys) {
            customer = await this.legalConnectService.updateLCCustomerAccount(
                this.userPermissionService.loggedInUser.customerNumber,
                this.userPermissionService.loggedInUser.companyNumber
            );
        }
        else {  
            this.isLoading = false;
            return false;
        }

        if(customer && !this.isCanceled) 
            user = await this.legalConnectService.updateLCUserAccount(this.userPermissionService.loggedInUser.email);
        if(user && !this.isCanceled) 
            login = await this.legalConnectService.loginLCUser(this.userPermissionService.loggedInUser.email);

        if(login && !this.isCanceled) {
            let claimNumber = this.routeState.claimNumber;
            let billingCode = this.routeState.clientMatterNumber;

            // LC API needs a callback url param passed in the GET or it breaks
            this.cookieURL = login.result.cookieUrl + '&callback=testCallback';

            // set the url for redirecting after getting the cookie
            this.forwardURL = login.result.forwardUrl 
                + '/NewOrder/EFile?ClaimNumber=' + claimNumber
                + '&BillingCode=' + billingCode;
            
            try {
                cookie = await this.legalConnectService.requestLCLoginCookie(this.cookieURL!);
            } catch(error: any) {
                // We get a CORS error from the LC API but still end up getting the cookie needed for SSO.
                if (error && (error == 'The server rejected your request without reason. This is most likely due to invalid CORS configuration.'
                    || (error.statusText && (error.statusText == 'Unknown Error' || error.statusText == 'Gateway Timeout')) )) {
                    cookie = 'CORS cookie hack';
                } else {
                    this.showError = true;
                    this.errorMessage = "Login to LegalConnect failed";
                    console.error('Error:', error);
                }
            }

            // forward the user to LegalConnect.
            if(cookie && !this.isCanceled) {
                // open LC in new window
                window.open(this.forwardURL, "_blank");
                // go back to dashboard after successful login
                this.router.navigate(['/']);
            }
        }

        this.isLoading = false;
        return (customer && user && login && cookie);
    }
}

interface RouteState {
    clientMatterNumber?: string,
    claimNumber?: string
}