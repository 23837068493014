<resource-list-grid>
	<resource-list-pane *ngIf="!isLoading" title="Document" [formGroup]="entityForm">
		<mat-form-field>
			<input matInput
				readonly
				formControlName="name"
				placeholder="Name"/>
		</mat-form-field>

		<mat-form-field>
			<input matInput
				readonly
				formControlName="docStatus"
				placeholder="Status"/>
		</mat-form-field>

		<mat-form-field>
			<input matInput
				readonly
				formControlName="fileType"
				placeholder="File Type"/>
		</mat-form-field>

		<mat-form-field>
			<input matInput
				readonly
				formControlName="fileName"
				placeholder="FileName"/>
		</mat-form-field>

		<mat-form-field>
			<input matInput
				readonly
				formControlName="documentType"
				placeholder="Type"/>
		</mat-form-field>

	</resource-list-pane>

	<resource-list-pane title="Order" *ngIf="order!=null && !isLoading">
		<mat-form-field>
			<input matInput
				readonly
				[value]=order.orderId
				placeholder="ID"/>
		</mat-form-field>

		<mat-form-field>
			<input matInput
				readonly
				[value]=order.name
				placeholder="Requested By"/>
		</mat-form-field>

		<mat-form-field>
			<input matInput
				readonly
				[value]=order.orderNumber
				placeholder="Order Number"/>
		</mat-form-field>

		<mat-form-field>
			<input matInput
				readonly
				[value]=order.caseName
				placeholder="Case Name"/>
		</mat-form-field>

		<mat-form-field>
			<input matInput
				readonly
				[value]=order.caseNumber
				placeholder="Case Number"/>
		</mat-form-field>
	</resource-list-pane>
</resource-list-grid>
