import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class ClientFile extends DexResourceModel {

    @Field(String)
    public filename: string = "";
    @Field(String)
    public filesize: number = 0;
    @Field(String)
    public client_uuid: string | null = null;
    @Field(String)
    public fileType_uuid: string | null = null;

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}
