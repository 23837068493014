import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class ClientPartner extends DexResourceModel {
    @Field(String)
    public clientName: string = "";

    @Field(String)
    public client_uuid?: string | null = null;

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}