import { Component, QueryList, ViewChild } from '@angular/core';
import { ReflexEnvironment as ENV, ResourceListPane } from '@smartsoftware/reflex-core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { 
    InvoiceFilterDataSource, 
    PagedDataSource, 
    PagedResourceListComponent, 
    Invoice_Service, 
    Invoice,
    ClientBranch_Service,
    UserPermission_Service
} from 'legalreflex-lib';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BreadCrumbService } from '../../../components/breadcrumb-bar/breadcrumb-service';


@Component({
    templateUrl: './list.html',
    styleUrls: ['./list.scss'],
    providers: [
        { provide: PagedDataSource, useExisting: InvoiceFilterDataSource }
    ]
  })
export class InvoiceList extends PagedResourceListComponent<Invoice> {
    @ViewChild('filter') filterPane: ResourceListPane = new ResourceListPane();
    public filterCollapsed: boolean = sessionStorage.getItem("filterCollapsed") == 'true';
    public columnsToDisplay = ['Download', 'invoiceNumber', 'invoiceDate', 'totalOrdersOnInvoice', 'revenueAmount', 'nonRevenueAmount', 'invoiceAmount', 'balanceRemaining'];
    public customerNumber: number = 0;
    public companyNumber: number = 0;
    public corpId: string = '';

    public missingCustomerOrCompany: boolean = false;
    public loaded: boolean = false;
    public failed: boolean = false;
    public invoiceCount: number = -1;
    public totalInvoiceCount: number = 2358935;

    public filters: InvoiceFilter = {
        customerNumber: this.customerNumber,
        companyNumber: this.companyNumber,
    }; 

    constructor(
        public dataSource: InvoiceFilterDataSource,
        protected resourceService: Invoice_Service,
        private clientBranchService: ClientBranch_Service,
        protected auth: OidcSecurityService,
        private userPermissionService: UserPermission_Service,
		private breadCrumbService: BreadCrumbService,
        protected dialog: MatDialog, 
        public route: ActivatedRoute, 
        public router: Router) 
    {
        super(dataSource, resourceService, dialog, route, router);
        this.breadCrumbService.breadcrumbs.next([
            { label: 'Invoice', url: '/invoice'},
            { label: 'Billing History' }
        ]);

        this.breadCrumbService.actions.next([
            {label:'Refresh', action: this.applyFilters.bind(this), icon:'refresh'}
        ]);
    }

    ngOnInit() {
        super.ngOnInit();

        this.resourceService.totalCount().subscribe((count)=>{
            this.totalInvoiceCount = count.result;
        });

        this.auth.isAuthenticated$.subscribe((authenticated) => {
            if(!authenticated.isAuthenticated) return;

            this.userPermissionService.userSync.subscribe((userSynced: boolean) => {
                if(!userSynced) return;
                let filterClick = document.getElementById('filterPaneInvoice');
                if(filterClick && !filterClick.hasAttribute('filterClickAttached')){
                    filterClick.setAttribute('filterClickAttached','true')
                    filterClick.addEventListener("click",()=>{
                        sessionStorage.setItem("filterCollapsed", this.filterPane.isCollapsed ? "true" : "false");
                    })
                }
        
                if(this.userPermissionService.loggedInUser?.companyNumber &&
                    this.userPermissionService.loggedInUser?.customerNumber) {
                    this.customerNumber = +this.userPermissionService.loggedInUser?.customerNumber;
                    this.companyNumber = this.userPermissionService.loggedInUser?.companyNumber;
                    if(this.userPermissionService.loggedInUser?.corpId) {
                        this.corpId = this.userPermissionService.loggedInUser?.corpId;
                        this.listClientBranches();
                    } else {
                        this.clearFilters();
                    }
                } else {
                    this.missingCustomerOrCompany = true;
                }
            });
        });
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        setTimeout(()=>{
            this.dataSource.reload();
            let filterClick = document.getElementById('filterPaneInvoice');
            if(filterClick && !filterClick.hasAttribute('filterClickAttached')){
                filterClick.setAttribute('filterClickAttached','true');
                filterClick.addEventListener("click",()=>{
                    sessionStorage.setItem("filterCollapsed", this.filterPane.isCollapsed ? "true" : "false");
                })
            }
        });
    }

    listClientBranches() {
        this.clientBranchService.findByCompanyNumberCustomerNumberAndCorpId(this.companyNumber, this.customerNumber, this.corpId)
            .subscribe((result)=>{
                if(result && result[0] && result[0]?.masterCustomerNumber && result[0]?.masterCompanyNumber) {
                    this.customerNumber = result[0].masterCustomerNumber;
                    this.companyNumber = result[0].masterCompanyNumber;
                }
                this.clearFilters();
            })
    }

    setEmptyFilters() {
        this.loaded = false;
        this.filters = {
            customerNumber: this.customerNumber,
            companyNumber: this.companyNumber,
            invoiceNumber: undefined,
            startInvoiceDate: undefined,
            endInvoiceDate: undefined
        }
    }

    applyFilters() {
        this.dataSource.filters = this.filters;
        this.dataSource.failed.subscribe((result)=>{
            this.failed = result;
        });
        this.dataSource.totalItems.subscribe((result)=>{
            this.invoiceCount = result;
            this.loaded = (this.invoiceCount != -1 && this.invoiceCount < this.totalInvoiceCount);
        });
    }

    clearFilters() {
        this.setEmptyFilters();
        this.applyFilters();
    }

    formatDate(date: Date) {
        let year = date.getUTCFullYear();
        let month = date.getUTCMonth()+1;
        let day = date.getUTCDate();
        return month.toString().padStart(2,'0') + '/' + day.toString().padStart(2,'0') + '/' + year;
    }

    downloadInvoice(invoice: Invoice): void {
        let url = ENV.config.invoiceDownloadUrl;
        let invoiceDate = new Date(invoice.invoiceDate);
        let year = invoiceDate.getUTCFullYear().toString();
        let month = (invoiceDate.getUTCMonth() + 1).toString().padStart(2,'0');
        let date = invoiceDate.getUTCDate().toString().padStart(2,'0');
        let fileName = 'omrinv_' + 
        invoice.companyNumber.toString().padStart(3,'0') + '_' +
        invoice.customerNumber.toString().padStart(5,'0') + '_' +
        invoice.invoiceNumber.toString().padStart(8,'0') + '_' +
        year + month + date + '.pdf';

        window.open(url + '/admin/' + year + '/' + month + '/' + fileName);
    }
}

interface InvoiceFilter {
    customerNumber: number,
    companyNumber: number,
    invoiceNumber?: number,
    startInvoiceDate?: Date,
    endInvoiceDate?: Date
}