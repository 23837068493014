<h1 mat-dialog-title>Case Restoration</h1>
<div mat-dialog-content>
    You are about to restore this entry, Please confirm:
    <div>{{data.name}}, {{data.caseNumber}}, {{data.clientMatterNumber}} </div>
</div>
<div mat-dialog-actions>
  <button type="button" mat-button mat-dialog-close (click)="close(false)">Cancel</button>
  <button type="button" mat-raised-button mat-dialog-close color="primary" (click)="close(true)">Confirm Restore</button>

</div>
