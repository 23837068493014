<resource-list-pane [collapsable]="false" title="Uploads">

    <ng-container *ngIf="!hide">

        <input type="file" #UploadFileInput id="fileUpload" (change)="onFileChange($event)" name="fileUpload"
            multiple />

        <mat-list>
            <mat-list-item *ngFor="let file of fileInfos; let i = index">
            <mat-icon mat-list-icon>folder</mat-icon>
            <div mat-line>{{file.name}}</div>
            <div *ngIf="!file.complete" mat-line> <mat-progress-bar mode="indeterminate"></mat-progress-bar> </div>
            <button mat-icon-button type="button" (click)="remove(file.name, i)" [disabled]="!file.complete" color="warn" aria-label="Upload remove button">
            <mat-icon>remove_circle</mat-icon>
            </button>
            </mat-list-item>
        </mat-list>

        <!-- <div class="add-control">
            <div class="span"></div>
            <button mat-button [disabled]="files.length <= 0" type="button" color="primary" (click)="submit()">
                <mat-icon>add</mat-icon>
                <span>Upload</span>
            </button>
        </div> -->
        <mat-progress-spinner *ngIf="isLoading" style="margin:0 auto;" color="primary" mode="indeterminate"></mat-progress-spinner>

    </ng-container>
</resource-list-pane>
