<h1 mat-dialog-title>
    Removing User From Case
</h1>
<div mat-dialog-content>
    <div *ngIf="data.isCurrentUser">Are you sure you want to remove your account from this case?</div>
    <div *ngIf="!data.isCurrentUser">
        Are you sure you want to remove this account from this case?
        <br><br>
        {{data.name}}<br>{{data.email}}
    </div>
</div>
<div class="actions">
    <button mat-raised-button type="button" (click)="close(false)">No</button>
    <button mat-raised-button type="button" color='primary' (click)="close(true)">Yes</button>
</div>