import { ActivatedRoute, Router } from "@angular/router";
import { BreadCrumbService } from "../../components/breadcrumb-bar/breadcrumb-service";
import { Order_Service, UserPermission_Service } from "legalreflex-lib";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { filter } from "rxjs/operators";
import { Component } from "@angular/core";

@Component({
    templateUrl: './pageNotFound.html'
})
export class PageNotFound {
    public typeUrl: string = ''
    constructor(
        protected auth: OidcSecurityService,
        private userPermissionService: UserPermission_Service,
		private breadCrumbService: BreadCrumbService,
        public route: ActivatedRoute, 
        public router: Router,
        public entityService: Order_Service
    ) {}

    ngOnInit(){}
    returnToDashboard(){
        this.router.navigate(['']);
    }
}