<h1 mat-dialog-title>Request Account</h1>
<div mat-dialog-content>
    <h2>User Info</h2><br>
    <div>
        <mat-form-field>
            <input matInput
            required
            (change)="this.checkFields()"
            placeholder="First Name" 
            [formControl]="firstNameCtrl"/>
        </mat-form-field>
        <mat-form-field>
            <input matInput
            required
            (change)="this.checkFields()"
            placeholder="Last Name" 
            [formControl]="lastNameCtrl"/>
        </mat-form-field>
    </div>
    <mat-form-field>
        <input matInput
        required
        (change)="this.checkFields()"
        placeholder="Email" 
        value={{data.email}}
        [formControl]="emailCtrl"/>
    </mat-form-field><br>
    <mat-form-field>
        <input matInput
        required
        (change)="this.checkFields()"
        placeholder="Firm" 
        value={{data.firm}}
        [formControl]="firmCtrl"/>
    </mat-form-field><br>
    <mat-form-field>
        <input matInput
        required
        (change)="this.checkFields()"
        placeholder="Branch" 
        value={{data.branch}}
        [formControl]="branchCtrl"/>
    </mat-form-field><br>
    <!-- <h2>Attorneys</h2><br>
    <div id="attorneyList" style="justify-content:space-between !important;">
        <div mat-line *ngFor="let attorney of attorneys;index as i" >{{attorney.name}} {{attorney.email}}
            <button mat-flat-button (click)="attorneys.splice(i,1)"><mat-icon>remove_circle</mat-icon></button><br></div>
        <mat-form-field>
            <input matInput
            [formControl]="attorneyNameCtrl"
            placeholder="Attorney Name"/>
        </mat-form-field> 
        <mat-form-field> 
            <input matInput
                [formControl]="attorneyEmailCtrl"
                placeholder="Attorney Email"/>
            <mat-error *ngIf="attorneyEmailCtrl.hasError('email')">Please enter a valid email address</mat-error>
        </mat-form-field>
        <button mat-flat-button (click)="pushAttorney()"><mat-icon>add</mat-icon></button>
    </div>    
    <br>  -->
</div>
<div mat-dialog-actions>
  <button mat-raised-button mat-dialog-close (click)="close()">Close</button>
  <div matTooltip="Please fill all user info fields" [matTooltipDisabled]="isFormComplete">
      <button mat-raised-button (click)="requestAccess()" [disabled]="!isFormComplete">Request Access</button>
  </div>
</div>
