import { Component, OnInit } from '@angular/core';
import { Breadcrumb, BreadcrumbAction, BreadCrumbService } from './breadcrumb-service';
import { Observable } from 'rxjs/internal/Observable';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'breadcrumb-bar',
    templateUrl: './breadcrumb-bar.html',
    styleUrls: ['./breadcrumb-bar.scss']
})
export class BreadcrumbBarComponent implements OnInit {

    constructor(
        private breadCrumbService: BreadCrumbService
    ) { }

    public breadcrumbs: BehaviorSubject<Breadcrumb[]> = this.breadCrumbService.breadcrumbs;
    public actions: BehaviorSubject<BreadcrumbAction[]> = this.breadCrumbService.actions;


    ngOnInit(): void {
        // Initialization logic goes here
    }

    invokeAction(action:BreadcrumbAction):void {
        if(action.action) 
            action.action()
    }

}
