
<resource-list-grid>

	<resource-list-pane *ngIf="(dataSource.loading | async)">
		<div class="loading">
            <div class="loading-text">Loading Client Matter Sets</div>
            <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
        </div>
	</resource-list-pane>

    <resource-list-pane title="Client Matter Sets" [hidden]="(dataSource.loading | async)">
		<mat-paginator
			[pageSizeOptions]="dataSource.pageSizeOptions"
			[pageIndex]="dataSource.pageIndex"
			[pageSize]="dataSource.pageSize"
			showFirstLastButtons >
		</mat-paginator>
		
		<mat-table multiTemplateDataRows [dataSource]="dataSource" matSort>

			<ng-container cdkColumnDef="view">
				<mat-header-cell *cdkHeaderCellDef class="view">View</mat-header-cell>
				<mat-cell *cdkCellDef="let entity" class="view">
					<a mat-flat-button [routerLink]="['/clientMatter', entity.id]">
						<mat-icon>list_alt</mat-icon>
					</a>
				</mat-cell>
			</ng-container>

            <ng-container cdkColumnDef="name">
				<mat-header-cell mat-sort-header *cdkHeaderCellDef>Set Name</mat-header-cell>
				<mat-cell *cdkCellDef="let entity"><span>{{entity.name}}</span></mat-cell>
			</ng-container>

			<ng-container cdkColumnDef="ftp_fetchFrequency">
				<mat-header-cell mat-sort-header *cdkHeaderCellDef>AutoSynced</mat-header-cell>
				<mat-cell *cdkCellDef="let entity">
				<mat-chip *ngIf ="entity.autoFetch" color="primary" selected><span>{{entity.autoFetch ? 'Yes' : 'No'}}</span></mat-chip>
				<mat-chip *ngIf ="!entity.autoFetch" color="warm" selected><span>{{entity.autoFetch ? 'Yes' : 'No'}}</span></mat-chip>
			</mat-cell>
			</ng-container>
			
			<ng-container cdkColumnDef="deletedAt">
				<mat-header-cell mat-sort-header *cdkHeaderCellDef>Enabled</mat-header-cell>
				<mat-cell *cdkCellDef="let entity">
				<mat-chip *ngIf ="!entity.isDeleted" color="primary" selected>{{!entity.isDeleted ? 'Active' : 'Inactive'}}</mat-chip>
				<mat-chip *ngIf ="entity.isDeleted" color="warm" selected>{{!entity.isDeleted ? 'Active' : 'Inactive'}}</mat-chip>
				</mat-cell>
			</ng-container>
			
			<mat-header-row *cdkHeaderRowDef="['view', 'name','ftp_fetchFrequency','deletedAt']; sticky: true"></mat-header-row>
			<mat-row *cdkRowDef="let row; columns: ['view', 'name','ftp_fetchFrequency','deletedAt']" class="entity-row"></mat-row>
		</mat-table>

		<mat-paginator
			[pageSizeOptions]="dataSource.pageSizeOptions"
			[pageIndex]="dataSource.pageIndex"
			[pageSize]="dataSource.pageSize"
			showFirstLastButtons >
		</mat-paginator>

	</resource-list-pane>
</resource-list-grid>