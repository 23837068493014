
<div class="wrap">
    <mat-card>
        <mat-card-content>
            <mat-list dense class="status-messages">
                <mat-list-item *ngFor="let message of log">{{message}}</mat-list-item>
            </mat-list>
        </mat-card-content>
        <mat-card-footer>
            <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
        </mat-card-footer>
    </mat-card>
</div>



