import { Injectable } from '@angular/core';
import { AddressBook } from '../models/addressBook.model';
import { ResourceService } from '@smartsoftware/reflex-core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Page, PageParams, Pager } from '../paged.datasource';
@Injectable({
  providedIn: 'root'
})
export class AddressBookService extends ResourceService<AddressBook> implements Pager<AddressBook> {
  protected servicePath = '/AddressBook';
  constructor(public http: HttpClient) {
    super(http);
    this.ModelType = AddressBook;
   }
   
    page(pageParams: PageParams): Observable<Page<AddressBook>> {
        
        let params = new HttpParams()
        .append('params', btoa(JSON.stringify(pageParams)));

        if(pageParams.filters.hasOwnProperty('source') && pageParams.filters['source'] == 'success'){
            params = new HttpParams()
            .append('params', btoa(JSON.stringify(pageParams)));
            return this.http.get<Page<AddressBook>>(`${this.serviceUrl}successPage`, { params })
                .pipe(map(page => {
                    page.items = page.items.map(i => new AddressBook(i));
                    return page;
                }));
        }

        return this.http.get<Page<AddressBook>>(`${this.serviceUrl}Page`, { params })
            .pipe(map(page => {
                page.items = page.items.map(i => new AddressBook(i));
                return page;
            }));
        
    }

   public push(entry: AddressBook): Observable<AddressBook> {

        if(!entry.name) {
            entry.name = "";
        }
        if(!entry.corp_id) {
            entry.corp_id = null;
        } else {
            entry.corp_id = entry.corp_id.trim();
        }
        if(!entry.owner_uuid) {
            entry.owner_uuid = null
        }
        if(!entry.streetAddress1) {
            entry.streetAddress1 = "";
        }
        if(!entry.city) {
            entry.city = "";
        }
        if(!entry.country) {
            entry.country = "";
        }

        return super.push(entry);
    }

    public getByOwnerUUID(uuid?: string){
        return this
            .http
            .get(
                this.serviceUrl + 'Owner?uuid=' + uuid,
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) =>
                        response.body.map((e: AddressBook) => new AddressBook(e))
                )
            );
    }

    
    public getByCorpId(corpId?: string){
        return this
            .http
            .get(
                this.serviceUrl + 'Firm?corp_id=' + corpId,
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) =>
                        response.body.map((e: AddressBook) => new AddressBook(e))
                )
            );
    }

    public getFirstLegalsAddressBook(){
        return this
            .http
            .get(
                this.serviceUrl + 'FirstLegal',
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) =>
                        response.body.map((e: AddressBook) => new AddressBook(e))
                )
            );
    }

    public getCourtsAddressBook(){
        return this
            .http
            .get(
                this.serviceUrl + 'Courts',
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) =>
                        response.body.map((e: AddressBook) => new AddressBook(e))
                )
            );
    }

    public getFilteredList(where: any) {
        return this
            .http
            .post(
                this.serviceUrl + 'getFilteredList',
                {
                    where
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) =>
                        response.body.map((e: any) => new AddressBook(e))
                )
            );
    }
}
