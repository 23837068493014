
<resource-list-grid>
	<resource-list-pane>
        <div class="loading" *ngIf="isLoading">
            <div class="loading-text">Loading Address Books</div>
            <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
        </div>

		<div [hidden]="isLoading">

			<mat-paginator
				(page)="pageChange($event)"
				[pageSizeOptions]="dataSource.pageSizeOptions"
				[pageIndex]="dataSource.pageIndex"
				[pageSize]="dataSource.pageSize"
				showFirstLastButtons >
			</mat-paginator>
			<mat-table multiTemplateDataRows [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
				<ng-container cdkColumnDef="select">
					<mat-header-cell *cdkHeaderCellDef>
						<mat-checkbox
							color="primary"
							(change)="selectAll()"
							[checked]="selection.hasValue() && allSelected()"
							(click)="selectionChange()">
						</mat-checkbox>
					</mat-header-cell>
					<mat-cell *cdkCellDef="let entity">
						<mat-checkbox
							color="primary"
							(change)="selection.toggle(entity)"
							[checked]="selection.isSelected(entity)"
							(click)="selectionChange($event)">
						</mat-checkbox>
					</mat-cell>
				</ng-container>

				<ng-container cdkColumnDef="view" >
					<mat-header-cell *cdkHeaderCellDef class="view"></mat-header-cell>
					<mat-cell *cdkCellDef="let entity" class="view">
						<mat-icon>{{entity == expandedEntity ? 'expand_less' : 'expand_more'}}</mat-icon>
						<button type="button" mat-icon-button *ngIf="!entity.deletedAt && (entity.addressType != 'COURT' && (!entity.isDefault))" [routerLink]="'/address-book/'+entity.id">
							<mat-icon>list_alt</mat-icon>
						</button>
						<button type="button" matTooltip="Editing of default/court addresses not allowed"
						mat-icon-button disabled *ngIf="!entity.deletedAt && (entity.addressType == 'COURT' || (entity.isDefault))">
							<mat-icon>list_alt</mat-icon>
						</button>
						<button type="button" mat-icon-button *ngIf="entity.deletedAt && (entity.addressType != 'COURT' && (!entity.isDefault))" (click)="restoreEntry(entity)">
							<mat-icon>restore</mat-icon>
						</button>
						<button type="button" matTooltip="Editing of default/court addresses not allowed"
						mat-icon-button disabled *ngIf="entity.deletedAt && (entity.addressType == 'COURT' || (entity.isDefault))">
							<mat-icon>restore</mat-icon>
						</button>

					</mat-cell>
				</ng-container>

				<ng-container cdkColumnDef="name">
					<mat-header-cell mat-sort-header *cdkHeaderCellDef>Name</mat-header-cell>
					<mat-cell *cdkCellDef="let entity"><span>{{entity.name}}</span></mat-cell>
				</ng-container>

				<ng-container cdkColumnDef="corp_id">
					<mat-header-cell mat-sort-header *cdkHeaderCellDef>Company</mat-header-cell>
					<mat-cell *cdkCellDef="let entity"><span>{{entity.corp_id}}</span></mat-cell>
				</ng-container>

				<ng-container cdkColumnDef="streetAddress1">
					<mat-header-cell mat-sort-header *cdkHeaderCellDef>Address</mat-header-cell>
					<mat-cell *cdkCellDef="let entity"><span>{{entity.streetAddress1}}</span></mat-cell>
				</ng-container> 

				<ng-container cdkColumnDef="state">
					<mat-header-cell mat-sort-header *cdkHeaderCellDef>State</mat-header-cell>
					<mat-cell *cdkCellDef="let entity"><span>{{entity.state}}</span></mat-cell>
				</ng-container>

				<ng-container cdkColumnDef="city">
					<mat-header-cell mat-sort-header *cdkHeaderCellDef>City</mat-header-cell>
					<mat-cell *cdkCellDef="let entity"><span>{{entity.city}}</span></mat-cell>
				</ng-container>

				<ng-container cdkColumnDef="primaryPhoneNumber">
					<mat-header-cell mat-sort-header *cdkHeaderCellDef>Phone</mat-header-cell>
					<mat-cell *cdkCellDef="let entity"><span>{{entity.primaryPhoneNumber}}</span></mat-cell>
				</ng-container>

				<ng-container cdkColumnDef="isPrivate">
					<mat-header-cell mat-sort-header *cdkHeaderCellDef>Shared</mat-header-cell>
					<mat-cell *cdkCellDef="let entity">
						<span *ngIf="!entity.isPrivate && !entity.isDefault">Yes</span>
						<span *ngIf="entity.isPrivate || entity.isDefault">No</span>
					</mat-cell>
				</ng-container>

				<ng-container cdkColumnDef="isDefault">
					<mat-header-cell mat-sort-header *cdkHeaderCellDef>Default</mat-header-cell>
					<mat-cell *cdkCellDef="let entity">
						<span *ngIf="entity.isDefault">Yes</span>
						<span *ngIf="!entity.isDefault">No</span>
					</mat-cell>
				</ng-container>

				<ng-container cdkColumnDef="expandedDetail">
					<mat-cell class="entity-detail" *cdkCellDef="let entity;" [@detailExpand]="expandedEntity === entity ? 'expanded' : 'collapsed'">
						<mat-tab-group [animationDuration]="100" *ngIf="entity == expandedEntity">
							<mat-tab label="Overview">
								<div class="entity-detail-row">
									<div class="entity-detail-column">
										<div class="entity-additional name">
											<label>Name</label>
											<span>{{!entity.name ? 'N/a' : entity.name}}&nbsp;</span>
										</div>
										<div class="entity-additional care-of">
											<label>Care Of</label>
											<span>{{!entity.careOfLine ? 'N/a' : entity.careOfLine}}&nbsp;</span>
										</div>
										<div class="entity-additional primary-address">
											<label>Address</label>
											<span>{{!entity.streetAddress1 ? 'N/a' : entity.streetAddress1}}&nbsp;</span>
										</div>
										<div class="entity-additional room-number">
											<label>Room Number/Suite</label>
											<span>{{!entity.roomNumber ? 'N/a' : entity.roomNumber}}&nbsp;</span>
										</div>
									</div>

									<div class="entity-detail-column">
										<div class="entity-additional postal-code">
											<label>Postal Code</label>
											<span>{{!entity.postalCode ? 'N/a' : entity.postalCode}}&nbsp;</span>
										</div>
										<div class="entity-additional state">
											<label>State</label>
											<span>{{!entity.state ? 'N/a' : entity.state}}&nbsp;</span>
										</div>
										<div class="entity-additional city">
											<label>City</label>
											<span>{{!entity.city ? 'N/a' : entity.city}}&nbsp;</span>
										</div>
									</div>

									<div class="entity-detail-column">
										<div class="entity-additional privacy">
											<label>Privacy Status</label>
											<span>{{!entity.isPrivate && !entity.isDefault ? 'Shared' : (entity.isPrivate ? 'Private' : 'Default')}}&nbsp;</span>
										</div>
										<div class="entity-additional state">
											<label>Phone</label>
											<span>{{!entity.primaryPhoneNumber ? 'N/a' : entity.primaryPhoneNumber}}&nbsp;</span>
										</div>
										<div class="entity-additional city">
											<label>Fax</label>
											<span>{{!entity.primaryFaxNumber ? 'N/a' : entity.primaryFaxNumber}}&nbsp;</span>
										</div>
									</div>
								</div>
							</mat-tab>
						</mat-tab-group>
					</mat-cell>
				</ng-container>

				<mat-header-row *cdkHeaderRowDef="addressColumns; sticky: true"></mat-header-row>
				<mat-row *cdkRowDef="let row; columns: addressColumns"
				class="entity-row"
				[class.entity-expanded-row]="expandedEntity === row"
				(click)="expandedEntity = expandedEntity === row ? null : row">
				</mat-row>
				<mat-row *cdkRowDef="let row; columns: ['expandedDetail']" [class.entity-expanded-row]="expandedEntity === row" class="detail-row"></mat-row>
			</mat-table>

			<mat-paginator
				(page)="pageChange($event)"
				[pageSizeOptions]="dataSource.pageSizeOptions"
				[pageIndex]="dataSource.pageIndex"
				[pageSize]="dataSource.pageSize"
				showFirstLastButtons>
			</mat-paginator>
		</div>
	</resource-list-pane>
	<resource-list-pane title="Filters" id="filterPaneAddressBook" #filter="resourceListPane" [isCollapsed]='filterCollapsed' *ngIf="filterFormGroup" [collapsable]='true' class="entity-filters">
			<form class="filters" (ngSubmit)="getFilter()" [formGroup]="filterFormGroup">
				<mat-label style="align-self:center;">Deleted</mat-label> 
				<br><hr class="solid" style="width:100%">
	
				<mat-button-toggle-group 
					style="width:min-content; align-self:center;"
					formControlName='deletedAt'
					color="primary"
					value="either">
					<mat-button-toggle value="deleted">Is</mat-button-toggle>
					<mat-button-toggle value="notDeleted">Not</mat-button-toggle>
					<mat-button-toggle value="either">Either</mat-button-toggle>
				</mat-button-toggle-group>
				<br>

				<mat-label>Privacy (select 1 or more)</mat-label> 
				<br><hr>

				<mat-checkbox color="primary" 
					formControlName="isPrivate"> Private 
				</mat-checkbox>
				<mat-checkbox color="primary" 
					formControlName="isShared"> Shared
				</mat-checkbox>
				<mat-checkbox color="primary" 
					formControlName="isDefault">Default
				</mat-checkbox>
				<mat-label>Address Type</mat-label> 
				<br>
				<hr>
			</form>
			
			<form *ngIf="filterFormGroup" class="filters" (ngSubmit)="getFilter()" [formGroup]="filterFormGroup">
				<mat-checkbox color="primary" 
					formControlName="addressType">Search Courts
				</mat-checkbox>
		
				<mat-form-field>
					<input matInput
						formControlName="name"
						placeholder="Name"/>
				</mat-form-field>
				<mat-form-field>
					<input matInput
						formControlName="streetAddress1"
						placeholder="Street Address"/>
				</mat-form-field>
				<mat-form-field>
					<input matInput
						formControlName="city"
						placeholder="City"/>
				</mat-form-field>
				<mat-form-field>
					<input matInput
						formControlName="state"
						placeholder="State"/>
				</mat-form-field>
				<mat-form-field>
					<input matInput
						formControlName="postalCode"
						placeholder="Postal Code"/>
				</mat-form-field>
				<div class="filterActions">
                    <button mat-flat-button type="button" (click)="getFilter(true)">
						Clear
					</button>
					<button mat-raised-button color="primary" type="submit">
						Search
					</button>
				</div>
			</form>
	</resource-list-pane>
</resource-list-grid>
