<resource-list-grid>
	<resource-list-pane>
        <div>
            <p style="text-align:center; margin-top: 20px; margin-bottom: 20px; font-size: 70px; font-weight: 700;">404</p>
            <p style="text-align:center; font-size: 25px; font-weight: 180;">The Page You Were Looking For Couldn't Be Found</p>
        </div>
        <button mat-raised-button color="primary" style="align-self:center; max-width: 150px;" (click)="returnToDashboard()">Go to Dashboard</button>
        <div>
            <p style="text-align:center; margin-top: 20px; margin-bottom: 20px; font-size: 15px; font-weight: 180;">
                Support Info
                <a href="tel:877.350.8698">877.350.8698</a> |
                <a href="mailto:clientcare@firstlegal.com">clientcare@firstlegal.com</a> |
                <a href="https://www.firstlegal.com">www.firstlegal.com</a>
            </p>
        </div>
    </resource-list-pane>
</resource-list-grid>

