
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';

import {
    Document,
    Document_Service,
    Order_Service,
    UserPermission_Service,
    OrderHistoryUserFilter,
    Order,
    Vendor_Service,
    Vendor,
    SystemConfig_service
} from 'legalreflex-lib';
import { BreadCrumbService } from '../../../components/breadcrumb-bar/breadcrumb-service';

import { ResourceEditComponent, ResourceFormService, ResourceListComponent, ResourceViewComponent } from '@smartsoftware/reflex-core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { ReflexEnvironment as ENV } from '@smartsoftware/reflex-core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
    templateUrl: './view.html',
    styleUrls: ['./view.scss'],
})
export class DocumentView extends ResourceEditComponent<Document, Document_Service> {
    @ViewChild(MatSort) sort: MatSort = new MatSort();
    
    public downloadUrl: string = '';
    public order: Order | null = null;
    public vendors: Vendor[] = [];

    constructor(
        public router: Router,
        public fb: FormBuilder,
        protected auth: OidcSecurityService,
        protected entityService: Document_Service,
        protected resourceFormService: ResourceFormService<Document>,
        protected orderService: Order_Service,
        protected vendorService: Vendor_Service,
        protected dialog: MatDialog,
        protected route: ActivatedRoute,
        private userPermissionService: UserPermission_Service,
		private breadCrumbService: BreadCrumbService,
        public sysConfig: SystemConfig_service
    ) {
        super(entityService, route, dialog, resourceFormService);
        this.entityForm = this.resourceFormService.from(new Document());

        this.entityUpdated.subscribe((res)=>{
            if(this.entity){
                this.vendorService.list({allowCache:false}).subscribe((vendorList)=>{this.vendors = vendorList});
                this.getOrder();
                this.setBreadCrumbs();
            }
        })
    }

    ngOnInit() {
        super.ngOnInit();
        if(this.sysConfig.maintenanceRedirectCheck()){  
            window.localStorage.removeItem("loggedInUserPermissionNodes");
            this.auth.logoff();
        };
        this.setBreadCrumbs();
    }

    setBreadCrumbs(){
        let crumbs = [];
        crumbs.push({ label: 'Documents', url:'/document'});
        if(this.entity) 
            crumbs.push({ label: "Document " + this.entityForm.controls['name'].value});
        else{
            crumbs.push({ label: "Loading"});
        }
        this.breadCrumbService.breadcrumbs.next(crumbs);

        let actions = [
            {label: "Open document", disabled: this.downloadUrl == '', action:this.openDocument.bind(this), icon: ""},
            {label: "View Order", disabled: this.order == null, isPrimary:true, routerLink:"/order/"+this.order?.id, icon:'open_in_new'}
        ];

        this.breadCrumbService.actions.next(actions);
    }

    openDocument(){
        let a = document.createElement('a');
        a.href = this.downloadUrl;
        a.target = "_blank" ;
        a.click();
        a.remove();
    }

    public getOrder(){
        this.entityService.getOrder(this.entity.uuid).subscribe((res: Array<Order>)=>{
            this.order = res[0];
            let vendor = this.vendors.find((vendor)=>{return vendor.id == this.order?.vendor_uuid});
            if(vendor && this.order.orderStatus != "Draft") {
                if(vendor.name == "Datatrac"){
                    this.order.orderNumber = this.order.companyNumber + '-' + this.order.vendorId;
                }
                else if(vendor.name == 'Acclaim' || vendor.name == 'Tristar FLI'){
                    this.order.orderNumber = this.order.vendorId;
                }
                else{
                    this.order.orderNumber = this.order.vendorId + '-' + this.order.vendorKeyPart;
                }        
            }
            else{
                this.order.orderNumber = "Draft";
            }

            this.entityService.getSignedUrl(this.entity, this.order).subscribe((message)=>{
                if(message.message)
                    this.downloadUrl = message.message;
                this.setBreadCrumbs();
            },(error)=>{
                console.log("error occured", error);
            })
        })
    }
}
