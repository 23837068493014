import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http'
import { ResourceListOptions, ResourceService } from '@smartsoftware/reflex-core';
import { ClientMatterSet } from '../models/clientMatterSet.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Page, PageParams } from '../paged.datasource';

@Injectable()
export class ClientMatterSet_Service extends ResourceService<ClientMatterSet> {
    protected servicePath: string = '/ClientMatterSet';

    public ModelType = ClientMatterSet;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }

    page(pageParams: PageParams): Observable<Page<ClientMatterSet>> {
        let params = new HttpParams()
            .append('params', btoa(JSON.stringify(pageParams)));

        return this.http.get<Page<ClientMatterSet>>(`${this.serviceUrl}Page`, { params })
            .pipe(map(page => {
                page.items = page.items.map(i => {
                    const obj = new ClientMatterSet(i);
                    obj.autoFetch = obj.ftp_fetchFrequency !== 'Never' ? true : false;
                    return obj;
                });
                return page;
            }));
    }

    public push(entry: ClientMatterSet): Observable<ClientMatterSet> {

        if(!entry.client_uuid){
            entry.client_uuid = null;
        }

        //set to inactive
        if(entry.check == "true" && entry.deletedAt == null){
            entry.deletedAt = new Date();
            }
            //set to active
   else if(entry.check == "true" && entry.deletedAt != null){
            entry.deletedAt = null;
            }
        if(entry.ftp_fetchFrequency == ""){
            entry.ftp_fetchFrequency = "Never"
        }
        if(entry.ftp_protocol == "" || !entry.ftp_protocol){
            entry.ftp_protocol = "None"
        }

        return super.push(entry);
    }

    public ftp(id: string){
        let params = new HttpParams().set('uuid', id);
        return this.http.get<ClientMatterSet[]>(`${this.serviceUrl}ftp`,{params})
            .pipe(map(docs => docs.map(doc => new ClientMatterSet(doc))));
    }


    list(options?: ResourceListOptions | undefined): Observable<ClientMatterSet[]> {
        if (!options) {
            options = {};
        }
        if (!options.where) {
            options.where = {};
        }
        if (!('includeDeleted' in options.where)) {
            options.where.includeDeleted = true;
        }
        return super.list(options);
    }

    public getDefaultClientMatterSet(successAccount_uuid: string, corpId: string, companyNumber: string, customerNumber: string) {
        let params = new HttpParams()
            .append('successAccount_uuid', successAccount_uuid)
            .append('corpId', corpId)
            .append('customerNumber', customerNumber)
            .append('companyNumber', companyNumber);

        return this.http.get<ClientMatterSet | undefined>(`${this.serviceUrl}getDefaultClientMatterSet`, { params });
    }

    public fetchAndParse(uuid: string) {
        let params = new HttpParams().append('uuid', uuid);
        return this.http.get<ClientMatterSet | undefined>(`${this.serviceUrl}import`, {params});
    }

    public verifyConnection(clientMatterSet: ClientMatterSet) {
        return this.http.post<any>(`${this.serviceUrl}verifyConnection`, clientMatterSet);
    }

}
