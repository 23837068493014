import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SuccessAccount } from '../../models';
import { SuccessAccount_Service } from '../../services';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

export const matchesOtherControl = (other: AbstractControl | null, eq = (a: any, b: any) => a == b) => {
  return (control: AbstractControl): ValidationErrors | null => {
      if ((!other && control) 
          || (!control && other) 
          || (control && other && !eq(control.value, other.value))) {
          return { matchesOther: true };
      }
      return null;
  }
}

export const PASSWORD_MIN_LENGTH = 12;
export const PASSWORD_MAX_LENGTH = 30;
export const PASSWORD_PATTERN = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[\\d\\D]{" + PASSWORD_MIN_LENGTH + "," + PASSWORD_MAX_LENGTH + "}");
export const PASSWORD_RULES = [
  Validators.required, 
  Validators.minLength(PASSWORD_MIN_LENGTH), 
  Validators.maxLength(PASSWORD_MAX_LENGTH), 
  Validators.pattern(PASSWORD_PATTERN)
];

export const PASSWORD_HINT = `
Password must be at least ${PASSWORD_MIN_LENGTH} characters long, less than ${PASSWORD_MAX_LENGTH} characters long,
and must contain, 1 lowercase letter, 1 uppercase letter, 1 number.
`;

export const randomPassword = () => {
  let letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let numbers = "1234567890";
  let extra = "~!@#$%^&*()_+-=<>,.?/{}[]";
  let alphabet = (letters + letters.toLowerCase() + numbers + extra).split('');
  // console.debug('combos', Math.pow(alphabet.length, PASSWORD_MIN_LENGTH));
  let pass = '';
  let n = alphabet.length - 1;
  for (let i = 0; i < PASSWORD_MIN_LENGTH; i++) {
    pass += alphabet[Math.round(Math.random() * n)];
  }
  if (!PASSWORD_PATTERN.test(pass)) {
    let upper = letters[Math.round(Math.random() * (letters.length - 1))];
    let lower = letters[Math.round(Math.random() * (letters.length - 1))].toLowerCase();
    let num = numbers[Math.round(Math.random() * (numbers.length - 1))];
    pass = [upper, lower, num].reduce((acc, x) => {
      let pos = Math.round(Math.random() * (acc.length - 1));
      return acc.slice(0, pos) + x + acc.slice(pos, acc.length);
    }, pass);
  }
  return pass;
}

@Component({
  selector: 'lib-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  /**
   * The account that is getting the password reset
   */
  @Input()
  public user: SuccessAccount | undefined;
  @Input()
  public kind: 'admin' | 'owner' = 'admin';
  @Output()
  public onPasswordValueChange = new EventEmitter<string>();
  @Output()
  public onPasswordChange = new EventEmitter<string>();

  
  public passwordVisibility: 'password' | 'text' = 'password';
  PASSWORD_MIN_LENGTH = PASSWORD_MIN_LENGTH;
  PASSWORD_MAX_LENGTH = PASSWORD_MAX_LENGTH;
  PASSWORD_HINT = PASSWORD_HINT;
  form: FormGroup;

  constructor(
    private successAccountService: SuccessAccount_Service,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
  ) { 
    this.form = this.fb.group({
      password: ['', PASSWORD_RULES],
    })
  }

  ngOnInit(): void {
    if (this.kind == 'owner') {
      this.form.addControl('currentPassword', new FormControl('', [Validators.required]));
      this.form.addControl('confirmedPassword', new FormControl('', [matchesOtherControl(this.password)]));
    }
    if (!this.isPasswordChange) {
      this.form.get('password')?.valueChanges.subscribe((value: string) => {
        this.onPasswordValueChange.emit(value);
      });
    }
    
  }

  get currentPassword() {
    return this.form.get('currentPassword');
  }

  get password() {
    return this.form.get('password');
  }

  get confirmedPassword() {
    return this.form.get('confirmedPassword');
  }

  get isPasswordChange() {
    return !!this.user?.uuid && (this.kind != 'admin');
  }

  doChangePassword(e: Event) {
    e.preventDefault();
    if (this.form.invalid || !this.user)
      return;

    let request = {
      password: this.form.get('password')?.value,
      currentPassword: this.form.get('currentPassword')?.value,
      successAccount_uuid: this.user.uuid
    }
    this.successAccountService.resetPassword(request)
      .subscribe({
        next: () => {
          this.onPasswordChange.emit(request.password);
          this.form.reset();
          this.snackBar.open(`Password was Changed`, undefined, { duration: 4000, horizontalPosition: 'left', panelClass: 'snack-error' })
        }, 
        error: err => {
          let message = err.error?.error || 'Something went wrong when attempting to reset the password';
          this.snackBar.open(message, undefined, { duration: 4000, horizontalPosition: 'left', panelClass: 'snack-error' })
          console.error(err);
        } 
      })
  }

  suggestPassword(e: Event) {
    e.preventDefault();
    let pass = randomPassword();
    this.password?.setValue(pass);

    if (this.isPasswordChange && this.kind == 'owner') {
      this.confirmedPassword?.setValue(pass);
    }
  }

  togglePasswordVisibility() {
      this.passwordVisibility = this.passwordVisibility == 'password' ? 'text' : 'password';
  }

  get visibilityIcon() {
      return this.passwordVisibility == 'password' ? 'visibility_off' : 'visibility';
  }
}
