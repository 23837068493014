import { AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms'
import { ResourceEditComponent, ResourceFormService, ResourceViewComponent } from '@smartsoftware/reflex-core';

import {
  ClientMatterSet,
  ClientMatterSet_Service,
  User,
  User_Service,
  SuccessAccount_Service,
  SuccessAccountRoleName,
  SuccessAccountSettings,
  SuccessAccountSettingsService,
  Client,
  Client_Service,
  CommonData_Service,
  ClientMatterEntry,
  ClientMatterEntry_Service,
  ClientMatterFtpLog,
  ClientMatterFtpLog_Service,
  LookupConfiguration,
  ClientBranch,
  ClientBranch_Service,
  SuccessAccount,
  SystemConfig_service

} from 'legalreflex-lib';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { BreadCrumbService } from '../../../components/breadcrumb-bar/breadcrumb-service';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  templateUrl: './view.html',
  styleUrls: ['./view.scss']
})
export class ClientMatterView extends ResourceViewComponent<ClientMatterSet,ClientMatterSet_Service> implements OnInit, AfterViewInit {
  
  //public clients$: BehaviorSubject<Client[]> = new BehaviorSubject<Client[]>([]);
 // public ftp_CMS$: BehaviorSubject<ClientMatterSet[]> = new BehaviorSubject<ClientMatterSet[]>([])
  
  public clientMatterEntries$: BehaviorSubject<ClientMatterEntry[]> = new BehaviorSubject<ClientMatterEntry[]>([])
  public CME_data = new MatTableDataSource(this.clientMatterEntries$.value);

  public clientBranches$: BehaviorSubject<ClientBranch[]> = new BehaviorSubject<ClientBranch[]>([]);
  public clientBranches = new MatTableDataSource(this.clientBranches$.value);

  public successAccounts$: BehaviorSubject<SuccessAccount[]> = new BehaviorSubject<SuccessAccount[]>([]);
  public successAccounts = new MatTableDataSource(this.successAccounts$.value);

  //public clientMap = new Map();
 // public clientLookUpConfig: LookupConfiguration<Client>;

  @ViewChild('CME') paginator!: MatPaginator;
  public accountPaginator!: MatPaginator;

  @ViewChildren("account")
  public accountPaginatorList!: QueryList<MatPaginator>;

  constructor(
    protected successAccountService: SuccessAccount_Service,
    protected userService: User_Service,
    protected entityService: ClientMatterSet_Service,
    protected route: ActivatedRoute,
    protected auth: OidcSecurityService,
    protected dialog: MatDialog,
    protected resourceFormService: ResourceFormService<ClientMatterSet>,
    protected clientService: Client_Service,
    protected commonDataService: CommonData_Service,
    protected clientMatterEntryService: ClientMatterEntry_Service,
    protected clientBranchService: ClientBranch_Service,
    protected breadCrumbService: BreadCrumbService,
    public sysConfig: SystemConfig_service

  ) {
    super(entityService, route, dialog);
    //this.clientLookUpConfig = clientService.makeLookupConfig();
  }

  public fetchClientBranches(entity: ClientMatterSet) {
    this.clientBranchService.getByDefaultClientMatterSetId(entity.uuid).subscribe(branches => {
        this.clientBranches$.next(branches);
        this.clientBranches.data = this.clientBranches$.value;
      })
  }
  
  public fetchAccounts(entity: ClientMatterSet) {
    this.successAccountService.getByDefaultClientMatterSetId(entity.uuid).subscribe(accounts => {
      this.successAccounts$.next(accounts);
      this.successAccounts.data = this.successAccounts$.value;
    })
  }

  public fetch_ClientMatterEntries(entity: ClientMatterSet){
    if(!entity || (entity && !entity.uuid)) {
      this.clientMatterEntries$.next([]);
      return;
    }

    this.clientMatterEntryService.list({
      reloadFresh: true,
      where: {
        filters: {
          clientMatterSet_uuid: {
            value: entity.uuid,
            operator: '='
          }
        }
      }
    }).subscribe(items => {
      this.clientMatterEntries$.next(items);
      this.CME_data.data = this.clientMatterEntries$.value;
    });
  }
   


   
  public fetch_RelatedData(e: ClientMatterSet){
    this.fetch_ClientMatterEntries(e);
    this.fetchClientBranches(e);
    this.fetchAccounts(e);
  }

  ngOnInit(): void {

    super.ngOnInit();

    if(this.sysConfig.maintenanceRedirectCheck()){  
      window.localStorage.removeItem("loggedInUserPermissionNodes");
      this.auth.logoff();
    };

    this.breadCrumbService.breadcrumbs.next([
      { label: 'Client Matters', url: '/clientMatter'},
      { label: 'Loading' }
    ]);

    this.entityUpdated.subscribe((entity) => {
      this.breadCrumbService.breadcrumbs.next([
        { label: 'Client Matters', url: '/clientMatter'},
        { label: this.entity.name }
      ]);
    
      this.breadCrumbService.actions.next([]);
      this.fetch_RelatedData(this.entity);
    });
  }

  ngAfterViewInit() {
    this.accountPaginatorList.changes.subscribe((paginators: QueryList <MatPaginator>) =>
    {
        this.accountPaginator = paginators.first;
        this.successAccounts.paginator = this.accountPaginator;
    });
    
    this.CME_data.paginator = this.paginator;

    super.ngAfterViewInit();
  }
}
