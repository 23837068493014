import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { Billing } from '../models';

@Injectable()
export class Billing_Service extends ResourceService<Billing> {
  protected servicePath: string = '/Billing';

  public ModelType = Billing;

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }
}