


import { Injectable } from "@angular/core";
import { ResourceService } from "@smartsoftware/reflex-core";
import { SystemConfig } from "../models/systemConfig.model";
import { HttpClient, HttpParams } from '@angular/common/http'
import { BehaviorSubject, Observable } from "rxjs";
import { tap, map } from "rxjs/operators";
import { Page, PageParams, Pager } from '../paged.datasource';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Router } from '@angular/router';
import { CheckForUpdateService } from "./checkForUpdate.service"; // TEMP FIX


@Injectable()
export class SystemConfig_service extends ResourceService<SystemConfig> implements Pager<SystemConfig>
{
    protected servicePath: string = '/SystemConfig';
    public ModelType = SystemConfig;
    private _sysConfigList: any = [];
    public sysConfigList: BehaviorSubject<SystemConfig[]> = new BehaviorSubject(this._sysConfigList);

    get sysConfigList$() {
        return this._sysConfigList;
    }

    set sysConfigList$(sysConfigList: SystemConfig[])
    {
        this._sysConfigList = sysConfigList;
        this.sysConfigList.next(sysConfigList);
    }
    
    constructor( 
        public auth: OidcSecurityService,
        private serviceWorker: CheckForUpdateService, // TEMP FIX
        public router: Router,
        protected http: HttpClient    
    ) {
        super(http);

        this.serviceWorker.checkForUpdates(); // TEMP FIX
    }

    public getSysConfigListObservable ()
    {
        return this.http.get<SystemConfig[]>(this.serviceUrl).pipe(tap((res => this.sysConfigList.next(res))));
    }

    public getMaintenanceMode(keyVal: string): boolean | string | null | undefined 
    {
        let config: SystemConfig[] = this.sysConfigList.value;
        // console.log("getMaintenanceMode\n", config);
        // console.log(`k:${keyVal}|\n`);
        let cur = config.find(r => r.key == keyVal)
        // console.log(`k:${keyVal} ||\n`, cur);
        if(cur == undefined)
            return undefined;
        if(cur.valueType?.toLowerCase() == 'boolean')
        {
            if(cur.value?.toLowerCase() == 'true')
                return true;
            if(cur.value?.toLowerCase() == 'false')
                return false
        }
        if(cur.valueType?.toLowerCase() == 'text')
            return cur.value;
        return null;
    };

    public maintenanceRedirectCheck(): boolean
    {
        this.sysConfigList.subscribe((config: SystemConfig[]) =>
            {
                if(this.getMaintenanceMode('isInMaintenanceMode'))
                {        
                    // window.localStorage.removeItem("loggedInUserPermissionNodes");
                    // this.auth.logoffLocal();
                    // this.router.navigate(['/']);
                    console.log("RedirectCheck : systemConfig is in maintainence mode.");
                }
                else
                    console.log("RedirectCheck : systemConfig is not in maintainence mode.");
            }
        )
        if(this.sysConfigList.value && this.getMaintenanceMode('isInMaintenanceMode')){
            return true
        }else{
            return false
        }
    }

    public updateSysConfig(val: SystemConfig) {
        return this.http.put<SystemConfig>(`${this.serviceUrl}${val.uuid}`, val);
        // for(let a of val)
        //     console.log(a); 
        // for(let sC of val)
        // {
        //     // console.log("usc", `${this.serviceUrl}updateConfigs`);
        //     console.log("Attempting put with: ", sC.uuid);
        //     this.http.put<SystemConfig>(`${this.serviceUrl}updateConfigs`, sC);
        //     // console.log("Attempt completed.")
        // }

    }

    page(pageParams: PageParams): Observable<Page<SystemConfig>> {
        // console.log("pageParams:\n", pageParams, 'adjusted', params);
        let params = new HttpParams()
            .append('params', btoa(JSON.stringify(pageParams)));
        return this.http.get<Page<SystemConfig>>(`${this.serviceUrl}page`, { params })
            .pipe(map(page => {
                page.items = page.items.map(i => new SystemConfig(i));
                return page;

                // let singlePage: any;
                // singlePage = page;
                // singlePage.items = page;
                // return singlePage;
            }));
    }

}