<h1 mat-dialog-title>Invalid Document Type</h1>
<div style="display:inline-block" mat-dialog-content>
    We are unable to process the selected file type through our portal.<br>
    Please upload only {{data.types}} type files or contact our Client Care Team for assistance at 
    <a href="mailto:clientcare@firstlegal.com">clientcare@firstlegal.com</a>.
</div>
<div mat-dialog-actions>
  <button mat-raised-button mat-dialog-close>Close</button>
  <button color='primary' mat-raised-button  [mat-dialog-close]="true">Save As Draft</button>
</div>
