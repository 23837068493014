import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class ClientType extends DexResourceModel {

	@Field(String)
	public name: string = "";

	constructor(data?: any) {
		super();
		this.mapData(data);
	}

}
