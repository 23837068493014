import { Component, Inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
    templateUrl: './frame.html',
    styleUrls: ['./frame.scss']
})
export class CaseFrame {

    constructor(
        public pageTitleService: Title
    ) {
        this.pageTitleService.setTitle("FirstConnect - Case");
    }


}
