import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { CaseContact } from '../models/caseContact.model';
import { Observable } from 'rxjs';

@Injectable()
export class CaseContact_Service extends ResourceService<CaseContact> {
    protected servicePath: string = '/CaseContact';

    public ModelType = CaseContact;

    public push(entry: CaseContact): Observable<CaseContact> {
               
        return super.push(entry);
    }

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }
}
