
<div class="wrap">
    <mat-card>
        <mat-card-title>
            Authentication Error
        </mat-card-title>
        <mat-card-content>
            <p>Something went wrong when attempting to authenticate with your credentials.</p>

            <div *ngIf="!isFLNAPIerror">
                <p>The issue could either be:</p>
                <ul>
                    <li>The credentials provided are incorrect</li>
                    <li>The account associated with the credentials has been deactivated</li>
                    <li>The authentication server may be experiencing some issues</li>
                </ul>
            </div>

            <div *ngIf="isFLNAPIerror">
                <p>FLNAPI Error: {{errorMessage}}</p>
                <p>{{serviceReported}}</p>
            </div>
            <p>Please <a href="mailto:clientcare@firstlegal.com">contact support</a> if the issue persists, or click the <a (click)="retry()">retry</a> button to try again.</p>
            <button mat-flat-button color="primary" (click)="retry()">Retry Login</button>
        </mat-card-content>
    </mat-card>
</div>
