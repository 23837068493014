import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class CaseContact extends DexResourceModel {

    @Field(String)
    public case_uuid: string = "";
    @Field(String)
    public contact_uuid: string = "";

    @Field(String)
    public contactType: string = "";

    @Field(String)
    public caseName: string = "";

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}
