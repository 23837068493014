import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavigationModule, ResourceModule, AuthGuardService } from '@smartsoftware/reflex-core';
import { AutoLoginPartialRoutesGuard as AuthGuard } from 'angular-auth-oidc-client';
import { AestheticsModule } from '../../aesthetics/aesthetics.module';
import { ComponentsModule } from '../../components/components.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LegalReflexLibModule, LegalReflexDirectivesModule } from 'legalreflex-lib';
import { CaseList } from './list/list';
import { CaseView } from './view/view';
import { CaseFrame } from './frame/frame';
import { CaseRestoreDialog } from './restore/caseRestoreDialog';
import { userDeletePopupDialog } from './view/userDelete';
import { ParticipantEditPopupDialog } from './participantEdit/participantEdit'; 
import { MissingCasePopupDialog } from './view/missingCase';
const routes: Routes = [
    {
        path: 'case',
        component: CaseFrame,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: CaseList
            },
            {
                path: ':id',
                component: CaseView
            }
        ]
    }
];

@NgModule({
    declarations: [
        CaseFrame,
        CaseList,
        CaseView,
        CaseRestoreDialog,
        userDeletePopupDialog,
        ParticipantEditPopupDialog,
        MissingCasePopupDialog
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AestheticsModule,
        ComponentsModule,
        ResourceModule,
        BrowserModule,
        BrowserAnimationsModule,
        MatTooltipModule,
        RouterModule,
        LegalReflexLibModule,
        LegalReflexDirectivesModule,
        RouterModule.forChild(routes)
    ],
    exports: [ 

    ],
    bootstrap: [

    ]
})
export class CaseModule { }
