import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class SuccessState extends DexResourceModel {

    @Field(String)
    public country_uuid: string = "";
    @Field(String)
    public stateName: string = "";
    @Field(String)
    public stateAbbreviation: string = "";
    @Field(Number)
    public FIPSCode: number = 0;
    @Field(Number)
    public zipCodeDatatrac: number = 0;

    @Field(Boolean)
    public isEFileAvailable: boolean = false;

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}
