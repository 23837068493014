import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class ClientMatterEntry extends DexResourceModel {

    @Field(String)
    public clientMatterSet_uuid: string | null = null;
    
    @Field(String)
    public name: string = "";
    
    @Field(String)
    public matter: string = "";

    @Field(Boolean)
    public excludedFromSync: boolean = false;

    @Field(Boolean)
    public autoPopulate: boolean = false;

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}
