import { Component, Inject, Input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { CommonData_Service, SuccessContactWithType } from 'legalreflex-lib';

@Component({
    selector: 'ParticipantEdit-dialog',
    templateUrl: 'participantEdit.html',
    styleUrls: ['./participantEdit.scss']
})  
export class ParticipantEditPopupDialog {

    @Input('firstNameCtrl')
    public firstNameCtrl = new FormControl(this.data.firstName)

    @Input('lastNameCtrl')
    public lastNameCtrl = new FormControl(this.data.lastName)

    @Input('phoneCtrl')
    public phoneCtrl = new FormControl(this.data.phone)

    @Input('emailCtrl')
    public emailCtrl = new FormControl(this.data.email, Validators.email)

    @Input('addressCtrl')
    public addressCtrl = new FormControl(this.data.address)

    @Input('cityCtrl')
    public cityCtrl = new FormControl(this.data.city)

    @Input('stateCtrl')
    public stateCtrl = new FormControl(this.data.state)

    @Input('zipCtrl')
    public zipCtrl = new FormControl(this.data.zip)
    
    @Input('companyNameCtrl')
    public companyNameCtrl = new FormControl(this.data.companyName)

    @Input('barIdCtrl')
    public barIdCtrl = new FormControl(this.data.barId)

    @Input('isOrganizationCtrl')
    public isOrganizationCtrl = new FormControl(this.data.isOrganization)

    @Input('contactTypeCtrl')
    public contactTypeCtrl = new FormControl(this.data.contactType)
    
    public participantCtrls = new FormGroup({
        firstName: this.firstNameCtrl, lastName:this.lastNameCtrl, phone:this.phoneCtrl, 
        email:this.emailCtrl, address:this.addressCtrl, city:this.cityCtrl, 
        state:this.stateCtrl, zip:this.zipCtrl, companyName:this.companyNameCtrl, 
        barId:this.barIdCtrl, isOrganization:this.isOrganizationCtrl, contactType:this.contactTypeCtrl
    })

    public isFormComplete: boolean = false
    public participant: SuccessContactWithType | null = null

    constructor(
        private dialogRef : MatDialogRef<ParticipantEditPopupDialog>,
        public locationService: CommonData_Service,
        
        @Inject(MAT_DIALOG_DATA) 
        public data: SuccessContactWithType
    ) {
        if(this.isOrganizationCtrl.value != false && this.isOrganizationCtrl.value != true)
            this.isOrganizationCtrl.setValue(false)
    }

    close() {
        return this.dialogRef.close()
    }

    OrgSwitch(){
        if(this.isOrganizationCtrl.value && this.contactTypeCtrl.value == 'attorney')
            this.contactTypeCtrl.reset()
        this.checkFields()
    }

    checkFields(){
        
        if(this.isOrganizationCtrl.value){
            this.firstNameCtrl.setErrors(null)
            this.lastNameCtrl.setErrors(null)
        }

        if(this.contactTypeCtrl.value == 'attorney'){
            this.companyNameCtrl.setErrors(null)
        }else{
            this.barIdCtrl.setErrors(null)
        }

        this.isFormComplete = this.participantCtrls.valid
        return this.participantCtrls.valid
    }

    saveParticipant(){
        if(!this.checkFields())
            return
        
        if(this.isOrganizationCtrl.value){
            this.firstNameCtrl.setValue(undefined)
            this.lastNameCtrl.setValue(undefined)
        }

        if(this.contactTypeCtrl.value == 'attorney'){
            this.companyNameCtrl.setValue(undefined)
        }else{
            this.barIdCtrl.setValue(undefined)
        }
        this.data = Object.assign(this.data, this.participantCtrls.value)
        this.dialogRef.close(this.data);
    }
    
}

