import { HttpBackend, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Document, Order, OrderServiceAffidavit, SuccessAccount } from '../../models';
import { AdminOrder_Service, Document_Service, SuccessAccount_Service, OrderServiceAffidavit_Service } from '../../services';
import { BehaviorSubject, Observable, Subject, combineLatest, of, throwError } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import { FC3_DATETIME_FORMAT, LookupConfiguration, ReflexDownloadFile, ReflexDownloadGroup, ReflexDownloaderService } from '../..';
import { AbstractControl, EmailValidator, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { InvalidDocumentDialog } from './invalidDocument';
import { MatDialog } from '@angular/material/dialog';
import { DocumentNameChangedDialog } from './documentNameChanged';

type UploadError<A, B> = { reason: A, file: B };

@Component({
  selector: 'lib-order-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class OrderDocumentsComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild("documents") matSort: MatSort = new MatSort();
  @ViewChild("affidavit") affidavitSort: MatSort = new MatSort();

  @Input()
  public cc: boolean = true;
  @Input()
  public order: Order | undefined;
  @Input()
  public documents: Document[] = [];
  @Input()
  public affidavits: OrderServiceAffidavit[] = [];
  @Input()
  public showUploadControls: boolean = true;
  @Input()
  public showAs: 'list' | 'table' = 'table';

  @Input()
  public showDeleteOption: boolean = false;
  
  @Input()
  public allowBatchDownloading: boolean = false;


  // Blob Type | Extension
  public acceptedFileTypes: string[][] = [
    ['application/pdf','PDF'],
    ['application/vnd.openxmlformats-officedocument.wordprocessingml.document','DOCX'],
    ['application/msword','DOC']
  ];

  newDocuments: Document[] = [];
  emails: string[] = [];
  emailControl: FormControl = new FormControl('',[Validators.email]);
  messageControl: FormControl = new FormControl('');
  // the files that are selected by the user
  files: File[] = [];
  public fileNames: Set<string> = new Set<string>();

  tick = new BehaviorSubject<number>(0);
  uploading: boolean = false;
  DATE_FORMAT=FC3_DATETIME_FORMAT;

  // accumulation of errors encountered during the upload process
  errors: UploadError<any, File>[] = [];

  // keeps track of the mapping between the user selected `File` and database `Document` model
  // during upload process.
  uploadingFiles: Map<File, Document> = new Map();

  public downloadAll() {
    let docGroup = new ReflexDownloadGroup('Documents',true,'FirstLegalDocuments');
        let observables : Observable<object>[] = [];
        this.documents.map((doc)=>{
            observables.push(this.documentService.getDocumentFile(doc)
            .pipe(
                catchError(err => {
                    let message = 'Error with document ' + doc.fileName + ":" + err.error.internalError;
                    this.snackBar.open(message, undefined, { duration: 4000, verticalPosition: 'bottom', horizontalPosition: 'left'});
                    throw err;
                })
            ));
        });
        combineLatest(observables).subscribe((result)=>{
            result.filter((fileInfo: any) => fileInfo.response).map((fileInfo:any)=>{
                if(!this.downloadURLs.get(fileInfo.document.uuid))
                    this.downloadURLs.set(fileInfo.document.uuid, fileInfo.response.signedUrl);
                let url = encodeURI('https://flproddata.s3.us-west-2.amazonaws.com/' + fileInfo.response.path);
                let file = new ReflexDownloadFile(url, this.downloadURLs.size + fileInfo.document.fileName , fileInfo.document.fileName);
                //file.body = new Blob([Buffer.from(fileInfo.response.file.data)], { type: fileInfo.document.fileType});
                docGroup.files.push(file);
            })
            docGroup.downloadStatus.next('DONE');
             this.reflexDownloadService.requestGroupDownload(docGroup)
        });

  }
  public get now() : Date {
    return new Date();
  }

  get progress() {
    if (!this.files.length || !this.uploading)
      return null;
    let result = (this.tick.value / this.files.length) * 100;
    return result;
  }

  // maps a document uuid to download url
  public downloadURLs: Map<string, string> = new Map();

  columns: string[] = ['actions',  'fileName',  'status', 'fileType', 'createdAt' ];
  dataSource: MatTableDataSource<Document> = new MatTableDataSource<Document>([]);
  affidavitSource: MatTableDataSource<OrderServiceAffidavit> = new MatTableDataSource<OrderServiceAffidavit>([]);
  private http: HttpClient;

  constructor(
    private documentService: Document_Service,
    private orderService: AdminOrder_Service,
    private successAccountService: SuccessAccount_Service,
    private httpBackend: HttpBackend,
    private snackBar: MatSnackBar,
    private reflexDownloadService: ReflexDownloaderService,
    private orderServiceAffidavitService: OrderServiceAffidavit_Service,
    private dialog: MatDialog) {
      this.http = new HttpClient(httpBackend);
  }
  
  ngAfterViewInit(): void {
    this.dataSource.sort = this.matSort;
    this.affidavitSource.sort = this.affidavitSort;
  }

  sortData(dataSource:MatTableDataSource<any> | null, event:any){
    switch (dataSource){
      case this.dataSource:
        this.dataSource.data = this.dataSource.sortData(this.dataSource.data, this.matSort);
        break;
      case this.affidavitSource:
        this.affidavitSource.data = this.affidavitSource.sortData(this.affidavitSource.data, this.affidavitSort);
        break;
      default:
        break;
    }
  }

  updateFileNames() {
    let joined = this.dataSource.data.filter(d => d.fileName).map(d => d.fileName || '').concat(this.files.map(f => f.name));
    this.fileNames = new Set<string>([...joined]);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if ('documents' in changes && changes['documents'].currentValue) {
      this.dataSource.data = changes['documents'].currentValue;
      this.updateFileNames();
    }
    if ('affidavits' in changes && changes['affidavits'].currentValue) {
      this.affidavitSource.data = changes['affidavits'].currentValue;
    }
  }

  ngOnInit(): void {
    if (this.showDeleteOption) {
        this.columns = [ 'delete', 'actions',  'fileName',  'status', 'fileType', 'createdAt' ];
    }
    let unique = (control: AbstractControl): ValidationErrors|null => {
      if (!control || !control.enabled)
        return null;
      let found = this.emails.find((email)=> email == this.emailControl.value)
      if (!found)
        return null;
      return {'unique':true};
    }
    this.emailControl.setValidators([unique, Validators.email])
    this.emailControl.updateValueAndValidity()
  }

  formatError(e: any) {
    let message = '';
    if (typeof e === 'string')
      message = e;
    else if (e instanceof HttpErrorResponse) {
      message = e.statusText;
      if (e.status == 0) {
        message = "The server rejected your request without reason. This is most likely due to invalid CORS configuration.";
      }
    }
    return message;
  }

  public onFileChange(event: any): void {
    let files: Array<File> = Array.from(event.target.files);
    this.files = files;
    this.updateFileNames();
  }

  public replaceOnFileChange(event: any, document: Document) {
    let files: Array<File> = Array.from(event.target.files);
    if (files.length != 1) {
      return;
    }

    let error = true;
    this.acceptedFileTypes.map((type)=>{
      if(files[0].type == type[0])
        error = false;
    })
    if(error) {
      let formats = this.acceptedFileTypes.map((type)=>{return "'" + type[1] + "'"});
      formats[formats.length - 1] = 'or ' + formats[formats.length - 1];
      this.dialog.open(InvalidDocumentDialog, {
          data: {types: formats.join(', '), invalidType: files[0].type}
      }).afterClosed().subscribe((result)=>{return;})
    } else {
        let file = files[0];
        let uniqueFileName = this.getUniqueFileName(file.name);
        let changedFileName: boolean = false;
        if (uniqueFileName !== file.name) {
            changedFileName = true;

            files[0] = new File([file], uniqueFileName, {type: file.type});
            this.fileNames.add(uniqueFileName);
            this.dialog.open(DocumentNameChangedDialog, {
                data: {changedFileNames: [file.name], newFileNames: [uniqueFileName]}
            });
        }
        
        let observables = files.map(file => { 
            return this.replace(file, document).pipe(
            catchError(error => {
                throw { reason:error, file } as UploadError<any, File>;
            })
            );
        });

        this.doUpload(observables);
    }
  }

  uploadFiles() {
    let observables = this.files.map(file => { 
        return this.upload(file).pipe(
          catchError(error => {
            throw { reason:error, file } as UploadError<any, File>;
          })
        );
    });
    this.doUpload(observables);
  }

  emailPush(){
    let index = this.emails.find((email)=> email == this.emailControl.value)
    if(!index && this.emailControl.errors == null){
      this.emails.push(this.emailControl.value)
      this.emailControl.reset()
    }
  }

  removeEmail(removed:string){
    let index = this.emails.findIndex((email)=> email == removed)
    this.emails.splice(index,1)
  }

  uploadReset(total:number, errors:number) {
    this.uploading = false;
    let message = `${total - errors} of ${total} files added successfully`;
    this.uploadingFiles.clear();
    this.files = [];
    // this.dataSource.data = this.dataSource.data.slice(); // trigger table row rendering
    this.dataSource.data = this.documents;
    this.updateFileNames();
    this.snackBar.open(message, undefined, { duration: 4000, horizontalPosition: 'left'});

  }

  doUpload(observables: Observable<any>[]) {
    this.uploading = true;
    let errors: UploadError<any, File>[] = this.errors = [];
    let progTick = this.tick;
    let tick = new Subject<number>();// this.tick;// new Subject<number>();
    let count = 0;
    let stop = false;
    progTick.next(count);
    tick.next(count);

    let done = tick.pipe(
      filter(completed => completed == observables.length)
    );
    
    done.subscribe({
      next: total => {
        if(this.order && this.emails.length > 0 && count == observables.length ){
          let val = document.getElementById('httpActivityBar');
          val?.setAttribute('mode','indeterminate');
          this.newDocuments = this.documents.filter((doc)=> doc.isRelayed == false)
          console.log("All Documents:", this.documents, "New Documents:", this.newDocuments);
          this.orderService.manualDocumentNotice(this.order, this.newDocuments, this.emails, this.messageControl.value)
            .subscribe((result)=>{
              this.newDocuments.map((doc)=> {doc.isRelayed = true});
              this.newDocuments = [];
              this.documents.map((doc)=> {doc.isRelayed = true});
              this.uploadReset(total, errors.length);
              val?.setAttribute('mode','determinate');
            })
        } else{
          this.newDocuments.map((doc)=> {doc.isRelayed = true});
          this.newDocuments = [];
          this.documents.map((doc)=> {doc.isRelayed = true});
          this.uploadReset(total, errors.length)
        }
      }
    });

   for (let o of observables) {
      o.subscribe({
        next: res => {
          tick.next(++count);
          progTick.next(count);
        },
        error: (uploadError: UploadError<any, File>) => {
          if (uploadError.reason instanceof HttpErrorResponse) {
            let reason = uploadError.reason as HttpErrorResponse;
            // identify if it's the s3 upload request 
            if (reason.url?.includes('AWSAccessKeyId')) {
              let doc = this.uploadingFiles.get(uploadError.file);
              if (doc) {
                doc.deletedAt = new Date();
                this.orderService.updateDocument(this.order as Order, doc).subscribe();
                // this.dataSource.data = this.dataSource.data.filter(d => d != doc);
                this.dataSource.data = this.documents;
                this.updateFileNames();
              }
            }
          }
          errors.push(uploadError);
          tick.next(++count);
          progTick.next(count);
        }
      });
   }
  }

  copyTo(file: File, document: Document) {
    let fileName = file.name;
    let parts = fileName.split('.');
    let name = fileName, fileType = '';

    if (parts.length > 1) {
      name = parts.slice(0, parts.length-1).join('.');
      fileType = parts[parts.length-1].toUpperCase();
    }
    document.name = name;
    document.fileName = fileName;
    document.vendorFileName = fileName;
    document.fileType = fileType;
  }

  replace(file: File, document: Document) {
    return this.uploadPipe(file, document)
      .pipe(tap(d => {
        this.downloadURLs.delete(d.uuid);
      }));
  }

  upload(file: File): Observable<Document> {
    let document = new Document({
      documentType: 'Return',
      status: 'Uploading',
    });
    return this.uploadPipe(file, document);
  }

  uploadPipe(file: File, document: Document): Observable<Document> {
    let order = this.order as Order;   
    if (!order)
      return throwError(() => new Error('No order'));

    this.copyTo(file, document);

    let isUpdate = !!document.uuid;

    let op = isUpdate
      ? this.orderService.updateDocument(order, document) 
      : this.orderService.addDocumentToOrder(order, document);

    let currentDoc = document;
    return op.pipe(
      // add document to data source for display
      tap(document => {
        currentDoc = document;
        this.uploadingFiles.set(file, currentDoc);
        if (!isUpdate) {
          this.documents.push(document);
          this.dataSource.data = this.documents;
          this.updateFileNames();
        }
      }),
      // get the signed url for adding file contents to s3 bucket
      switchMap(document => {
        // Issue with doc id being removed/missing during request to create/update
        currentDoc.id = document.id
        this.uploadingFiles.set(file, currentDoc);
        if (!isUpdate) {
          this.documents[this.documents.length-1].id = document.id
        }
        return this.orderService.getDocumentSignedURL(order.uuid, document.uuid, 'putObject');
      }),
      // upload the file contents to s3
      switchMap(url => {
        return this.http.put(url, file,
            {
                headers: {
                    "Content-Type": file.type
                },
                //reportProgress: true,
                responseType: 'json',
                observe: 'response'
            }
        );
      }),
      // update the document status 
      switchMap(_ => {
        currentDoc.docStatus = 'Done'; 
        this.uploadingFiles.delete(file);
        return this.orderService.updateDocument(order, currentDoc);
      })
    );
  }

  downloadURL(doc: Document) {
    if (this.downloadURLs.has(doc.uuid)) {
      window.open(this.downloadURLs.get(doc.uuid) as string, '_blank');
      return;
    } else {
        if( !doc.fileName) return;
        if (!this.order || !this.order.uuid) {
            this.documentService.getDocumentFile(doc)
                .pipe(
                    catchError(err => {
                        let message = 'Error with document ' + doc.fileName + ":" + err.error.internalError;
                        this.snackBar.open(message, undefined, { duration: 4000, verticalPosition: 'bottom', horizontalPosition: 'left'});
                        throw err;
                    })
                ).subscribe((fileInfo) => {
                    this.downloadURLs.set(doc.uuid, fileInfo.response.signedUrl);
                    window.open(fileInfo.response.signedUrl, '_blank');
                })
        } else {
            this.documentService.getDownloadUrl(this.order.uuid, doc.fileName)
                .subscribe((data)=>{
                    this.downloadURLs.set(doc.uuid, data);
                    window.open(data, '_blank');
            }, (error)=>{
                let docInfo = `${doc.fileName}`;
                let message = `Something went wrong getting the file contents for ${docInfo}. `;
                if (error instanceof HttpErrorResponse || error instanceof Error) {
                    message += error.message;
                } else if (typeof error === 'string') {
                    message += error;
                }
                this.snackBar.open(message, undefined, { duration: 4000, horizontalPosition: 'left'});
            });
        }
    }
  }

  async affidavitDownload(url: string){
    if(url.includes(`servemanager.com/api/v2`)){
      let index = url.indexOf('documents');
      let link = url.slice(index);
      this.orderServiceAffidavitService.getServeManagerDocuments(link).subscribe(async (res)=>{
          let data = res.body.data
          let buff = new Uint8Array(res.body.data);
          let blob = new Blob([buff.buffer], {type:'application/pdf'})
          console.debug("Blob vs ArrayBuffer:", blob, buff);
          let x = URL.createObjectURL(blob);
          window.open(URL.createObjectURL(blob))
          URL.revokeObjectURL(x);
      })
    }else{
      window.open(url, '_blank');
    }
  }
  // downloadURL(doc: Document) {
  //     if (this.downloadURLs.has(doc.uuid)) {
  //       window.open(this.downloadURLs.get(doc.uuid) as string, '_blank');
  //       return;
  //     }
  //     let orderId = this.order?.uuid;
  //     if (!orderId)
  //       return;
  //     this.orderService.getDocumentSignedURL(orderId, doc.uuid).pipe(
  //       tap(url => {
  //         this.downloadURLs.set(doc.uuid, url);
  //         window.open(url, '_blank');
  //       }),
  //       // switchMap(url => {
  //         // would need to add our domain in S3 bucket CORS config
  //       //   return this.http.get<File>(url);
  //       // }),
  //       // tap(file => {
  //       //   console.log('file', file);
  //       // })
  //     )
  //     .subscribe({
  //       error: e => {
  //         let docInfo = `${doc.fileName}`;
  //         let message = `Something went wrong getting the file contents for ${docInfo}. `;
  //         if (e instanceof HttpErrorResponse || e instanceof Error) {
  //           message += e.message;
  //         }
  //         else if (typeof e === 'string') {
  //           message += e;
  //         }
  //         this.snackBar.open(message, undefined, { duration: 4000, horizontalPosition: 'left'});
  //       }
  //     });
  // }

  deleteDocuments(order: Order | undefined, document_uuid: string, document: Document) {
        this.orderService.deleteByOrderIdAndDocumentId(order?.uuid || '', document_uuid).subscribe({
            next: () => {
              this.snackBar.open(`Successfully deleted Document ${document.name} `, undefined, { duration: 4000, horizontalPosition: 'left' })
              
              this.documents = this.documents.filter((document)=>{
                return document.id != document_uuid;
              });
              this.dataSource.data = this.documents;
              this.updateFileNames();
            },
            error: () => {
              this.snackBar.open(`Something went wrong while deleting Document  ${document.name} `, undefined, { duration: 4000, horizontalPosition: 'left' })
            }
        });
  }

  @ViewChild("fileUploader")
  fileDropRef: any;

  public onSelect(event: Event) {
    const element = event.target as HTMLInputElement;

    if(!element.files || element.files.length == 0) return;

    const files = element.files;

    let error = false;
    let newFiles : File[] = [];
    let changedFileNames: Set<string> = new Set<string>();
    let newFileNames: string[] = [];
    Array.from(files).forEach((file: File) => {
        let allowFile = false;
        this.acceptedFileTypes.map((type)=>{
            if(file.type == type[0])
                allowFile = true;
        })
        if(allowFile) {
            let uniqueFileName = this.getUniqueFileName(file.name);
                if (uniqueFileName !== file.name) {
                    changedFileNames.add(file.name);
                    newFileNames.push(uniqueFileName);

                    file = new File([file], uniqueFileName, {type: file.type});
                    this.fileNames.add(uniqueFileName);
                }
                this.fileNames.add(uniqueFileName);
            newFiles.push(file);
        } else if(!error) {
            error = true;
            newFiles = [];
            let formats = this.acceptedFileTypes.map((type)=>{return "'" + type[1] + "'"});
            formats[formats.length - 1] = 'or ' + formats[formats.length - 1];
            this.dialog.open(InvalidDocumentDialog, {
                data: {types: formats.join(', '), invalidType: file.type}
            }).afterClosed().subscribe((result)=>{
                return;
            })
        }
    });

    if (changedFileNames.size > 0) {
        this.dialog.open(DocumentNameChangedDialog, {
            data: {changedFileNames: [...changedFileNames], newFileNames}
        });
    }
    
    if(!error){
        this.files = this.files.concat(newFiles);
    }
    this.fileDropRef.nativeElement.value = '';
  }

  public removeFile(index: number) {
    let removed = this.files.splice(index,1);
    if (removed.length > 0) {
        this.fileNames.delete(removed[0].name);
    }
  }

  public formatBytes(bytes: number, decimals?: number) {
    if(bytes == 0) return '0 Bytes';
    var k = 1024,
      dm = decimals || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  public getUniqueFileName(fileName: string) {
    if (!this.fileNames.has(fileName)) {
        return fileName;
    }

    let increment = 1;
    const name = fileName.substring(0, fileName.lastIndexOf('.'));
    const suffix = fileName.substring(fileName.lastIndexOf('.'));
    while (this.fileNames.has(`${name}-${increment}${suffix}`))
    {
        increment++;
    }

    return `${name}-${increment}${suffix}`;
    }
}

