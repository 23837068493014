import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class Project extends DexResourceModel {

    @Field(Date)
    public publishedAt: null | Date = new Date();

    //@Field(String)
    //public warehouse_uuid: string | null = null;
    // Remove?
    @Field(Number)
    public project_hid: number = 0;

    @Field(String)
    public preparedBy_userId: string | null = null;

    @Field(String)
    public client_uuid: string | null = null;

    @Field(String)
    public case_uuid: string | null = null;

    @Field(String)
    public project_status_uuid: string | null = null;

    @Field(String)
    public project_name: string = "";

    @Field(String)
    public project_description: string = "";

    @Field(String)
    public project_type_uuid: string | null = null;

    @Field(String)
    public project_originatingPerson: string = "";

    @Field(String)
    public project_feedback: string = "";

    @Field(String)
    public billedTo: string = "client";

    @Field(String)
    public contact_name: string = "";

    @Field(String)
    public contact_company: string = "";

    @Field(String)
    public contact_address1: string = "";

    @Field(String)
    public contact_address2: string = "";

    @Field(String)
    public contact_city: string = "";

    @Field(String)
    public contact_state: string = "CA";

    @Field(String)
    public contact_postal: string = "";

    @Field(String)
    public billing_rateTemplate_uuid: string | null = null;

    @Field(String)
    public billing_frequency: string = "monthly";

    @Field(String)
    public claim_number: string = "";

    @Field(String)
    public claim_adjuster: string = "";

    @Field(String)
    public claim_info: string = "";

    @Field(String)
    public leadAttorney_contact_uuid: string | null = null;

    @Field(String)
    public projectOwner_user_uuid: string | null = null;

    @Field(String)
    public invoiceTerm_uuid: string | null = null;

    @Field(String)
    public statementTo: string = "";

    @Field(String)
    public statementsEmailList: string = "";

    @Field(String)
    public invoiceTo: string = "";

    @Field(String)
    public invoicesEmailList: string = "";

    @Field(String)
    public fileFolderName: string = "";

    @Field(String)
    public insurance_client_uuid: string | null = null;

    @Field(Boolean)
    public sameAsFirm: boolean = false;

    @Field(Boolean)
    public ledToProjectWork: boolean = false;

    @Field(Date)
    public opportunityDate: Date | null = null;

    @Field(Date)
    public activeDate: Date | null = null;

    @Field(Date)
    public closedDate: Date | null = null;

    @Field(Date)
    public openDate: Date | null = null;

    @Field(String)
    public partnerVendor_uuid: string | null = null;

    @Field(String)
    public contact_uuid: string | null = null;

    @Field(Number)
    public project_id?: number | null = null;

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

    public get data(): any {
        let json = super.data;

        json.project_id = this.project_id;

        return json;
    }
}
