import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class Note extends DexResourceModel {

    @Field(String)
    public notes: string = "";

    public connection_uuid: string | null = null;

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}
