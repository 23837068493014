import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { Note } from '../models/note.model';

@Injectable()
export class Note_Service extends ResourceService<Note> {
    protected servicePath: string = '/Note';

    public ModelType = Note;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }
}
