import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ResourceService } from "@smartsoftware/reflex-core";
import { VendorAccount } from "../models/vendorAccount.model";
import { Observable, ReplaySubject } from "rxjs";
import { map } from "rxjs/operators";
import { ReflexEnvironment as ENV } from "@smartsoftware/reflex-core";
import { SuccessAccount } from "../models/successAccount.model";

@Injectable()
export class VendorAccount_Service extends ResourceService<VendorAccount> {
    protected servicePath =  '/VendorAccount';

    public ModelType = VendorAccount;

    private _otherVendorData: Array<any> = [];
    get getOtherVendorData(): Array<any> {
        return this._otherVendorData;
    }
    set setOtherVendorData(data: Array<any>) {
        this._otherVendorData = data;
        this.otherVendorData.next(this._otherVendorData);
    }
 
    public otherVendorData: ReplaySubject<Array<any>> = new ReplaySubject(1);
    
    constructor(
        protected http: HttpClient
    ){
        super(http);
    }

    public push(entry: VendorAccount): Observable<VendorAccount> {
        
        if(!entry.vendor_uuid) {
            entry.vendor_uuid = "";
        }

        if(!entry.successAccount_uuid) {
            entry.successAccount_uuid = "";
        }

        if(!entry.username) {
            entry.username = null;
        }

        if(!entry.email) {
            entry.email = null;
        }

        return super.push(entry);
    }

    public getServiceUrl(): string {
        return this.serviceUrl
    }

    public createVendorAccounts(vendorAccounts: Array<VendorAccount>): Observable<Array<VendorAccount>> {
        return this
            .http
            .post(
                this.serviceUrl + 'createVendorAccounts',
                {
                    accounts: vendorAccounts
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => 
                        response.body.map((e: VendorAccount) => new VendorAccount(e))
                )
            );

    }

    public createVendorAccount(data: VendorAccount): Observable<Array<VendorAccount>> {
        // console.log('TFTEST new VendorAccount data', data);
        // console.log('TFTEST vendor_uuid', data.vendor_uuid);
        // console.log('TFTEST successAccount_uuid', data.successAccount_uuid);
        // console.log('TFTEST username', data.username);

        return this
            .http
            .post(
                this.serviceUrl + 'createVendorAccount',
                {
                    vendor_uuid: data.vendor_uuid,
                    successAccount_uuid: data.successAccount_uuid,
                    username: data.username
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => 
                        response.body.map((e: VendorAccount) => new VendorAccount(e))
                )
            );

    }

    public getVendorAccountsByUsernameAndVendor(data: {username: string, vendorName?: string}): Observable<Array<VendorAccount>> {
        return this
            .http
            .post(
                this.serviceUrl + 'getList',
                {
                    username: data.username,
                    vendorName: data.vendorName
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => 
                        response.body.map((e:VendorAccount) => new VendorAccount(e))
                )
            );
    }

    public getOtherVendorAccounts(vendorData: { email: string}) {
        Promise.all([
            this.getAcclaimAccount({email: vendorData.email}).toPromise(),
            this.getTristarAccount({email: vendorData.email}).toPromise()
        ]).then((responses: any) => {

            let otherVendorData: Array<any> = [];

            responses
                .filter((response: any) => {
                    if(!response.success) {
                        console.log('ERROR: Could not fetch vendor data');
                        // might need to handle this in some way 
                    } 

                    return response.success;
                })
                .map((response: any) => {
                    if(Array.isArray(response.data)) {
                        otherVendorData = otherVendorData.concat(response.data);
                    } else {
                        otherVendorData.push(response.data)
                    }
                })

            this.setOtherVendorData = otherVendorData;
        })
    }

    public getTristarAccount(tristarData: { email: string}) {
        return this
            .http
            .post(
                ENV.config['openapiUrl'] + '/successconnect/tristarAccount/get',
                {
                    email: tristarData.email
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => {
                        return response.body.result;
                    }
                )
            );
    }

    public getAcclaimAccount(acclaimData: { email: string }) {
        return this
            .http
            .post(
                ENV.config['openapiUrl'] + '/successconnect/acclaimAccount/get',
                {
                    email: acclaimData.email
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => {
                        return response.body.result;
                    }
                )
            );
    }

    /**
     * Replaces and/or updates all vendors associated with the given account with the provided vendors array
     * @param account 
     * @param vendors 
     * @returns 
     */
    public updateVendorAccounts(account: SuccessAccount, vendors: VendorAccount[]) {
        return this.http.post<VendorAccount[]>(`${this.serviceUrl}Update/${account.uuid}`, vendors);
    }

}