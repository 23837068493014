<resource-list-grid>
    <resource-list-pane title="Filter Entries" [collapsable]='true' [isCollapsed]="true" >
        <form *ngIf="filterFormGroup" (ngSubmit)="applyFilter($event)" [formGroup]="filterFormGroup">
            <mat-form-field>
                <input matInput
                    formControlName="name"
                    placeholder="Name" />
            </mat-form-field>
            <button class="d-block w-100" type="button" mat-button (click)="clearFilterForm();">
                Reset
            </button>
            <button class="d-block w-100" type="submit" mat-flat-button color="primary">
                Apply Filters
            </button>
        </form>
    </resource-list-pane>

    <resource-list-pane title="Client Matter Entries">

        <mat-table [dataSource]="dataSource" matSort>
            <ng-container cdkColumnDef="matter">
            <mat-header-cell mat-sort-header *cdkHeaderCellDef>Matter</mat-header-cell>
            <mat-cell *cdkCellDef="let entry">
                <span>{{entry.matter}}</span>
            </mat-cell> 
            </ng-container>

            <ng-container cdkColumnDef="name">
                <mat-header-cell mat-sort-header *cdkHeaderCellDef>Name</mat-header-cell>
                <mat-cell *cdkCellDef="let entry">
                <span>{{entry.name}}</span>
                </mat-cell> 
            </ng-container>

            <ng-container cdkColumnDef="updatedAt">
                <mat-header-cell mat-sort-header *cdkHeaderCellDef>Updated At</mat-header-cell>
                <mat-cell *cdkCellDef="let entry">
                <span>{{entry.updatedAtUTC | reflexTimeAgo}}</span>
                </mat-cell> 
            </ng-container>

        <mat-header-row *cdkHeaderRowDef="columns; sticky: true"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: columns"></mat-row>     
        </mat-table>
        
        <mat-paginator #CME [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons
        aria-label="Select page of Client Matter Entries">
        </mat-paginator>
    </resource-list-pane>
</resource-list-grid>