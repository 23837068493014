<div mat-dialog-title>
    <span>{{notificationSubject}}</span>
    <button mat-icon-button mat-dialog-close (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<h3>
    <span *ngIf="notificationTo!=null">To: {{notificationTo}} {{notificationCC}}<br></span>
</h3>
<div>
    <div id="content-parent" mat-dialog-content>
        <div id="content"></div>
    </div>
    <div mat-dialog-actions>
        <button type="button" color='primary' mat-raised-button (click)="close('resend')">Resend</button>
    </div>
</div>