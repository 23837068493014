<h1 mat-dialog-title>{{data.uuid ? "Editing Participant:" + data.firstName + " " + data.lastName : "Creating Participant"}}</h1>
<div mat-dialog-content>
    <form [formGroup]="participantCtrls">
            <mat-form-field>
                <mat-select
                required
                (selectionChange)="this.checkFields()"
                placeholder="Type" 
                value={{data.contactType}}
                [formControl]="contactTypeCtrl">
                    <mat-option *ngIf="isOrganizationCtrl.value!=true" value="attorney">Attorney</mat-option>
                    <mat-option value="plaintiff">Plaintiff</mat-option>
                    <mat-option value="defendant">Defendant</mat-option>
                </mat-select>
            </mat-form-field>&nbsp;
            <mat-button-toggle-group 
                [formControl]="isOrganizationCtrl"
                color="primary">
                <mat-button-toggle (click)="OrgSwitch()" [value]=true>Organization</mat-button-toggle>
                <mat-button-toggle (click)="OrgSwitch()" [value]=false>Non-Organization</mat-button-toggle>
            </mat-button-toggle-group>

        <div *ngIf="isOrganizationCtrl.value!=true">
            <mat-form-field>
                <input matInput
                [required]="isOrganizationCtrl.value!=true"
                (change)="this.checkFields()"
                placeholder="First Name" 
                [formControl]="firstNameCtrl"/>
            </mat-form-field>
            <mat-form-field>
                <input matInput
                [required]="isOrganizationCtrl.value!=true"
                (change)="this.checkFields()"
                placeholder="Last Name" 
                [formControl]="lastNameCtrl"/>
            </mat-form-field>
            <mat-form-field *ngIf="contactTypeCtrl.value == 'attorney'">
                <input matInput
                required
                (change)="this.checkFields()"
                placeholder="BarID" 
                value={{data.barId}}
                [formControl]="barIdCtrl"/>
            </mat-form-field>
        </div>

        <div>
            <mat-form-field *ngIf="contactTypeCtrl.value != 'attorney' || isOrganizationCtrl.value == true">
                <input matInput
                required
                (change)="this.checkFields()"
                placeholder="Company" 
                value={{data.companyName}}
                [formControl]="companyNameCtrl"/>
            </mat-form-field>
            <mat-form-field>
                <input matInput
                required
                (change)="this.checkFields()"
                placeholder="Email" 
                value={{data.email}}
                [formControl]="emailCtrl"/>
            </mat-form-field>
            <mat-form-field>
                <input matInput
                required
                (change)="this.checkFields()"
                placeholder="Phone" 
                value={{data.phone}}
                [formControl]="phoneCtrl"/>
            </mat-form-field>
        </div>

        <div>
            <mat-form-field style="width:100%;">
                <input matInput
                required
                (change)="this.checkFields()"
                placeholder="Address" 
                value={{data.address}}
                [formControl]="addressCtrl"/>
            </mat-form-field>
        </div>

        <div>
            <mat-form-field>
                <input matInput
                required
                (change)="this.checkFields()"
                placeholder="City" 
                value={{data.city}}
                [formControl]="cityCtrl"/>
            </mat-form-field>
            <mat-form-field>
                <mat-select
                placeholder="State"
                required
                (change)="this.checkFields()"
                value={{data.state}}
                [formControl]="stateCtrl">
                    <mat-option *ngFor="let state of locationService.states" value="{{state.abbreviation}}">{{state.name}}, {{state.abbreviation}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <input matInput
                required
                (change)="this.checkFields()"
                placeholder="Postal Code" 
                value={{data.zip}}
                [formControl]="zipCtrl"/>
            </mat-form-field>
        </div>
        <br>       
    </form>
 
</div>
<div mat-dialog-actions>
  <button mat-raised-button mat-dialog-close (click)="close()">Close</button>
  <div matTooltip="Please fill all fields" [matTooltipDisabled]="isFormComplete">
      <button mat-raised-button color='primary' (click)="saveParticipant()" [disabled]="!isFormComplete">Save</button>
  </div>
</div>
