import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'
import { ResourceService, ReflexEnvironment as ENV } from '@smartsoftware/reflex-core';
import { User } from '../models/user.model';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';

export const GUEST_USER: User = new User({
    displayName: 'Guest',
});

export enum RoleName {
    client_firm_admin = "client_firm_admin", client_office_admin = "client_office_admin", client_office_staff = "client_office_staff"
}

@Injectable()
export class User_Service extends ResourceService<User> {
    protected servicePath: string = '/User';
    public flapiUrl = ENV.config['flapiUrl'];

    private user$ = new BehaviorSubject<User>(GUEST_USER);

    /**
     * The current user
     */
    public get user(): Observable<User> {
        return this.user$.asObservable();
    }

    public ModelType = User;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }
    /**
     * 
     * @param action create_users, modify_users, modify_clients, read_clients, modify_projects, read_projects, etc
     * @returns 
     */
    // hasPermission(action: string): Observable<boolean> {
    //     // ignore action for now, and return this.user.isAdmin
    //     return this.user.pipe(map(user => user.isAdmin));
    // }
    // /**
    //  * 
    //  * @param role admin, customer service/support, billing/finance, etc
    //  */
    // hasRole(role: string): Observable<boolean>  {
    //     if (role != RoleName.office_admin)
    //         return of(false);
    //     return this.user.pipe(map(user => user.isAdmin));
    // }

    // setAuthenticatedUserByEmail(email: string): Observable<User> {
    //     return this.getByEmail(email).pipe(  
    //         tap((user: User) => {
    //             this.user$.next(user);
    //         })
    //     );
    // }

    // getByEmail(email: string): Observable<User> {
    //     return this.list({
    //         allowCache: false,
    //         where: {
    //             filters: {
    //                 email: {
    //                     value: email,
    //                     operator: '='
    //                 }
    //             }
    //         }
    //     }).pipe(map(users => {
    //         if (!users)
    //             return GUEST_USER;
    //         if (users.length != 1) {
    //             console.warn(`Found ${users.length} users with email ${email}`);
    //             return GUEST_USER;
    //         }
    //         return users[0];
    //     }))
    // }

    public getUserInfo(userLogin:string) {
        return this
            .http
            .post(
                ENV.config['openapiUrl']  + '/firstLegal/getUserInfo',
                {
                    userLogin: userLogin
                },
                {
                    observe: 'response'
                })
            .pipe(
                map(
                    (response: HttpResponse<any>) => {
                        return response.body;
                    }
                ));
    }
}