import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class Project_ConflictParty extends DexResourceModel {

	@Field(String)
	public name: string = "";

	@Field(String)
	public project_uuid : string = "";

	@Field(String)
	public relationshipToClient: string = "";

	@Field(String)
	public roleType: string = "";

	@Field(String)
	public info: string = "";

	constructor(data?: any) {
		super();
		this.mapData(data);
	}
}
