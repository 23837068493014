import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavigationModule, ResourceModule, AuthGuardService } from '@smartsoftware/reflex-core';
import { AestheticsModule } from '../../aesthetics/aesthetics.module';
import { ComponentsModule } from '../../components/components.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AutoLoginPartialRoutesGuard as AuthGuard } from 'angular-auth-oidc-client';
import { LegalReflexLibModule } from 'legalreflex-lib';
import { DocumentFrame } from './frame/frame';
import { DocumentList } from './list/list';
import { DocumentView } from './view/view';
const routes: Routes = [
    {
        path: 'document',
        component: DocumentFrame,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: DocumentList
            },
            {
                path: ':id',
                component: DocumentView
            }
        ]
    }
];

@NgModule({
    declarations: [
        DocumentFrame,
        DocumentList,
        DocumentView,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AestheticsModule,
        ComponentsModule,
        ResourceModule,
        BrowserModule,
        BrowserAnimationsModule,
        MatTooltipModule,
        RouterModule,
        LegalReflexLibModule,
        MatFormFieldModule,
        MatInputModule,
        RouterModule.forChild(routes)
    ],
    exports: [

    ],
    bootstrap: [

    ]
})
export class DocumentModule { }