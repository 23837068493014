import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { LRFile } from '../models';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class File_Service extends ResourceService<LRFile> {
    protected servicePath: string = '/LRFile';

    public ModelType = LRFile;

    public fetchFiles$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }

    public getCaseFiles(case_uuid: string) {
        return this
            .http
            .post(
                this.serviceUrl + 'case',
                {
                    case_uuid
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) =>
                        response.body.map((e: any) => new LRFile(e))
                )
            );
    }

    public getProjectFiles(project_uuid: string) {
        return this
            .http
            .post(
                this.serviceUrl + 'project',
                {
                    project_uuid
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) =>
                        response.body.map((e: any) => new LRFile(e))
                )
            );
    }
}
