import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {
  public isFLNAPIerror: boolean = false;
  public errorMessage: string = '';
  public serviceReported: string = '';

  constructor( 
    public oidcService: OidcSecurityService,
    public route: ActivatedRoute) { }

  ngOnInit(): void {
    // can't seem to figure out how to get the error message that is sent in the redirect uri
    // just going to just show a generic error message here.

    // this.oidcService.stsCallback$.subscribe(what => {
    //   this.what = what;
    // })
    // this.route.queryParams.pipe(
    //   filter(params => 'error' in params)
    // ).subscribe(params => {
    //     this.params = params;
    // })

    if(window.localStorage.getItem('error')) {
      this.errorMessage = window.localStorage.getItem('error') as string;
      this.errorMessage = decodeURI(this.errorMessage);
      console.debug('unauthorized: errorMessage:', this.errorMessage);

      this.errorMessage = this.errorMessage.slice(this.errorMessage.indexOf('&error_description='), this.errorMessage.indexOf('&state='))
      this.isFLNAPIerror = this.errorMessage.includes('FLNAPI');
      this.errorMessage = this.errorMessage.slice(this.errorMessage.indexOf(' '));
      if(this.errorMessage.indexOf('Service reported%3A') != -1) {
        this.serviceReported = this.errorMessage.slice(this.errorMessage.indexOf('Service reported%3A'));
        this.errorMessage = this.errorMessage.slice(0, this.errorMessage.indexOf('Service reported%3A'));
        this.serviceReported = this.serviceReported.replace('%3A', ':');
      }

      window.localStorage.removeItem('error');
    }
  }

  retry() {
    this.oidcService.authorize();
  }

}
