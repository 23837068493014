import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { ProjectBillingRate_TemplateItem } from '../models/projectBillingRate_templateItem.model';

@Injectable()
export class ProjectBillingRate_TemplateItem_Service extends ResourceService<ProjectBillingRate_TemplateItem> {
  protected servicePath: string = '/ProjectBillingRateTemplateItem';

  public ModelType = ProjectBillingRate_TemplateItem;

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }
}
