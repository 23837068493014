<h1 *ngIf="!auth.isAuthenticated()">Welcome to First Connect</h1>
<div class="row" *ngIf="!auth.isAuthenticated()">
	<div class="column">
		<p>FirstConnect is our Order Entry Portal for all of our Litigation Support Services.</p>
		<p>Our offerings include court filing, service of process, investigations, depositions, records retrieval, and messenger service. Our first-class team is available to give you concierge service or simply help support you along the way.</p>

		<div *ngIf="!maintenanceMode && !authAvailable">
			<h2>The authentication service is unavailable.<br>Please try again later.</h2>
		</div>
		<div *ngIf="!maintenanceMode && authAvailable">
			<h2>Login to get started</h2>
			<button mat-raised-button class="login-button" color="primary" (click)="login()">Login</button>
			<div class="pad-tb-20">
				<a routerLink="/forgot-password">I forgot my username/password</a>
			</div>
			
		</div>
		<div *ngIf="maintenanceMode">
			<h2>Under Maintenance</h2>
			<p>{{maintenanceModeMessage}}</p>
		</div>
		
	</div>
	<div class="column">
		<h2>First time here?</h2>
		<p>If you're an existing user of our Legacy FirstConnect portal, please login using your existing credentials. 
		
		<br><br>If you're new to FirstConnect, just click “Get Started,” and we'll quickly get you set up with your account credentials.</p>
		<a mat-raised-button href="https://www.firstlegal.com/get-started/" target="_blank">Get Started</a>

		<h2>Talk with a human being</h2>
		<p>Need help? We are happy to help you through the process at any stage.</p>

		<mat-list>
			<mat-list-item>
				<mat-icon>phone</mat-icon>
				<a href="tel:877.350.8698">877.350.8698</a>
			</mat-list-item>
			<mat-list-item>
				<mat-icon>help</mat-icon>
				<a href="mailto:clientcare@firstlegal.com">clientcare@firstlegal.com</a>
			</mat-list-item>
			<mat-list-item>
				<mat-icon>web</mat-icon>
				<a href="https://www.firstlegal.com">www.firstlegal.com</a>
			</mat-list-item>
		</mat-list>
	</div>
</div>


<div class="row twoThirds" *ngIf="auth.isAuthenticated()">

	<div class="column">
		<div class="widget">
			<button class="widgetAction" mat-flat-button [routerLink]="['/order/new']" >New Order <mat-icon>chevron_right</mat-icon></button>
			<h2>Draft Orders</h2>

			<div *ngIf="this.loadingOrders | async">Loading...</div>
			<div *ngIf="!(this.loadingOrders | async) && (drafts | async)?.length == 0">No Drafts Saved Yet</div>

			<mat-table [dataSource]="drafts" class="orderTable" *ngIf="!(this.loadingOrders | async) && !!(drafts | async)?.length">
				<mat-header-row *cdkHeaderRowDef="draftTableColumns; sticky: true"></mat-header-row>
				<mat-row *cdkRowDef="let entity; columns: draftTableColumns" class="entity-row"> </mat-row>

				<ng-container cdkColumnDef="detailAction">
					<mat-header-cell *cdkHeaderCellDef> </mat-header-cell>
					<mat-cell *cdkCellDef="let entity" class="icon-only">
						<button mat-icon-button [routerLink]="['/order', entity.id]" aria-label="View full order details">
							<mat-icon>list_alt</mat-icon>
						</button>
					</mat-cell>
				</ng-container>

				<ng-container cdkColumnDef="resumeAction">
					<mat-header-cell *cdkHeaderCellDef> </mat-header-cell>
					<mat-cell *cdkCellDef="let entity" [matTooltip]="dictionary.tooltip.dashboard_resumeDraftOrder">
						<button mat-icon-button [routerLink]="['/order/resume', entity.id]" *ngIf="checkDraftOwner(entity)" aria-label="Resume Draft Order">
							<mat-icon>play_arrow</mat-icon>
						</button>
					</mat-cell>
				</ng-container>

				<ng-container cdkColumnDef="orderNumber">
					<mat-header-cell *cdkHeaderCellDef>Matter Number</mat-header-cell>
					<mat-cell *cdkCellDef="let entity"><span>{{entity.clientMatterNumber}}</span></mat-cell>
				</ng-container>

				<ng-container cdkColumnDef="createdAt">
					<mat-header-cell *cdkHeaderCellDef>Saved Date</mat-header-cell>
					<mat-cell *cdkCellDef="let entity"><span>{{entity.createdAtUTC | reflexTimeAgo}}</span></mat-cell>
				</ng-container>

				<ng-container cdkColumnDef="orderStatus">
					<mat-header-cell *cdkHeaderCellDef>Status</mat-header-cell>
					<mat-cell *cdkCellDef="let entity"><span>{{entity.orderStatus}}</span></mat-cell>
				</ng-container>
				
			</mat-table>
		</div>
		<div class="widget">
			<button class="widgetAction" mat-flat-button [routerLink]="['/order']" >More Orders <mat-icon>chevron_right</mat-icon></button>
			<h2>Recent Order Activity</h2>

			<div *ngIf="this.loadingOrders | async">Loading...</div>
			<div *ngIf="!(this.loadingOrders | async) && (orders | async)?.length == 0">No Orders Saved Yet</div>

			<mat-table [dataSource]="orders" class="orderTable" *ngIf="!(this.loadingOrders | async) && !!(orders | async)?.length">
				<mat-header-row *cdkHeaderRowDef="orderTableColumns; sticky: true"></mat-header-row>
				<mat-row *cdkRowDef="let entity; columns: orderTableColumns" class="entity-row"> </mat-row>

				<ng-container cdkColumnDef="action">
					<mat-header-cell *cdkHeaderCellDef> </mat-header-cell>
					<mat-cell *cdkCellDef="let entity">
						<a [routerLink]="['/order', entity.id]" aria-label="View full order details">
							<mat-icon>list_alt</mat-icon>
						</a>
					</mat-cell>
				</ng-container>

				<ng-container cdkColumnDef="orderNumber">
					<mat-header-cell *cdkHeaderCellDef>Order Number</mat-header-cell>
					<mat-cell *cdkCellDef="let entity"><span>{{entity.orderStatus == "Rejected" ? "Submission Error" : (entity.orderNumber ? entity.orderNumber : "Missing Number, ID:" + entity.orderId) }}</span></mat-cell>
				</ng-container>

				<ng-container cdkColumnDef="orderDate">
					<mat-header-cell *cdkHeaderCellDef>Order Date</mat-header-cell>
					<mat-cell *cdkCellDef="let entity"><span>{{entity.orderStatus == "Rejected" ? (entity.updatedAtUTC | reflexTimeAgo) : (entity.orderDateUTC ? entity.orderDateUTC : entity.updatedAtUTC) | reflexTimeAgo}}</span></mat-cell>
				</ng-container>
				
				<ng-container cdkColumnDef="lastUpdated">
					<mat-header-cell *cdkHeaderCellDef>Last Updated</mat-header-cell>
					<mat-cell *cdkCellDef="let entity"><span>{{(entity.updatedAtUTC > entity.vendor_lastUpdatedAt ? entity.updatedAtUTC : entity.vendorDateUTC) | reflexTimeAgo}}</span></mat-cell>
				</ng-container>

				<ng-container cdkColumnDef="displayStatus">
					<mat-header-cell *cdkHeaderCellDef>Status</mat-header-cell>
					<mat-cell *cdkCellDef="let entity"><span>{{entity.displayStatus ? entity.displayStatus : entity.orderStatus}}</span></mat-cell>
				</ng-container>
				
			</mat-table>
		</div>
	</div>

	<div class="column">
		<div class="widget">
			<h2>Recent Notifications</h2>

			<mat-list>
				<mat-list-item *ngIf="!(notifications | async)?.length">
					No notifications
				</mat-list-item>
				<mat-list-item *ngFor="let notice of notifications | async; index as i" (click)="openNotification(notice)" [class.read]="notice.viewedAt">
					<mat-icon>{{notice.viewedAt ? 'mark_email_read' : 'mail'}}</mat-icon>
					<div class="content">
						<div class="subject">{{notice.subject}} </div>
						<div class="createdAt"> from {{(notice.createdAtUTC || now).toString() | reflexTimeAgo}}</div>
					</div>
				</mat-list-item>
			</mat-list>
		</div>
	</div>
</div>
