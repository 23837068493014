import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LandingModule } from './landing/landing.module';
import { AuthModule } from './auth/auth.module';
import { OrderModule } from './order/order.module';
import { AddressBookModule } from './address-book/address-book.module';
import { ImpersonateModule } from 'legalreflex-lib';
import { UsersModule } from './users/users.module';
import { CaseModule } from './case/case.module';
import { NotificationModule } from './notification/notification.module';
import { InvoiceModule } from './invoice/invoice.module';
import { ClientMatterValidationModule } from './clientMatter/clientMatterValidation.module';
import { DocumentModule } from './document/document.module';
import { LegacyModule } from './redirect/fc2-legacy.module';
import { PageNotFound } from './redirect/pageNotFound';
const routes: Routes = [
    {
        path: 'not-found',
        component: PageNotFound
    },
	{
		path: '**',
		component: PageNotFound
	}   

];

@NgModule({
    imports: [
        AuthModule,
        InvoiceModule,
        OrderModule,
        AddressBookModule,
        ImpersonateModule,
        UsersModule,
        CaseModule,
        NotificationModule,
        ClientMatterValidationModule,
        DocumentModule,
        LegacyModule,

        // It's important that Landing is the last routing module imported
        LandingModule,
        RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule
    ],
    declarations: [
    ]
})
export class RoutesModule { }
