import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { UserCase } from '../models/userCase.model';

@Injectable()
export class UserCase_Service extends ResourceService<UserCase> {
    protected servicePath: string = '/UserCase';

    public ModelType = UserCase;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }
}
