<div [formGroup]="form" *ngIf="form">


        <div class="d-flex v-align gap-m">
            
            <section name="nocontact">
                <div class="mat-subheading-2">Do not contact me</div>
                <div class="d-flex">
                    <mat-checkbox color="primary" formControlName="hasNoContactHours" aria-label="Do not contact me between">Do not contact me between</mat-checkbox>
                </div>
                <div class="shim" *ngIf="hasNoContactHours?.value">
                    <mat-form-field>
                        <mat-label>From</mat-label>
                        <input formControlName="noContactFromTime" matInput type="time" placeholder="7:00 PM"/>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Until</mat-label>
                        <input formControlName="noContactToTime" matInput type="time" placeholder="7:00 PM"/>
                    </mat-form-field>
                </div>
            </section>

			<section name="timezone">
				<div class="mat-subheading-2">Region</div>
				<div class="d-flex">
					<mat-form-field class="grow-1">
						<mat-label>Time Zone</mat-label>
						<mat-select formControlName="timeZone">
							<mat-option *ngFor="let zone of zones" [value]="zone.value">
								{{zone.label}} 
								<small class="mat-caption">({{prettyZone(zone.value)}})</small> 
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>


			</section>
			<section name="contact-method" [hidden]="true">
				<div class="mat-subheading-2">Preferred Method of Contact</div>
				<mat-radio-group formControlName="contactPreference" class="d-flex h-align">
					<mat-radio-button  [value]="ContactPreference.email" aria-label="Email">Email</mat-radio-button>
					<mat-radio-button  [value]="ContactPreference.phone" aria-label="Phone" disabled matTooltip="Not yet available">Phone</mat-radio-button>
					<mat-radio-button [value]="ContactPreference.sms" aria-label="SMS Text Message" disabled matTooltip="Not yet available">SMS Text Message</mat-radio-button>
				</mat-radio-group>
			</section>

			<section name="frequency" [hidden]="true">
				<div class="mat-subheading-2">Preferred Notification Frequency</div>
				<div>
					<mat-radio-group formControlName="contactTrigger" class="d-flex v-align">
						<mat-radio-button [value]="ContactTrigger.immediate">Send notifications immediately upon receipt</mat-radio-button>
						<mat-radio-button [value]="ContactTrigger.schedule" disabled matTooltip="Not yet available">Send notifications in digest form on a schedule</mat-radio-button>
						<mat-radio-button [value]="ContactTrigger.pause" disabled matTooltip="Not yet available">Temporarily pause all notifications until...</mat-radio-button>
					</mat-radio-group>

					<div class="shim" *ngIf="contactTrigger?.value == ContactTrigger.schedule">
						<p class="mat-subheading-1">Schedule Frequency</p>
						<mat-radio-group formControlName="contactFrequency" class="d-flex h-align">
							<mat-radio-button [value]="ContactFrequency.once_daily">Once daily</mat-radio-button>
							<mat-radio-button [value]="ContactFrequency.multiple_daily">Multiple times daily</mat-radio-button>
						</mat-radio-group>
						
						<p>
							Select times 
						</p>
						<button mat-button color="primary" [disabled]="scheduleTimeLimitReached" (click)="addScheduledTime($event)">
							<mat-icon>add</mat-icon>
							<mat-label>Add Time</mat-label>
						</button>
						<mat-list >
							<mat-list-item class="shim" *ngFor="let control of scheduledTimes">
								<span class="d-flex space-between center">
									<button mat-icon-button
										
										matTooltip="Remove"
										[disabled]="scheduledTimes.length == 1"
										(click)="removeScheduleTime(control, $event)"><mat-icon class="centered" color="primary">remove</mat-icon></button>
									<mat-form-field>
										<mat-label>Choose a time</mat-label>
										<input [formControl]="control" matInput type="time" placeholder="7:00 PM" required />
									</mat-form-field>
								</span>

							</mat-list-item>
						</mat-list>
					
						<div>
							<span>Days of the week</span> 
							<div *ngIf="form.controls.notificationScheduleDays.hasError('empty')" 
								class="mat-caption">
								<mat-icon inline color="warn">error</mat-icon> At least 1 day of the week must be selected
							</div>
						</div>
						<mat-selection-list class="days" formControlName="notificationScheduleDays">
							<mat-list-option #m [class]="active(m)" mat-fab [value]="Day.M">{{Day.M}}</mat-list-option>
							<mat-list-option #tu [class]="active(tu)" mat-fab [value]="Day.Tu">{{Day.Tu}}</mat-list-option>
							<mat-list-option #w [class]="active(w)" mat-fab [value]="Day.W">{{Day.W}}</mat-list-option>
							<mat-list-option #th [class]="active(th)" mat-fab [value]="Day.Th">{{Day.Th}}</mat-list-option>
							<mat-list-option #f [class]="active(f)" mat-fab [value]="Day.F">{{Day.F}}</mat-list-option>
							<mat-list-option #sat [class]="active(sat)" mat-fab [value]="Day.Sat">{{Day.Sat}}</mat-list-option>
							<mat-list-option #sun [class]="active(sun)" mat-fab [value]="Day.Sun">{{Day.Sun}}</mat-list-option>
						</mat-selection-list>
					</div>
				
					<div class="shim" *ngIf="contactTrigger?.value == ContactTrigger.pause">
						<mat-form-field>
							<mat-label>Choose a date</mat-label>
							<input formControlName="pauseNotificationsToDate" matInput [matDatepicker]="picker">
							<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
							<mat-datepicker #picker></mat-datepicker>
						</mat-form-field>
						at
						<mat-form-field>
							<mat-label>Choose a time</mat-label>
							<input formControlName="pauseNotificationsToTime" matInput type="time" placeholder="7:00 PM"/>
						</mat-form-field>

					</div>
	
				</div>
			</section>

			<section name="ccs">
				<div class="mat-subheading-2">Send Additional CCs on All Notifications</div>
				

			
				<mat-form-field>
					<mat-label>Enter Email Address..</mat-label>
					<input matInput email
						formControlName="selectedCC" #ccSelect
						[(ngModel)] = "val"	
						/>
						<mat-error *ngIf="form.controls.selectedCC.hasError('email')">
							Invalid Email Address
						</mat-error>	
					<mat-icon matSuffix>manage_search</mat-icon>
				</mat-form-field>
			
				<button mat-button 
					color="primary" 
					[disabled]="!ccSelect || numberOfCCsLimitReached" 
					(click)="checkAndAdd(val)">
					
					<mat-icon>add</mat-icon>
					<mat-label>Add Recipient</mat-label>
				</button>
				<mat-list>

					<mat-list-item *ngFor="let cc of notificationStrings">
						<span class="d-flex space-between center">
							<button 
								mat-icon-button 
								matTooltip="Remove"
								(click)="removeCC(cc)">
								<mat-icon class="centered" color="primary">remove</mat-icon>
							</button>
							<span>{{cc}}</span> 

						</span>
					</mat-list-item>
				</mat-list>

				<span *ngIf="notificationStrings.length == 0" class="mat-caption">No recipients have been added to your default notifications yet.</span>
				<span *ngIf="numberOfCCsLimitReached" class="mat-caption">The maximum number of additional notifications has been reached.</span>
			</section>

		</div>

</div>