
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import {
    Document,
    Document_Service,
    Order_Service,
    UserPermission_Service,
    OrderHistoryUserFilter,
    PagedResourceListComponent,
    DocumentDataSource,
    ReflexDownloadComponent,
    ReflexDownloaderService,
    ReflexDownloadGroup,
    ReflexDownloadFile,
    WIP,
    SystemConfig_service
} from 'legalreflex-lib';
import { animate, trigger, state, style, transition } from '@angular/animations';
import { BreadCrumbService } from '../../../components/breadcrumb-bar/breadcrumb-service';
import { Buffer } from 'buffer';
import { ResourceListComponent, ResourceListPane } from '@smartsoftware/reflex-core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { FormBuilder, FormControl } from '@angular/forms';
import { Observable, combineLatest } from 'rxjs';
import { catchError, combineAll, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    templateUrl: './list.html',
    styleUrls: ['./list.scss'],
})
export class DocumentList extends PagedResourceListComponent<Document> {
    @ViewChild(MatSort) sort: MatSort = new MatSort();
    @ViewChild('filter') filterPane: ResourceListPane = new ResourceListPane();
    public filterCollapsed: boolean = sessionStorage.getItem("filterCollapsed") == 'true';

    public WIP : boolean = WIP()
    public columnsToDisplay = ['select', 'download', 'name', 'docStatus', 'fileType', 'fileName', 'documentType'];
    public orderFilters: OrderHistoryUserFilter = {}; 
    public downloadURLs: Map<string, string> = new Map();
    public isLoading: boolean = true;
    public noDocuments: boolean = false;

    constructor(
        public dataSource: DocumentDataSource,
        public router: Router,
        public fb: FormBuilder,
        protected downloaderService: ReflexDownloaderService,
        protected auth: OidcSecurityService,
        protected entityService: Document_Service,
        protected orderService: Order_Service,
        protected dialog: MatDialog,
        protected snackbar: MatSnackBar,
        protected route: ActivatedRoute,
        private userPermissionService: UserPermission_Service,
        private reflexDownloadService: ReflexDownloaderService,
		private breadCrumbService: BreadCrumbService,
        public sysConfig: SystemConfig_service
    ) {
        super(dataSource, entityService,  dialog, route, router);
        this.breadCrumbService.breadcrumbs.next([
            { label: 'Documents', url: '/document'},
            { label: 'History' }
        ])
        this.setBreadCrumbs();
    }

    ngOnInit() {
        super.ngOnInit();
        if(this.sysConfig.maintenanceRedirectCheck()){  
            window.localStorage.removeItem("loggedInUserPermissionNodes");
            this.auth.logoff();
        };
        this.dataSource.sort = this.sort;
        this.dataSource.pageSize = 25;
        this.filterFormGroup = this.fb.group({
            orderId: [''],
            name: [''],
            status: [''],
            fileType: [''],
            fileName: [''],
            documentType: [''],
            source: ['success'],
        });
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
        setTimeout(() => {
            if (this.dataSource.sort) {
                this.dataSource.sort.active = 'name';
                this.dataSource.sort.direction = 'asc';
            }
            this.getDocuments();
            let filterClick = document.getElementById('filterPaneDocument');
            if(filterClick && !filterClick.hasAttribute('filterClickAttached')){
                filterClick.setAttribute('filterClickAttached','true')
                filterClick.addEventListener("click",()=>{
                    sessionStorage.setItem("filterCollapsed", this.filterPane.isCollapsed ? "true" : "false");
                })
            }
        });
        this.dataSource.loading.subscribe((state)=>{
            this.isLoading = state;
        });
    }

    pageChange(){
        this.selection.clear();
        this.setBreadCrumbs();
    }

    selectionChange(event?: Event){
        if(event){
            event.stopPropagation();
        }
        // To fire after selection update goes through
        setTimeout(() => {
            this.setBreadCrumbs();
        })
    }

    setBreadCrumbs(){
        let actions = [];
        this.breadCrumbService.actions.next([]);
        actions.push({label:'Refresh', action: this.getDocuments.bind(this,false), icon:'refresh'});
        if(this.selection.isEmpty())
            actions.push({label:'Get Documents', disabled:true, tooltip:'Please select 1 or more documents.', icon:'download'});
        else
            actions.push({label:'Get Documents', action: this.downloadDocument.bind(this, this.selection.selected), icon:'download'});

        this.breadCrumbService.actions.next(actions);
    }

    public getDocumentUrl(docs: Array<Document>) {
        this.entityService.getSignedUrl(docs[0]).subscribe((result)=>{
            this.downloadURLs.set(result.docId, result.message);
            window.open(result.message, '_blank');
        })
        // let observables : Observable<object>[] = [];
        // docs.map((doc)=>{
        //     if(!this.downloadURLs.has(doc.uuid))
        //     observables.push(this.entityService.getSignedUrl(doc));
        // });
        // combineLatest(observables).subscribe((result)=>{
        //     result.map((document:any)=>{
        //         window(open)
        //         this.downloadURLs.set(document.docId, document.message);
        //     });
        // });
    }

    public downloadDocument(docs: Array<Document>){
        let docGroup = new ReflexDownloadGroup('Documents',true,'FirstLegalDocuments');
        let observables : Observable<object>[] = [];
        docs.map((doc)=>{
            observables.push(this.entityService.getDocumentFile(doc)
            .pipe(
                catchError(err => {
                    let message = 'Error with document ' + doc.fileName + ":" + err.error.internalError;
                    this.snackbar.open(message, undefined, { duration: 4000, verticalPosition: 'bottom', horizontalPosition: 'left'});
                    throw err;
                })
            ));
        });
        combineLatest(observables).subscribe((result)=>{
            result.map((fileInfo:any)=>{
                if(!this.downloadURLs.get(fileInfo.document.uuid))
                    this.downloadURLs.set(fileInfo.document.uuid, fileInfo.response.signedUrl);
                let url = encodeURI('https://flproddata.s3.us-west-2.amazonaws.com/' + fileInfo.response.path);
                let file = new ReflexDownloadFile(url, this.downloadURLs.size + fileInfo.document.fileName , fileInfo.document.fileName);
                file.body = new Blob([Buffer.from(fileInfo.response.file.data)], { type: fileInfo.document.fileType});
                docGroup.files.push(file);
            })
            docGroup.downloadStatus.next('DONE');
            // Issue with direct get requests on local testing
            //this.reflexDownloadService.requestGroupDownload(docGroup)
            this.reflexDownloadService.saveFileGroup(docGroup);
        });
    }

    getDocuments(reset?:boolean) {
        if(reset){
            this.filterFormGroup = this.fb.group({
                orderId: [''],
                name: [''],
                status: [''],
                fileType: [''],
                fileName: [''],
                documentType: [''],
                source: ['success'],
            });
        }
        this.selection.clear();
        this.setBreadCrumbs();
        this.onFilterSubmit();
    }

    sortData(event: any){
        this.dataSource.data = this.dataSource.sortData(this.dataSource.data,this.sort);
    }    
}
