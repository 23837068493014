import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { ContactFile } from '../models/contactFile.model';

@Injectable()
export class ContactFile_Service extends ResourceService<ContactFile> {
    protected servicePath: string = '/ContactFile';

    public ModelType = ContactFile;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }
}
