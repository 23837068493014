import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavigationModule, ResourceModule, AuthGuardService } from '@smartsoftware/reflex-core';
import { AestheticsModule } from '../../aesthetics/aesthetics.module';
import { ComponentsModule } from '../../components/components.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { MatInputModule } from '@angular/material/input';
import { AutoLoginPartialRoutesGuard as AuthGuard } from 'angular-auth-oidc-client';
import { Invoice_Service, LegalReflexLibModule } from 'legalreflex-lib';
import { InvoiceFrame } from './frame/frame';
import { InvoiceList } from './list/list';

const routes: Routes = [
    {
        path: 'invoice',
        component: InvoiceFrame,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: InvoiceList
            }
        ]
    }
];

@NgModule({
    declarations: [
        InvoiceFrame,
        InvoiceList
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AestheticsModule,
        ComponentsModule,
        ResourceModule,
        BrowserModule,
        BrowserAnimationsModule,
        MatTooltipModule,
        RouterModule,
        LegalReflexLibModule,
        MatFormFieldModule,
        MatInputModule,
        MatStepperModule,

        RouterModule.forChild(routes)
    ],
    providers: [
    ],
    exports: [
    ],
    bootstrap: [

    ]
})
export class InvoiceModule { }
