import { Component, Input, Output, EventEmitter, HostBinding, HostListener, ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DialogRef } from '../dialog/dialog';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
    selector: 'lib-legalreflex-toolbar',
    templateUrl: './toolbar.html',
    styleUrls: ['./toolbar.scss']
})
export class LegalReflexToolbar {

    @HostBinding('class.isScrolledDown')
    public isScrolledDown: boolean = false;

    @Input('mode')
    public _mode: '' | 'list' | 'edit' = '';

    @Input('isLoading')
    public isLoading: boolean = false;

    @Input('isLookup')
    public isLookup?: boolean = false;

    @Input('isEmpty')
    public isEmpty?: boolean = false;

    @Input('disableCreate')
    public disableCreate: boolean = false;

    @Input('ngForm')
    public _ngForm?: FormGroup;
    public get ngForm(): FormGroup | undefined {
        return this._ngForm;
    }

    public set ngForm(v: FormGroup | undefined) {

        this._ngForm = v;
        console.log(this._ngForm);
    }

    public get isEdit() : boolean {
        return this._mode === 'edit';
    }

    public set mode(mode: '' | 'list' | 'edit') {
        this.mode = mode;
    }

    public get mode() {
        return this.mode;
    }

    @Output('action')
    public action = new EventEmitter<string>();

    public isAdmin: boolean = false;
    constructor(
        public router: Router,
        public route: ActivatedRoute,
        private element: ElementRef
    ) {
        // this.auth.user$.subscribe(u => this.checkUser(u));
    }

    public handleSave(event: any, ref?: DialogRef<boolean>) {
        if (event === 'save') {
            this.click('save');
            !!ref && ref.close(true);
        }
    }

    public navigateToList() {
        this.router.navigate(['../']);
    }

    public click(event: string) {
        this.action.emit(event);
    }

    @HostListener('window:scroll', ['$event'])
    public onWindowScroll(event: any) {
        this.isScrolledDown = (window.scrollY > 5);
        window.document.body.style.marginTop = this.isScrolledDown ? this.element.nativeElement.offsetHeight + 'px' : "0px";
    }
}
