import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {Sort} from '@angular/material/sort';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms'
import { 
    SuccessAccount, 
    SuccessAccount_Service, 
    SuccessAccountSettings, 
    UserPermission_Service, 
    VendorAccount_Service, 
    validators, 
    NotificationPreferencesComponent,
    SpecialInstructionPreferencesComponent,
    ChangePasswordComponent,
    SuccessAccountSettingsService,
    SuccessAccountPermissionNode_Service,
    Dictionary_Service,
    SystemConfig_service
} from 'legalreflex-lib';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { filter } from 'rxjs/operators';
import { BreadCrumbService } from '../../../components/breadcrumb-bar/breadcrumb-service';
import { ResourceEditComponent, ResourceFormService } from '@smartsoftware/reflex-core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';

@Component({
    templateUrl: './view.html',
    styleUrls: ['./view.scss']
})
export class UserView extends ResourceEditComponent<SuccessAccount, SuccessAccount_Service> implements OnInit {

    @ViewChild(NotificationPreferencesComponent) noficationPreferences?: NotificationPreferencesComponent;
    @ViewChild(SpecialInstructionPreferencesComponent) specialInstructions?: SpecialInstructionPreferencesComponent;
    @ViewChild(ChangePasswordComponent) changePasswordComponent?: ChangePasswordComponent;

    public settings : SuccessAccountSettings = new SuccessAccountSettings();

    constructor(
        protected entityService: SuccessAccount_Service,
        // indirectly call syncUser by injecting the service, to ensure it gets called
        private userPermissionservice: UserPermission_Service,
        private successAccountPermissionService: SuccessAccountPermissionNode_Service,
        private successAccountSettingsService: SuccessAccountSettingsService,
        private vendorAccountService: VendorAccount_Service,
        private authService: OidcSecurityService,
        private fb : FormBuilder,
        private snackBar: MatSnackBar,
        private breadCrumbService: BreadCrumbService,
        protected pageTitleService: Title,
        protected route: ActivatedRoute,
        protected dialog: MatDialog,
        protected resourceFormService: ResourceFormService<SuccessAccount>,
        public dictionary: Dictionary_Service,
        public sysConfig: SystemConfig_service
    ) {
        super(entityService, route, dialog, resourceFormService);

		this.pageTitleService.setTitle("FirstConnect - Profile");
		  
        this.breadCrumbService.breadcrumbs.next([
            { label: 'Users', url: '/Users'},
            { label: 'Loading' }
        ]);

        this.breadCrumbService.actions.next([
            { label: 'Save', action: () => this.save(), isPrimary: true, icon: ''}
        ]);


    }

    ngOnInit(): void {
        super.ngOnInit();
        if(this.sysConfig.maintenanceRedirectCheck()){  
            window.localStorage.removeItem("loggedInUserPermissionNodes");
            this.authService.logoff();
        };
//        this.populateForms();
        let showSettings: any = "";
        console.log("entity", this.entity);

        this.entityStatus.subscribe(
            (entity) => {
                if(!entity) return;

                this.breadCrumbService.breadcrumbs.next([
                    { label: 'Users', url: '/users'},
                    { label: `${entity.firstName} ${entity.lastName}` }
                ]);

                this.successAccountSettingsService.getByAccountUUID(entity.uuid).subscribe(
                    (settings) => {
                        this.settings = settings;
                        showSettings = settings;                        
                    }
                );

                this.successAccountPermissionService.getPermissionNodesBySuccessAccount({successAccount_uuid: entity.uuid}).subscribe(
                    (permissions) => {
                        //console.log("permissions", permissions);
                    }
                );
                
                console.log("entity", this.entity);
            });
        // Workaround to load preferences for the user when the page loads.
        this.settings = showSettings;

    }


    save() {
        // this will save the main entity
        super.save();
        
        if (this.entity) {
            this.noficationPreferences?.bindFormValuesToSettings();
            this.successAccountSettingsService.push(this.settings).subscribe(settings => {
                this.settings = settings;
                this.snackBar.open('Preferences Saved', undefined, { duration: 4000, horizontalPosition: 'left', panelClass: 'snack-error' });
            });
        }
    }

/* Pulled from old preferences.ts
    saveNotificationSettings() {
        if (this.settings) {
            this.noficationPreferences?.bindFormValuesToSettings();
            this.successAccountService.saveSettings(this.settings)
                .subscribe({
                    next: settings => {
                        this.snackBar.open('Preferences Saved', undefined, { duration: 4000, horizontalPosition: 'left', panelClass: 'snack-error' })
                    }, 
                    error: err => {
                        let message = err.error?.error || 'Something went wrong saving the preferences';
                        this.snackBar.open(message, undefined, { duration: 4000, horizontalPosition: 'left', panelClass: 'snack-error' })
                        console.error(err);
                    }
                });
        }
    }

    saveSpecialInstructions() {
        if (this.settings) {
            this.specialInstructions?.bindFormValuesToSettings();
            this.successAccountService.saveSettings(this.settings)
                .subscribe({
                    next: settings => {
                        this.snackBar.open('Preferences Saved', undefined, { duration: 4000, horizontalPosition: 'left', panelClass: 'snack-error' })
                    }, 
                    error: err => {
                        let message = err.error?.error || 'Something went wrong saving the preferences';
                        this.snackBar.open(message, undefined, { duration: 4000, horizontalPosition: 'left', panelClass: 'snack-error' })
                        console.error(err);
                    }
                });
        }
    }
*/
}
