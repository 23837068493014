import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { ClientAddress } from '../models/clientAddress.model';

@Injectable()
export class ClientAddress_Service extends ResourceService<ClientAddress> {
  protected servicePath: string = '/ClientAddress';

  public ModelType = ClientAddress;

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }
}
