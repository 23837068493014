import { ReflexEnvironment as environment } from '@smartsoftware/reflex-core';

/*
 * this can be used to hide "work in progress" and untested features from end users on the prod and beta versions of the site.
 *
 * USAGE: 
 * In your ts file, add the following import and variable:
 *
 * import { WIP } from 'legalreflex-lib';
 * 
 * public WIP : boolean = WIP();
 * 
 * In your html file where appropriate add: 
 * 
 * <div *ngIf="WIP"></div>  
 */
export function WIP() : boolean {
    return !['prod', 'beta'].includes(environment.config['resourcePrefix']);
}