import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { ClientNote } from '../models/clientNote.model';

@Injectable()
export class ClientNote_Service extends ResourceService<ClientNote> {
    protected servicePath: string = '/ClientNote';

    public ModelType = ClientNote;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }
}
