<div *ngIf="WIP">
</div>
<!-- 
    - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    -
    -   Case and court
    -
    - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
 -->
<div class="loading" *ngIf="isLoading">
    <div class="loading-text">Loading Case</div>
    <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
</div>

<div *ngIf="!isLoading">

    <form [formGroup]="entityForm" *ngIf="entityForm">
        <resource-list-grid>
                <resource-list-pane title="Case Details">
                    <mat-form-field>
                        <mat-label>Case Name</mat-label>
                        <input matInput required 
                            formControlName="name"
                            (change)="setBreadCrumbs()"/>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Case Number</mat-label>
                        <input matInput required 
                            formControlName="caseNumber"
                            (change)="setBreadCrumbs()"/>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Client Matter Reference</mat-label>
                        <input
                            matInput required
                            type="text"
                            aria-label="Client"
                            formControlName="clientMatterNumber"
                            (change)="setBreadCrumbs()"
                            [matAutocomplete]="autoMatter">
                        <mat-autocomplete
                            (openedChange)="zIndex($event)"
                            (optionSelected)="getClientMatter($event.option.value)"
                            #autoMatter="matAutocomplete">
                            <mat-option
                            *ngFor="let client of filteredClientMatters | async" [value]="client[0]">
                                {{ client[1] }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field> 
                    <div [hidden]="!userManage.value">
                        <mat-form-field>
                            <mat-label>Primary User</mat-label>
                            <mat-select formControlName="successAccount_uuid" (openedChange)="zIndex($event)" (selectionChange)="setBreadCrumbs()">
                                <mat-option *ngFor="let user of allUsers; let i = index" value={{user.uuid}}>{{user.firstName}} {{user.lastName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <mat-checkbox *ngIf="formHasControl" color="primary" style="margin-bottom: 10px;" [formControl]="sendNotificationCtrl">
                        Send notifications to permitted users
                    </mat-checkbox>   

                    <mat-checkbox *ngIf="formHasControl && (userManage.value || entityForm.controls['successAccount_uuid'].value  == this.userId)" color="primary" style="margin-bottom:10px;" formControlName="isRestricted">
                        Restrict case to permitted users 
                    </mat-checkbox>

                </resource-list-pane>

                <resource-list-pane title="Court Details">
                    <div class="lookup-form" *ngIf="lookupAddress">
                        <mat-form-field style="margin-left:20px; width:60%;">
                            <mat-label><mat-icon>search</mat-icon> Court</mat-label>
                            <input
                                placeholder="Type to search by name"
                                matInput
                                type="text"
                                aria-label="Court"
                                [formControl]="addressLookupCtrl"
                                (change)="setBreadCrumbs()"
                                (ngModelChange)="filterEntries(addressHash$, courtAddressList, 'name', $event)"
                                [matAutocomplete]="autoCourt">
                            <mat-autocomplete
                                (opened)="zIndex(true)"
                                (closed)="zIndex(false)"
                                (optionSelected)="getAddress($event.option.value)"
                                #autoCourt="matAutocomplete"
                                [displayWith]="filterDisplay">
                                <mat-option
                                *ngFor="let court of courtAddressList | async" [value]="court.id">
                                    {{ court.name }} 
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                        <button style="margin: 0 0 10px 30px; width:120px" mat-raised-button (click)="lookupAddress = !lookupAddress;">Close Lookup</button>
                    </div>
                    <button *ngIf="!lookupAddress" style="margin: 10px 0 10px 0; align-self: end;" mat-raised-button color="primary" (click)="lookupAddress = !lookupAddress;"><mat-icon>book</mat-icon>Open Lookup</button>
                    
                    <mat-form-field>
                        <mat-label>Name</mat-label>
                        <input matInput readonly value="{{courtAddress?.name}}"/>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Jurisdiction</mat-label>
                        <input matInput readonly value="{{courtAddress?.jurisdiction}}"/>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>County</mat-label>
                        <input matInput readonly value="{{courtAddress?.county}}"/>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Address Information</mat-label>
                        <input matInput readonly value="{{courtAddress?.streetAddress1}}"/>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Phone</mat-label>
                        <input matInput readonly value="{{courtAddress?.primaryPhoneNumber}}"/>
                    </mat-form-field>      
                </resource-list-pane>
        </resource-list-grid>

    <!-- 
        - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
        -
        -   Case Orders and Associated Documents
        -
        - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    -->
        <resource-list-grid>
            <resource-list-pane title="Orders" *ngIf="formHasControl" [collapsable]='true'>

                <mat-paginator #orderPaginator="matPaginator" 
                    [pageSizeOptions]="[5,10]"
                    showFirstLastButtons>
                </mat-paginator>

                <mat-table multiTemplateDataRows class="tableClamp" [dataSource]="orderSource" matSort #order="matSort" (matSortChange)="sortData('order')">
                
                    <ng-container cdkColumnDef="view">
                        <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
                        <mat-cell *cdkCellDef="let entity">
                            <mat-icon>{{entity.order == expandedOrder ? 'expand_less' : 'expand_more'}}</mat-icon>
                            <button mat-icon-button [routerLink]="'/order/'+entity.order.id">
                                <mat-icon>list_alt</mat-icon>
                            </button>
                        </mat-cell>
                    </ng-container>

                    <ng-container cdkColumnDef="order.orderNumber">
                        <mat-header-cell mat-sort-header *cdkHeaderCellDef>Order Number</mat-header-cell>
                        <mat-cell *cdkCellDef="let entity"><span>{{entity.order.orderNumber}}</span></mat-cell>
                    </ng-container>
                    
                    <ng-container cdkColumnDef="order.orderDate">
                        <mat-header-cell mat-sort-header *cdkHeaderCellDef>Order Date</mat-header-cell>
                        <mat-cell *cdkCellDef="let entity"><span>{{entity.order.orderDateUTC | reflexTimeAgo}}</span></mat-cell>
                    </ng-container>

                    <ng-container cdkColumnDef="service">
                        <mat-header-cell mat-sort-header *cdkHeaderCellDef>Service Line</mat-header-cell>
                        <mat-cell *cdkCellDef="let entity"><span>{{serviceName(entity.service)}}</span></mat-cell>
                    </ng-container>
        
                    <ng-container cdkColumnDef="serviceType">
                        <mat-header-cell mat-sort-header *cdkHeaderCellDef>Service Type</mat-header-cell>
                        <mat-cell *cdkCellDef="let entity"><span>{{entity.serviceType}}</span></mat-cell>
                    </ng-container> 
        
                    <ng-container cdkColumnDef="order.orderStatus">
                        <mat-header-cell mat-sort-header *cdkHeaderCellDef>Status</mat-header-cell>
                        <mat-cell *cdkCellDef="let entity"><span>{{entity.order.orderStatus}}</span></mat-cell>
                    </ng-container>

                    <ng-container cdkColumnDef="order.updatedAt">
                        <mat-header-cell mat-sort-header *cdkHeaderCellDef>Updated</mat-header-cell>
                        <mat-cell *cdkCellDef="let entity"><span>{{entity.order.updatedAtUTC | reflexTimeAgo}}</span></mat-cell>
                    </ng-container>

                    <ng-container cdkColumnDef="expandedDetail">
                        <mat-cell class="entity-detail" *cdkCellDef="let entity;" [@detailExpand]="expandedOrder === entity.order ? 'expanded' : 'collapsed'">
                            <mat-tab-group [animationDuration]="100" *ngIf="entity.order == expandedOrder">
                                <mat-tab label="Order Details">
                                    <div class="entity-detail-row">
                                        <div class="entity-detail-column">
                                            <div class="entity-additional order-number">
                                                <label>Order Number</label>
                                                <span>{{entity.order.orderNumber ? entity.order.orderNumber : ''}}&nbsp;</span>
                                            </div>
                                            <div class="entity-additional claim-number">
                                                <label>Claim Number</label>
                                                <span>{{entity.order.claimNumber ? entity.order.claimNumber : ''}}&nbsp;</span>
                                            </div>
                                            <div class="entity-additional client-matter-number">
                                                <label>Client Matter Number</label>
                                                <span>{{entity.order.clientMatterNumber ? entity.order.clientMatterNumber : ''}}&nbsp;</span>
                                            </div>
                                            <div class="entity-additional company-Number">
                                                <label>Company</label>
                                                <span>{{entity.order.companyNumber ? entity.order.companyNumber : ''}}&nbsp;</span>
                                            </div>
                                        </div>
                                        <div class="entity-detail-column">
                                            <div class="entity-additional customer-number">
                                                <label>Customer Number</label>
                                                <span>{{entity.order.customerNumber ? entity.order.customerNumber : ''}}&nbsp;</span>
                                            </div>
                                            <div class="entity-additional order-status">
                                                <label>Order Status</label>
                                                <span>{{entity.order.orderStatus ? entity.order.orderStatus : ''}}&nbsp;</span>
                                            </div>
                                            <div class="entity-additional placed-by">
                                                <label>Placed By</label>
                                                <span>{{entity.order.placedByEmail ? entity.order.placedByEmail : ''}}&nbsp;</span>
                                            </div>
                                        </div>
                                        <div class="entity-detail-column">
                                            <div class="entity-additional service">
                                                <label>Service</label>
                                                <span>{{entity.service ? serviceName(entity.service) : ''}}&nbsp;</span>
                                            </div>
                                            <div class="entity-additional service-category">
                                                <label>Service Category</label>
                                                <span>{{entity.serviceCategory ? entity.serviceCategory : ''}}&nbsp;</span>
                                            </div>
                                            <div class="entity-additional service-type">
                                                <label>Service Type</label>
                                                <span>{{entity.serviceType ? entity.serviceType : ''}}&nbsp;</span>
                                            </div>
                                        </div>
                                        <div class="entity-detail-column">
                                            <div class="entity-additional customer-number">
                                                <label>Order Date</label>
                                                <span>{{entity.order.orderDateUTC | date : 'medium' }}&nbsp;</span>
                                            </div>
                                            <div class="entity-additional order-status">
                                                <label>Job Date</label>
                                                <span>{{entity.order.jobDateUTC | date : 'medium' }}&nbsp;</span>
                                            </div>
                                            <div class="entity-additional placed-by">
                                                <label>Due Date</label>
                                                <span>{{entity.order.dueDateUTC | date : 'medium' }}&nbsp;</span>
                                            </div>
                                            <div class="entity-additional update-date">
                                                <label>Updated</label>
                                                <span>{{entity.order.updatedAtUTC | date : 'medium' }}&nbsp;</span>
                                            </div>
                                        </div>
                                    </div>
                                </mat-tab>
                                <mat-tab>
                                    <ng-template mat-tab-label>
                                        Documents
                                        <span class="chip-count">
                                            {{(documentSource.data || []).length}}
                                        </span>
                                    </ng-template>
                                    <div *ngIf="expandedOrder">
                                        <div style="padding:10px">
                                            <lib-order-documents [showUploadControls]="false" [cc]="false" *ngIf="documentSource.data[0]" 
                                                [order]="expandedOrder" 
                                                [documents]="documentSource.data? documentSource.data : []"
                                                [allowBatchDownloading]="false">
                                            </lib-order-documents>
                                        </div>
                                    </div>
                                    <div *ngIf="!documentSource.data[0]">
                                        <div style="padding:10px">
                                            No Documents Found
                                        </div>
                                    </div>
                                </mat-tab>
                            </mat-tab-group>
                        </mat-cell>
                        
                    </ng-container>

                    <mat-header-row *cdkHeaderRowDef="orderColumns; sticky: true"></mat-header-row>
                    <mat-row *cdkRowDef="let row; columns: orderColumns"
                        class="entity-row"
                        [class.entity-expanded-row]="expandedOrder === row.order"
                        (click)="getOrderDocuments(row)">
                    </mat-row>
                    <mat-row *cdkRowDef="let row; columns: ['expandedDetail']" [class.entity-expanded-row]="expandedOrder === row.order" class="detail-row"></mat-row>
                </mat-table>
                <div style="align-self:center; margin-bottom: 1%;" *ngIf="!orderSource.data.length">No Orders found</div>
                
            </resource-list-pane>
        </resource-list-grid>

    <!--
        - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
        -
        -   Permitted Users 
        -
        - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    -->
        <resource-list-grid *ngIf="formHasControl && (userManage.value || entityForm.controls['successAccount_uuid'].value  == this.userId)">
            <resource-list-pane title="Permitted Users" [collapsable]='true'>
                <mat-table multiTemplateDataRows class="tableClamp" [dataSource]="userSource" matSort #user="matSort" (matSortChange)="sortData('user')">

                    <ng-container cdkColumnDef="view">
                        <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
                        <mat-cell *cdkCellDef="let entity">
                            <button mat-icon-button (click)="removePermittedUser(entity)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </mat-cell>
                    </ng-container>

                    <ng-container cdkColumnDef="firstName">
                        <mat-header-cell mat-sort-header *cdkHeaderCellDef>First Name</mat-header-cell>
                        <mat-cell *cdkCellDef="let entity">
                            <span style="text-transform:capitalize;">{{entity.firstName}} </span>
                        </mat-cell>
                    </ng-container>

                    <ng-container cdkColumnDef="lastName">
                        <mat-header-cell mat-sort-header *cdkHeaderCellDef>Last Name</mat-header-cell>
                        <mat-cell *cdkCellDef="let entity">
                            <span style="text-transform:capitalize;">{{entity.lastName}} </span>
                        </mat-cell>
                    </ng-container>

                    <ng-container cdkColumnDef="email">
                        <mat-header-cell mat-sort-header *cdkHeaderCellDef>Email</mat-header-cell>
                        <mat-cell *cdkCellDef="let entity">
                            {{entity.email}}
                        </mat-cell>
                    </ng-container>

                    <ng-container cdkColumnDef="username">
                        <mat-header-cell mat-sort-header *cdkHeaderCellDef>Username</mat-header-cell>
                        <mat-cell *cdkCellDef="let entity">
                            {{entity.username}}
                        </mat-cell>
                    </ng-container>

                    <ng-container cdkColumnDef="active">
                        <mat-header-cell mat-sort-header *cdkHeaderCellDef>Active Account</mat-header-cell>
                        <mat-cell *cdkCellDef="let entity">
                            {{entity.active ? "Active" : "Inactive"}}
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *cdkHeaderRowDef="userColumns; sticky: true"></mat-header-row>
                    <mat-row class="reg-row" *cdkRowDef="let row; columns: userColumns; let i = index">
                    </mat-row>
                </mat-table>
                
                <div style="align-self:center;" *ngIf="!userSource.data.length">No permitted users</div>
                <div [hidden]="!userManage.value">
                    <mat-form-field style="position:relative; z-index: 0;">
                        <mat-select placeholder="Add Users" (openedChange)="zIndex($event)" (selectionChange)="setSelectedUser($event.value)" [formControl]="permittedUserCtrl">
                            <mat-option>None</mat-option>
                            <mat-option *ngFor="let user of nonPermittedUsers; let i = index" value={{i}}>
                                {{user.firstName}} {{user.lastName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <button *ngIf="selectedUser" style="margin-left:20px"type="button" mat-raised-button (click)="addPermittedUser()">Add User</button>
                    <br>
                </div>
            </resource-list-pane>
        </resource-list-grid>

    <!-- 
        - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
        -
        -   Case Participants
        -
        - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    -->
    <!-- 
        Participants currently disabled
    -->
    <!--  
        <resource-list-grid >
            <resource-list-pane title="Case Participants" [collapsable]='true'>
                <mat-table multiTemplateDataRows class="tableClamp" [dataSource]="participantSource" matSort #participant="matSort" (matSortChange)="sortData('participant')">
                    
                    <ng-container cdkColumnDef="view" style="flex: 0 1 70px">
                        <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
                        <mat-cell *cdkCellDef="let entity" >
                            <button mat-icon-button (click)="deleteParticipant(entity)">
                                <mat-icon>delete</mat-icon>
                            </button>
                            <button mat-icon-button (click)="editParticipant(entity)">
                                <mat-icon>edit</mat-icon>
                            </button>
                        </mat-cell>
                    </ng-container>

                    <ng-container cdkColumnDef="contactType">
                        <mat-header-cell mat-sort-header *cdkHeaderCellDef>Type</mat-header-cell>
                        <mat-cell *cdkCellDef="let entity">
                            <span style="text-transform:capitalize;">{{entity.contactType ? entity.contactType : 'n/a'}} </span>
                        </mat-cell>
                    </ng-container>
                    <ng-container cdkColumnDef="firstName">
                        <mat-header-cell mat-sort-header *cdkHeaderCellDef>Name</mat-header-cell>
                        <mat-cell *cdkCellDef="let entity">
                            <span>{{entity.firstName ? entity.firstName : 'n/a'}} {{entity.lastName ? entity.lastName : ''}}</span>
                        </mat-cell>
                    </ng-container>
                    <ng-container cdkColumnDef="email">
                        <mat-header-cell mat-sort-header *cdkHeaderCellDef>Email</mat-header-cell>
                        <mat-cell *cdkCellDef="let entity">
                            <span>{{entity.email ? entity.email : 'n/a'}}</span>
                        </mat-cell>
                    </ng-container>
                    <ng-container cdkColumnDef="phone">
                        <mat-header-cell mat-sort-header *cdkHeaderCellDef>Phone</mat-header-cell>
                        <mat-cell *cdkCellDef="let entity">
                            <span>{{entity.phone ? entity.phone : 'n/a'}}</span>
                        </mat-cell>
                    </ng-container>
                    <ng-container cdkColumnDef="companyName">
                        <mat-header-cell mat-sort-header *cdkHeaderCellDef>Company</mat-header-cell>
                        <mat-cell *cdkCellDef="let entity">
                            <span>{{entity.contactType == 'attorney' ? 'n/a' : (entity.companyName ? entity.companyName : '')}}</span>
                        </mat-cell>
                    </ng-container>
                    <ng-container cdkColumnDef="address">
                        <mat-header-cell mat-sort-header *cdkHeaderCellDef>Address</mat-header-cell>
                        <mat-cell *cdkCellDef="let entity">
                            <span>{{entity.address ? entity.city : 'n/a'}}, {{entity.address && entity.zip ? entity.zip : ''}}</span>
                        </mat-cell>
                    </ng-container>
                    <ng-container cdkColumnDef="city">
                        <mat-header-cell mat-sort-header *cdkHeaderCellDef>City</mat-header-cell>
                        <mat-cell *cdkCellDef="let entity">
                            <span>{{entity.city ? entity.city : 'n/a'}}</span>
                        </mat-cell>
                    </ng-container>
                    <ng-container cdkColumnDef="state">
                        <mat-header-cell mat-sort-header *cdkHeaderCellDef>State</mat-header-cell>
                        <mat-cell *cdkCellDef="let entity">
                            <span>{{entity.state ? entity.state : 'n/a'}}</span>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *cdkHeaderRowDef="participantColumns; sticky: true"></mat-header-row>
                    <mat-row class="reg-row" *cdkRowDef="let row; columns: participantColumns; let i = index">
                    </mat-row>
                </mat-table>
                <div style="align-self:center;" *ngIf="!participantSource.data.length">No Participants found</div>
                <button style="width:160px;margin-bottom:10px;" color=primary mat-raised-button (click)="editParticipant()">
                    <mat-icon>library_add</mat-icon>
                    Add Participant
                </button>
            </resource-list-pane>
        </resource-list-grid> 
    -->

    <!-- 
        - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
        -
        -   Documents
        -
        - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    -->

        
       <resource-list-grid *ngIf="formHasControl">
            <resource-list-pane title="Documents" >
                <div class="orderProperties">
                    <div class="row">
                        <div class="column">
                            <lib-order-documents [showUploadControls]="false" [cc]="false" *ngIf="caseDocumentSource.data[0]" 
                                [documents]="caseDocumentSource.data ? caseDocumentSource.data : []"
                                [order]="undefined"
                                [allowBatchDownloading]="true">
                            </lib-order-documents>
                        </div>
                    </div>
                </div>
            </resource-list-pane>
        </resource-list-grid> 
        <!--<resource-list-grid *ngIf="formHasControl">
            
            <resource-list-pane title="Documents" [collapsable]='true'>

                <mat-paginator #documentPaginator="matPaginator" 
                    [pageSizeOptions]="[5,10]"
                    showFirstLastButtons>
                </mat-paginator>

                <mat-table multiTemplateDataRows class="tableClamp" [dataSource]="documentOrderSource" matSort #document="matSort" (matSortChange)="sortData('document')">
                    <ng-container cdkColumnDef="view">
                        <mat-header-cell *cdkHeaderCellDef>Download</mat-header-cell>
                        <mat-cell *cdkCellDef="let entity" >
                            <button matTooltip="Get Download URL" 
                                mat-icon-button 
                                *ngIf="!downloadURLs.has(entity.document.uuid)"
                                (click)="getUrls(entity)">
                                <mat-icon>download</mat-icon>
                            </button>
                            <a mat-icon-button *ngIf="downloadURLs.has(entity.document.uuid)" [href]="downloadURLs.get(entity.document.uuid)" 
                                target="_blank" 
                                matTooltip="Open in new tab">
                                <mat-icon>open_in_new</mat-icon>
                            </a>
                        </mat-cell>
                    </ng-container>
                    <ng-container cdkColumnDef="orderNumber">
                        <mat-header-cell mat-sort-header *cdkHeaderCellDef>Order Number</mat-header-cell>
                        <mat-cell *cdkCellDef="let entity">
                            <span>{{entity.orderNumber ? entity.orderNumber : ''}}</span>
                        </mat-cell>
                    </ng-container>
                    <ng-container cdkColumnDef="document.fileName">
                        <mat-header-cell mat-sort-header *cdkHeaderCellDef>Filename</mat-header-cell>
                        <mat-cell *cdkCellDef="let entity">
                            <span>{{entity.document.fileName ? entity.document.fileName : ''}} </span>
                        </mat-cell>
                    </ng-container>
                    <ng-container cdkColumnDef="document.name">
                        <mat-header-cell mat-sort-header *cdkHeaderCellDef>Name</mat-header-cell>
                        <mat-cell *cdkCellDef="let entity">
                            <span>{{entity.document.name ? entity.document.name : ''}}</span>
                        </mat-cell>
                    </ng-container>
                    <ng-container cdkColumnDef="document.status">
                        <mat-header-cell mat-sort-header *cdkHeaderCellDef>Staus</mat-header-cell>
                        <mat-cell *cdkCellDef="let entity">
                            <span>{{entity.document.status ? entity.document.status : ''}}</span>
                        </mat-cell>
                    </ng-container>
                    <ng-container cdkColumnDef="document.fileType">
                        <mat-header-cell mat-sort-header *cdkHeaderCellDef>File Type</mat-header-cell>
                        <mat-cell *cdkCellDef="let entity">
                            <span>{{entity.document.fileType ? entity.document.fileType : ''}}</span>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *cdkHeaderRowDef="documentOrderColumns; sticky: true"></mat-header-row>
                    <mat-row class="reg-row" *cdkRowDef="let row; columns: documentOrderColumns; let i = index">
                    </mat-row>
                </mat-table>
                <div style="align-self:center; margin-bottom: 1%;" *ngIf="!documentOrderSource.data.length">No Documents found</div>
            </resource-list-pane> 
        </resource-list-grid>-->
    </form>
    <resource-list-grid *ngIf="noCaseFound">
        <resource-list-pane title="Case Details">
            <h3>No Case Found</h3>
        </resource-list-pane>
    </resource-list-grid>

</div>
