
<resource-list-grid>
    <resource-list-pane>

        <div class="loading" *ngIf="isLoading">
            <div class="loading-text">Loading Notifications</div>
            <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
        </div>

        <div [hidden]="isLoading">
            <mat-paginator
                (page)="pageChange($event)"
                [pageSizeOptions]="dataSource.pageSizeOptions"
                [pageIndex]="dataSource.pageIndex"
                [pageSize]="dataSource.pageSize"
                showFirstLastButtons>
            </mat-paginator>
            <mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" multiTemplateDataRows id="orderTable">

                <ng-container cdkColumnDef="select">
                    <mat-header-cell *cdkHeaderCellDef>
                        <mat-checkbox
                            color="primary"
                            (change)="selectAll()"
                            [checked]="selection.hasValue() && allSelected()"
                            (click)="selectionChange()">
                        </mat-checkbox>
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let entity">
                        <mat-checkbox
                            color="primary"
                            (change)="selection.toggle(entity)"
                            [checked]="selection.isSelected(entity)"
                            (click)="selectionChange($event)">
                        </mat-checkbox>
                    </mat-cell>
                </ng-container>
                
                <ng-container cdkColumnDef="view">
                    <mat-header-cell class="view" *cdkHeaderCellDef></mat-header-cell>
                    <mat-cell class="view" *cdkCellDef="let entity">
                        <button mat-icon-button *ngIf="!entity.deletedAt" >
                            <mat-icon>list_alt</mat-icon>
                        </button>
                        <button mat-icon-button *ngIf="entity.deletedAt" (click)="restoreEntry(entity, $event)">
                            <mat-icon>restore</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <ng-container cdkColumnDef="subject">
                    <mat-header-cell mat-sort-header *cdkHeaderCellDef>Subject</mat-header-cell>
                    <mat-cell *cdkCellDef="let entity">
                        <span>{{entity.subject}}</span>
                    </mat-cell>
                </ng-container>

                <ng-container cdkColumnDef="notificationTypeId">
                    <mat-header-cell mat-sort-header *cdkHeaderCellDef>Notification Type</mat-header-cell>
                    <mat-cell *cdkCellDef="let entity"><span>{{noticeTypes.get(entity.notificationTypeId)}}</span></mat-cell>
                </ng-container>

                <ng-container cdkColumnDef="createdAt">
                    <mat-header-cell mat-sort-header *cdkHeaderCellDef>Created At</mat-header-cell>
                    <mat-cell *cdkCellDef="let entity"><span>{{entity.createdAtUTC | reflexTimeAgo}}</span></mat-cell>
                </ng-container>

                <ng-container cdkColumnDef="deliveredAt">
                    <mat-header-cell mat-sort-header *cdkHeaderCellDef>Delivered At</mat-header-cell>
                    <mat-cell *cdkCellDef="let entity"><span>{{entity.deliveredAt ? (entity.deliveredAt | reflexTimeAgo) : "Not Delivered Yet" }}
                    </span></mat-cell>
                </ng-container>

                <mat-header-row *cdkHeaderRowDef="columnsToDisplay; sticky: true"></mat-header-row>
                <mat-row *cdkRowDef="let entity; columns: columnsToDisplay"
                    class="entity-row"
                    [class.unviewed]="entity.viewedAt == null"
                    (click)="openNotification(entity)"
                    >
                </mat-row>
            </mat-table>
            <div *ngIf="isLoading" style="justify-content: center;display: flex;margin: 10px 0;" color="primary">
                <mat-spinner></mat-spinner>
            </div>

            <mat-paginator
                (page)="pageChange($event)"
                [pageSizeOptions]="dataSource.pageSizeOptions"
                [pageIndex]="dataSource.pageIndex"
                [pageSize]="dataSource.pageSize"
                showFirstLastButtons>
            </mat-paginator>
        </div>
    </resource-list-pane>
    <resource-list-pane *ngIf="filterFormGroup" title="Filters" id="filterPaneNotification" #filter="resourceListPane" [isCollapsed]='filterCollapsed' [collapsable]='true' class="entity-filters">
        <form class="filters" (ngSubmit)="filterNotices()" [formGroup]="filterFormGroup">
            <mat-label style="align-self:center;">Deleted</mat-label> 
            <br><hr class="solid" style="width:100%">

            <mat-button-toggle-group 
                style="width:min-content; align-self:center;"
                formControlName='deletedAt'
                color="primary"
                value="either">
                <mat-button-toggle value="deleted">Is</mat-button-toggle>
                <mat-button-toggle value="">Not</mat-button-toggle>
                <mat-button-toggle value="either">Either</mat-button-toggle>
            </mat-button-toggle-group>
			<br>

            <mat-label style="align-self:center;">Viewed/Unviewed</mat-label> 
            <br><hr class="solid">

            <mat-button-toggle-group 
                style="width:min-content; align-self:center;"
                formControlName='viewedAt'
                color="primary"
                value="either">
                <mat-button-toggle value="either">Either</mat-button-toggle>
                <mat-button-toggle value="viewed">Viewed</mat-button-toggle>
                <mat-button-toggle value="unviewed">Unviewed</mat-button-toggle>
            </mat-button-toggle-group>
            <mat-form-field>
                <input matInput
                    formControlName="subject"
                    placeholder="Subject"/>
            </mat-form-field>
            <mat-form-field>
                <input matInput
                    formControlName="notificationType"
                    placeholder="Type"/>
            </mat-form-field>
            <mat-form-field>
				<mat-label>Start Date</mat-label>
				<input matInput 
					formControlName="createdAt_start" 
					resource-filter-context="greaterThanEqual"
					[matDatepicker]="startNotificationDatePicker">
				<mat-datepicker-toggle matSuffix [for]="startNotificationDatePicker"></mat-datepicker-toggle>
				<mat-datepicker #startNotificationDatePicker></mat-datepicker>
			</mat-form-field>

			<mat-form-field>
				<mat-label>End Date</mat-label>
				<input matInput 
					formControlName="createdAt_end" 
					resource-filter-context="lessThanEqual" 
					[matDatepicker]="endNotificationDatePicker">
				<mat-datepicker-toggle matSuffix [for]="endNotificationDatePicker"></mat-datepicker-toggle>
				<mat-datepicker #endNotificationDatePicker></mat-datepicker>
			</mat-form-field>

            <div class="filterActions">
                <button mat-flat-button type="button" (click)="filterNotices(true)">
                    Clear
                </button>
                <button mat-raised-button color="primary" type="submit">
                    Search
                </button>
            </div>
        </form>
    </resource-list-pane>
</resource-list-grid>