import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { ProjectStatus } from '../models/projectStatus.model';

@Injectable()
export class ProjectStatus_Service extends ResourceService<ProjectStatus> {
  protected servicePath: string = '/ProjectStatus';

  public ModelType = ProjectStatus;

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }
}
