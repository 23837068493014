import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { 
    PermissionNode, 
    SuccessAccountPermissionNode 
} from '../models';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class SuccessAccountPermissionNode_Service extends ResourceService<SuccessAccountPermissionNode> {
    protected servicePath: string = '/SuccessAccountPermissionNode';

    private _successAccountPermissionNode: SuccessAccountPermissionNode[] = [];
    get successAccountPermissionNode(): SuccessAccountPermissionNode[] {
        return this._successAccountPermissionNode;
    }
    set successAccountPermissionNode(data: SuccessAccountPermissionNode[]) {
        this._successAccountPermissionNode = data;
    }

    private _isDataSynced = false;
    get isDataSynced(): boolean {
        return this._isDataSynced;
    }
    set isDataSynced(status: boolean) {
        this._isDataSynced = status;
        this.dataSync.next(this._isDataSynced);
    }

    public ModelType = SuccessAccountPermissionNode;
    public dataSync: BehaviorSubject<boolean> = new BehaviorSubject(this._isDataSynced);

    constructor(
        protected http: HttpClient
    ) {
        super(http);
        this.syncSuccessAccountPermissionNodeData();
    }
  
    public syncSuccessAccountPermissionNodeData(){
        this.allSuccessAccountPermissionNode()
        .then(
            (response: any) => {
                this.isDataSynced = true;
            }
        )

    }

    public allSuccessAccountPermissionNode(): any {
        return this
            .http
            .get(
                this.serviceUrl,
                {
                    observe: 'response'
                }
            )
            .pipe(
                tap((response: any) => {
                    for(let x in response.body)
                    {
                        if(response.body[x].deletedAt == null){
                            this.successAccountPermissionNode.push(response.body[x])
                        }
                    }
                })
            )   
            .toPromise();
    }
    
    public push(entry: SuccessAccountPermissionNode): Observable<SuccessAccountPermissionNode> {
        
        if(!entry.successAccount_uuid) {
            entry.permissionNode_uuid = "";
        }

        if(!entry.permissionNode_uuid){
            entry.permissionNode_uuid = "";
        }
        
        return super.push(entry);
    }

    public getPermissionNodesBySuccessAccount(data: {successAccount_uuid: string}): Observable<Array<PermissionNode>> {
        // console.log('TFTEST get permission node by SuccessAccount', data);
        return this
            .http
            .post(
                this.serviceUrl + 'getPermissionNodesBySuccessAccount',
                {
                    successAccount_uuid: data.successAccount_uuid,
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => 
                        response.body.map((e:PermissionNode) => new PermissionNode(e))
                )
            );
    }
}
