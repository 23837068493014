import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { ClientFile } from '../models/clientFile.model';

@Injectable()
export class ClientFile_Service extends ResourceService<ClientFile> {
    protected servicePath: string = '/ClientFile';

    public ModelType = ClientFile;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }
}
