import { Field } from '@smartsoftware/reflex-core';

import { DexResourceModel } from '../abstracts/dexResource.model';

export class ClientCompany extends DexResourceModel {

	@Field(String)
    public clientId: string = "";

	@Field(String)
	public companyId: string = "";

	constructor(data?: any) {

		super();

		this.mapData(data);

	}

}