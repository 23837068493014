<resource-list-grid *ngIf="entity">
    <resource-list-pane [collapsable]='true' title="Details Panel">
        <div class="orderProperties">
            <div class="row">
                <div class="column">    
                    <div class="property">
                        <label>Set Name</label>
                        <span>{{ entity.name }}</span>
                    </div>
                    <div class="property">
                        <label>Auto Fetch</label>
                        <span>{{ entity.autoFetch ? 'Yes' : 'No' }}</span>
                    </div>
                    <div class="property">
                        <label>Is enabled?</label>
                        <span>{{entity.deletedAt === null ? 'Yes' : 'No'}}</span>
                    </div>
                    <div class="property">
                        <label>Last Sync Date</label>
                        <span>{{entity.ftp_fetchFrequency === 'Never' ? 'Never' : (entity.updatedAt.toString() | reflexTimeAgo)}}</span>
                    </div>
                </div>
            </div>
        </div>
    </resource-list-pane> 
    <resource-list-pane title="Client Branches">
        <mat-table [dataSource]="clientBranches" matSort>
            <ng-container cdkColumnDef="name">
                <mat-header-cell mat-sort-header *cdkHeaderCellDef>Name</mat-header-cell>
                <mat-cell *cdkCellDef="let entry">{{entry.name}}</mat-cell> 
            </ng-container>
            <mat-header-row *cdkHeaderRowDef="['name']; sticky: true"></mat-header-row>
            <mat-row *cdkRowDef="let row; columns: ['name']"></mat-row>
        </mat-table>
    </resource-list-pane>
    <resource-list-pane title="User Accounts">
        <mat-table [dataSource]="successAccounts" matSort>
            <ng-container cdkColumnDef="email">
               <mat-header-cell mat-sort-header *cdkHeaderCellDef>Email</mat-header-cell>
               <mat-cell *cdkCellDef="let entry">{{entry.email}}</mat-cell> 
            </ng-container>
            
            <mat-header-row *cdkHeaderRowDef="['email']; sticky: true"></mat-header-row>
            <mat-row *cdkRowDef="let row; columns: ['email']"></mat-row>
        </mat-table>
        <mat-paginator #account [pageSizeOptions]="[5, 10, 20]"
            showFirstLastButtons
            aria-label="Select page of Client Matter Entries">
        </mat-paginator>
    </resource-list-pane>
</resource-list-grid>

    
<div class="grid">
    <div class="grid_col-6">
        <page-CME-list *ngIf="entity && entity.uuid" [clientMatterSet_uuid]="entity.uuid">
        </page-CME-list>
       <!-- <resource-list-pane title="Client Matter Entry List">
            <mat-table [dataSource]="CME_data" matSort>
                <ng-container cdkColumnDef="matter">
                   <mat-header-cell mat-sort-header *cdkHeaderCellDef>Matter</mat-header-cell>
                   <mat-cell *cdkCellDef="let entry">{{entry.matter}}</mat-cell> 
                </ng-container>
                <ng-container cdkColumnDef="name">
                    <mat-header-cell mat-sort-header *cdkHeaderCellDef>Name</mat-header-cell>
                    <mat-cell *cdkCellDef="let entry">{{entry.name}}</mat-cell> 
                 </ng-container>
                 <ng-container cdkColumnDef="updatedAt">
                    <mat-header-cell mat-sort-header *cdkHeaderCellDef>Last updated</mat-header-cell>
                    <mat-cell *cdkCellDef="let entry">{{entry.updatedAt.toString() | reflexTimeAgo}}</mat-cell> 
                 </ng-container>
             
                 <mat-header-row *cdkHeaderRowDef="['matter', 'name', 'updatedAt']; sticky: true"></mat-header-row>
                <mat-row *cdkRowDef="let row; columns: ['matter', 'name', 'updatedAt']"></mat-row>     
            </mat-table>
    
            <mat-paginator #CME [pageSizeOptions]="[5, 10, 20]"
                showFirstLastButtons
                aria-label="Select page of Client Matter Entries">
            </mat-paginator>
        </resource-list-pane> -->
    </div>
</div>