import { Injectable } from "@angular/core";
import { ResourceService } from "@smartsoftware/reflex-core";
import { SuccessCountry } from "../models/country.model";
import { HttpClient } from '@angular/common/http'


@Injectable()
export class SuccessCountry_Service extends ResourceService<SuccessCountry>{
    protected servicePath: string = '/Country';
    public ModelType = SuccessCountry;

    constructor( protected http: HttpClient ){
        super(http);
    }
}