import {  Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export enum ContactPreference {
    email="email", phone="phone", sms="sms"
}
export enum ContactTrigger {
    immediate="immediate", schedule="schedule", pause="pause"
}
export enum ContactFrequency {
    once_daily="once_daily", multiple_daily="multiple_daily"
}
export enum Day {
    M="M", Tu="Tu", W="W", Th="Th", F="F", Sat="Sat", Sun="Sun"
}

type UUID = string;

export class SuccessAccountSettings extends DexResourceModel {

    @Field(String)
	public successAccount_uuid: UUID = "";
    @Field(String)
    public timezoneCode: string | null = null;

    @Field(String)
    public alternateEmail: string = "";
    @Field(String)
    public mobilePhone: string = "";
    @Field(String)
    public businessPhone: string = "";
    @Field(String)
    public businessPhoneExtension: string = "";

    /* Notifications ------------------------------------------------------------------ */
    @Field(String) 
    public contactPreference: ContactPreference = ContactPreference.email;
	@Field(String) 
    public contactTriggerOption: ContactTrigger = ContactTrigger.immediate;

    // when the trigger is set to temporarily pause, we can restore previous selection
    // pause until x time, then restore original setting
    // public previousTriggerOption: ContactTrigger | null = null;
	
    @Field(String) 
    public contactFrequencyOption: ContactFrequency = ContactFrequency.once_daily;

    // fixed amount of custom notification send times. 
    // don't think there would ever be more than a handful of these
    // stores the nth minute of the day
    @Field(Number) 
    public scheduleSendTime1: number | null = null;
    @Field(Number) 
    public scheduleSendTime2: number | null = null;
    @Field(Number) 
    public scheduleSendTime3: number | null = null;

    /**
     * Dot Delimited days string
     */
    @Field(String) 
    public scheduleDays: string = "";

    @Field(Date) 
    public pauseNotificationsFromDate: Date | null = null;
    @Field(Date) 
    public pauseNotificationsToDate: Date | null = null;


    // fixed amount of CC's on notifications.
    // don't think there would ever be more than a handful of these
    @Field(String) 
    public notificationCC1: string | null = null;
    @Field(String) 
    public notificationCC2: string | null = null;
    @Field(String) 
    public notificationCC3: string | null = null;

    /*  ------------------------------------------------------------------------------- */

    /* Case and Job Preferences ------------------------------------------------------- */
    @Field(Boolean) 
    public casePreferenceAdvanceFees: boolean = false;
    @Field(Boolean) 
    public casePreferenceAlwaysDeliverCourtesyFeesBack = false;

    @Field(Boolean) 
    public hasNoContactHours = false;
    @Field(Number) 
    public noContactFromTime: number | null = null;
    @Field(Number) 
    public noContactToTime: number | null = null;
    
    /*  Insurance ------------------------------------------------------------------------------- */
    @Field(String)
    public insuranceCompany: string | null = null;
    @Field(String)
    public insuranceAdjuster: string | null = null;
    @Field(Boolean)
    public insuranceInherited: boolean = false;

    /*  ------------------------------------------------------------------------------- */

    @Field(Boolean) 
    public twoFactorEnabled: boolean = false;
    @Field(String) 
    public linkedToAccount: UUID | null = null;

    @Field(Date)
	public createdAt: Date = new Date();
	@Field(Date)
	public updatedAt: Date = new Date();
    @Field(Date)
	public deletedAt: Date | null = null;	

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

    public get data(): any {
        let json = super.data;

        return json;
    }

    static readonly MAX_SCHEDULE_TIMES = 3;
    static readonly MAX_NOTIFICATION_CCS = 3;

    get scheduledTimes(): number[] {
        return [this.scheduleSendTime1, this.scheduleSendTime2, this.scheduleSendTime3].filter(x => x != null) as number[]
    }
    set scheduledTimes(value: number[]) {
        let [one, two, three] = value;
        this.scheduleSendTime1 = one || null;
        this.scheduleSendTime2 = two || null;
        this.scheduleSendTime3 = three || null;
    }

    get notificationCCs(): string[] {
        return [this.notificationCC1, this.notificationCC2, this.notificationCC3].filter(x => x != null) as UUID[];
    }
    set notificationCCs(value: string[]) {
        let [one, two, three] = value;
        this.notificationCC1 = one || null;
        this.notificationCC2 = two || null;
        this.notificationCC3 = three || null;
    }

    get notificationScheduleDays(): Day[] {
        if (!this.scheduleDays)
            return [];
        return this.scheduleDays.split('.') as Day[];
    }

    set notificationScheduleDays(value: Day[]) {
        this.scheduleDays = value.join('.');
    }

    static convertTimeStringToMinutes (time: string)  {
        if (!time || time.length == 0) {
            return null;
        }
        let [hour, minute] = time.split(':');
        // transform to the nth minute of the day
        let t = Number.parseInt(hour) * 60 + Number.parseInt(minute);
        return t;
    }
    static convertMinutesToTimeString (time: number | null) {
        if (!time)
            return null;
        let minutes = Math.floor(time % 60);
        let hour = Math.floor(time / 60);
        let hh = hour < 10 ? '0' + hour : hour;
        let mm = minutes < 10 ? '0' + minutes : minutes;
        return `${hh}:${mm}`;
    }

}
