import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { OrderCache } from '../models/orderCache.model';
import { ResourceListOptions, ResourceService } from "@smartsoftware/reflex-core";
import { Observable, merge } from "rxjs";
import { map, tap } from 'rxjs/operators';

@Injectable()
export class OrderCache_Service extends ResourceService<OrderCache> {
    
    protected servicePath = '/OrderCache';
    public ModelType = OrderCache;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }

    public list(options: ResourceListOptions = {}) : Observable<Array<OrderCache>> {
        let sources : Observable<Array<OrderCache>>[] = [];

        let cachePrefix = options.where ? window.btoa(options.where).replace(/=+$/,'') : '';

        // Load the cache unless explicitly requested not to
        if(options.allowCache !== false)
            sources.push(
                this.listFromCache(cachePrefix)
            )

        if(!options.email && options.byUser) {
            console.error('Error: missing email');
            return merge<OrderCache[],OrderCache[]>(...sources);
        }

        if(!options.corpId && options.byCorp) {
            console.error('Error: missing corpId');
            return merge<OrderCache[],OrderCache[]>(...sources);
        }

        let filters: orderCacheUserFilter = {
            email: undefined,
            corpId: undefined,
            division: undefined,
            requestedBy: undefined,
            orderNumber: undefined,
            reference: undefined,
            caseNumber: undefined,
            orderStatus: undefined,
            serviceType: undefined,
            subject: undefined,
            deliveryLocation: undefined,
            deliveryAddress: undefined,
            caseName: undefined,
            claimNumber: undefined
        };
        Object.keys(filters).map((key:string) => {
            if(options[key]) {
                filters[key as keyof orderCacheUserFilter] = options[key];
            }
            if(!filters[key  as keyof orderCacheUserFilter]) {
                delete filters[key  as keyof orderCacheUserFilter];
            }
        }); 

        let requestOptions :any = {
            observe: 'response',
            body: filters
        };
        // console.log('TFTEST request options', requestOptions);
        let requestUrl = this.serviceUrl + (options.byUser ?  'getCacheByUser' : 'getCacheByCorp');
        console.log('IS IT THIS? requestUrl', requestUrl);
        
        // Reload from server unless explicitly requested not to
        if(options.reloadFresh !== false) {
            sources.push(
                this.http
                    .request<OrderCache>
                    (
                        'post',
                        requestUrl,
                        requestOptions
                    )
                    .pipe(
                        map<any, OrderCache[]>(
                            response => {
                                if(!response.body.success) {
                                    console.error(response.body.message);
                                    return [] as OrderCache[];    
                                }

                                return response.body.result.map((e: any) => new OrderCache(e))
                            }
                        ),
                        tap(
                            // Update the cache for future loads
                            (entities) => this.listToCache(cachePrefix, entities)
                        )
                    )
            )
        }

        return merge<OrderCache[], OrderCache[]>(...sources);
    }

    public getCacheByUser(filter: orderCacheUserFilter): Observable<Array<OrderCache>> {
        return this
            .http
            .post(
                this.serviceUrl + 'getCacheByUser',
                filter,
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => {
                        if(!response.body.success) {
                            console.error(response.body.message)
                            return [];
                        }

                        return response.body.result.map((e: OrderCache) => new OrderCache(e))
                    }
                )
            );
    }

    public getCacheByCorp(filter: orderCacheUserFilter): Observable<Array<OrderCache>> {
        return this
            .http
            .post(
                this.serviceUrl + 'getCacheByCorp',
                filter,
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => {
                        if(!response.body.success) {
                            console.error(response.body.message)
                            return [];
                        }

                        return response.body.result.map((e: OrderCache) => new OrderCache(e))
                    }
                )
            );
    }

}

interface orderCacheUserFilter {
    email?: string,
    corpId?: string,
    division?: string,
    requestedBy?: string,
    orderNumber?: string,
    reference?: string,
    caseNumber?: string,
    orderStatus?: string,
    serviceType?: string,
    subject?: string,
    deliveryLocation?: string,
    deliveryAddress?: string,
    caseName?: string,
    claimNumber?: string
}