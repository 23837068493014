


import { Injectable } from "@angular/core";
import { ResourceService } from "@smartsoftware/reflex-core";
import { Telemetry, TelemetryEventType } from "../models/telemetry.model";
import { HttpClient } from '@angular/common/http'
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { SuccessAccount } from "../models";
import { SuccessAccount_Service } from "./successAccount.service";
// import { SuccessAccount } from "legalreflex-lib";

@Injectable()
export class Telemetry_Service extends ResourceService<Telemetry>
{
    protected servicePath: string = '/Telemetry';
    public ModelType = Telemetry;

    constructor( protected http: HttpClient, router: Router, account: SuccessAccount_Service )
    { 
        super(http); 
        router.events.subscribe(navEvent =>
        {
            account.user.subscribe(successAcct =>
            {
                if(successAcct.uuid && navEvent instanceof NavigationEnd)
                    this.announce(TelemetryEventType.RouteChange, router.url, successAcct.uuid).subscribe();
            })
        });
    }

    public announce(eventType: TelemetryEventType, route?: ActivatedRoute | string, successAccount?: SuccessAccount | string, jsonData?: object | string)
    {
        // console.log(`announce called:\n ev:${eventType}, r:${route}, sa:${successAccount}, jd:${jsonData}`);
        let telemetricObject = new Telemetry();
        telemetricObject.eventType = eventType;
        if(route)
        {
            if(route instanceof ActivatedRoute)
                route.url.subscribe(event => telemetricObject.route = event.toString())
            else
                telemetricObject.route = route;
        }
        if(successAccount)
        {
            if(successAccount instanceof SuccessAccount)
                telemetricObject.successAccount_uuid = successAccount.uuid;
            else
                telemetricObject.successAccount_uuid = successAccount;
        }
        if(jsonData)
        {
            if(typeof jsonData !== 'string')
                telemetricObject.jsonData = JSON.stringify(jsonData);
            else
                telemetricObject.jsonData = jsonData;
        }
        // return telemetricObject;
        // if(telemetricObject.successAccount_uuid)
        //     telemetricObject.uuid  = telemetricObject.successAccount_uuid;
        // console.log(`${this.serviceUrl}${telemetricObject.uuid}`);
        // console.log(`TelemetricObject: ${JSON.stringify(telemetricObject)}`);
        return this.http.post<Telemetry>(`${this.serviceUrl}${telemetricObject.uuid}`, telemetricObject, {
            reportProgress: true,
            responseType: 'json',
            observe: 'response'
        });
        // return this.http.get<Telemetry>(`${this.serviceUrl}${telemetricObject.uuid}`);
    };
}