import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { BaseFile } from './baseFile.model';

export class LRFile extends BaseFile {

    @Field(String)
    public filename: string = "";
    @Field(String)
    public filesize: number = 0;
    @Field(String)
    public partnerVendor_uuid: string | null = null;
    @Field(String)
    public fileType_uuid: string | null = null;

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}
