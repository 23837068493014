import {Component, Inject, Input } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { 
    PASSWORD_MAX_LENGTH,
    PASSWORD_MIN_LENGTH
} from 'legalreflex-lib';

// const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}/gm;
// const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#\$%\^&\*\(\)_+-=<>,\.\?/\{\}\[\]])[A-Za-z\d~!@#\$%\^&\*\(\)_+-=<>,\.\?/\{\}\[\]]{8,}/gm;
// const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\d\D]{12,72}/gm;
const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[\\d\\D]{" + PASSWORD_MIN_LENGTH + "," + PASSWORD_MAX_LENGTH + "}");

@Component({
  selector: 'first-login-dialog',
  templateUrl: 'firstLogin.html',
})
export class FirstLoginDialog {
    public badPassword: boolean = false;
    public passwordVisibility: 'password' | 'text' = 'password';
    public specialSymbolList =` ~!@#$%^&*()_-+=<>,.?/{}[]`;
    PASSWORD_MAX_LENGTH = PASSWORD_MAX_LENGTH;
    PASSWORD_MIN_LENGTH = PASSWORD_MIN_LENGTH;
    
    constructor(
        private dialogRef : MatDialogRef<FirstLoginDialog>,
        @Inject(MAT_DIALOG_DATA) public data: UserData
    ) {
    }

    togglePasswordVisibility() {
        this.passwordVisibility = this.passwordVisibility == 'password' ? 'text' : 'password';
    }

    get visibilityIcon() {
        return this.passwordVisibility == 'password' ? 'visibility_off' : 'visibility';
    }
    
    submit() {
        // this.badPassword = this.data.password.search(passwordRegex) == -1;
        this.badPassword = !passwordRegex.test(this.data.password);
        
        if(!this.badPassword) {
            // console.log('first login: data:', this.data);
    
            this.dialogRef.close(this.data);
        } 
    }
}

export interface UserData {
    username: string,
    password: string
}