<h1 mat-dialog-title>Order Failed</h1>
<div mat-dialog-content>
    <p>{{data.message}}<br>
        <br>
        You may save the order as a Draft by clicking the button below. <br>
        <span *ngIf="data.failedDocuments.length">
            Or you may try to upload the documents again by clicking 'Retry Upload' <br>
        </span>
        If you need immediate assistance, please contact our Client Care department <br>
        by using the Support button or by emailing 
        <a href='mailto:fcsupport@firstlegal.com'>fcsupport@firstlegal.com</a>
    </p>
</div>
<div mat-dialog-actions>
    <!-- <button mat-raised-button mat-dialog-close (click)="close()">Close</button> -->
    <button mat-raised-button (click)="saveAsDraft()">Save as Draft</button>
    <!-- retry for failed documents, only show if documents failed -->
    <button mat-raised-button color="primary" *ngIf="data.failedDocuments.length" (click)="retryUpload()">Retry Upload</button>
</div>