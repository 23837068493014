import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { ClientUser } from '../models/clientUser.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ClientUser_Service extends ResourceService<ClientUser> {
    protected servicePath: string = '/ClientUser';

    public ModelType = ClientUser;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }

    public push(entry: ClientUser): Observable<ClientUser> {

        if(!entry.client_uuid){
            entry.client_uuid = null;
        }
        if(!entry.successAccount_uuid){
            entry.successAccount_uuid = null
        }

        return super.push(entry);
    }

}
