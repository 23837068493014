import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class OrderService extends DexResourceModel {

    @Field(String)
    public order_uuid: string = "";
    
    @Field(Number)
    public vendor_id: number | null = null;

    @Field(String)
    public serviceType: string | null = null;
    
    @Field(String)
    public description: string | null = null;

    @Field(Number)
    public jobNumber: number | null = null;;

    //declared already on ResourceModel
    // @Field(String)
    // public status: string | null = null;
    public orderServiceStatus: string | null = null;

    @Field(Number)
    public numberOfAttempts: number | null = null;

    @Field(Number)
    public numberOfAffidavits: number | null = null;

    get data(): any {
        let d = super.data;
        d.status = this.orderServiceStatus;
        return d;
    };

    protected mapData(data?: any): void {
        super.mapData(data);
        this.orderServiceStatus = data?.status;
    }

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}
