import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { 
    SuccessAccountRole, 
} from '../models';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

@Injectable()
export class SuccessAccountRole_Service extends ResourceService<SuccessAccountRole> {
    protected servicePath: string = '/SuccessAccountRole';

    public ModelType = SuccessAccountRole;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }

    public createSuccessAccountRole(data: SuccessAccountRole): Observable<SuccessAccountRole> {
        return this
            .http
            .post(
                this.serviceUrl + 'createSuccessAccountRole',
                {
                    role_uuid: data.role_uuid, 
                    successAccount_uuid: data.successAccount_uuid,
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => {
                        if(!response.body.success) 
                            throw new Error("Could not assign role to SuccessAccount");
                        
                        return new SuccessAccountRole(response.body.result);
                    }
                )
            );
    }

    // Moved to Role_Service
    // public getRolesBySuccessAccount(data: {successAccount_uuid: string}): Observable<Array<Role>> {
    //     //console.log('TFTEST get roles by SuccessAccount', data);
    //     return this
    //         .http
    //         .post(
    //             this.serviceUrl + 'getRolesBySuccessAccount',
    //             {
    //                 successAccount_uuid: data.successAccount_uuid,
    //             },
    //             {
    //                 observe: 'response'
    //             }
    //         )
    //         .pipe(
    //             map(
    //                 (response: HttpResponse<any>) => 
    //                     response.body.result.map((e:Role) => new Role(e))
    //             )
    //         );
    // }

}
