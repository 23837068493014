import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';

import { RoutesModule } from './routes/routes.module';
import { AestheticsModule } from './aesthetics/aesthetics.module';
import { ComponentsModule } from './components/components.module';
import { ApiHealth_Service, CheckForUpdateService, LegalReflexLibModule } from 'legalreflex-lib';
import { NavigationModule } from '@smartsoftware/reflex-core';

import { SiteFrameComponent, HttpActivityInterceptor } from './components/siteFrame/siteFrame';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthModule, LogLevel,  AuthInterceptor, OidcConfigService } from 'angular-auth-oidc-client';
import { ReflexEnvironment as environment } from '@smartsoftware/reflex-core';
import { AuthFrame } from './routes/auth/frame/frame';

export function configureAuth(oidcConfigService: OidcConfigService, apiHealthService: ApiHealth_Service) {
	// return () =>
	// 	oidcConfigService.withConfigs([{
	// 		//for local:
	// 		authority: 'https://127.0.0.1:4280',
	// 		//for production:
	// 		//authority: 'https://auth.firstlegal.io',
	// 		//for staged:
	// 		//authority: 'https://auth.stage.firstlegal.io',
	// 		redirectUrl: window.location.origin,
	// 		postLogoutRedirectUri: window.location.origin,
	// 		clientId: 'successconnect',
	// 		scope: 'openid profile email authorization_code token',
	// 		responseType: 'code',
	// 		silentRenew: true,
	// 		silentRenewUrl: `${window.location.origin}/silent-renew`,
	// 		logLevel: LogLevel.Error,
	// 		//logLevel: LogLevel.Debug,
	// 		eagerLoadAuthWellKnownEndpoints: true,
	// 		authWellknownEndpointUrl: `http://127.0.0.1:4280/.well-known/openid-configuration`
	// 	}]).then(
	// 	() => {
	// 		console.log('Auth server responded');
	// 		apiHealthService.isAuthAvailable = true;
	// 	});

	return () => {
		let { apiEnvironment, resourcePrefix, baseDomain, flapiUrl, openapiUrl } = environment.config;

		oidcConfigService.withConfigs([{
			//for production/development:
			authority: environment.config['stsServer'],
			redirectUrl: window.location.origin,
			postLogoutRedirectUri: window.location.origin,
			clientId: 'successconnect',
			scope: 'openid profile email authorization_code token',
			responseType: 'code',
			silentRenew: true,
			silentRenewUrl: `${window.location.origin}/silent-renew`,
			logLevel: LogLevel.Error,
			//logLevel: LogLevel.Debug,
			eagerLoadAuthWellKnownEndpoints: true,
			unauthorizedRoute: '/unauthorized',
			secureRoutes: [
				`https://${apiEnvironment}.${resourcePrefix}.${baseDomain}`,
				flapiUrl,
				openapiUrl
			]
		}])
		.then(
			() => {
				console.log('Auth server responded');
				apiHealthService.isAuthAvailable = true;
				// if(window.location.href.includes('/?error=')) {
				if(window.location.href.includes('error=')) {
					window.localStorage.setItem('error', window.location.href.slice(window.location.href.indexOf('error=')));
				}
			},
			(error) => {
				console.log('ERROR: Could not reach auth server');
				apiHealthService.isAuthAvailable = false;
			}
		);
	}
}

@NgModule({
	declarations: [
		SiteFrameComponent,
		AuthFrame
	],
	imports: [
		CommonModule,
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		RoutesModule,
		RouterModule,
		AestheticsModule,
		ComponentsModule,
		NavigationModule,
		LegalReflexLibModule,
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: true,
			// Register the ServiceWorker after 5 seconds
			registrationStrategy: 'registerWithDelay:5000'
		  }),

		AuthModule.forRoot({})
	],
	exports: [
		AuthModule
	],
	providers: [
		OidcConfigService,
		CheckForUpdateService,
	    {
	      provide: APP_INITIALIZER,
	      useFactory: configureAuth,
	      deps: [OidcConfigService, ApiHealth_Service],
	      multi: true,
	    },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpActivityInterceptor,
			multi: true
		},
		Title
	],
	bootstrap: [
		SiteFrameComponent
	]
})
export class AppModule { }