import {Component, Inject } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';



@Component({
  selector: 'file-size-dialog',
  templateUrl: 'fileSize.html',
})
export class FileSizeDialog {
    constructor(@Inject(MAT_DIALOG_DATA) public data: {files:string, size:number}) {}
}
