import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { SuccessCaseAccess } from '../models/successCaseAccess.model';
import { Observable } from 'rxjs';

@Injectable()
export class SuccessCaseAccess_Service extends ResourceService<SuccessCaseAccess> {
    protected servicePath: string = '/SuccessCaseAccess';

    public ModelType = SuccessCaseAccess;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }

}
