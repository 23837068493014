import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { RateTemplate } from '../models/rateTemplate.model';

@Injectable()
export class RateTemplate_Service extends ResourceService<RateTemplate> {
    protected servicePath: string = '/RateTemplate';

    public ModelType = RateTemplate;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }
}
