import { Field } from "@smartsoftware/reflex-core";
import { DexResourceModel } from "../abstracts";

export class Document extends DexResourceModel {

    @Field(String)
    public name: string | null = null;

    //declared already on ResourceModel
    // @Field(String)
    // public status: string | null = null;
    public docStatus: string | null = null;

    @Field(String)
    public fileType: string | null = null;

    @Field(String)
    public fileName: string | null = null;

    @Field(String)
    public vendorFileName: string | null = null;

    @Field(String)
    public documentType: string | null = null;

    @Field(Boolean)
    public isRelayed: Boolean = false;


    get data(): any {
        let d = super.data;
        d.status = this.docStatus;
        return d;
    };

    protected mapData(data?: any): void {
        super.mapData(data);
        this.docStatus = data?.status;
    }

    constructor(data?: any) {
        super();
        this.mapData(data);
    }
}