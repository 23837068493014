import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class UserIdentity extends DexResourceModel {

    @Field(String)
    public partnerVendor_uuid: string | null = null;
    @Field(String)
    public identity: string = "";
    @Field(Date)
    public expiresAt: Date | null = null;

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}
