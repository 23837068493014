import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { ClientCode } from '../models/clientCode.model';

@Injectable()
export class ClientCode_Service extends ResourceService<ClientCode> {
  protected servicePath: string = '/ClientCode';

  public ModelType = ClientCode;

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }
}
