import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { OrderServiceAffidavit } from '../models/orderServiceAffidavit.model';
import { map } from 'rxjs/operators';

@Injectable()
export class OrderServiceAffidavit_Service extends ResourceService<OrderServiceAffidavit> {
    protected servicePath: string = '/OrderServiceAffidavit';

    public ModelType = OrderServiceAffidavit;
    
    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }

    public getServeManagerDocuments(url: string) {
        return this
            .http
            .post(
                this.serviceUrl + 'getServeManagerDocuments',
                {
                    url:url
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => {
                        return response;
                    }
                )
            )
    }
}
