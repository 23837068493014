import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class OrderServiceAttempt extends DexResourceModel {

    @Field(String)
    public orderService_uuid: string = "";
    
    @Field(Date)
    public attemptedAt: Date | null = null;

    @Field(Number)
    public vendor_id: number | null = null;

    //declared already on ResourceModel
    // @Field(String)
    // public status: string | null = null;
    public orderServiceAttemptStatus: string | null = null;

    @Field(String)
    public subStatus: string | null = null;
    
    public get attemptedAtUTC(): Date | null{
        return this.pacificDateAsUTC(this.attemptedAt);
    }

    get data(): any {
        let d = super.data;
        d.status = this.orderServiceAttemptStatus;
        return d;
    };

    protected mapData(data?: any): void {
        super.mapData(data);
        this.orderServiceAttemptStatus = data?.status;
    }

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}
