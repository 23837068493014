


import { Field } from "@smartsoftware/reflex-core";
import { DexResourceModel } from "../abstracts/dexResource.model";

export class OrderSpecialInstruction extends DexResourceModel {
    @Field(String)
    public order_uuid: string = "";
    @Field(String)
    public pickupInstruction: string | null = null;
    @Field(String)
    public pickupAdditionalInstruction: string | null = null;
    @Field(String)
    public deliveryInstruction: string | null = null;
    @Field(String)
    public deliveryAdditionalInstruction: string | null = null;

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

    public get data(): any {
        let json = super.data;

        return json;
    }
}