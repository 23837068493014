import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { ClientType } from '../models/clientType.model';

@Injectable()
export class ClientType_Service extends ResourceService<ClientType> {
  protected servicePath: string = '/ClientType';

  public ModelType = ClientType;

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }
}
