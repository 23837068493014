import { Component, Inject, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Notification } from 'legalreflex-lib';


@Component({
  selector: 'notificationRestore-dialog',
  templateUrl: 'notificationRestoreDialog.html',
  styleUrls: ['./notificationRestoreDialog.scss']

})
export class NotificationRestoreDialog {

  public decided: boolean = false;
  constructor(
    private dialogRef : MatDialogRef<NotificationRestoreDialog>,

    @Inject(MAT_DIALOG_DATA) 
    public data: Notification
  ) {
  }

  close(result:boolean){
    this.dialogRef.close(result)
  }
}
