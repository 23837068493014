import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface Breadcrumb {
    label: string;
    url ?: string;
}
export interface BreadcrumbAction {
    label: string;
    action ?: () => void;
    routerLink ?: any;
    isPrimary ?: boolean;
    icon: string;
    disabled ?: boolean;
    tooltip ?: string;
}

@Injectable({
    providedIn: 'root'
})
export class BreadCrumbService {
    public breadcrumbs: BehaviorSubject<Breadcrumb[]> = new BehaviorSubject<Breadcrumb[]>([]);
    public actions: BehaviorSubject<BreadcrumbAction[]> = new BehaviorSubject<BreadcrumbAction[]>([]);

}