<div class="wrap d-flex">


<mat-card *ngIf="isRedeemingResetCode">
    <mat-card-title>
        Reset Your Password
    </mat-card-title>
    <mat-card-subtitle>
        Please provide a new password.
    </mat-card-subtitle>
    <mat-card-footer>
        <div *ngIf="message">
            <div class="tag mat-elevation-z2" [class]="message.level">
                <mat-icon>{{message.level}}</mat-icon> 
                <div>{{message.message}}</div>
            </div>   
            <a (click)="gotoLogin()" mat-button *ngIf="message.level == 'info'">Login</a>
        </div>
        <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    </mat-card-footer>
    <mat-card-content>
        <form class="d-flex v-align" [formGroup]="resetForm" (submit)="submitResetForm()">
            <mat-form-field>
                <mat-label>New Password</mat-label>
                <input matInput type="password" formControlName="password" required />
                <mat-error *ngIf="password?.errors?.required">
                    Required
                </mat-error>
                <mat-hint>
                    {{PASSWORD_HINT}}
                </mat-hint>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Confirm New Password</mat-label>
                <input matInput type="password" formControlName="confirmedPassword" [errorStateMatcher]="errorStateMatcher" required />
                <mat-error *ngIf="confirmedPassword?.errors?.matchesOther">
                    Password does not match the new password
                </mat-error>
            </mat-form-field>
            <button mat-flat-button color="primary" [disabled]="resetForm.invalid || loading">Reset Password</button>
        </form>
    </mat-card-content>
</mat-card>
<mat-card *ngIf="!isRedeemingResetCode">
    <mat-card-title>
        Forgotten Username and/or Password
    </mat-card-title>
    <mat-card-subtitle>
        <p>Please provide the email address that is associated with your account. </p>
        <p>The sent email message will contain your username and a link to reset your password.</p>
    </mat-card-subtitle>
    <mat-card-footer>
        <div *ngIf="message" class="tag mat-elevation-z2" [class]="message.level">
            <mat-icon>{{message.level}}</mat-icon> 
            <div>{{message.message}}</div>
        </div>   
        <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    </mat-card-footer>
    <mat-card-content>
        <form class="d-flex v-align" [formGroup]="requestForm" (submit)="submitRequestForm()">
            <mat-form-field>
                <mat-label>Email</mat-label>
                <input matInput type="email" formControlName="email" required />
            </mat-form-field>
            <button mat-flat-button color="primary" [disabled]="requestForm.invalid || loading">Send Reset Email</button>
        </form>
    </mat-card-content>
</mat-card>
</div>