import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavigationModule, ResourceModule } from '@smartsoftware/reflex-core';
import { AestheticsModule } from '../../aesthetics/aesthetics.module';

import { LandingFrame } from './frame/frame';
import { DashboardView } from './dashboard/dashboard';
import { PasswordPromptDialog } from './dashboard/dialog/passwordPrompt';
import { UnauthorizedComponent, ForgotPasswordComponent } from 'legalreflex-lib';
import { FirstLoginDialog } from './dashboard/dialog/firstLogin';

const routes: Routes = [
	{
		path: '',
		component: LandingFrame,
		children: [
			{
				path: '',
				component: DashboardView
			},
			{
				path: 'dashboard',
				component: DashboardView
			}
		]
	},
    {
        path: 'unauthorized',
        component: UnauthorizedComponent
    },
	{
		path: 'forgot-password',
		component: ForgotPasswordComponent
	},
	{
		path: 'forgot-password/:code',
		component: ForgotPasswordComponent
	}
];

@NgModule({
	declarations: [
		LandingFrame,
		DashboardView,
		PasswordPromptDialog,
		FirstLoginDialog
	],
	imports: [
		CommonModule,
        AestheticsModule,
		ResourceModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,

		RouterModule.forChild(routes)
	],
	exports: [

	],
	bootstrap: [

	]
})
export class LandingModule { }
