import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { BaseFile } from './baseFile.model';

export class CaseFile extends BaseFile {

    @Field(String)
    public case_uuid: string | null = null;
    @Field(String)
    public file_uuid: string | null = null;

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}
