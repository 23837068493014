import { NgModule } from "@angular/core";
import { PhoneMask } from "./phone-number.directive";
import { UuidMask } from "./uuid.directive";
@NgModule({
    imports: [

    ],
    declarations: [
        PhoneMask,
        UuidMask
    ],
    providers: [

    ],
    exports: [
        PhoneMask,
        UuidMask
    ]
})
export class LegalReflexDirectivesModule { }
