<page-notice color="warn" *ngIf="missingCustomerOrCompany">
    <h1>Missing Customer and/or Company Number</h1>
    <p>This account is missing either customer number and/or company number.</p>
</page-notice>

<div *ngIf="!missingCustomerOrCompany">
	<page-notice color="warn" *ngIf="failed && loaded">
		<h1>Loading Failed</h1>
		<p>Failed trying to list invoices.</p>
	</page-notice>

	<div [hidden]="!loaded && !failed">
		<resource-list-grid>
			<resource-list-pane class="p-relative" title="Billing History">
				<mat-paginator
					[pageSizeOptions]="dataSource.pageSizeOptions"
					[pageIndex]="dataSource.pageIndex"
					[pageSize]="dataSource.pageSize"
					showFirstLastButtons>
				</mat-paginator>
		
				<mat-table [dataSource]="dataSource" matSort>
					<ng-container cdkColumnDef="Download">
						<mat-header-cell *cdkHeaderCellDef></mat-header-cell>
						<mat-cell *cdkCellDef="let entity">
							<button mat-icon-button (click)="downloadInvoice(entity)" matTooltip="Open PDF" matTooltipPosition="right">
								<mat-icon>list_alt</mat-icon>
							</button>
						</mat-cell>
					</ng-container>
							
					<ng-container cdkColumnDef="invoiceNumber">
						<mat-header-cell mat-sort-header *cdkHeaderCellDef> Invoice Number </mat-header-cell>
						<mat-cell *cdkCellDef="let entity"> {{entity.invoiceNumber}} </mat-cell>
					</ng-container>
			
					<ng-container cdkColumnDef="invoiceDate">
						<mat-header-cell mat-sort-header *cdkHeaderCellDef> Invoice Date </mat-header-cell>
						<mat-cell *cdkCellDef="let entity"> {{formatDate(entity.invoiceDate)}} </mat-cell>
					</ng-container>
			
					<ng-container cdkColumnDef="totalOrdersOnInvoice">
						<mat-header-cell mat-sort-header *cdkHeaderCellDef> Total Orders On Invoice </mat-header-cell>
						<mat-cell *cdkCellDef="let entity"> {{entity.totalOrdersOnInvoice}} </mat-cell>
					</ng-container>
			
					<ng-container cdkColumnDef="revenueAmount">
						<mat-header-cell class="currency" mat-sort-header *cdkHeaderCellDef> Service Fee </mat-header-cell>
						<mat-cell class="currency" *cdkCellDef="let entity"> {{(entity.revenueAmount? entity.revenueAmount : 0) | currency }} </mat-cell>
					</ng-container>
			
					<ng-container cdkColumnDef="nonRevenueAmount">
						<mat-header-cell class="currency" mat-sort-header *cdkHeaderCellDef> Advanced Fees </mat-header-cell>
						<mat-cell class="currency" *cdkCellDef="let entity"> {{(entity.nonRevenueAmount? entity.nonRevenueAmount : 0) | currency}} </mat-cell>
					</ng-container>
			
					<ng-container cdkColumnDef="invoiceAmount">
						<mat-header-cell class="currency" mat-sort-header *cdkHeaderCellDef> Invoice Amount </mat-header-cell>
						<mat-cell class="currency" *cdkCellDef="let entity"> {{(entity.invoiceAmount? entity.invoiceAmount : 0) | currency}} </mat-cell>
					</ng-container>
			
					<ng-container cdkColumnDef="balanceRemaining">
						<mat-header-cell class="currency" mat-sort-header *cdkHeaderCellDef> Balance Remaining </mat-header-cell>
						<mat-cell class="currency" *cdkCellDef="let entity"> {{(entity.balanceRemaining? entity.balanceRemaining : 0) | currency}} </mat-cell>
					</ng-container>
		
					<mat-header-row *cdkHeaderRowDef="columnsToDisplay; sticky: true"></mat-header-row>
					<mat-row *cdkRowDef="let row; columns: columnsToDisplay"></mat-row>
				</mat-table>
		
				<mat-paginator
					[pageSizeOptions]="dataSource.pageSizeOptions"
					[pageIndex]="dataSource.pageIndex"
					[pageSize]="dataSource.pageSize"
					showFirstLastButtons >
				</mat-paginator>
			</resource-list-pane>
		
			<resource-list-pane id="filterPaneInvoice" #filter="resourceListPane" [isCollapsed]='filterCollapsed' [collapsable]="true" title="Filters">
				<mat-form-field class="filter">
					<mat-label>Invoice Number</mat-label>
					<input matInput type="text" [(ngModel)]="filters.invoiceNumber" placeholder="Invoice Number">
				</mat-form-field>
		
				<mat-form-field>
					<mat-label>Start Invoice Date</mat-label>
					<input [(ngModel)]="filters.startInvoiceDate" matInput [matDatepicker]="startInvoiceDatePicker">
					<mat-datepicker-toggle matSuffix [for]="startInvoiceDatePicker"></mat-datepicker-toggle>
					<mat-datepicker #startInvoiceDatePicker></mat-datepicker>
				</mat-form-field>
				<mat-form-field>
					<mat-label>End Invoice Date</mat-label>
					<input [(ngModel)]="filters.endInvoiceDate" matInput [matDatepicker]="endInvoiceDatePicker">
					<mat-datepicker-toggle matSuffix [for]="endInvoiceDatePicker"></mat-datepicker-toggle>
					<mat-datepicker #endInvoiceDatePicker></mat-datepicker>
				</mat-form-field>
				
				<button class="d-block w-100" type="button" mat-button (click)="clearFilters();"> Reset </button>
				<button class="d-block w-100" type="button" mat-flat-button color="primary" (click)="applyFilters();"> Apply Filters </button>
			</resource-list-pane>
		</resource-list-grid>
		
		<page-notice color="warn" *ngIf="invoiceCount == 0">
			<h1>No Invoices Found</h1>
			<p>There are no invoices related to this account.</p>
		</page-notice>
		
		<page-notice color="accent">
			<h1>Need Assistance Accessing Archived Documents?</h1>
			<p>Older invoices may have been moved to our archives and are not directly accessible through our online system. If you need to retrieve archived invoices or require assistance with any documents, please reach out to our billing support team at <a href="mailto:clientcare@firstlegal.com">clientcare@firstlegal.com</a>. For recent Depositions and Records Retrieval invoices, please visit the corresponding portal to view billing related to that division.
			<br><br>For additional information, we invite you to review our detailed knowledge article that provides further insights on this topic.</p>
		</page-notice>
	</div>

	<div class="loading" [hidden]="loaded">
		<div class="loading-text">Loading Billing History</div>
		<mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
	</div>
</div>