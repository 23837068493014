import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { InvoiceTerm } from '../models/invoiceTerm.model';

@Injectable()
export class InvoiceTerm_Service extends ResourceService<InvoiceTerm> {
  protected servicePath: string = '/InvoiceTerm';

  public ModelType = InvoiceTerm;

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }
}
