import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { ClientMatterFtpLog } from '../models/clientMatterFtpLog.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Page, PageParams } from '../paged.datasource';

@Injectable()
export class ClientMatterFtpLog_Service extends ResourceService<ClientMatterFtpLog> {
    protected servicePath: string = '/ClientMatterFtpLog';

    public ModelType = ClientMatterFtpLog;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }

    public push(entry: ClientMatterFtpLog): Observable<ClientMatterFtpLog> {

        if(!entry.clientMatterSet_uuid){
            entry.clientMatterSet_uuid = null;
        }

        return super.push(entry);
    }

    page(pageParams: PageParams): Observable<Page<ClientMatterFtpLog>> {
        let params = new HttpParams()
            .append('params', btoa(JSON.stringify(pageParams)));

        return this.http.get<Page<ClientMatterFtpLog>>(`${this.serviceUrl}Page`, { params })
            .pipe(map(page => {
                page.items = page.items.map(i => new ClientMatterFtpLog(i));
                return page;
            }));
    }

}
