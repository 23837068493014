import { Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export enum ServiceTypeDocsRequired {
    Required = 'Required',
    Optional = 'Optional',
    None = 'None'
}

export class ServiceType extends DexResourceModel {
   
    @Field(String)
	public name: string = "";
    @Field(String)
	public displayName: string | null = null; 
    @Field(String)
	public filingServiceCategory_uuid: string = "";
    @Field(Number)
	public serviceType: number = 0;
    @Field(String)
	public description: string = "";
    @Field(Boolean)
    public isRush: boolean = false;
    @Field(Number) 
    public dayModifier: number = 0;
    @Field(Number) 
    public hourModifier: number = 0;
    @Field(Number) 
    public minuteModifier: number = 0;
    @Field(Boolean)
    public backendUseOnly: boolean = false;
    @Field(Number)
    public sortOrder: number = 0;
    @Field(Boolean)
    public isDefault: boolean = false;
    @Field(String)
    public documentsRequired: ServiceTypeDocsRequired = ServiceTypeDocsRequired.Optional;
    @Field(Boolean)
    public pickupAddressRequired: boolean = false;
    @Field(Boolean)
    public isHourly: boolean = false;
    @Field(Boolean)
	public requiresFullData: boolean = true;
    @Field(Boolean)
    public deliveryAddressRequired: boolean = true;

    @Field(Date)
    public createdAt: Date = new Date();
	@Field(Date)
	public updatedAt: Date = new Date();
    @Field(Date)
	public deletedAt: Date | null = null;	
	
    constructor(data?: any) {
        super();
        this.mapData(data);
    }

    public get data(): any {
        let json = super.data;

        return json;
    }

}
