import { Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class VendorAccount extends DexResourceModel {
	@Field(String)
    public vendor_uuid: string = "";
    @Field(String)
    public successAccount_uuid: string = "";
    @Field(String)
    public username: string | null = null;
    @Field(String)
    public email: string | null = null;
    @Field(String)
    public vendorName: string = "";
    @Field(Date)
	public createdAt: Date = new Date();
	@Field(Date)
	public updatedAt: Date = new Date();
    @Field(Date)
	public deletedAt: Date | null = null;	

	constructor(data?: any) {
        super();
        this.mapData(data);
    }

    public get data(): any {
        let json = super.data;
        if (this.uuid)
            json.uuid = this.uuid;
        return json;
    }
}