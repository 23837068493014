import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class Case extends DexResourceModel {

    @Field(String)
    public name: string = "";

    @Field(String)
    public caseNumber?: number | null = null;

	@Field(String)
	public vendor_uuid?: string | null;

	@Field(String)
	public successAccount_uuid?: string | null;

	@Field(String)
	public corpId?: string | null;

	@Field(String)
	public clientMatterNumber?: string | null;

	@Field(Boolean)
	public isRestricted?: boolean;

	@Field(String)
	public addressBook_uuid?: string | null;
    
    @Field(String)
	public frequentCallerId: string = "";

    @Field(String)
    public check: string = "";
    @Field(String)
    public activeStatus: string = "";

    public set_Status(){
        if(this.deletedAt == null)
            this.activeStatus = "active";

        else{
            this.activeStatus = "inactive"
        }
            
    }

    public setRestriction(val : boolean){
        if(val == true)
        this.isRestricted = false;
        else{
            this.isRestricted = true;
        }
    }
    
    constructor(data?: any) {
        super();
        this.mapData(data);
    }

    public get data(): any {
        let json = super.data;

        json.caseNumber = this.caseNumber;

        return json;
    }

}
