import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutoLoginPartialRoutesGuard as AuthGuard } from 'angular-auth-oidc-client';
import { NavigationModule, ResourceModule, AuthGuardService } from '@smartsoftware/reflex-core';
import { AestheticsModule } from '../../aesthetics/aesthetics.module';

import { CanLeaveService, LegalReflexDirectivesModule, LegalReflexLibModule } from 'legalreflex-lib';
import { ClientMatterFrame } from './frame/frame';
import { ClientMatterList } from './list/list';
import { ClientMatterView } from './view/view';
import { ComponentsModule } from '../../components/components.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CME_List } from '../../../../../legalreflex-site/src/app/routes/client-matter-validation/view/view.CME';



const routes: Routes = [
    {
        path: 'clientMatter',
        component: ClientMatterFrame,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: ClientMatterList
            },
            {
                path: ':id',
                component: ClientMatterView,
                canDeactivate: [CanLeaveService]
            }
        ]
    }
];

@NgModule({
    declarations: [
        ClientMatterFrame,
        ClientMatterList,
        ClientMatterView,
        CME_List
    ],
    imports: [
       /* CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AestheticsModule,
        ComponentsModule,
        ResourceModule,
        BrowserModule,
        LegalReflexLibModule,
        RouterModule,*/

        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AestheticsModule,
        ComponentsModule,
        ResourceModule,
        BrowserModule,
        BrowserAnimationsModule,
        MatTooltipModule,
        RouterModule,
        LegalReflexLibModule,
        LegalReflexDirectivesModule,
        RouterModule.forChild(routes)
    ],
    exports: [

    ]
})
export class ClientMatterValidationModule { }
