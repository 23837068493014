import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'
import { ResourceService} from '@smartsoftware/reflex-core';
import { SuccessCaseContact } from '../models/successCaseContact.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SuccessCaseContact_Service extends ResourceService<SuccessCaseContact> {
    protected servicePath: string = '/SuccessCaseContact';

    public ModelType = SuccessCaseContact;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }

}
