<div class="page-frame">
    <h1>Unauthorized</h1>

    <div class="row">
		<div class="column" *ngIf="!auth.isAuthenticated()">
            <p>You are not authenticated and anonymous users are not authorized to access this resource.</p>
            <p>If you believe this is a mistake, reach out to support to have your permissions properly configured.</p>
			<button mat-raised-button routerLink="">Return to Dashboard</button>
        </div>
        <div class="column" *ngIf="auth.isAuthenticated()">
            <p>You are authenticated, but not authorized to access this resource.</p>
            <p>If you believe this is a mistake, reach out to support to have your permissions properly configured.</p>
			<button mat-raised-button routerLink="">Return to Dashboard</button>
        </div>
    </div>
</div>
