import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class RateTemplate extends DexResourceModel {

    @Field(String)
    public name: string = "";

    @Field(String)
	public consultationRate: string = "";

	@Field(String)
	public projectManagementRate: string = "";

	@Field(String)
	public contractAttorneyReviewRate: string = "";

	@Field(String)
	public analystRate: string = "";

	@Field(String)
	public analystProjectManagementRate: string = "";

	@Field(String)
	public expertRate: string = "";

	@Field(String)
	public expertAfterHoursRate: string = "";

	@Field(String)
	public standardRate: string = "";

	@Field(String)
	public forensicCollectionRate: string = "";

	@Field(String)
	public trialServicesRate: string = "";

	@Field(String)
	public fullDataProcessing: string = "";

	@Field(String)
	public nativeDataProcessing: string = "";

	@Field(String)
	public reviewPlatformHosting: string = "";

	@Field(String)
	public reviewPlatformUserLicenses: string = "";

	@Field(String)
	public dataStorage: string = "";

	@Field(String)
	public sharefileUser: string = "";

	@Field(String)
	public trialBoards: string = "";

	@Field(String)
	public trialEquipment: string = "";

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}
