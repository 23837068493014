import { Injectable } from '@angular/core'

import { Observable, BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { HttpClient, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { scan } from 'rxjs/operators';
import JSZip from 'jszip';

/**
 * Class for requesting and maintaining file downloads 
 * on the site
 * TODO: Make the notifications pop cross tab. Fade the glyph after 10s of inactivity
 */
@Injectable({
    providedIn: 'root'
})
export class ReflexDownloaderService {

    public downloadStatus = new BehaviorSubject<string>("");
    public downloadProgress = new BehaviorSubject<number | null>(null);
    public displayDetails = new BehaviorSubject<boolean>(false);
    private _fileGroups: ReflexDownloadGroup[] = [];
    private _chunkSize: string = '5mb';
    private _concurrentChunks: number = 6;

    constructor(
        private httpClient: HttpClient
    ) {
    }

    public get chunkSize() {
        return this._chunkSize;
    }

    public set chunkSize(size: string) {
        //TODO: add validation to the size string
        this._chunkSize = size;
    }

    public get concurrentChunks() {
        return this._concurrentChunks;
    }

    public set concurrentChunks(chunks: number) {
        // Only use a positive int as the chunk number
        let c = Math.round(chunks)
        if(c > 0)
            this._concurrentChunks = chunks;
    }

    /**
    * Requests a download for a group of multiple files with option to compress them
    * @param {ReflexFileGroup} fileGroup Group of files to be downloaded
    */
    public requestGroupDownload(fileGroup: ReflexDownloadGroup) {
        // Set the worker up to download the group
        let observables = this.downloadMultipleFiles(fileGroup)
        let index = 0;
        // Attach the request subscription to the ReflexDownloadFiles in case they need to be cancelled later
        observables.forEach((observable) => {
            let subscription : Subscription = observable.subscribe((file: ReflexDownloadFile) => {
            })
            fileGroup.files[index].requestSubscription = subscription;
            ++index;
        })
        
        // Keep track of the new group
        this._fileGroups.unshift(fileGroup);

        // Whenever a group updates its progress, update the global progress
        fileGroup.downloadProgress.subscribe(() => {
            let totalProgress = 0.0;
            if(this.fileGroups.length) {
                this.fileGroups.forEach(group => {
                    totalProgress += group.downloadProgress.value;
                });

                this.downloadProgress.next(totalProgress / this.fileGroups.length);
            } else {
                this.downloadProgress.next(null);
            }
        })
        // Same for status
        fileGroup.downloadStatus.subscribe(() => {
            if(this.fileGroups.length) {
                let allDone = true;
                // Go through each group and check its status
                this.fileGroups.forEach(group => {
                    if(group.downloadStatus.value != 'DONE')
                        allDone = false;
                })
                // Update whether or not all groups are done
                let progress = Number(this.downloadProgress.value);
                this.downloadStatus.next((allDone || progress >= 100) ? 'DONE' : 'IN PROGRESS')
            } else {
                this.downloadStatus.next('NO FILE GROUPS')
            }
        });
    }

    /**
    * Requests a download for an array of files with option to compress them
    * @param {ReflexDownloadFile[]} files Group of files to be downloaded
    * @param {string} displayName? Name to be displayed in the downloader window
    * @param {boolean} shouldCompress Whether or not the group can be downloaded as a compressed zip. Default false
    * @param {string} compressedFileName? Name the compressed file is downloaded as
    */
    public requestBatchDownload(files: ReflexDownloadFile[], displayName?: string, shouldCompress: boolean = false, compressedFileName?: string) {
        // Save the targeted files as a group
        let groupToDownload = new ReflexDownloadGroup(displayName, shouldCompress, compressedFileName);
        groupToDownload.files = files;
        this.requestGroupDownload(groupToDownload);
    }

    public get fileGroups() {
        return this._fileGroups;
    }

    /**
    * Toggle for the display the reflex-download component watches for detail display
    * @param {boolean} state? Whether or not to display download details. No input will flip the current state
    */
    public toggleDetails(state?: boolean) {
        if(state === undefined) {
            this.displayDetails.next(!this.displayDetails.value);
        } else {
            this.displayDetails.next(state);
        }
    }

    /**
    * Calculates the current progress and status of a group based on its children
    * @param {ReflexDownloadGroup} fileGroup The group to update
    */
    private updateGroupProgress(fileGroup: ReflexDownloadGroup) {
        if(fileGroup.files.length) {
            let groupProgress = 0.0
            let allDone = true;
            // Go through each file and check its progress/status
            fileGroup.files.forEach(file => {
                groupProgress += file.downloadProgress.value;
                if(file.downloadStatus.value != 'DONE')
                    allDone = false;
            })
            // Update with the average progress and if all files are done
            let changeToDone = fileGroup.downloadStatus.value !== 'DONE' && allDone;
            fileGroup.downloadStatus.next(allDone ? 'DONE' : 'IN PROGRESS')
            fileGroup.downloadProgress.next(groupProgress / fileGroup.files.length);

            // If the group changed from in progress to done, automatically prompt the user to download.
            if(changeToDone) {
                this.saveFileGroup(fileGroup);
            }
        }
    }

    /**
    * Sets up the actual download requests of a file group
    * @param {ReflexFileGroup} fileGroup Group of files to be downloaded
    */
    private downloadMultipleFiles(fileGroup: ReflexDownloadGroup) {
        return fileGroup.files.map((downloadFile): Observable<ReflexDownloadFile> => {
            // Have the file group watch all the progress and status changes of its child files
            downloadFile.downloadProgress.subscribe(() => {
                this.updateGroupProgress(fileGroup);
            })
            downloadFile.downloadStatus.subscribe(() => {
                this.updateGroupProgress(fileGroup);
            })
            // TODO: Add preflight to split downloads into chunks if it is greater than 2 chunks
            // Make the get request
            return this.httpClient.get(downloadFile.url, {
                reportProgress: true,
                observe: 'events',
                responseType: 'blob'
            }).pipe(
                scan((downloadFile: ReflexDownloadFile, httpEvent: HttpEvent<Blob>, index: number): ReflexDownloadFile => {
                    //Handle each of the different event types
                    
                    // Request sent, no progress yet
                    if (httpEvent.type == HttpEventType.Sent) {
                        downloadFile.downloadProgress.next(0);
                        downloadFile.downloadStatus.next('PENDING');
                        downloadFile.body = null;
                        return downloadFile;
                    }

                    // Header should contain the length of the file, use it to track progress
                    if (httpEvent.type == HttpEventType.ResponseHeader) {
                        downloadFile.fileSize = Number(httpEvent.headers.get('Content-Length'));
                        downloadFile.downloadProgress.next(0);
                        downloadFile.downloadStatus.next('PENDING');
                        downloadFile.body = null;
                        return downloadFile;
                    }
                    
                    // Download has started, track loaded size against size provided in header
                    if (httpEvent.type == HttpEventType.DownloadProgress || httpEvent.type == HttpEventType.UploadProgress) {
                        downloadFile.downloadProgress.next(Math.round((100 * httpEvent.loaded) / downloadFile.fileSize));
                        downloadFile.downloadStatus.next('IN PROGRESS');
                        downloadFile.body = null;
                        return downloadFile;
                    }

                    // Request has fully completed
                    if (httpEvent.type == HttpEventType.Response) {
                        downloadFile.body = (httpEvent as HttpResponse<Blob>).body;
                        downloadFile.downloadProgress.next(100);
                        downloadFile.downloadStatus.next('DONE');
                        return downloadFile;
                    }

                    // We don't really expect this event type, so don't do anything with it
                    if (httpEvent.type == HttpEventType.User) {
                        return downloadFile;
                    }

                    throw new Error('unknown HttpEvent');

            }, downloadFile));
        });
    }

    /*
    * Returns an average of all the current file groups' progress
    */
    public totalDownloadProgress(): number | null {
        return this.downloadProgress.value;
    }

    /**
    * Saves a completed fileGroup to the user's device
    * TODO: Figure out trigger condition to remove groups/files from the service, aside from cancellation
    * @param {ReflexFileGroup} group Group of files to be saved
    */
    public saveFileGroup(group: ReflexDownloadGroup) {
        // If the whole group hasn't finished yet, we can't properly download it
        if(group.downloadStatus.value !== 'DONE')
            return;

        // Check for compression
        if(group.shouldCompress) {
            // Add all the files to a zip
            this.zip(group.files.map((file: ReflexDownloadFile) => {
                return {
                    fileData: file.body as Blob,
                    fileName: file.renameFileName ? file.renameFileName : file.url.split('/').pop() as string
                }
            })).subscribe({
                next: (data) => {
                    //console.log('zipping next');
                    // Update the zip progress
                    group.compressionProgress.next(data.progress);
                    // If we got a zip file in the data, the compression is done and we can save it
                    if (data.zipFile) {
                        this.downloadLocalFile(data.zipFile, group.compressedFileName ? group.compressedFileName : 'archive.zip')
                    }
                },
                complete: () => {
                    //console.log('zipping complete');
                },
                error: (error) => {
                    //console.log('zipping error');

                }
            });
        } else {
            // No compression, just try to download the files
            group.files.forEach((file) => {
                this.downloadLocalFile(file.body, file.renameFileName ? file.renameFileName : file.url.split('/').pop() as string)
            })
        }
    }

    /**
    * Prompts the user to save a file
    * @param {ReflexDownloadFile} file File to be saved
    */
    public saveFile(file: ReflexDownloadFile) {
        if(file.downloadStatus.value === 'DONE')
            this.downloadLocalFile(file.body, file.renameFileName ? file.renameFileName : file.url.split('/').pop() as string)
    }

    /**
    * Prompts the user to save a file
    * @param {Blob | null} body Contents of file to save
    * @param {string} name Name of file to save
    */
    private downloadLocalFile(body: Blob | null, name: string) {
        const downloadAnchor = document.createElement("a");
        downloadAnchor.style.display = "none";
        downloadAnchor.href = URL.createObjectURL(body);
        downloadAnchor.download = name;
        downloadAnchor.click();
        downloadAnchor.remove();
        //window.open(URL.createObjectURL(body), name)
    }

    /**
    * Cancels the download request of a file and removes it from the group by index
    * @param {ReflexDownloadGroup} group Group to remove a file from
    * @param {number} fileIndex Index of file to be removed
    */
    public cancelFile(group: ReflexDownloadGroup, fileIndex: number) {
        // Sanity check
        if(fileIndex < group.files.length) {
            // Cancel the download request
            group.files[fileIndex].requestSubscription?.unsubscribe();
            // Remove the file from the group
            group.files.splice(fileIndex, 1);
            // Update the group's progress
            this.updateGroupProgress(group);
        }

    }

    /**
    * Takes an array of objects containing a file body and name to zip up into an archive
    * @param {{fileName: string, fileData: Blob}[]} files Array of file names and bodies
    */
    private zip(files: { fileName: string, fileData: Blob }[]): Observable<ZipStatus<Blob>> {
        return new Observable((subscriber) => {
            const zip = new JSZip();

            // Feed each file into jszip
            files.forEach(file => {
                zip.file(file.fileName, file.fileData);
            });

            // Zip the file and provide progress updates
            zip.generateAsync({type: "blob", streamFiles: true}, (metadata) => {
                subscriber.next({
                    state: 'ZIPPING',
                    progress: metadata.percent,
                    zipFile: null
                });

            }).then(function (content) {
                subscriber.next({
                    state: 'DONE',
                    progress: 100,
                    zipFile: content
                });

                subscriber.complete();
            });
        });
    }
}

export interface ZipStatus<T> {
    progress: number;
    state: 'ZIPPING' | 'DONE';
    zipFile: Blob | null;
}

export class ReflexDownloadFile {
    url: string;
    renameFileName?: string;
    displayName?: string;
    fileSize: number = 0;
    body: Blob | null = null;
    requestSubscription: Subscription | null = null;

    downloadStatus = new BehaviorSubject<string>("");
    downloadProgress = new BehaviorSubject<number>(0.0);
    compressionProgress = new BehaviorSubject<number>(0.0);

    constructor(url: string, renameFileName?: string, displayName?: string) {
        this.url = url;
        this.renameFileName = renameFileName;
        this.displayName = displayName;
    }
}


export class ReflexDownloadGroup {
    displayName?: string;
    shouldCompress: boolean = false;
    compressedFileName?: string;

    downloadStatus = new BehaviorSubject<string>("");
    downloadProgress = new BehaviorSubject<number>(0.0);
    compressionProgress = new BehaviorSubject<number>(0.0);
    files: ReflexDownloadFile[] = [];

    constructor(displayName?: string, shouldCompress: boolean = false, compressedFileName?: string) {
        this.displayName = displayName;
        this.shouldCompress = shouldCompress;
        this.compressedFileName = compressedFileName ? compressedFileName : this.displayName?.replace(/[^\w\s]/gi, '').replace(/[\s]/gi, '_')
    }
}