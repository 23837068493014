import { Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Optional, Output, Self, ViewChild,  } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { Observable, Subject, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, switchMap, tap } from 'rxjs/operators';
import { LookupConfiguration } from './configurations';
import { MatFormFieldControl } from '@angular/material/form-field';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';


@Component({
  selector: 'app-lookup-input',
  templateUrl: './lookup.component.html',
  styleUrls: ['./lookup.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: LookupComponent }]
})
export class LookupComponent implements OnInit, OnDestroy, MatFormFieldControl<any>, ControlValueAccessor  {

  @Input() config: LookupConfiguration<any> | null = null;
  @Input() limit: number | undefined;
  @Input() readonly: boolean = false;
  @Input() sortFunction: ((a:any,b:any) => number) | undefined;
  @Input('aria-describedby') userAriaDescribedBy: string | undefined;
  @Output() selectedOption: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(MatAutocompleteTrigger) autoCompleteTrigger?: MatAutocompleteTrigger;

  public control: FormControl = new FormControl(null);
  public suggestions$: Observable<any[]> = new Observable<any[]>();

  public _selected: any | undefined;
  static _nextId = 0;
  private _placeholder: string | undefined;
  stateChanges = new Subject<void>();
  touched: boolean = false;
  focused: boolean = false;
  private _required = false;
  private _disabled = false;

  @HostBinding() id: string = `app-lookup-${LookupComponent._nextId++}`;
  onChange = (_: any) => {};
  onTouched = () => {};

  constructor(
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl,
  ) {
        // Replace the provider from above with this.
        if (this.ngControl != null) {
          // Setting the value accessor directly (instead of using
          // the providers) to avoid running into a circular import.
          this.ngControl.valueAccessor = this;
        }
   }
  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  get selected() {
    return this._selected;
  }

  set selected(val: any) {
    this._selected = val;
    this.values.unshift(this.config?.identifier(val));
    // don't need the whole sequence of changes, only need to keep around current and previous, drop the rest
    this.values = this.values.slice(0, 2); 
    this.control.setValue(val, {emitEvent: false});
    if (this.values.length > 1) {
      let current = this.values[0];
      let previous = this.values[1];
      if (current != previous)
        this.onChange(this.value);
    }
    else if (this.control.touched) {
      // adding this check, otherwise the initial value will trigger the onChange handler
      // and the form will 'think' this control has changes
      this.onChange(this.value);
    }
    //this.onChange(this.value);
    this.selectedOption.emit(val);
    this.stateChanges.next();
  }
  values: any[] = [];
  set value(id: any) {
    console.debug('setting value', id);
    if (!id) {
      this.selected = undefined;
    } else {
      this.config?.lookup(id)
        .pipe(
          tap(model => {
            this.selected = model;
          }),
        )
        .subscribe({
          error: e => {
            this.selected = undefined;
          }
        });
    }
  }

  get value() {
    let value = this.config?.identifier(this.selected);
    // if (value == '')
    //   return null;
    return value;
  }

  @Input()
  get placeholder() { return this._placeholder || ''; }
  set placeholder(val: string) {
    this._placeholder = val;
    this.stateChanges.next();
  }

  
  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }
  
  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  onInputBoxFocusOut(event: FocusEvent) {

    this.control.setValue(this.selected);
  }

  get empty(): boolean {
    return !!!this._selected
  }

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  

  get errorState(): boolean {
    return this.touched && this.ngControl.invalid != null && this.ngControl.invalid;
  }

  controlType?: string | undefined = 'app-lookup';

  autofilled?: boolean | undefined;

  setDescribedByIds(ids: string[]): void {
    const controlElement = this._elementRef.nativeElement
      .querySelector('.app-lookup-container')!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this._elementRef.nativeElement.querySelector('input')?.focus();
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
  }

  ngOnInit(): void {
    if (!this.config) 
      throw new Error('Required config not provided');
    let config = this.config;
    this.suggestions$ = this.control.valueChanges
      .pipe(
          startWith(''),
          debounceTime(400),
          distinctUntilChanged(),
          switchMap((term:any, i: number) => {
              // mat-autocomplete seems to set the value of control to be
              // one of the option values, so sometimes it isn't the text input value
              if (typeof term === 'string')
                return config.suggest(term, this.limit);
              return of([]);
          }),
          tap(options => {  
              if(this.sortFunction)
                  options.sort(this.sortFunction)
              if (options.length == 1) {
                  let [ option ] = options;
                  this.selected = option;
                  this.autoCompleteTrigger?.closePanel();
              }    
          })
      );
  }


  get displayWith() {
    return this.config?.display ? this.config.display : (option: any) => '';
  }

  _handleInput(control: AbstractControl, nextElement?: HTMLInputElement): void {
    
  }

  checkIfEmpty() {
    /*
        for some reason, this.control.valueChanges 
        doesn't emit an event when user clears the input
    */
    if (!this.control.value && this.selected) {
        this.selected = undefined;
    }
 }
}
