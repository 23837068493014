import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class ClientPartnerVendor extends DexResourceModel {

	@Field(String)
	public clientPartner_uuid?: string | null = null;

	@Field(String)
	public vendor_uuid?: string | null = null;

	@Field(String)
    public activeStatus : string = "";

    @Field(String)
    public vendorName : string = "";

	public set_Status(){
        if(this.deletedAt == null)
            this.activeStatus = "active";

        else
            this.activeStatus = "inactive"
    }

    public get_Status(){
        return this.activeStatus;
    }

    get active(): boolean {
        return this.deletedAt == null;
    }
    set active(active: boolean) {
        if (active) {
            this.deletedAt = null;
        } else {
            this.deletedAt = new Date();
        }
    }




	constructor(data?: any) {
		// Constructor calls empty super
		super();
        this.mapData(data);
	}
}
