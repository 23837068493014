import { Component, Inject, Input } from '@angular/core';
import { EmailValidator, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { Mailing_Service } from 'legalreflex-lib';
@Component({
    templateUrl: 'requestAcclaimAccount.html',
    styleUrls: ['./requestAcclaimAccount.scss']
})
export class RequestAcclaimAccountDialog {

    @Input('firstNameCtrl')
    public firstNameCtrl = new FormControl(this.data.firstName)

    @Input('lastNameCtrl')
    public lastNameCtrl = new FormControl(this.data.lastName)

    @Input('emailCtrl')
    public emailCtrl = new FormControl(this.data.email, Validators.email)

    @Input('firmCtrl')
    public firmCtrl = new FormControl(this.data.firm)

    @Input('branchCtrl')
    public branchCtrl = new FormControl(this.data.branch)

    @Input('attorneyNameCtrl')
    public attorneyNameCtrl = new FormControl(null)
    
    @Input('attorneyEmailCtrl')
    public attorneyEmailCtrl = new FormControl(null, Validators.email)

    public isFormComplete: boolean = false
    public attorneys: Array<attorneyInfo> = []

    constructor(
        private dialogRef : MatDialogRef<RequestAcclaimAccountDialog>,
        private mailing_service : Mailing_Service,

        @Inject(MAT_DIALOG_DATA) 
        public data: ServiceData
    ) {
        if(this.data.name){
            let fullName = this.data.name.split(' ')
            if(fullName.length == 2){
                this.data.firstName = fullName[0]
                this.data.lastName = fullName[1]
            }
        }
    }

    close() {
        this.dialogRef.close(false);
    }

    requestAccess() {
        if(!this.checkFields()){
            return
        }

        this.data.name = this.firstNameCtrl.value + " " + this.lastNameCtrl.value
        this.data.email = this.emailCtrl.value
        this.data.firm = this.firmCtrl.value
        this.data.branch = this.branchCtrl.value
        let requestedAttorneys = {attorneys:this.attorneys}
        this.mailing_service.sendFLDS(this.data, requestedAttorneys).subscribe((res:any)=>{
             console.log("res:", res) 
        }),((err:any)=>{console.log("err:", err)})
        this.dialogRef.close(this.data);
    }

    pushAttorney(){
        if((this.attorneyNameCtrl.value  && !this.attorneyEmailCtrl.value)|| (this.attorneyNameCtrl.value && this.attorneyEmailCtrl.value && !this.attorneyEmailCtrl.hasError('email'))){
            this.attorneys.push({name:this.attorneyNameCtrl.value, email: this.attorneyEmailCtrl.value})
            this.attorneyNameCtrl.reset()
            this.attorneyEmailCtrl.reset()
        }
    }

    checkFields(){
        let controls = {
            firstNameCtrl: this.firstNameCtrl,
            lastNameCtrl: this.lastNameCtrl,
            emailCtrl: this.emailCtrl,
            firmCtrl: this.firmCtrl,
            branchCtrl: this.branchCtrl
        }        

        let cons = Object.entries(controls).find((res)=>{
            if(res[1].errors)
                return res
            return undefined
        })

        if(cons != undefined){
            this.isFormComplete = false
            return false
        }

        this.isFormComplete = true
        return true
    }
}

export interface ServiceData {
    firstName: string,
    lastName: string,
    name: string,
    email: string,
    firm: string,
    branch: string,
}

export interface attorneyInfo {
    name: string,
    email: string | null
}