<ng-content></ng-content>

<div class="spacer position-fixed" *ngIf="isEdit">
	<button
		mat-raised-button
		type="button"
		[routerLink]="['../']">
		<mat-icon>chevron_left</mat-icon>
		Cancel
	</button>
	<span class="spacer"></span>
	<div matTooltip="Please enter one or more lookup properties below" [matTooltipDisabled]=!isEmpty >
		<button
			mat-raised-button
			*ngIf="!isLoading && isLookup"
			type="button"
			[disabled]=isEmpty
			(click)="click('getOrder')">
			Lookup
		</button>
	</div>

	<div matTooltip="Please fill all required(*) fields" [matTooltipDisabled]=!isEmpty >
		<button
			mat-raised-button
			*ngIf="!isLookup"
			[disabled]="isEmpty || isLoading"
			type="button"
			(click)="click('save')"
			[color]="ngForm?.dirty ? 'primary' : ''">
			Save
		</button>
	</div>
</div>
