import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { ResourceListOptions, ResourceService } from '@smartsoftware/reflex-core';
import { FLCompany } from '../models/flCompany.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Page, PageParams } from '../paged.datasource';

@Injectable()
export class FLCompany_Service extends ResourceService<FLCompany> {
    protected servicePath: string = '/FLCompany';

    public ModelType = FLCompany;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }

    public push(entry: FLCompany): Observable<FLCompany> {

        return super.push(entry);
    }

    list(options?: ResourceListOptions | undefined): Observable<FLCompany[]> {
        if (!options) {
            options = {};
        }
        if (!options.where) {
            options.where = {};
        }
        if (!('includeDeleted' in options.where)) {
            options.where.includeDeleted = true;
        }
        return super.list(options);
    }

    page(pageParams: PageParams): Observable<Page<FLCompany>> {
        let params = new HttpParams()
            .append('params', btoa(JSON.stringify(pageParams)));
    
        return this.http.get<Page<FLCompany>>(`${this.serviceUrl}Page`, { params })
            .pipe(map(page => {
                page.items = page.items.map(i => new FLCompany(i));
                return page;
            }));
    }
    
    public makeLookupConfig() {
        const service = this;
        let identifier = (type: FLCompany) => type && type.uuid;
        let lookup = (id: string) => service.get(id);
        let display = (type: FLCompany) => type && type.companyName;
        let list = service.list({reloadFresh:true});
        let suggest = (term: string) => {
            return list.pipe(
                map(types => {
                    term = term ? term.toLowerCase() : '';
                    return types.filter(t => {
                        return !term || (t.companyName && t.companyName.toLowerCase().includes(term));
                    });
                })
            );
        }
        return { identifier, lookup, display, suggest };
    }

}
