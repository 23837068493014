import { AbstractControl, FormControl, FormGroupDirective, NgForm, ValidationErrors } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { ResourceModel, ResourceService } from "@smartsoftware/reflex-core";
import { BehaviorSubject, Observable } from "rxjs";
import { debounceTime, map, switchMap, take } from "rxjs/operators";


export class OnChangeErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
      return !!(control && control.invalid && (control.dirty || control.touched));
    }
}

/**
 * Should actually validate top-level form, not individual control field
 * @param service 
 * @param entity 
 * @param hash 
 * @returns 
 */
export function uniqueResource <T extends ResourceModel>(service: ResourceService<T>, entity: T, key: keyof T) {

    let subject = new BehaviorSubject('');
    let filters = { deletedAt: { operator: 'IS', value: null }} as any;
    let pipeline: Observable<ValidationErrors | null> = subject.pipe(
        debounceTime(400),
        switchMap(val => {
            filters[key] = { operator: '=', value: val };
            return service.list({reloadFresh: true, allowCache:false, where: { filters } });
        }),
        take(1), // important, will never complete without this, leaving control in a quasi state of being as both valid and invalid at the same time
        map(entities => {
            let targetKey = filters[key].value; 
            for (let other of entities) {
                // cannot be a duplicate with itself
                if (entity.id == other.id) 
                    continue;
                let otherKey = other[key];
                if (otherKey === targetKey) {
                    return { unique: true};
                }
                    
            }
            return null;
        })
    );

    return (control: AbstractControl): Observable<ValidationErrors | null> => {
        subject.next(control.value);
        return pipeline;
    };
}

export const URLValidator = (control: AbstractControl): ValidationErrors | null => {
    if (!control || !control.enabled)
        return null;
    let value: string = control.value;
    if (!value)
        return null;
    let pattern = /^(https|http)\:\/\/[A-Za-z0-9]{1}[A-Za-z0-9\.\-]*/i
    let matches = value.match(pattern);
    if (matches && !(matches[0].endsWith(".") || matches[0].endsWith('-')))
        return null;
    return {
        url: true
    }
}

export const USZipCodeValidator = (control: AbstractControl): ValidationErrors | null => {
    const reg = /^\d{5}(?:[-\s]\d{4})?$/
    let value: string = control.value;
    if (!value)
        return null; // not required
    return reg.test(value) ? null : { zipcode: true };
}

export const dependsOn = (...others: AbstractControl[]) => {
    return (control: AbstractControl): ValidationErrors | null => {
        for (let other of others) {
            if (other.invalid || !other.value)
                return { dependsOn: true };
        }
        return null;
    }
}