import { ResourceModel, Field } from '@smartsoftware/reflex-core';

export class DexResourceModel extends ResourceModel {

    public get uuid(): string {
        return (this.id || '').toString();
    }
    public set uuid(value: string) {
        this.id = value;
    }

    /*
        When the database record is actually created the createdAt date is offset by -8
        hours to reflect pacific time, this gets recorded in the database. It becomes a
        problem when the JavaScript Date objects (instantiated by the mssql library) in
        `IResult` recordsets, are serialized to their JSON representation by AWS, probably
        with JSON.stringify.  
        The JSON representation is an ISO8601 formatted string and is UTC, but really it
        is in Pacific Time. This makes all the Updated, Created, Deleted On dates offset
        by 8 hours. 

        For Example: 

        SQL Server extension in VSCode displays a createdOn date for a model record of
        2023-12-05 12:45:22.0000000. This is 12:45 Pacific Time

        The same createdOn date in JSON from the API endpoints is:
        2023-12-05T12:45:22.000Z. This 12:45 UTC Time, which is an 8-hour difference.
     */

    public get createdAtUTC() : Date | null {
        return this.pacificDateAsUTC(this.createdAt);
    }

    public get updatedAtUTC() : Date | null {
        return this.pacificDateAsUTC(this.updatedAt);
    }

    public get deletedAtUTC() : Date | null {
        return this.pacificDateAsUTC(this.deletedAt);
    }

    protected pacificDateAsUTC(date: Date | null) : Date | null {
        if (!date)
            return null;
            let result = new Date(date);  
            
            // TEMP FIX: for displaying the times correctly based on location and DST.
            // DEV NOTE: attempted to use luxon library to fix this but typescript failed to compile.
            // this is likely due to the version of typescript defined in the site/package.json being < 4.3.
            // when the version was increased to 4.8.4 angular complained that it required typescript 4.0 - 4.2
            // so at some point someone may need to update both the angular and typescript version (and whatever else breaks)
            // but that will require more testing.
            let standardTime = new Date(result.getFullYear(), 0, 1); // January 
            let daylightTime = new Date(result.getFullYear(), 6, 1); // July
            
            let isDST = result.getTimezoneOffset() < Math.max(standardTime.getTimezoneOffset(), daylightTime.getTimezoneOffset());
    
            // offset is based on pacific time being -8 or -7 based on DST
            result.setHours(result.getHours() + (isDST ? 7 : 8)); // createdAt time is Pacific
            return result;
    }

    /**
     * Added by @jbethke because it's just stupidly convenient
     * @returns the current date in UTC
     */
    protected dateNow() {
        return new Date();
    }
}
