import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ApiHealth_Service {
    
    private _isAuthAvailable = false;
    get isAuthAvailable(): boolean {
        return this._isAuthAvailable;
    }

    set isAuthAvailable(status: boolean) {
        this._isAuthAvailable = status;
        this.authAvailable.next(this._isAuthAvailable);
    }

    public authAvailable: BehaviorSubject<boolean> = new BehaviorSubject(this._isAuthAvailable);

    public loading: boolean = false;

    constructor() {}
}