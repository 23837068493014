import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ResourceService } from "@smartsoftware/reflex-core";
import { ClientCompany } from "../models/clientCompanymodel";
import { Observable } from "rxjs";
import { Client } from "../models";

@Injectable()
export class ClientCompany_Service extends ResourceService<ClientCompany> {
    protected servicePath =  '/ClientCompany';

    public ModelType = ClientCompany;
 
    constructor(
        protected http: HttpClient
    ){
        super(http);
    }

    public getServiceUrl(): string {
        return this.serviceUrl
    }

    public getByClientId(clientId: string) : Observable<Array<ClientCompany>> {
        return this.http.get<ClientCompany[]>(`${this.serviceUrl}getByClientId?clientId=${clientId}`)
    }

    public updateClientCompanies(client: Client, clientCompanies: ClientCompany[]) {
        const body = {
            clientId: client.uuid,
            companyIds: clientCompanies.map(cc => cc.companyId)
        }
        return this.http.post<ClientCompany[]>(`${this.serviceUrl}updateByClientId`, body);
    }

}