import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  Notification, NotificationType_Service
} from 'legalreflex-lib';
import { render } from '../../../../../../../../src/Runtime/Logics/template.logic';

@Component({
  selector: 'notification-popup-dialog',
  templateUrl: 'notificationPopup.html',
  styleUrls: ['./notificationPopup.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class NotificationPopupDialog {

  public notificationSubject : string | null = null
  public notificationContent : string | null = ""
  public notificationHtml : string | null = ""
  public notificationCC : string | null = null
  public notificationTo : string | null = null

  constructor(
    private dialogRef : MatDialogRef<NotificationPopupDialog>,
    private notificationType : NotificationType_Service,

    @Inject(MAT_DIALOG_DATA) 
    public data: Notification)
    {
      this.notificationContent = data.contentText
      this.notificationHtml = data.contentHtml
      this.notificationSubject = data.subject
      this.notificationTo = data.emailTo
      this.notificationCC = data.emailCC
      if (data.notificationTypeId) {
        this.notificationType.get(data.notificationTypeId).subscribe((res)=>{
          if (res.jsonDataExampleBody && data.jsonData) {
              try {
                  const ctx = JSON.parse(data.jsonData)
                  this.notificationHtml = render(res.templateHtml? res.templateHtml: "Missing Template Html", ctx)
                  if(!this.notificationSubject)
                    this.notificationSubject = render(res.subject? res.subject : "Missing Template subject", ctx)
              } catch (err) {
                  alert(`Error parsing JSON example: ${err}`)
                  this.close()
              }
          } else {
            this.notificationHtml = res.templateHtml
          }          
        })
      }
      else if(data.jsonData){  
        this.notificationSubject = data.subject
        try {
          this.data.jsonData = JSON.parse(data.jsonData)
        } catch (error) {
          this.data.jsonData = JSON.parse('{"error":"' + error + '"}')
        }
        this.translateJsonProperties()
      }
    }

    ngOnInit() {
      this.dialogRef.keydownEvents().subscribe(event => {if (event.key === "Escape") {this.close()}})
      this.dialogRef.backdropClick().subscribe(event => {this.close()})
    }

    ngAfterViewInit() {
      const div = document.getElementById('content') as HTMLDivElement;
      div.innerHTML = this.notificationHtml? this.notificationHtml : ''
      const parent = document.getElementById('content-parent') as HTMLDivElement;
      parent.scrollTo(0,0)
      
    }

    close(resend?:string) {
      if(resend){
        this.data.deliveredAt = null;
        this.data.deliveryStatusResponse = null;
        this.data.viewedAt = null;
        this.data.deliveredBrowserPushNotification = false;
        let createdAt = new Date();
        createdAt.setHours(createdAt.getHours() - 8);
        this.data.createdAt = createdAt;
        return this.dialogRef.close({data:this.data})
      }
      if(this.data.viewedAt == null){
        this.data.viewedAt = new Date()
        return this.dialogRef.close({data:this.data})
      }
      this.dialogRef.close()
    }


    public translateJsonProperties(){
      if(this.data.jsonData == null){
        return ''
      }
      Object.entries(JSON.parse(this.data.jsonData)).map((res)=>{
        if(typeof res[1] === 'string' && this.data.contentHtml){
          let regExp = new RegExp("{{"+res[0]+"}}","g")
          this.data.contentHtml = this.data.contentHtml.replace(regExp, res[1])
        }
      })
      this.notificationContent = this.data.contentHtml
      return this.notificationContent
    }
}

