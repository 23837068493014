import { Component, Inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
    templateUrl: './frame.html',
    styleUrls: ['./frame.scss']
})
export class NotificationFrame {

    constructor(
        public pageTitleService: Title
    ) {
        this.pageTitleService.setTitle("FirstConnect - Notifications");
    }
}
