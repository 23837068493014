import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { PartnerVendorClient } from '../models/partnerVendorClient.model';

@Injectable()
export class PartnerVendorClient_Service extends ResourceService<PartnerVendorClient> {
    protected servicePath: string = '/PartnerVendorClient';

    public ModelType = PartnerVendorClient;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }
}
