


import { Field } from "@smartsoftware/reflex-core";
import { DexResourceModel } from "../abstracts/dexResource.model";

export class OrderNote extends DexResourceModel
{
    
    @Field(String)
    public note: string = "";
    @Field(String)
    public vendorUserId: string = "";
    @Field(Boolean)
    public printOnDispatchTicket: boolean = false;
    @Field(Boolean)
    public visibleToCustomers: boolean = false;
    @Field(String)
    public order_uuid: string = "";

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

    public get data(): any {
        let json = super.data;

        return json;
    }
    
}