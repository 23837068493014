import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http'
import { ResourceListOptions, ResourceService } from '@smartsoftware/reflex-core';
import {
    NotificationType,
    CaseUser,
    // CaseOwner,
    Note,
    Case
} from '../models';
import { Page, PageParams, Pager } from '../paged.datasource';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class NotificationType_Service extends ResourceService<NotificationType> implements Pager<NotificationType>{
    protected servicePath: string = '/NotificationType';

    public ModelType = NotificationType;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }

    page(pageParams: PageParams): Observable<Page<NotificationType>> {
        
        let params = new HttpParams()
        .append('params', btoa(JSON.stringify(pageParams)));
        
        return this.http.get<Page<NotificationType>>(`${this.serviceUrl}page`, { params })
            .pipe(map(page => {
                page.items = page.items.map(i => new NotificationType(i));
                return page;
            }));
    }

    list(options?: ResourceListOptions | undefined): Observable<NotificationType[]> {
        if (!options) {
            options = {};
        }
        if (!options.where) {
            options.where = {};
        }
        if (!('includeDeleted' in options.where)) {
            options.where.includeDeleted = true;
        }
        return super.list(options);
    }


    public syncWithStripo(uuid: string | number): Observable<NotificationType> {
        return this.http.post<NotificationType>(`${this.serviceUrl}syncWithStripo?uuid=${uuid}`,{});
    }

    public makeLookupConfig() {
        const service = this;
        let identifier = (type: NotificationType) => type && type.uuid;
        let lookup = (id: string) => service.get(id);
        let display = (type: NotificationType) => type && type.name;
        let suggest = (term: string) => {
            return service.list({reloadFresh:true}).pipe(
                map(types => {
                    term = term ? term.toLowerCase() : '';
                    return types.filter(t => {
                        return !term || (t.name && t.name.toLowerCase().includes(term));
                    });
                })
            );
        }
        return { identifier, lookup, display, suggest };
    }

    public getByInternalName(internalName: string) {

        let params = new HttpParams()
        .append('internalName', internalName);
        
        return this.http.get<NotificationType>(`${this.serviceUrl}getByInternalName`, { params });
    }
}
