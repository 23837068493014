import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class OrderDetail extends DexResourceModel {
	@Field(Number)
	public orderId: string | number = 0;
	@Field(String)
	public orderType: string = "";
	@Field(String)
	public orderStatus: String = "";
	@Field(Date)
	createdAt: Date = new Date();
	@Field(Date)
	updatedAt: Date = new Date();	

	constructor(data?: any) {
        super();
        this.mapData(data);
    }

    public get data(): any {
        let json = super.data;
        return json;
    }
}
