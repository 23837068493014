import { Component, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { Notification as Notice, Notification_Service, SuccessAccount_Service } from 'legalreflex-lib';
import { NotificationPopupDialog } from '../../routes/notification/dialog/notificationPopup';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'user-control',
	templateUrl: './userControl.html',
	styleUrls: ['./userControl.scss']
})
export class UserControlComponent implements OnInit {
	public recentNotifications: Notice[] = []
    public notificationLength: number = -1;
    public interval: number | undefined;// = setInterval(()=>{},1000); 
    //public channel = new BroadcastChannel('recent-notify');
    protected lastNotificationCall: Date = new Date();
    public waitTime: number = (1000 * 60 * 1);
    private currentDialog?: MatDialogRef<NotificationPopupDialog>;

	constructor(
		public auth: OidcSecurityService,
		//protected userService: userService,
		public userService: SuccessAccount_Service,

		public notificationService : Notification_Service,
        protected dialog: MatDialog,
        protected snackbar: MatSnackBar

	) {
		this.recentNotifications = [];
        sessionStorage.setItem("filterCollapsed", "true")
        // this.channel.onmessage = (messageEvent) => {
        //     clearInterval(this.interval)
        //     this.intervalSet(false)
        // }
		//this.auth.user$.subscribe(u => this.userUpdated(u));
		//this.auth.idTokenClaims$.subscribe((claims) => console.log(claims));
		//this.auth.error$.subscribe((error) => console.error(error));
	}

	ngOnInit() {
		let authURL = window.location.pathname.includes('impersonate') ? window.location.origin : window.location.href;
        // checkAuth is expecting the start of an authorization code flow. It will check for the auth code in the query string params
        // seems if the user is not authenticated, and the code is missing from the query string, and the window location is not /, it will navigate to /, and therefore
        // the impersonate component will never get loaded (which means impersonateService.doImpersonate will never get called). 
        // Giving an explicit url seems to fix this behaviour.
		this.auth.checkAuth(authURL)
			.subscribe((response) => {
				// this should be handled by a gaurd on the routes themselves
				// if(!response && window.location.href != window.location.origin + '/') {
				// 	window.location.href = window.location.origin;
				// }

			});
        if(this.auth.isAuthenticated()) {
            document.addEventListener("visibilitychange", this.visibilityChangeListener);
            this.startNotificationPolling();
        }

        // this.auth.isAuthenticated$.subscribe((authenticated)=>{
        //     if (!authenticated) return;
        //     document.addEventListener("visibilitychange", this.visibilityChangeListener);
        //     this.startNotificationPolling();
        // });
       

        // this.auth.isAuthenticated$.subscribe((res)=>{
        //     if(res)
        //         this.getNotifications()
        //     document.addEventListener("visibilitychange", () => {
        //         if(document.hidden){
        //             clearInterval(this.interval);
        //             this.interval = undefined;
        //         }
        //         else{
        //             if (!this.interval)
        //                 this.intervalSet(false);
        //         }
        //     })
        // })
    }

	login() {
		this.auth.authorize();
	}

	logout() {
        window.localStorage.removeItem("loggedInUserPermissionNodes");
		this.auth.logoff();
	}

    get totalNotices(){
        return this.notificationLength 
    }

	getNotifications(manual?:boolean, showSnackMessage: boolean = true) {
        let lastTime: Date;
        if (manual) {
            lastTime = new Date();
            lastTime.setDate(lastTime.getDate() - 7);
        } else {
            lastTime = new Date(this.lastNotificationCall);
        } 
        lastTime.setHours(lastTime.getHours() - 8); // because pacific time
        this.notificationService.getRecentNotifications({
            notificationLimit:10,
            includeViewed:false, 
            createdAt: lastTime
        }).subscribe((entities)=>{

            this.lastNotificationCall = new Date()
            if(manual && showSnackMessage){
                if(entities.length <= 0)
                this.snackbar.open('No new notifications in the last 7 days found.', undefined, { duration: 4000, verticalPosition: 'top', horizontalPosition: 'right', panelClass: 'snack-error' })
            }
            entities.notifications.forEach((notice:Notice)=>{
                //let noticeTime = notice.createdAtUTC?.getTime()
                // condition can be removed
                //if(noticeTime && this.lastNotificationCall.getTime() - noticeTime < (1000 * 60 *10)){
                    if(Notification.permission == 'granted'){
                        let recentNotice = new Notification(notice.subject? notice.subject: "",
                        {
                            body:notice.contentText ? notice.contentText: undefined,
                            data:notice.data
                        })

                        notice.deliveredBrowserPushNotification = true;
                        this.notificationService.push(notice).subscribe();
                        
                        recentNotice.onclick = () => {
                            this.openNotification(notice)
                        }
                    }
                //}
            })
            this.recentNotifications = entities.notifications
            this.notificationLength = entities.length
            sessionStorage.setItem("userNotifications", JSON.stringify(this.recentNotifications))
        })
        //this.channel.postMessage('notifying')
        // this.notificationService.getRecentNotifications({notificationLimit:10,includeViewed:false}).subscribe((entities)=>{
        //     if(manual){
        //         if(entities.length <= 0)
        //         this.snackbar.open('No new notifications in the last 7 days found', undefined, { duration: 4000, verticalPosition: 'top', horizontalPosition: 'right', panelClass: 'snack-error' })
        //     }
        //     this.lastNotificationCall = new Date()
        //     entities.notifications.map((notice:Notice)=>{
        //         let noticeTime = notice.createdAtUTC?.getTime()
        //         if(noticeTime && this.lastNotificationCall.getTime() - noticeTime < (1000 * 60 *10)){
        //             if(Notification.permission == 'granted'){
        //                 let recentNotice = new Notification("New Notification", 
        //                 {
        //                     body:notice.subject ? notice.subject: undefined,
        //                     data:notice.data
        //                 });
        //                 // update mark database Notification that it has been sent via push notification
        //                 recentNotice.onclick = () => {
        //                     this.openNotification(notice)
        //                 }
        //             }
        //         }
        //     })
        //     this.recentNotifications = entities.notifications
        //     this.notificationLength = entities.length
        //     sessionStorage.setItem("userNotifications", JSON.stringify(this.recentNotifications))
        //     this.intervalSet(false)
        // })
    }

    visibilityChangeListener = (e: Event) => {
        console.debug('visibilitychange emiitted', e);
        console.debug('document.visibilityState', document.visibilityState);
        if(document.visibilityState !== "visible"){
            this.stopNotificationPolling(); 
        }
        else{
           this.startNotificationPolling();
        }
    }

    startNotificationPolling() {
        if (this.interval) {
            console.warn('already started');
            return;
        }
        this.getNotifications(true, false); // initial fetch for notifications
        console.debug('Starting polling interval for newly received notifications');
        this.interval = window.setInterval((()=> this.getNotifications(false, false)), this.waitTime )
        // listen for visibility change to start and stop polling.
        //document.addEventListener("visibilitychange", this.visibilityChangeListener);
    }
    
    stopNotificationPolling() {
        if (!this.interval) {
            console.warn('already stopped');
            return;
        }
        console.debug('Stopping polling interval for newly received notifications')
        clearInterval(this.interval);
        this.interval = undefined;
        //document.removeEventListener('visibilitychange', this.visibilityChangeListener);
    }


    public openNotification(entity:Notice){
        let ind = this.recentNotifications.findIndex((notice)=>{return notice.uuid == entity.uuid || notice.id == entity.id})
        if(ind != -1){
            this.recentNotifications.splice(ind,1)
            this.notificationLength -= 1
        }
        window.focus()

        let previousDialog: MatDialogRef<NotificationPopupDialog> | undefined;
        if (this.currentDialog) {
            previousDialog = this.currentDialog;
        } 
        this.currentDialog = this.dialog.open(NotificationPopupDialog, {
            data: entity
        })
        this.currentDialog.afterOpened().subscribe(() => {
            if (previousDialog) {
                previousDialog.close();
                previousDialog = undefined;
            }
        });
        this.currentDialog.afterClosed().subscribe((res)=>{
            //this.currentDialog = undefined;
            if(res){
                // acknowledge / dismiss notification
                Object.assign(entity, res.data)
                this.notificationService.push(entity).subscribe()
            }
        })
    }
}