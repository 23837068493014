import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class Contact extends DexResourceModel {
    @Field(String)
    public firstName: string = "";
    @Field(String)
    public lastName: string = "";
    @Field(String)
    public email: string = "";
    @Field(String)
	public phone: string = "";
    @Field(String)
	public address: string = "";
    @Field(String)
	public city: string = "";
    @Field(String)
	public state: string = "";
    @Field(String)
	public zip: string = "";
    @Field(String)
	public barId: string = "";
    @Field(String)
	public isOrganization: string = "";
    @Field(String)
    public companyName: string = "";

    @Field(String)
    public check: string = "";
    @Field(String)
    public activeStatus : string = "";

    public set_Status(){
        if(this.deletedAt == null)
            this.activeStatus = "active";

        else{
            this.activeStatus = "inactive"
        }
            
    }

    public get_Status(){
        return this.activeStatus;
    }

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}
