import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { ContactNote } from '../models/contactNote.model';

@Injectable()
export class ContactNote_Service extends ResourceService<ContactNote> {
    protected servicePath: string = '/ContactNote';

    public ModelType = ContactNote;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }
}
