import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { ResourceListComponent } from '@smartsoftware/reflex-core';

import { ClientMatterSet, ClientMatterSet_Service, PagedResourceListComponent, ClientMatterSetDataSource, SystemConfig_service} from 'legalreflex-lib';
import { BreadCrumbService } from '../../../components/breadcrumb-bar/breadcrumb-service';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  templateUrl: './list.html',
  styleUrls: ['./list.scss']
})
export class ClientMatterList extends PagedResourceListComponent<ClientMatterSet> {
  @ViewChild(MatSort) sort?: MatSort;

  constructor(
    protected entityService: ClientMatterSet_Service,
    public dataSource: ClientMatterSetDataSource,
    protected route: ActivatedRoute,
    protected router: Router,
    protected auth: OidcSecurityService,
    protected dialog: MatDialog,
    private breadCrumbService: BreadCrumbService,
    public sysConfig: SystemConfig_service

  ) { 
    super(dataSource,entityService,dialog,route,router);

    this.breadCrumbService.breadcrumbs.next([
      { label: 'Client Matters', url: '/clientMatter'},
      { label: 'Client Matter Sets' }
    ]);
    this.breadCrumbService.actions.next([
      {label:'Refresh', action:this.dataSource.reload.bind(this.dataSource), icon:'refresh'}
    ]);
  }

  ngOnInit(): void {
    super.ngOnInit();
    if(this.sysConfig.maintenanceRedirectCheck()){  
      window.localStorage.removeItem("loggedInUserPermissionNodes");
      this.auth.logoff();
    };
    this.dataSource.pageSize = 50;
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    setTimeout(() => {
      if (this.dataSource.sort) {
        this.dataSource.sort.active = 'name';
        this.dataSource.sort.direction = 'asc';
      }
      this.dataSource.reload();
    });

  }
}
