import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RouterModule } from '@angular/router';
import { ReflexDownloaderModule } from './reflex-downloader';

@NgModule({
    imports: [
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatProgressBarModule,
        ReflexDownloaderModule
    ],
    declarations: [
    ],
    providers: [
        
    ],
    exports: [
        ReflexDownloaderModule,
    ]
})
export class LegalReflexModulesModule { }
