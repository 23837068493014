import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class UserProject extends DexResourceModel {
    @Field(Boolean)
    public projectOwner: boolean = false;
    @Field(String)
    public project_uuid: string = "";
    @Field(String)
    public user_uuid: string = "";

    constructor(data?: any) {
        super();
        this.mapData(data);
    }
}
