import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { ResourceService } from "@smartsoftware/reflex-core";
import { SuccessCase } from "../models/successCase.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Order, Vendor } from "../models";
import { SuccessAccount } from "../models/successAccount.model";
import { Page, PageParams, Pager } from '../paged.datasource';

@Injectable()
export class SuccessCase_Service extends ResourceService<SuccessCase> implements Pager<SuccessCase> {
    protected servicePath = '/SuccessCase';
    public ModelType = SuccessCase;
    constructor(
        protected http: HttpClient,
    ) {
        super(http);
    }

    page(pageParams: PageParams): Observable<Page<SuccessCase>> {

        let params = new HttpParams()
        .append('params', btoa(JSON.stringify(pageParams)));
        return this.http.get<Page<SuccessCase>>(`${this.serviceUrl}successPage`, { params })
            .pipe(map(page => {
                page.items = page.items.map(i => new SuccessCase(i));
                return page;
            }));

    }

    list(): Observable<any> {

        return this
            .http
            .get(
                this.serviceUrl + "getListLimit", 
                {
                    observe:'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>)=>
                        response.body.map((e:SuccessCase) => new SuccessCase(e))
                )
            )
    }

    get(id:string) {
        return this
            .http
            .get(
                this.serviceUrl + id + "?id=" + id, 
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>)=>
                    new this.ModelType(response.body.result[0])
                )
            )
    }
    
    public batchDeleteEntries(body:any): Observable<Array<SuccessCase>> {
        return this
            .http
            .post(
                this.serviceUrl + 'batchDeleteEntries',
                body,
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => 
                        response.body.map((e:SuccessCase) => new SuccessCase(e))
                )
            )
    }

    public filterCases(body:any): Observable<Array<SuccessCase>> {
        return this
            .http
            .post(
                this.serviceUrl + 'filterCases',
                body,
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => 
                        response.body.map((e:SuccessCase) => new SuccessCase(e))
                )
            )
    }

    public getCaseById(id: string): Observable<Array<SuccessCase>> {
        return this
            .http
            .get(
                this.serviceUrl + id + "?id=" + id,
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => 
                        response.body.result.map((e:SuccessCase) => new SuccessCase(e))
                )
            )
    }

    public getCaseOrders(uuid:string): Observable<Array<Order>> {
        return this
            .http
            .post(
                this.serviceUrl + 'getCaseOrders',
                {
                    uuid:uuid
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => 
                        response.body.result.map((e:Order) => new Order(e))
                )
            );
    }

    public getVendorList(): Observable<Array<Vendor>> {
        return this
            .http 
            .get(
                this.serviceUrl.split('SuccessCase')[0] + 'Vendor',
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => 
                        response.body.map((e: Vendor) => new Vendor(e))
                )
            );
    }

    public getPermittedUsers(uuid: string): Observable<Array<SuccessAccount>> {
        return this
            .http
            .post(
                this.serviceUrl + 'getPermittedUsers',
                {
                    uuid:uuid
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) =>
                        response.body.map((e:SuccessAccount)=> new SuccessAccount(e))
                )
            )
    }

    public getByOrders(corpId:string, uuid:string): Observable<Array<SuccessCase>> {
        return this
            .http
            .post(
                this.serviceUrl + 'getByOrders',
                {
                    corpId: corpId,
                    uuid: uuid
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => 
                        response.body.result.map((e:SuccessCase) => new SuccessCase(e))
                )
            );
    }

    public getCaseContacts(uuid:string): Observable<Array<SuccessContactWithType>> {
        return this
            .http
            .post(
                this.serviceUrl + 'getCaseContacts',
                {
                    uuid:uuid
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => 
                        response.body.map((e:SuccessContactWithType) => (e))
                )
            );
    }

    public insertCase(body:SuccessCase): Observable<SuccessCase> {
        return this
            .http
            .post(
                this.serviceUrl + 'insertCase',
                body,
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => 
                        response.body.result
                )
            )
    }

    public updateCase(body:SuccessCase): Observable<SuccessCase> {

        return this
            .http
            .put(
                this.serviceUrl + 'updateCase/' + body.uuid,
                body,
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => 
                        response.body.result
                )
            )
    }
    public getByCorp(): Observable<Array<SuccessCase>> {
        return this
            .http
            .get(
                this.serviceUrl + 'getByCorp',
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => 
                        response.body.map((e:SuccessCase) => new SuccessCase(e))
                )
            )
    }
    public getByUser(): Observable<Array<SuccessCase>> {
        return this
            .http
            .get(
                this.serviceUrl + 'getByUser',
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => 
                        response.body.map((e:SuccessCase) => new SuccessCase(e))
                )
            )
    }
}

export interface SuccessContactWithType { 
    uuid: string,
    createdAt?: Date,
    updatedAt?: Date,
    deletedAt?: Date | null,
    firstName?: string,
    lastName?: string,
    phone?: string,
    email?: string
    address?: string,
    city?: string,
    state?: string,
    zip?: string,
    companyName?: string,
    barId?: string,
    isOrganization?: boolean,
    // Joined From CaseContact Table
    contactType?: string,
    caseContact_uuid?: string,
}