import { Injectable } from "@angular/core";
import { ResourceService } from "@smartsoftware/reflex-core";
import { SuccessCounty } from "projects/legalreflex-lib/src";
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";


@Injectable()
export class SuccessCounty_Service extends ResourceService<SuccessCounty>{
    protected servicePath: string = '/County';
    public ModelType = SuccessCounty;

    constructor( protected http: HttpClient ){
        super(http);
    }

    public getByStateUuid(uuid: string, efileOnly?: boolean) {
        return this.http.get<SuccessCounty[]>(`${this.serviceUrl}state?stateUuid=${uuid}&efileOnly=${efileOnly}`);
    }
}