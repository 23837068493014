import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { ReflexEnvironment as ENV } from '@smartsoftware/reflex-core';
// import { ResourceService } from "@smartsoftware/reflex-core";
// import { SuccessCase } from "../models/successCase.model";
// import { SuccessCaseContact } from "../models/successCaseContact.model";
// import { BehaviorSubject, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
// import { SuccessAccount } from "../models/successAccount.model";
// import { Page, PageParams, Pager } from '../paged.datasource';

@Injectable()
export class LegalConnect_Service {

    constructor(
        protected http: HttpClient
    ) {}

    private updateCustomerAccount(customerNumber: string, companyNumber: number) : Promise<LCResponse>{
        return this
            .http
            .post(
                ENV.config['flapiUrl'] + '/LegalConnect/auth/updateCustomerAccount',
                {
                    customerNumber: customerNumber,
                    companyNumber: companyNumber
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) =>
                        response.body as LCResponse
                )
            )
            .toPromise();
    }
    
    private updateUserAccount(email: string) {
        return this
            .http
            .post(
                ENV.config['flapiUrl'] + '/LegalConnect/auth/updateUserAccount',
                {
                    emailAddress: email
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) =>
                        response.body as LCResponse
                )
            )
            .toPromise();
    }

    private loginUser(email: string) {
        return this
            .http
            .post(
                ENV.config['flapiUrl'] + '/LegalConnect/auth/loginUser',
                {
                    emailAddress: email
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) =>
                        response.body as LCResponse
                )
            )
            .toPromise();
    }

    private requestLoginCookie(cookieUrl: string) {
        return this
            .http
            .get(
                cookieUrl, 
                {
                    observe: 'response',
                    responseType: 'text',
                    withCredentials: true
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => 
                        response.body //as LCResponse
                )
            )
            .toPromise();
    }

    public getLCAttorneys(customerNumber: string, companyNumber: number) {
        return this
            .http
            .post(
                ENV.config['flapiUrl'] + '/FirstConnect/attorney/getList',
                {
                    customerNumber: customerNumber,
                    companyNumber: companyNumber
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => response.body
                )
            );
    } 

    private createAttorney(fullName: string, email: string, barId: number, customerNumber: string, companyNumber: number) {
        return this
            .http
            .post(
                ENV.config['flapiUrl'] + '/FirstConnect/attorney/create',
                {
                    emailAddress: email,
                    fullName: fullName,
                    barId: barId,
                    customerNo: customerNumber,
                    companyNo: companyNumber
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) =>
                        response.body as LCAttorney
                )
            )
            .toPromise();
    }

    public async updateLCCustomerAccount(customerNumber: string, companyNumber: number) : Promise<boolean> {
        let response = await this.updateCustomerAccount(customerNumber, companyNumber);
        
        console.log('LC update customer account', response);
        
        if(!response.success) return Promise.resolve(false);
        
        return Promise.resolve(true);
    } 

    public async updateLCUserAccount(email: string) : Promise<boolean> {
        let response = await this.updateUserAccount(email);
        
        console.log('LC update user account', response);
        
        if(!response.success) return Promise.resolve(false);
        
        return Promise.resolve(true);
    }

    public async loginLCUser(email: string) : Promise<LCResponse> {
        let response = await this.loginUser(email);
        
        console.log('LC login', response);
        
        if(!response.success) return Promise.resolve({success:false, result: undefined});
        
        return Promise.resolve(response);
    }

    public async requestLCLoginCookie(cookieUrl: string) : Promise<any> {
        let response = await this.requestLoginCookie(cookieUrl);

        console.log('LC cookie', response);
        
        // if(!response.success) return Promise.resolve(false);
        
        return Promise.resolve(response);
    }

    // public async getLCAttorneys(customerNumber: string, companyNumber: number) : Promise<boolean> {
    //     let response = await this.getAttorneys(customerNumber, companyNumber);
        
    //     console.log('LC attorneys', response);
        
    //     if(!response || !response.length) return Promise.resolve(false);
        
    //     return Promise.resolve(true);
    // }

    public async createLCAttorney(email: string, fullName: string, barId: number, customerNumber: string, companyNumber: number) : Promise<LCAttorney> {
        let response = await this.createAttorney(email, fullName, barId, customerNumber, companyNumber);
        
        console.log('LC create Attorney', response);
        
        if(!response) return Promise.reject("Could not create attorney");
        
        return Promise.resolve(response);
    }
}

export interface LCResponse {
    success: boolean,
    result: any
}

interface LCAttorney {
    id: number,
    emailAddress?: string,
    fullName?: string,
    barId?: number,
    gfAccountId?: string | null,
    companyNo?: number,
    customerNo?: string,
    lcAccountNumber?: number
}
