import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'
import { ResourceService, ReflexEnvironment as ENV} from '@smartsoftware/reflex-core';
import { OidcSecurityService } from 'angular-auth-oidc-client';

import {
    OrderDetail
} from '../models';

import { map } from 'rxjs/operators';

@Injectable()
export class OrderDetail_Service extends ResourceService<OrderDetail> {
    protected servicePath: string = '/Order';

    public ModelType = OrderDetail;

    constructor(
        protected auth: OidcSecurityService,
        protected http: HttpClient,
    ) {
        super(http);
    }

    // DEPRECATED
    // public getOrder(orderId: string) {
    //     return this
    //         .http
    //         .get(
    //             ENV.config['flapiUrl'] + '/OpenAPI/order/status?orderId=' + orderId,
    //             {
    //                 observe: 'response'
    //             }
    //         )
    //         .pipe(
    //             map(
    //                 (response: HttpResponse<any>) => {
    //                     if(response.body.success) {
    //                         return new OrderDetail(response.body.result);
    //                     } else {
    //                         return response.body;
    //                     }
    //                 }
    //             )
    //         )
    // }

}
