import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { PartnerVendorNotes } from '../models/partnerVendorNotes.model';

@Injectable()
export class PartnerVendorNotes_Service extends ResourceService<PartnerVendorNotes> {
    protected servicePath: string = '/PartnerVendorNotes';

    public ModelType = PartnerVendorNotes;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }
}
