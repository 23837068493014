
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core';
import { ResourceService } from '@smartsoftware/reflex-core';
import { Service, ServiceCategory } from '../models';
import { ReflexEnvironment as ENV } from "@smartsoftware/reflex-core";
import { BehaviorSubject, Observable } from "rxjs";
import { tap, map } from 'rxjs/operators';
import { ServiceWithCategories } from '../definitions';
import { Page, PageParams } from '../paged.datasource';

@Injectable()
export class Service_Service extends ResourceService<Service> {

    protected servicePath = '/Service';

    private _isDataSynced = false;
    get isDataSynced(): boolean {
        return this._isDataSynced;
    }
    set isDataSynced(status: boolean) {
        this._isDataSynced = status;
        this.dataSync.next(this._isDataSynced);
    }

    // jbethke. refactored to be a BehaviorSubject
    public services : BehaviorSubject<ServiceWithCategories[]> = new BehaviorSubject<ServiceWithCategories[]>([]);
    public ModelType = Service;
    public dataSync: BehaviorSubject<boolean> = new BehaviorSubject(this._isDataSynced);

    constructor(
        protected http: HttpClient
    ) {
        super(http);

        this.fetchAllServices();
    }

    page(pageParams: PageParams): Observable<Page<Service>> {
        let params = new HttpParams()
            .append('params', btoa(JSON.stringify(pageParams)));
        return this.http.get<Page<Service>>(`${this.serviceUrl}Page`, { params })
            .pipe(map(page => {
                page.items = page.items.map(i => new Service(i));
                return page;
            }));
    }

    public async fetchAllServices(): Promise<ServiceWithCategories[]> {
        return this
            .http
            .get(
                ENV.config['openapiUrl'] + '/successconnect/service/listAll?showInternal=true',
                {
                    observe: 'response'
                }
            )
            .pipe(
                //jbethke - refactor to map then tap, instead of resolving in the promise
                map((response: HttpResponse<any>) => {
                    return response.body.result.map((e: Object) => e as ServiceWithCategories);
                }),
                tap((services: ServiceWithCategories[]) => {                 
                    this.services.next(services);
                    this.isDataSynced = true;
                })
            )   
            .toPromise();
    }

}
export enum Services {
    COURT_PROCESS =  "COURT & PROCESS",
    COURTPROCESS = "COURT & PROCESS",
    RECORDRETRIEVAL = "RECORD RETRIEVAL",
    DEPOSITIONS = "DEPOSITIONS",
    DIGITAL = "DIGITAL", 
    EDISCOVERY = "EDISCOVERY",
    INVESTIGATIONS  = "INVESTIGATIONS"
}


export enum ServiceTypes {
    COURT_PROCESS = "COURTPROCESS",
    SERVICE_OF_PROCESS = "SOP",
    EFILE = "EFILE",
    EFILE_DIRECT_TYPE = "DIRECT",
    COURT_RUN = "COURT RUN",
    COURT_FILING = "COURT FILING",
    RESEARCH = "RESEARCH",
    MESSENGER = "MESSENGER SERVICE",
    PDF_FAX_FILING = "PDF/FAX FILING",
    PDF_COURTESY_DELIVERY = "PDF COURTESY DELIVERY",
    PDF_COURTESY_DELIVERY_TYPE = "PDF COURTESY DELIVERY",

    EDISCOVERY = "EDISCOVERY",
    TRIALSERVICES = "TRIALSERVICES",

    DIGITAL = "DIGITAL",
    COURTBRIEFS = "COURT BRIEFS",
    EXHIBIT_TRIAL_BINDERS = "EXHIBIT",
    SCANNING = "SCANNING",
    OCR = "OCR",
    MAILOUT = "MAILOUT",
    TAB = "TAB",
    BATESSTAMPING = "BATES STAMPING",

    INVESTIGATIONS = "INVESTIGATIONS",
    LIABILITY_RESPONSE = "LIABILITYRESPONSE",
    INSURANCE_DEFENSE = "INSURANCEDEFENSE",
    SUBJECT_INFORMATION = "SUBJECTINFORMATION",
    SKIPTRACE = "SKIPTRACE",
    SUB_ROSA = "SUBROSA",

    OTHER = "OTHER"
}