import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { ResourceListComponent, ResourceListHash } from '@smartsoftware/reflex-core';

import { 
        Client_Service,
        PagedResourceListComponent,
        ClientMatterEntry_Service,
        ClientMatterEntryDataSource,
        ClientMatterEntry
        } from 'legalreflex-lib';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector:'page-CME-list',  
  templateUrl: './view.CME.html',
  styleUrls: ['./view.scss']
})
export class CME_List extends PagedResourceListComponent<ClientMatterEntry> {
    @Input()
    public clientMatterSet_uuid: string =''; 
    public columns = ["matter","name","updatedAt"];
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        protected entityService: ClientMatterEntry_Service,
        public dataSource: ClientMatterEntryDataSource,
        protected clientService: Client_Service,
        protected route: ActivatedRoute,
        protected router: Router,
        protected dialog: MatDialog,
        public fb: FormBuilder 
      ) { 
        super(dataSource,entityService,dialog,route,router);
        this.sort = new MatSort();
      }
      @ViewChild('CME') paginator!: MatPaginator;

      applyFilter(event: Event) {
        let filterValue = this.filterFormGroup?.get('name')?.value;

        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.dataSource.filter = filterValue;
        this.onFilterSubmit();
      }

      ngOnInit(): void {
        super.ngOnInit();
        this.dataSource.pageSize = 50;

        this.filterFormGroup = this.fb.group({
          clientMatterSet_uuid: [this.clientMatterSet_uuid],
          name: [''],
          deletedAt: [null]
        });
    }
    
    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        super.ngAfterViewInit();
        setTimeout(() => {
            if (this.dataSource.sort) {
            this.dataSource.sort!.active = 'name';
            this.dataSource.sort!.direction = 'asc';
            }
            this.onFilterSubmit();
            //this.dataSource.reload();
        });
        
      }

}