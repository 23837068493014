import { Injectable } from "@angular/core";
import { ResourceService } from "@smartsoftware/reflex-core";
import { SuccessState } from "../models/state.model";
import { HttpClient } from '@angular/common/http'


@Injectable()
export class SuccessState_Service extends ResourceService<SuccessState>{
    protected servicePath: string = '/State';
    public ModelType = SuccessState;

    constructor( protected http: HttpClient ){
        super(http);
    }
}