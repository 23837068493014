<div class="downloader" [ngClass]="expanded ? 'expanded' : 'collapsed'" (click)="expand()">
    <span>{{ downloadMessage() }}</span>
    <mat-progress-bar *ngIf="downloadProgress() !== null" mode="determinate" [value]="downloadProgress()"></mat-progress-bar>
    <div *ngFor="let group of downloaderGroups()" class="file-group">
        <div class="group-header download-header">
            <span>{{group.displayName}}</span>
            <div></div>
            <button matTooltip="Download group" 
                mat-icon-button 
                *ngIf="group.downloadProgress.value >= 100 && (group.shouldCompress || group.files.length == 1)"
                (click)="downloadGroup($event, group)">
                <mat-icon>download</mat-icon>
            </button>
        </div>
        <mat-progress-bar color="accent" *ngIf="group.compressionProgress.value > 0" mode="determinate" [value]="compressionProgress(group)"></mat-progress-bar>
        <div *ngFor="let file of group.files; index as i" class="file-progess">
            <div class="download-header">
                <span>{{file.displayName}}</span> 
                <div></div>
                <button matTooltip="Download file" 
                    mat-icon-button 
                    *ngIf="file.downloadProgress.value >= 100"
                    (click)="downloadFile($event, file)">
                    <mat-icon>download</mat-icon>
                </button>
                <button matTooltip="Cancel download" 
                    mat-icon-button 
                    color="warn"
                    *ngIf="file.downloadProgress.value < 100"
                    (click)="cancelFile($event, group, i)">
                    <mat-icon>cancel</mat-icon>
                </button>
            </div>
            <mat-progress-bar mode="determinate" [value]="fileProgress(file)"></mat-progress-bar>
        </div>
    </div>
</div>
