import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class Invoice extends DexResourceModel {

	@Field(Number)
	public companyNumber: number = 0;
	@Field(Number)
	public customerNumber: number = 0;
	@Field(Number)
	public invoiceNumber: number = 0;
	@Field(Date)
	public invoiceDate: Date = new Date();
    @Field(String)
	public invoiceStatus: string = "";
	@Field(Number)
	public totalOrdersOnInvoice: number = 0;
	@Field(Number)
	public revenueAmount: number|undefined;
	@Field(Number)
	public nonRevenueAmount: number|undefined;
	@Field(Number)
	public invoiceAmount: number|undefined;
	@Field(Number)
	public balanceRemaining: number|undefined;

	constructor(data?: any) {
		super();
		this.mapData(data);
	}

}
