<form #editForm="ngForm" [formGroup]="entityForm" *ngIf="entityForm !== undefined">
    <resource-list-grid *ngIf="entity">
        <resource-list-pane 
        title="Notification Render" *ngIf="entity">
            <div style="border-style: solid; border-color:grey;" >
                <div style="margin: 0 24px">
                    <h3 class="noticeSubject">
                        <span>{{notificationSubject}}</span>
                    </h3>
                    <div>
                        <span>To: {{entity.emailTo ? entity.emailTo : ''}}</span>
                    </div>
                    <br>
                    <div id="viewcontent-parent" mat-dialog-content>
                        <div id="viewcontent"></div>
                    </div>
                </div>
            </div>
        </resource-list-pane>
        <resource-list-pane title="Notification Details">
            <resource-list-pane title="Basic Details">
                <mat-form-field>
                    <mat-label>Subject</mat-label>
                    <input matInput readonly
                        formControlName="subject"/>
                </mat-form-field>
                
                <mat-form-field>
                    <mat-label>Content Html</mat-label>
                    <input matInput readonly
                        formControlName="contentHtml"/>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Content Text</mat-label>
                    <input matInput readonly
                        formControlName="contentText"/>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Type</mat-label>
                    <input matInput readonly
                        value="{{noticeTypes.get(entity.notificationTypeId)}}"/>
                </mat-form-field>
            </resource-list-pane>

            <resource-list-pane title="Activity">
                <mat-form-field>
                    <mat-label>Emailed To</mat-label>
                    <input matInput readonly
                        formControlName="emailTo"/>
                </mat-form-field>

                <mat-form-field *ngIf="isUser">
                    <mat-label>BCC</mat-label>
                    <input matInput readonly
                        formControlName="emailBCC"/>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Created At</mat-label>
                    <input matInput readonly
                        formControlName="createdAt"/>
                </mat-form-field>

                <mat-form-field *ngIf="entity.deliveredAt">
                    <mat-label>Delivered At</mat-label>
                    <input matInput readonly
                        formControlName="deliveredAt"/>
                </mat-form-field>

                <mat-form-field *ngIf="entity.deletedAt">
                    <mat-label>Deleted At</mat-label>
                    <input matInput readonly
                        value="{{entity.deletedAt}}"/>
                </mat-form-field>
            </resource-list-pane>            
        </resource-list-pane>

    </resource-list-grid>
</form>
