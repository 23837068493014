import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { ToServe } from '../models/toServe.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ToServe_Service extends ResourceService<ToServe> {
    protected servicePath: string = '/ToServe';

    public ModelType = ToServe;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }

    public push(entry: ToServe): Observable<ToServe> {

        return super.push(entry);
    }

    public getDeliveryToServesByOrderId(orderId: string) {
        return this.http.get<ToServe[]>(`${this.serviceUrl}DeliveryToServe/${orderId}`)
            .pipe(map(servees => servees.map(s => new ToServe(s))));
    }
}
