import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class Submission extends DexResourceModel {

	@Field(String)
	public vendorKey: string | null = null;

	@Field(String)
	public vendorSubmissionId: string | null = null;

	@Field(String)
	public order_uuid: string | null = null;

    @Field(String)
	public statusNotes: string | null = null;

    @Field(String)
	public requestNotes: string | null = null;

    @Field(String)
	public refresh_token: string | null = null;
    

	constructor(data?: any) {
		super();
		this.mapData(data);
	}
}
