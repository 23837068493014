import { Injectable } from "@angular/core";
import { ResourceService } from "@smartsoftware/reflex-core";
import { SuccessZipCode } from "../models/zipCode.model";
import { HttpClient } from '@angular/common/http'


@Injectable()
export class SuccessZipCode_Service extends ResourceService<SuccessZipCode>{
    protected servicePath: string = '/ZipCode';
    public ModelType = SuccessZipCode;

    constructor( protected http: HttpClient ){
        super(http);
    }
}