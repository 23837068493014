import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { combineLatest } from 'rxjs';
import { 
	Role, 
	PermissionNode, 
	SuccessAccount, 
	RolePermissionNode, 
} from '../models';
import { Role_Service } from './role.service';
import { PermissionNode_Service } from './permissionNode.service';
import { RolePermissionNode_Service } from './rolePermissionNode.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { SuccessAccount_Service } from './successAccount.service';
import { SuccessAccountPermissionNode_Service } from './successAccountPermissionNode.service';
import { stringify } from 'querystring';

@Injectable()
export class UserPermission_Service{

    private _isDataSynced = false;
    get isDataSynced(): boolean {
        return this._isDataSynced;
    }

    set isDataSynced(status: boolean) {
        this._isDataSynced = status;
        this.dataSync.next(this._isDataSynced);
    }

	private _corpRoles: Array<Role> = [];
	get corpRoles(): Array<Role>{
		return this._corpRoles;
	}
	set corpRoles(roles: Array<Role>) {
		this._corpRoles = roles;
	}

	private _corpRolePermissionNodes: Array<RolePermissionNode> = [];
	get corpRolePermissionNodes(): Array<RolePermissionNode>{
		return this._corpRolePermissionNodes;
	}
	set corpRolePermissionNodes(rolePermissionNodes: Array<RolePermissionNode>) {
		this._corpRolePermissionNodes = rolePermissionNodes;
	}

	private _loggedInUserRoles: Array<Role> = [];
	get loggedInUserRoles(): Array<Role>{
		return this._loggedInUserRoles;
	}
	set loggedInUserRoles(roles: Array<Role>) {
		this._loggedInUserRoles = roles;
	}

	private _loggedInUserPermissionNodes: BehaviorSubject<{[key: string]: PermissionNode}> = new BehaviorSubject({});
	get loggedInUserPermissionNodes(): {[key: string]: PermissionNode}{
		return this._loggedInUserPermissionNodes.getValue();
	}
	
	set loggedInUserPermissionNodes(permissionNodes: {[key: string]: PermissionNode}) {
		this._loggedInUserPermissionNodes.next(permissionNodes);
	}

	private _loggedInUser: SuccessAccount | undefined;
	get loggedInUser(): SuccessAccount | undefined{
		return this._loggedInUser
	}
	set loggedInUser(successAccountUser: SuccessAccount | undefined) {
		this._loggedInUser = successAccountUser
	}

	private _isUserSynced = false;
	get isUserSynced(): boolean {
		return this._isUserSynced;
	}
	set isUserSynced(status: boolean) {
		this._isUserSynced = status;
		this.userSync.next(this._isUserSynced)
	}

	public userSync: BehaviorSubject<boolean> = new BehaviorSubject(this._isUserSynced);
    public dataSync: BehaviorSubject<boolean> = new BehaviorSubject(this._isDataSynced);
	public rolePermissionHashMap = new Map()

    constructor(
		private PermissionNodeService: PermissionNode_Service,
		private RolePermissionNodeService: RolePermissionNode_Service,
		private RoleService: Role_Service,
		private SuccessAccountPermissionNodeService: SuccessAccountPermissionNode_Service,
		private auth: OidcSecurityService,
		private SuccessAccountService: SuccessAccount_Service,
    ) {
        const userPermissionEntry = window.localStorage.getItem('loggedInUserPermissionNodes');
        if (userPermissionEntry) {
            this.loggedInUserPermissionNodes = JSON.parse(userPermissionEntry);
        }
		this.syncUser();
    }

	private syncUser(): any {
		return this.auth.userData$.subscribe(({userData, allUserData}) => {
			let response = this.auth.getUserData();
			if(response && response.sub) {
				// console.log('TFTEST user sub');
				// console.log(response.sub);
				return this.SuccessAccountService
					.getSuccessAccountByUsername({username: response.sub})
					.subscribe((successAccount:any) => {
						// console.log('TFTEST userPerms successAccount');
						// console.log(successAccount);
						if(successAccount) {
							this.SuccessAccountService.currentUser = successAccount;
							this.loggedInUser = successAccount;
							Promise.all([
								this.RoleService.getRolesBySuccessAccount({successAccount_uuid:successAccount.uuid}),
								this.SuccessAccountPermissionNodeService.getPermissionNodesBySuccessAccount({successAccount_uuid:successAccount.uuid}),
								this.RoleService.getRolesForCorpId({corpId:successAccount.corpId})
							])
							.then((data: any) => {
								data[0].subscribe((userRoles: Array<Role>) => {
									// console.log('TFTEST user roles')
									// console.log(userRoles);
									this.loggedInUserRoles = userRoles;
								});

								data[1].subscribe((permissionNodes: Array<PermissionNode>) => {
									// console.log('TFTEST user permissionNodes');
									// console.log(permissionNodes);
                                    let obj: {[key: string]: PermissionNode} = {};
                                    permissionNodes.forEach(pn => {
                                        obj[`${pn.code}`] = pn;
                                    });

                                    this.loggedInUserPermissionNodes = obj;

                                    window.localStorage.setItem("loggedInUserPermissionNodes", JSON.stringify(obj));
								});

								data[2].subscribe((corpRoles: Array<Role>) => {
									this.corpRoles = corpRoles;
									// console.log('TFTEST corp roles');
									// console.log(corpRoles);

									this.RolePermissionNodeService.allCorpRolePermissionNodes({roles: corpRoles})
										.subscribe((corpRolePermissionNodes: Array<RolePermissionNode>) => {
											// console.log('TFTEST corp role permission nodes');
											// console.log(corpRolePermissionNodes);
											this.corpRolePermissionNodes = corpRolePermissionNodes;

											combineLatest([this.RoleService.dataSync,this.PermissionNodeService.dataSync]).subscribe((synced:Array<boolean>) => {
												if(!synced.includes(false))
													this.getAllUserRolePermissionNodes();
											})

											this.isUserSynced = true;
										})
								})
							})
						} else {
							this.isUserSynced = false;
						}

						return;
					})
			}

			this.isUserSynced = false;
			return;
        });
    }

	public getAllUserRolePermissionNodes() : any {
		// console.log('TFTEST get all user role permission nodes')
		if(this.RoleService.isDataSynced == false)
			return

		// console.log('corp roles');
		// console.log(this.corpRoles);
		this.corpRoles.map((role: Role) => {

			// console.log('corp role permission nodes')
			// console.log(this.corpRolePermissionNodes);
			// build list of permission node uuids for the role
			let RolePermissionNode_uuids : Array<string> = this.corpRolePermissionNodes
				.filter((rolePermissionNode: RolePermissionNode) => { 
					return rolePermissionNode.role_uuid == role.uuid;
				})
				.map((rolePermissionNode: RolePermissionNode) => {
					return rolePermissionNode.permissionNode_uuid
				});

			// console.log('permission node data synced');
			// console.log(this.PermissionNodeService.isDataSynced)
			if(this.PermissionNodeService.isDataSynced == false)
				return 
			
			// console.log('permissions');
			// console.log(this.PermissionNodeService.permissions);

			let userPermissions : Array<PermissionNode> = [];
			// match the role permission nodes with the list of permissions
			RolePermissionNode_uuids.map((uuid: string) => {
				let permissionNode: PermissionNode | undefined = this.PermissionNodeService.permissions.find((permissionNode: PermissionNode) => {
					return permissionNode.uuid == uuid;
				});
				
				if(permissionNode)
					userPermissions.push(permissionNode);
			});

			let rolePerms: rolePerms = {role: role, permissionNodes: userPermissions}
			this.rolePermissionHashMap.set(role.uuid, rolePerms)
			this.isDataSynced = true;
		})
	}

	public getRolePermission(role_uuid:string){
		return(this.rolePermissionHashMap.get(role_uuid))
	}

	// hasRole Performs a search through its SuccessAccountRole Service selecting all roles that share an entry with the users 
	// SuccessAccount uuid, then searches through that list, returning true or false depending on if the role is found
	public hasRole(roleName: string): boolean {
		if(!this.isUserSynced || !this.loggedInUser)
			return false;
		
		let match: Role | undefined = this.loggedInUserRoles.find((role)=> {return role.internalName == roleName});
		
		return !!match;
	}


	public canDo(permissionCode:string): boolean {
		const permitted = this.loggedInUserPermissionNodes.hasOwnProperty(permissionCode);

		return permitted
	}
}

interface rolePerms {
	role: Role
	permissionNodes: Array<PermissionNode>
}
