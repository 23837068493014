import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { ClientMatterEntry } from '../models/clientMatterEntry.model';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Page, PageParams } from '../paged.datasource';

@Injectable()
export class ClientMatterEntry_Service extends ResourceService<ClientMatterEntry> {
    protected servicePath: string = '/ClientMatterEntry';

    public ModelType = ClientMatterEntry;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }
    page(pageParams: PageParams): Observable<Page<ClientMatterEntry>> {
        let params = new HttpParams()
            .append('params', btoa(JSON.stringify(pageParams)));

        return this.http.get<Page<ClientMatterEntry>>(`${this.serviceUrl}Page`, { params })
            .pipe(map(page => {
                page.items = page.items.map(i => new ClientMatterEntry(i));
                return page;
            }));
    }

    public push(entry: ClientMatterEntry): Observable<ClientMatterEntry> {

        if(!entry.clientMatterSet_uuid){
            entry.clientMatterSet_uuid = null;
        }

        return super.push(entry);
    }

    public getPage(setId: string, page: number, batchSize: number): Observable<any[]> {
        return this.http.get<string[]>(`${this.serviceUrl}getMatterPage?setId=${setId}&page=${page}&batchSize=${batchSize}`);
    }

    public getIdCount(setId: string): Observable<number> {
        const params = new HttpParams().append('setId', setId);
        return this.http.get<number>(`${this.serviceUrl}getMatterIdCount`, {params});
    }

    public update(entry: ClientMatterEntry) {
        return this.http.put<ClientMatterEntry>(`${this.serviceUrl}${entry.uuid}`, entry);
    }

    public getPageForConfig(setId: string, batchSize: number, query: string) {
        return this.http.get<ClientMatterEntry[]>(`${this.serviceUrl}getBySetIdAndString?setId=${setId}&batchSize=${batchSize}&query=${query}`);
    }

    public makeLookupConfig(setId: string) {
        const service = this;
        let identifier = (type: ClientMatterEntry) => type && type.uuid;
        let lookup = (id: string) => service.get(id);
        let display = (type: ClientMatterEntry) => {
            if (!type) 
                return '';

            return `${type.matter} | ${type.name}`
        };
        let suggest = (term: string, limit?: number) => {
            if (!term) 
                return of([]);
      
            return service.getPageForConfig(setId, limit || 50, term);
        }
        return { identifier, lookup, display, suggest };
    }

}
