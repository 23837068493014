import { Injectable } from '@angular/core';
import { ResourceService } from '@smartsoftware/reflex-core';
import { SuccessAccountSettings } from '../models/successAccountSettings.model';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SuccessAccountSettingsService extends ResourceService<SuccessAccountSettings> {
  protected servicePath = '/SuccessAccountSettings';
  public ModelType = SuccessAccountSettings;
  
  constructor(protected http: HttpClient) { 
    super(http);
  }

  getByAccountUUID(uuid: string) {
    if (!uuid || uuid.length == 0) {
      return of(new SuccessAccountSettings());
    }
    return this.http.get<SuccessAccountSettings>(`${this.serviceUrl}SuccessAccount`, {
      params: { uuid }
    }).pipe(
      catchError(e => {
        // really should check the error here to see if the settings weren't found, or don't exist yet
        // not sure how to control the responses from the dexterity lib
        // this might end up making a bunch of new account settings, if there's an actual problem with this endpoint
        return of({successAccount_uuid: uuid});
      }),
      map(settings => {
        let result = new SuccessAccountSettings(settings);
        // if (!result.successAccount_uuid) {
        //   result.successAccount_uuid = uuid;
        // }
        return result;
      })
    );
  }

}
