import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { FileType } from '../models/fileType.model';

@Injectable()
export class FileType_Service extends ResourceService<FileType> {
    protected servicePath: string = '/FileType';

    public ModelType = FileType;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }
}
