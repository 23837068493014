import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavigationModule, ResourceModule, AuthGuardService } from '@smartsoftware/reflex-core';
import { AutoLoginPartialRoutesGuard as AuthGuard } from 'angular-auth-oidc-client';
import { AestheticsModule } from '../../aesthetics/aesthetics.module';
import { ComponentsModule } from '../../components/components.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LegalReflexLibModule, LegalReflexDirectivesModule } from 'legalreflex-lib';
import { AddressList } from './list/list';
import { AddressCreate } from './create/create';
import { AddressFrame } from './frame/frame';
import { AddressRestoreDialog } from './restore/addressRestoreDialog';
const routes: Routes = [
    {
        path: 'address-book',
        component: AddressFrame,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: AddressList
            },
            {
                path: ':id',
                component: AddressCreate,
            }
        ]
    }
];

@NgModule({
    declarations: [
        AddressFrame,
        AddressList,
        AddressCreate,
        AddressRestoreDialog,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AestheticsModule,
        ComponentsModule,
        ResourceModule,
        BrowserModule,
        BrowserAnimationsModule,
        MatTooltipModule,
        RouterModule,
        LegalReflexLibModule,
        LegalReflexDirectivesModule,
        RouterModule.forChild(routes)
    ],
    exports: [

    ],
    bootstrap: [

    ]
})
export class AddressBookModule { }
