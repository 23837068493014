import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { 
  Project, 
  Note,
  Contact,
  CaseUser
} from '../models';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class Project_Service extends ResourceService<Project> {
  protected servicePath: string = '/Project';

  public ModelType = Project;

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  public push(entry: Project): Observable<Project> {
    /*
    if (!entry.warehouse_uuid) {
        entry.warehouse_uuid = null;
    }
    */

    if (!entry.invoiceTerm_uuid) {
        entry.invoiceTerm_uuid = null;
    }        
    
    if (!entry.leadAttorney_contact_uuid) {
        entry.leadAttorney_contact_uuid = null;
    }        
    
    if (!entry.project_status_uuid) {
        entry.project_status_uuid = null;
    }        
    
    if (!entry.project_type_uuid) {
        entry.project_type_uuid = null;
    }        
    
    if (!entry.case_uuid) {
        entry.case_uuid = null;
    }        
    
    if (!entry.billing_rateTemplate_uuid) {
        entry.billing_rateTemplate_uuid = null;
    }

    if (!entry.projectOwner_user_uuid) {
        entry.projectOwner_user_uuid = null;
    }

    if (!entry.client_uuid) {
      entry.client_uuid = null;
    }   

    if (!entry.preparedBy_userId) {
      entry.preparedBy_userId = null;
    }
    
    if (!entry.partnerVendor_uuid) {
      entry.partnerVendor_uuid = null;
    }

    if (!entry.contact_uuid) {
      entry.contact_uuid = null;
    }

    if (!entry.insurance_client_uuid) {
      entry.insurance_client_uuid = null;
    }
    
    return super.push(entry);
  }

  public getNotes(uuid: string) {
    return this
        .http
        .post(
            this.serviceUrl + 'notes',
            {
                uuid
            },
            {
                observe: 'response'
            }
        )
        .pipe(
            map(
                (response: HttpResponse<any>) =>
                    response.body.map((e: any) => new Note(e))
            )
        );
}

public getContacts(uuid: string) {
    return this
        .http
        .post(
            this.serviceUrl + 'contacts',
            {
                uuid
            },
            {
                observe: 'response'
            }
        )
        .pipe(
            map(
                (response: HttpResponse<any>) =>
                    response.body.map((e: any) => new Contact(e))
            )
        );
}


public getUsers(uuid: string) {
    return this
        .http
        .post(
            this.serviceUrl + 'users',
            {
                uuid
            },
            {
                observe: 'response'
            }
        )
        .pipe(
            map(
                (response: HttpResponse<any>) =>
                    response.body.map((e: any) => new CaseUser(e))
            )
        );
}
}
