
<div *ngIf="!documents.length && !affidavits.length" class="pa-5">
    No documents are available for this order.
</div>
<div *ngIf="documents.length && affidavits.length">
    <br>
    <label>Attached Documents</label>
</div>
<div *ngIf="allowBatchDownloading && documents.length">
    <button (click)="downloadAll()" mat-raised-button color="primary">Download All</button>
</div>
<mat-table *ngIf="documents.length" [dataSource]="dataSource" matSort #documents="matSort">
    <ng-container *ngIf="showDeleteOption" cdkColumnDef="delete">
        <mat-header-cell  *cdkHeaderCellDef>Delete</mat-header-cell>
        <mat-cell *cdkCellDef="let entity">
            <button matTooltip="Delete" 
                mat-icon-button 
                (click)="deleteDocuments(order, entity.uuid, entity)">
                <mat-icon>delete</mat-icon>
            </button>
        </mat-cell>
    </ng-container>
    <ng-container cdkColumnDef="actions">
        <mat-header-cell *cdkHeaderCellDef>Download</mat-header-cell>
        <mat-cell *cdkCellDef="let entity" >

            <button matTooltip="Get Download URL" 
                mat-icon-button 
                (click)="downloadURL(entity)">
                <mat-icon>download</mat-icon>
            </button>
            <!-- <a mat-icon-button *ngIf="downloadURLs.has(entity.uuid)" [href]="downloadURLs.get(entity.uuid)" 
                target="_blank" 
                matTooltip="Open in new tab">
                <mat-icon>open_in_new</mat-icon>
            </a> -->
            <input type="file" 
                #fileRef
                style="display:none;"
                (change)="replaceOnFileChange($event, entity)" />
            <button mat-icon-button matTooltip="Replace" (click)="fileRef.click()" *ngIf="showUploadControls"><mat-icon>swap_horiz</mat-icon></button>
        </mat-cell>
    </ng-container>
    <ng-container cdkColumnDef="name">
        <mat-header-cell mat-sort-header *cdkHeaderCellDef>Name</mat-header-cell>
        <mat-cell *cdkCellDef="let entity">{{entity.name}}</mat-cell>
    </ng-container>
    <ng-container cdkColumnDef="status">
        <mat-header-cell mat-sort-header *cdkHeaderCellDef>Status</mat-header-cell>
        <mat-cell *cdkCellDef="let entity">{{entity.docStatus}}</mat-cell>
    </ng-container>
    <ng-container cdkColumnDef="fileName">
        <mat-header-cell mat-sort-header *cdkHeaderCellDef>File Name</mat-header-cell>
        <mat-cell *cdkCellDef="let entity">{{entity.fileName}}</mat-cell>
    </ng-container>
    <ng-container cdkColumnDef="vendorFileName">
        <mat-header-cell mat-sort-header *cdkHeaderCellDef>Vendor File Name</mat-header-cell>
        <mat-cell *cdkCellDef="let entity">{{entity.vendorFileName}}</mat-cell>
    </ng-container>
    <ng-container cdkColumnDef="documentType">
        <mat-header-cell mat-sort-header *cdkHeaderCellDef>Document Type</mat-header-cell>
        <mat-cell *cdkCellDef="let entity">{{entity.documentType}}</mat-cell>
    </ng-container>
    <ng-container cdkColumnDef="fileType">
        <mat-header-cell mat-sort-header *cdkHeaderCellDef>File Type</mat-header-cell>
        <mat-cell *cdkCellDef="let entity">
            <div [ngSwitch]="entity.fileType" [matTooltip]="entity.fileType">
                <span *ngSwitchCase="'application/pdf'"><mat-icon>description</mat-icon> PDF</span>
                <span *ngSwitchCase="'pdf'"><mat-icon>description</mat-icon> PDF</span>
                <span *ngSwitchCase="'application/msword'"><mat-icon>description</mat-icon> Word</span>
                <span *ngSwitchCase="'application/vnd.openxmlformats-officedocument.wordprocessingml.document'"><mat-icon>description</mat-icon> Word</span>
                <span *ngSwitchCase="'application/doc'"><mat-icon>description</mat-icon> Doc</span>
                <span *ngSwitchCase="'doc'"><mat-icon>description</mat-icon> Doc</span>
                <span *ngSwitchCase="'docx'"><mat-icon>description</mat-icon> Doc</span>
                <span *ngSwitchCase="'ini'"><mat-icon>description</mat-icon> Text</span>
                <span *ngSwitchCase="'md'"><mat-icon>description</mat-icon> Text</span>
                <span *ngSwitchCase="'application/vnd.ms-excel'"><mat-icon>border_all</mat-icon> Excel</span>
                <span *ngSwitchCase="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"><mat-icon>border_all</mat-icon> Excel</span>
                <span *ngSwitchCase="'application/vnd.ms-powerpoint'"><mat-icon>description</mat-icon> PowerPoint</span>
                <span *ngSwitchCase="'application/vnd.openxmlformats-officedocument.presentationml.presentation'"><mat-icon>description</mat-icon> PowerPoint</span>
                <span *ngSwitchCase="'application/zip'"><mat-icon>archive</mat-icon> ZIP</span>
                <span *ngSwitchCase="'zip'"><mat-icon>archive</mat-icon> ZIP</span>
                <span *ngSwitchCase="'image/jpeg'"><mat-icon>image</mat-icon> JPEG</span>
                <span *ngSwitchCase="'jpg'"><mat-icon>image</mat-icon> JPEG</span>
                <span *ngSwitchCase="'image/png'"><mat-icon>image</mat-icon> PNG</span>
                <span *ngSwitchCase="'png'"><mat-icon>image</mat-icon> PNG</span>
                <span *ngSwitchDefault>Unknown</span>
            </div>
        </mat-cell>
    </ng-container>
    <ng-container cdkColumnDef="createdAt">
        <mat-header-cell mat-sort-header *cdkHeaderCellDef>Created</mat-header-cell>
        <mat-cell *cdkCellDef="let entity"><span>{{entity.createdAtUTC | date:DATE_FORMAT}}</span></mat-cell>
    </ng-container>
    <mat-header-row *cdkHeaderRowDef="columns; sticky: true"></mat-header-row>
    <mat-row *cdkRowDef="let row; columns: columns"></mat-row>
</mat-table>
<div *ngIf="affidavits.length">
    <br *ngIf="documents.length">
    <label>Affidavits</label>
</div>
<mat-table *ngIf="affidavits.length" [dataSource]="affidavitSource" matSort #affidavit="matSort" (matSortChange)="sortData(affidavitSource, $event)">
    <ng-container cdkColumnDef="actions">
        <mat-header-cell *cdkHeaderCellDef>Download</mat-header-cell>
        <mat-cell *cdkCellDef="let entity" >
            <button matTooltip="Get Download URL" 
                mat-icon-button 
                (click)="affidavitDownload(entity.linkTo)">
                <mat-icon>download</mat-icon>
            </button>
        </mat-cell>
    </ng-container>
    <ng-container cdkColumnDef="title">
        <mat-header-cell mat-sort-header *cdkHeaderCellDef>Name</mat-header-cell>
        <mat-cell *cdkCellDef="let entity">{{entity.title}}</mat-cell>
    </ng-container>
    <ng-container cdkColumnDef="createdAt">
        <mat-header-cell mat-sort-header *cdkHeaderCellDef>Created</mat-header-cell>
        <mat-cell *cdkCellDef="let entity"><span>{{entity.createdAtUTC | date:DATE_FORMAT}}</span></mat-cell>
    </ng-container>
    <mat-header-row *cdkHeaderRowDef="['actions','title','createdAt']; sticky: true"></mat-header-row>
    <mat-row *cdkRowDef="let row; columns: ['actions','title','createdAt']"></mat-row>
</mat-table>

<div>
    <div *ngIf="cc">
        <h2>User CC</h2>
        <div class="single-file" *ngFor="let email of emails; let i = index">
            <div class="delete"(click)="removeEmail(email)">
                <mat-icon>delete</mat-icon>
            </div>
            <div class="info">
                <h4 class="name">{{ email }}</h4>
            </div>
        </div>

        <form>
            <mat-form-field>
                <mat-label>Email</mat-label>
                <input required matInput type="email" [formControl]="emailControl">
                <mat-error *ngIf="emailControl.hasError('email')">Must be formatted as an email</mat-error>
                <mat-error *ngIf="emailControl.hasError('unique')">Must not already be listed</mat-error>
                <mat-error *ngIf="emailControl.hasError('required')">Must enter an email</mat-error>
            </mat-form-field>
        </form>
        <button type="button" mat-raised-button (click)="emailPush()">Add email</button>
    </div>

    <div *ngIf="cc">
        <form>
            <h2>Included Message</h2>
            <mat-form-field style="width:30vw">
                <mat-label>Message</mat-label>
                <textarea matInput  [formControl]="messageControl"></textarea>
            </mat-form-field>
        </form>
    </div>

    <div>
        <h2 *ngIf="showUploadControls">New documents</h2>
        <div class="drop-zone" *ngIf="showUploadControls">
            <div class="instructions">
                <mat-icon>cloud_upload</mat-icon>
                <span style="text-align: center; display:inherit">Accepted File Types:
                    <span *ngFor="let fileType of acceptedFileTypes; let i = index"> 
                        {{ acceptedFileTypes.length > i + 1 ? fileType[1] + ',' :  ' and ' + fileType[1] }} 
                    </span>  
    
                </span>
                <div class="drop-header">Drag & Drop Files Here</div>
                <p>or</p>
                <div class="fileDrop">
                    <button mat-flat-button for="fileDropRef">
                        <span>Browse for File</span>
                    </button>
                </div>
                <input type="file" #fileUploader multiple (change)="onSelect($event)" id="fileDropRef">
            </div>
        </div>
        <button *ngIf="showUploadControls" mat-flat-button [disabled]="files.length < 1" type="button" color="primary" (click)="uploadFiles()">
            <mat-icon>add</mat-icon>
            <span>Add {{files.length}} selected file(s)</span>
        </button>
    </div>

    <br>
</div>

<div *ngIf="!uploading">
    <br>
    <br>
    <div class="single-file" *ngFor="let file of files; let i = index">
        <div class="file-icon" style="width: 50px;">
            <mat-icon >article</mat-icon>
        </div>
        <div class="info">
            <h4 class="name">{{ file.name }}</h4>
            <p class="size">{{ formatBytes(file.size) }}</p>
        </div>
        <div class="delete" (click)="removeFile(i)">
            <mat-icon class="centered" color="primary">delete</mat-icon>
        </div>
    </div>
</div>

<div *ngIf="errors.length" class="error-panel mat-elevation-z2">
    <div class="d-flex gap-s">
        <mat-icon color="warn">error</mat-icon>
        <span class="mat-caption">Errors occurred when adding the following files:</span>
    </div>
    <mat-list>
        <mat-list-item *ngFor="let e of errors">
            <span matLine>{{e.file.name}}</span>
            <span matLine>{{formatError(e.reason)}}</span>
        </mat-list-item>
    </mat-list>
</div>