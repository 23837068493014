import {Component, Inject } from '@angular/core';
// import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';

// export interface OrderData {
//   eFileControlNo: number,
//   eServiceControlNo: number
// }

@Component({
  selector: 'order-created-dialog',
  templateUrl: 'orderCreated.html',
})
export class OrderCreatedDialog {
    // constructor(@Inject(MAT_DIALOG_DATA) public data: OrderData) {}
    // constructor() {}
}
