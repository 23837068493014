
<div *ngIf="entity">
    <div class="static-field" *ngIf="entity.uuid">
        <label>ID</label>
        <span>{{entity.uuid}}</span>
    </div>
    <div class="static-field" *ngIf="entity.createdAt">
        <label>Created At</label>
        <span>{{entity.createdAtUTC | date:FC3_DATETIME_FORMAT}}</span>
    </div>
    <div class="static-field" *ngIf="entity.updatedAt">
        <label>Updated At</label>
        <span>{{entity.updatedAtUTC | date:FC3_DATETIME_FORMAT}}</span>
    </div>
    <div class="static-field" *ngIf="entity.deletedAt">
        <label>Deleted At</label>
        <span>{{entity.deletedAtUTC | date:FC3_DATETIME_FORMAT}}</span>
    </div>
    <div *ngIf="is_success_account">
        <div class="static-field" *ngIf="success_account.lastLogin">
            <label>Last Login At</label>
            <span>{{success_account.lastLogin | date:FC3_DATETIME_FORMAT}}</span>
        </div>
    </div>
</div>

<ng-content></ng-content>
