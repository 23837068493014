




import { Injectable } from '@angular/core';
import { ResourceService } from '@smartsoftware/reflex-core';
import { OrderSpecialInstruction } from '../models';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class OrderSpecialInstruction_Service extends ResourceService<OrderSpecialInstruction>
{
    protected servicePath = '/OrderSpecialInstruction';
    public ModelType = OrderSpecialInstruction;
    constructor( protected http: HttpClient )
    {
        super(http);
    }
}

