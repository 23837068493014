import { Directive, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { CanLeaveService } from '../../services/canLeave.service';
import { DialogComponent } from '../dialog/dialog';
import { Observable, of, defer } from 'rxjs';
import { tap } from 'rxjs/operators';

@Directive({
    selector: 'lr-dialog[dontLeave]'
})
export class CanLeaveDirective {

    constructor(private canLeave: CanLeaveService, private dialog: DialogComponent<boolean>) {
        // Hooks on the allowDeactivation observer
        this.canLeave.allowDeactivation(this.canLeave$);
    }

    /** When true, pops-up a dialog asking for user's consent to leave*/
    @Input() dontLeave: boolean = false;

    /** Reflects the dontLeave changes */
    @Output() dontLeaveChange = new EventEmitter<boolean>();

    // CanLeave Observavble
    private get canLeave$(): Observable<boolean> {
        // Builds an observable conditionally at subscription time
        return defer(() => this.dontLeave ? this.dialog.open().afterClosed() as Observable<any> : of(true))
            // Makes sure all the following requests will be true once the first has been granted
            .pipe(
                tap(
                    allowed => (allowed === this.dontLeave) && this.dontLeaveChange.emit(this.dontLeave = !allowed)
                    /*
                    
                                        {
                                            console.log('canLeave$ allowed: ', allowed);
                                            if (!!allowed == this.dontLeave) {
                                                console.log()
                                                this.dontLeave = !!!allowed;
                                                this.dontLeaveChange.emit(allowed);
                                                console.log(this.dontLeave);
                                            }
                                        }
                                        */
                )
            );
    }

    // Prevents the tab/page to be closed
    @HostListener('window:beforeunload', ['$event']) private beforeUnload(ev: Event) {
        return !this.dontLeave;
    }
}
