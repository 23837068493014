


import { Field } from "@smartsoftware/reflex-core";
import { DexResourceModel } from "../abstracts/dexResource.model";

export enum TelemetryEventType 
{
    RouteChange = "ROUTECHANGE",
    recordsSSO = "RECORDS-SSO",
    depositionsSSO = "DEPOSITIONS-SSO",
    efileSSO = "EFILE-SSO"
}

export class Telemetry extends DexResourceModel
{
    
    @Field(String)
    public successAccount_uuid: string | null = null;
    
    @Field(String)
    public eventType: string = "";
    
    @Field(String)
    public route: string | null = null;
    
    @Field(String)
    public jsonData: string | null = null;

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

    public get data(): any {
        let json = super.data;

        return json;
    }
}