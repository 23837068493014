import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { Document } from '../models/document.model';
import { map } from 'rxjs/operators';
import { Page, PageParams } from '../paged.datasource';
import { Observable } from 'rxjs';
import { Order } from '../models/order.model';

@Injectable()
export class Document_Service extends ResourceService<Document> {
    protected servicePath: string = '/Document';

    public ModelType = Document;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }

    page(pageParams: PageParams): Observable<Page<Document>> {
        let params = new HttpParams()
            .append('params', btoa(JSON.stringify(pageParams)));
        return this.http.get<Page<Document>>(`${this.serviceUrl}successPage`, { params })
            .pipe(map(page => {
                page.items = page.items.map(i => new Document(i));
                return page;
            }));
    }


    public getOrder(documentId: string) {
        return this
            .http
            .post(
                this.serviceUrl + 'getOrder',
                {
                    documentId:documentId
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) =>
                        response.body.map((e: Order) => new Order(e))
                )
            );
    }

    public getCaseDocuments(caseId:string){
        return this
        .http
        .post(
            this.serviceUrl + 'getCaseDocuments',
            {
                caseId:caseId,
            },
            {
                observe: 'response'
            }
        )
        .pipe(
            map(
                (response: HttpResponse<any>) => {return response.body.recordset}
            )
        );

    }

    public getSignedUrl(document:Document, order?: Order){
        let docId = document.id
        return this
            .http
            .post(
                this.serviceUrl + 'getSignedUrl',
                {
                    document:document,
                    order:order
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => {return {docId: docId as string, message: response.body.message}}
                )
            );
    }

    public getDocumentFile(document:Document, order?:Order){
        return this
            .http
            .post(
                this.serviceUrl + 'getDocumentFile',
                {
                    document:document,
                    order:order
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) =>
                        {return {response:response.body,document:document}}
                )
            )
    }
    
    public getDownloadUrl(orderId: string, fileName: string){
        return this.http
            .post(
                this.serviceUrl + 'getDownloadUrl',
                {
                    fileName:fileName,
                    orderId:orderId
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => {
                        return response.body.message;
                    }
                )
            );
    }
    
    public findDocumentsByOrderId(num:string, id: string) {
        return this.http.get<Document[]>(`${this.serviceUrl.split('Document')[0]}Order/${id}/Documents`)
            .pipe(map(documentArray => documentArray.map(doc => new Object({doc:new Document(doc), orderNum: num,id:id}))));
    }
}
