import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'
import { ReflexEnvironment as ENV } from '@smartsoftware/reflex-core';
//import { ServiceResponse } from '@smartsoftware/dexterity';

import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddDocumentData, RemoveDocumentData } from '../definitions';
//import { S3FileParam } from '@smartsoftware/dexterity-aws';

@Injectable()
export class FileAction_Service {
    public uploadComplete$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    constructor(
        protected http: HttpClient,
    ) { }

    public requestUploadUrl(file: File, filename: string, fileFolderName: string, entityType: string) {
        return this
            .http
            .post<any>(
                ENV.config['apiUrl'] + '/FileAction/legal-reflex/signed/upload',
                {
                    folderName: `uploads/${entityType}/${fileFolderName}`,
                    filename,
                    contentType: file.type
                },
                { observe: 'response' }
            );
    }

    public requestOrderUploadUrl(uploadData:any) {
        return this
            .http
            .post(
                ENV.config['flapiUrl'] + '/OpenAPI/order/addDocument',
                {
                    orderId: uploadData.orderId,
                    documentName: uploadData.documentName
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => {
                        return response.body;
                    }
                )
            );
    }

    public uploadFile(file: File, url: string) {
        return this
            .http
            .put(
                url,
                file,
                {
                    headers: {
                        "Content-Type": file.type
                    },
                    reportProgress: true,
                    responseType: 'json',
                    observe: 'response'
                }
            );
    }

    public requestDownloadUrl(filename: string, folderName: string, entityType: string) {
        return this
            .http
            .post<any>(
                ENV.config['apiUrl'] + '/FileAction/legal-reflex/signed/download',
                {
                    folderName: `uploads/${entityType}/${folderName}`,
                    filename
                },
                { observe: 'response' }
            );
    }

    public requestOrderDownloadUrl(filename: string, folderName: string) {
        return this
            .http
            .post<any>(
                ENV.config['apiUrl'] + '/FileAction/legal-reflex/signed/download',
                {
                    folderName: `srv/nfs/OCSCstore/prod/temp/order/${folderName}`,
                    filename,
                    bucket: 'flproddata'
                },
                { observe: 'response' }
            );
    }

    public async removeDocumentsFromOrder(orderId: string, documents: RemoveDocumentData) {
        return this
            .http
            .post(
                ENV.config['openapiUrl'] + `/order/document/remove/${orderId}`,
                documents,
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => {
                        console.log('TFTEST - documents for draft response', response);
                        return response.body
                    }
                )
            )
    }

    public async addDocumentsToOrder(orderId: string, documents: AddDocumentData) {
        return this
            .http
            .post(
                ENV.config['openapiUrl'] + `/order/document/add/${orderId}`,
                documents,
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => {
                        console.log('TFTEST - documents for draft response', response);
                        return response.body
                    }
                )
            )
    }

    

    public async uploadOrderDocument(uploadURI: string, file: File) {
    
        const headers = new Headers({ 'Content-Type': file.type });
        const response = await fetch(uploadURI, {
            method: 'PUT',
            headers: headers,
            body: file
        });

        return response;
    }

    // public uploadOrderDocument(path: S3FileParam , file: File) {
    //     return this
    //         .http
    //         .post<ServiceResponse>(
    //             ENV.config['apiUrl'] + '/FileAction/first-connect/order/upload',
    //             {
    //                 path: path,
    //                 file: file
    //             },
    //             { 
    //                 observe: 'response' 
    //             }
    //         );
    // }
}
