import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FrameComponent } from './frame/frame.component';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import {  NavigationModule, ResourceModule } from '@smartsoftware/reflex-core';
import { LegalReflexDirectivesModule } from '../../directives/directives.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';

const routes: Routes = [
  {
    path: 'impersonate',
    component: FrameComponent,
  }
]

@NgModule({
  declarations: [
    FrameComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    NavigationModule, 
    ResourceModule,
    LegalReflexDirectivesModule,
    MatProgressBarModule,
    MatListModule,
    MatCardModule,
    RouterModule.forChild(routes)
  ]
})
export class ImpersonateModule { }
