import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class SuccessCounty extends DexResourceModel {

    @Field(String)
    public state_uuid: string = "";
    @Field(String)
    public countyName: string = "";
    @Field(Number)
    public FIPSCode: number = 0;


    @Field(Boolean)
    public isEFileAvailable: boolean = false;


    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}
