import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { Project_ConflictParty } from '../models/project_conflictParty.model';

@Injectable()
export class Project_ConflictParty_Service extends ResourceService<Project_ConflictParty> {
  protected servicePath: string = '/ProjectConflictParty';

  public ModelType = Project_ConflictParty;

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }
}
