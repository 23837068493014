import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { ServiceAddress } from '../models/serviceAddress.model';

import { map } from 'rxjs/operators';

@Injectable()
export class ServiceAddress_Service extends ResourceService<ServiceAddress> {
    protected servicePath: string = '/ServiceAddress';

    public ModelType = ServiceAddress;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }

    public getDeliveryAddressesByOrderId(orderId: string) {
        return this.http.get<ServiceAddress[]>(`${this.serviceUrl}DeliveryServiceAddresses/${orderId}`)
            .pipe(map(addresses => addresses.map(a => new ServiceAddress(a))));
    }

    public ToServeDeliveryAddresses(orderId: string) {
        return this.http.post<ServiceAddress[]>(`${this.serviceUrl}ToServeDeliveryAddresses/`,{orderId:orderId})
            .pipe(map(addresses => addresses.map(a => new ServiceAddress(a))));
    }

}
