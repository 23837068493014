<!--
<div class="authenticatedUser" *ngIf="auth.userData$ | async as user">
	<img [src]="user.picture" />
	<div class="details">
		<span class="nickname">{{user.name}}</span>
		<div class="controls">
			<button mat-icon-button>
				<mat-icon>account_box</mat-icon>
			</button>
			<button mat-icon-button (click)="logout()">
				<mat-icon>logout</mat-icon>
			</button>
		</div>
	</div>

</div>
-->
<div class="unAuthenticatedUser" *ngIf="auth.isAuthenticated()">
	<div class="details">
		<span class="nickname"></span>
		<div class="controls">
			
			<button mat-icon-button [routerLink]="['users',(userService.user | async)?.uuid]"  matTooltip="Your Profile">
				<mat-icon>account_box</mat-icon>
			</button>

			<button mat-icon-button [matMenuTriggerFor]="menu" matBadge="{{notificationLength? notificationLength : recentNotifications.length}}" [matBadgeHidden]="recentNotifications.length == 0" matBadgeColor="accent" matTooltip="Notifications">
				<mat-icon>notifications</mat-icon>
			</button>
			<mat-menu #menu="matMenu">
				<button *ngFor="let entry of recentNotifications" (click)="openNotification(entry)" mat-menu-item >{{entry.subject}}</button>
				<button (click)="getNotifications(true)" mat-menu-item>Find new notices</button>
			</mat-menu>				
			<button mat-icon-button (click)="logout()"  matTooltip="Logout">
				<mat-icon>logout</mat-icon>
			</button>
		</div>
	</div>
</div>

<div class="unAuthenticatedUser" *ngIf="!auth.isAuthenticated()">
	<div class="details">
		<span class="nickname"></span>
		<div class="controls">
		</div>
	</div>
</div>
<!--
<div class="unAuthenticatedUser" *ngIf="(auth.isAuthenticated$ | async) === false">
	<div class="controls">
		<button mat-raised-button (click)="login()">Login</button>
	</div>
</div>
-->