import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  templateUrl: './frame.html',
  styleUrls: ['./frame.scss']
})
export class ClientMatterFrame implements OnInit {

  constructor(public pageTitleService: Title) 
  {
    this.pageTitleService.setTitle("FirstConnect - Client Matters");
   }

  ngOnInit(): void {
  }

}
