import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { Contact,Order } from '../models/';
import { Note } from '../models/note.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class Contact_Service extends ResourceService<Contact> {
    protected servicePath: string = '/Contact';

    public ModelType = Contact;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }

    public push(entry: Contact): Observable<Contact> {
        
        
        if(entry.check == "true" && entry.deletedAt == null){
            entry.deletedAt = new Date();
            }
            //set to active
            else if(entry.check == "true" && entry.deletedAt != null){
                entry.deletedAt = null;
            }
               
        return super.push(entry);
    }

    public getOrdersByContact(id: string){
        let params = new HttpParams().set('uuid', id);

        return this.http.get<Order[]>(`${this.serviceUrl}getOrdersByContact`,{params})
            .pipe(map(docs => docs.map(doc => new Order(doc))));
    }

    public getNotes(uuid: string) {
        return this
            .http
            .post(
                this.serviceUrl + 'notes',
                {
                    uuid
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) =>
                        response.body.map((e: any) => new Note(e))
                )
            );
    }
}
