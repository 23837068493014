import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { FLCompanyBranch } from '../models/flCompanyBranch.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Page, PageParams } from '../paged.datasource';

@Injectable()
export class FLCompanyBranch_Service extends ResourceService<FLCompanyBranch> {
    protected servicePath: string = '/FLCompanyBranch';

    public ModelType = FLCompanyBranch;

    public getByCompanyUUID(uuid?: string){
        return this
            .http
            .get(
                this.serviceUrl + 'FLCompanyBranch?uuid=' + uuid,
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) =>
                        response.body.map((e: FLCompanyBranch) => new FLCompanyBranch(e))
                )
            );
    }

    page(pageParams: PageParams): Observable<Page<FLCompanyBranch>> {
        let params = new HttpParams()
            .append('params', btoa(JSON.stringify(pageParams)));
    
        return this.http.get<Page<FLCompanyBranch>>(`${this.serviceUrl}Page`, { params })
            .pipe(map(page => {
                page.items = page.items.map(i => new FLCompanyBranch(i));
                return page;
            }));
    }
    
    public push(entry: FLCompanyBranch): Observable<FLCompanyBranch> {

        if(!entry.address){
            entry.address = "";
        }
        if(!entry.address2){
            entry.address2 = ""
        }
        if(!entry.city){
            entry.city = "";
        }
        if(!entry.state){
            entry.state = "";
        }
        if(!entry.zip){
            entry.zip = "";
        }
        if(!entry.phone){
            entry.phone = "";
        }

        //set to inactive
        if(entry.check == "true" && entry.deletedAt == null){
            entry.deletedAt = new Date();
            }
            //set to active
   else if(entry.check == "true" && entry.deletedAt != null){
            entry.deletedAt = null;
            }
            return super.push(entry);
    }


    public getCompanyBranchByCompany(id: string) {
        let params = new HttpParams().set('company_uuid', id);

        return this.http.get<FLCompanyBranch[]>(`${this.serviceUrl}getCompanyLocationByCompany`,{params})
            .pipe(map(docs => docs.map(doc => new FLCompanyBranch(doc))));
    }        

    public getDistinctCompanyNumbersByCorpId(corpId: string): Observable<string[]> {
        return this.http.get<string[]>(`${this.serviceUrl}findByCorpId/${corpId}`)
            .pipe(
                map(results => {
                    return results;
                })
            );
    }
    
    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }

}
