import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { ProjectType } from '../models';
import { map } from 'rxjs/operators';

@Injectable()
export class ProjectType_Service extends ResourceService<ProjectType> {
    protected servicePath: string = '/ProjectType';

    public ModelType = ProjectType;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }
}
