import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ResourceService } from '@smartsoftware/reflex-core';
import { ClientPartnerVendor } from '../models/clientPartnerVendor.model';
import { Vendor } from '../models/vendor.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ClientPartnerVendor_Service extends ResourceService<ClientPartnerVendor> {
    protected servicePath: string = '/ClientPartnerVendor';

    public ModelType = ClientPartnerVendor;

    public push(entry: ClientPartnerVendor): Observable<ClientPartnerVendor> {

        if(!entry.clientPartner_uuid){
            entry.clientPartner_uuid = null;
        }
        if(!entry.vendor_uuid){
            entry.vendor_uuid = null;
        }
        return super.push(entry);
    }

    public getVendorName(id: string) {
        let params = new HttpParams().set('vendor_uuid', id);

        return this.http.get<Vendor[]>(`${this.serviceUrl}getVendorName`,{params})
            .pipe(map(docs => docs.map(doc => new Vendor(doc))));
    }

    // public getVendorByUUID(id: string) {
    //     let params = new HttpParams().set('vendor_uuid', id);

    //     return this.http.get<Vendor[]>(`${this.serviceUrl}getVendorByUUID`,{params})
    //         .pipe(map(docs => docs.map(doc => new Vendor(doc))));
    // }

    public getClientPartnerVendorByClientUUID(id: string) {
        let params = new HttpParams().set('uuid', id);
        return this.http.get<ClientPartnerVendor[]>(`${this.serviceUrl}getClientPartnerVendorByClientUUID`,{params})
            .pipe(map(docs => docs.map(doc => new ClientPartnerVendor(doc))));
    }

    public changeStatus(id: string): Observable<Array<ClientPartnerVendor>> {
        let params = new HttpParams().set('uuid', id);

        return this.http.get<ClientPartnerVendor[]>(`${this.serviceUrl}changeStatus`,{params})
            .pipe(map(docs => docs.map(doc => new ClientPartnerVendor(doc))));
        
    }

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }

}
