<form [formGroup]="legalConnectFormGroup">
    <resource-list-grid >
        <resource-list-pane >
            <h1>Login to LegalConnect</h1>
            <div class="loading" *ngIf="isLoading">
                <div class="loading-text">{{ loadingMessage }}</div>
                <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
            </div>
            <div *ngIf="!hasAttorneys && !isLoading">
                <h2>This account does not have an Attorney associated with it. Please add an Attorney:</h2>
                <div class="row">
                    <div class="column" >
                        
                        <mat-form-field >
                            <input matInput required placeholder="Full Name" formControlName="fullName">
                        </mat-form-field>
                        
                        <mat-form-field >
                            <input matInput required placeholder="Email" formControlName="email">
                        </mat-form-field>
                        
                        <mat-form-field >
                            <input matInput required placeholder="Bar Id" formControlName="barId">
                        </mat-form-field>

                    </div>
                </div>
            </div>
            <mat-error *ngIf="showError">{{ errorMessage }}</mat-error>
            <div class="formActions">
                <button mat-raised-button color="primary" *ngIf="(!hasAttorneys && !isLoading)" [disabled]="!legalConnectFormGroup.valid" (click)="save();">Submit</button>
                <button mat-raised-button color="primary" *ngIf="(hasAttorneys || isLoading)" [disabled]="isLoading" (click)="loginToLegalConnect();">Retry</button>
                <button mat-raised-button (click)="cancel();">Cancel</button>
            </div>
        </resource-list-pane>
    </resource-list-grid>
</form>