import { jsPDF } from "jspdf";
import { Injectable } from '@angular/core';
import{
    Order,
    Order_Service,
    ServiceAddress_Service,
    User,
    User_Service
} from 'legalreflex-lib'

import '../../../assets/OpenSans-Medium-normal.js' 
import '../../../assets/OpenSans-Bold-bold.js'
@Injectable()
export class OrderPrint_Service {


    constructor(
        public serveService: ServiceAddress_Service
    ){}

    public print(data: ReviewData, outputType: outputTypes = "dataurlnewwindow"){
        this.printDetails(data, outputType);
    }

    public printDetails(data: ReviewData, outputType: outputTypes = "dataurlnewwindow"){
        let doc = new jsPDF('p', 'in', [8.5,11], true);
        //[950, 1150] Original px Dimensions
        // Needs addition of ttf file for font addition
        // Conversion takes place through jspdf fontconverter, can just open up converter
        // in the jsPDF/fontconverter/fontconverter.html and drop in ttf file
        // or wrap up ttf file request result into binary string
        // https://github.com/parallax/jsPDF/blob/master/README.md#use-of-utf-8--ttf

        let defaultFontColor = "#0F1319";
        let fieldFontColor = "#444444";
        doc.setTextColor(defaultFontColor);

        let rowPosition = .20;
        let pageWidth = 8.5;
        let pageHeight = 11;
        let margin = 1.667;
        let lineSpacing = 0.926;
        //4 column grid
        let columnWidth = pageWidth / 4;
        let columnPadding = 4;
        let column1 = margin;
        let column2 = column1 + columnWidth;
        let column3 = column2 + columnWidth;
        let column4 = column3 + columnWidth;

        doc.setLineWidth(0.0037);
        doc.setFont('OpenSans-Medium', 'normal');
        doc.setFillColor('#eeeeee').rect(0, 0, pageWidth, pageHeight, "F");
        //short hand for formatting
        let quickcheck = ():void => {
            if(rowPosition >= pageHeight){
                doc.addPage();
                doc.setPage(doc.getNumberOfPages());
                rowPosition = 0.046;
                doc.setFillColor('#eeeeee').rect(0, 0, pageWidth, pageHeight, "F");
                doc.setFillColor('#FFFFFF').rect(0.463, 0, 7.87, pageHeight, "F");
            }
        }

        let hyl = (size:number = 5.5): jsPDF => {
            quickcheck();
            return doc.setFontSize(size).setTextColor("#4f7ba0");
        }

        let bold = (size:number, color:string = "#0F1319"): jsPDF => {
            quickcheck();
            return doc.setFontSize(size).setTextColor(color);
        }

        let fl = (): jsPDF => {
            quickcheck();
            return doc.setFontSize(7).setTextColor(defaultFontColor);
        }

        let th = (): jsPDF => { 
            quickcheck();
            return doc.setFontSize(5.5).setTextColor(defaultFontColor);
        }

        let td = (): jsPDF => { 
            quickcheck();
            return doc.setFontSize(5.5).setTextColor('#FFFFFF');
        }

        // Header

        hyl().text("FirstConnect Order Confirmation", 0.51, 0.37);
        let appurl = data.env;
        hyl().textWithLink("View In First Connect", 7.5, 0.37 ,{url: appurl});
        doc.setLineWidth(0.0037);
        doc.setDrawColor('#12426A');
        doc.line(7.47, 0.38, 8.28, 0.38);

        // Top Blue Logos
        let logo = new Image();
        let logoSrc = "../../../assets/images/fln_logocorpreversed.png";
        logo.src = logoSrc;
        doc.setFillColor('#12426A').rect(0.463, 0.463, 7.87, 1.48, "F");
        doc.setFillColor('#FFFFFF').rect(0.463, 1.38, 7.87, pageHeight - 1.38, "F");
        doc.addImage(logo, 'PNG', 3.24, 0.65, 2.037, 0.51);

        logo = new Image();
        logoSrc = "../../../assets/images/file_thru_trial_white.png";
        logo.src = logoSrc;
        doc.addImage(logo, 'PNG', 7.45, 1.11, 0.51, 0.138);

        // Checkmark and submission blurb
        rowPosition += 1.333;

        // First Legal Control Table
        doc.setFillColor('#eeeeee').rect(0.65, rowPosition, 7.40, 0.278, "F");
        rowPosition += 0.166;
        doc.setFont('OpenSans-Bold', 'bold');
        bold(7).text("First Legal Control #:", lineSpacing, rowPosition);
        hyl(7).textWithLink(data.orderNumber, column1 + (columnWidth/3), rowPosition, {url:appurl});
        doc.setFont('OpenSans-Medium', 'normal');
        rowPosition += 0.241;

        doc.setFont('OpenSans-Medium', 'normal');
        fl().text("Service:", lineSpacing, rowPosition);
        fl().text(data.service, column1 + (columnWidth/3), rowPosition);
        rowPosition += 0.185;

        fl().text("Type:", lineSpacing, rowPosition);
        fl().text(data.serviceType.split(' - ')[0], column1 + (columnWidth/3), rowPosition);
        rowPosition += 0.185;

        fl().text("Urgency:", lineSpacing, rowPosition);
        let urgency = data.serviceType.split(' - ')
        fl().text(urgency[1] ? urgency[1] : '', column1 + (columnWidth/3), rowPosition);
        rowPosition += 0.185;

        fl().text("Client Matter:", lineSpacing, rowPosition);
        fl().text(data.clientMatterNumber, column1 + (columnWidth/3), rowPosition);
        rowPosition += 0.185;

        fl().text("Claim Number:", lineSpacing, rowPosition);
        fl().text(data.claimNumber, column1 + (columnWidth/3), rowPosition);
        rowPosition += 0.185;

        fl().text("Case Name:", lineSpacing, rowPosition);
        fl().text(data.caseName, column1 + (columnWidth/3), rowPosition);
        rowPosition += 0.185;

        fl().text("Case Number:", lineSpacing, rowPosition);
        fl().text(data.caseNumber, column1 + (columnWidth/3), rowPosition);
        rowPosition += 0.148;
        
        doc.setFillColor('#eeeeee').rect(0.65, rowPosition, 7.40, 0.278, "F");
        rowPosition += 0.185;
        // First Legal Pickup Table
        doc.setFont('OpenSans-Bold', 'bold');
        bold(7).text("Pickup From:", lineSpacing, rowPosition);
        doc.setFont('OpenSans-Medium', 'normal');
        rowPosition += 0.278;

        fl().text("Contact:", lineSpacing, rowPosition);
        fl().text(data.pickupContact, column1 + (columnWidth/3), rowPosition);
        rowPosition += 0.185;

        fl().text("Company:", lineSpacing, rowPosition);
        fl().text(data.pickupCompany ? data.pickupCompany.split(' - ')[0] : "", column1 + (columnWidth/3), rowPosition);
        rowPosition += 0.185;

        let addressString = data.pickupAddress[0] + (data.pickupAddress[1]? data.pickupAddress[1] : "");
        fl().text("Address:", lineSpacing, rowPosition);
        fl().text(addressString, column1 + (columnWidth/3), rowPosition);
        if(data.pickupAddress[2]){
            rowPosition += data.pickupAddress[0] != "" ? 0.111 : 0;
            fl().text(data.pickupAddress[2], column1 + (columnWidth/3), rowPosition);
        }
        rowPosition += 0.185;
        
        fl().text("Phone:", lineSpacing, rowPosition);
        fl().text(data.pickupPhone, column1 + (columnWidth/3), rowPosition);
        rowPosition += 0.185;

        fl().text("Pickup Date:", lineSpacing, rowPosition);
        fl().text(data.pickupDate, column1 + (columnWidth/3), rowPosition);
        rowPosition += 0.148;

        doc.setFillColor('#eeeeee').rect(0.65, rowPosition, 7.40, 0.278, "F");
        rowPosition += 0.185;

        
        // First Legal Deliver Table
        doc.setFont('OpenSans-Bold', 'bold');
        bold(7).text("Deliver To:", lineSpacing, rowPosition);
        doc.setFont('OpenSans-Medium', 'normal');
        rowPosition += 0.278;
        if(data.servee.length > 1){
            for(let cur = 0; cur < data.servee.length; cur++){
                fl().text("Servee", lineSpacing, rowPosition);
                fl().text("#" + (cur + 1) + ":", lineSpacing + 0.370, rowPosition);
    
                fl().text("Contact:", lineSpacing + 0.555, rowPosition);
                fl().text(data.servee[cur], column1 + (columnWidth/3), rowPosition);
                rowPosition += 0.185;
    
                fl().text("Company:", lineSpacing + 0.555, rowPosition);
                fl().text(data.serveCompany[cur], column1 + (columnWidth/3), rowPosition);
                rowPosition += 0.185;
    
                fl().text("Address:", lineSpacing + 0.555, rowPosition);
                fl().text(data.serveAddress[cur], column1 + (columnWidth/3), rowPosition);
                rowPosition += 0.185;
                
                fl().text("Phone:", lineSpacing + 0.555, rowPosition);
                fl().text(data.servePhone[cur], column1 + (columnWidth/3), rowPosition);
                rowPosition += 0.278;
            }
        } else {
            fl().text("Contact:", lineSpacing , rowPosition);
            fl().text(data.servee[0], column1 + (columnWidth/3), rowPosition);
            rowPosition += 0.185;

            fl().text("Company:", lineSpacing, rowPosition);
            fl().text(data.serveCompany[0], column1 + (columnWidth/3), rowPosition);
            rowPosition += 0.185;

            let addressString = data.serveAddress[0] + (data.serveAddress[1]? data.serveAddress[1] : "");
            fl().text("Address:", lineSpacing, rowPosition);
            fl().text(addressString, column1 + (columnWidth/3), rowPosition);
            if(data.serveAddress[2]){
                rowPosition += data.serveAddress[0] != "" ? 0.111 : 0;
                fl().text(data.serveAddress[2], column1 + (columnWidth/3), rowPosition);
            }
            rowPosition += 0.185;
            
            fl().text("Phone:", lineSpacing, rowPosition);
            fl().text(data.servePhone[0], column1 + (columnWidth/3), rowPosition);
            rowPosition += 0.278;
        }

        doc.setFillColor('#eeeeee').rect(0.65, rowPosition, 7.40, 0.278, "F");
        rowPosition += 0.185;
        // First Legal Special Instructions
        doc.setFont('OpenSans-Bold', 'bold');
        bold(7).text("Special Instructions:", lineSpacing, rowPosition);
        doc.setFont('OpenSans-Medium', 'normal');
        rowPosition += 0.278;

        // fl().text(data.specialInstructions, lineSpacing, rowPosition)
        if(data.pickupSpecialInstructions.length > 120){
            let curLength: number = 0;
            while(curLength + 120 < data.pickupSpecialInstructions.length - 1){
                fl().text(data.pickupSpecialInstructions.slice(curLength, curLength + 120), lineSpacing, rowPosition);
                curLength += 120;
                rowPosition += 0.111;
            }
            if(curLength <= data.pickupSpecialInstructions.length)
            {
                fl().text(data.pickupSpecialInstructions.slice(curLength, data.pickupSpecialInstructions.length), lineSpacing, rowPosition);
                rowPosition += 0.111;
            }
        }
        else{
            fl().text(data.pickupSpecialInstructions, lineSpacing, rowPosition);
        }
        rowPosition += 0.148;

        // fl().text(data.specialInstructions, lineSpacing, rowPosition)
        if(data.deliverySpecialInstructions.length > 120){
            let curLength: number = 0;
            while(curLength + 120 < data.deliverySpecialInstructions.length - 1){
                fl().text(data.deliverySpecialInstructions.slice(curLength, curLength + 120), lineSpacing, rowPosition);
                curLength += 120;
                rowPosition += 0.111;
            }
            if(curLength <= data.deliverySpecialInstructions.length)
            {
                fl().text(data.deliverySpecialInstructions.slice(curLength, data.deliverySpecialInstructions.length), lineSpacing, rowPosition);
                rowPosition += 0.111;
            }
        }
        else{
            fl().text(data.deliverySpecialInstructions, lineSpacing, rowPosition);
        }
        rowPosition += 0.148;

        doc.setFillColor('#eeeeee').rect(0.65, rowPosition, 7.40, 0.278, "F");
        rowPosition += 0.185;
        // First Legal Documents
        doc.setFont('OpenSans-Bold', 'bold');
        bold(7).text("Document(s):", lineSpacing, rowPosition);
        doc.setFont('OpenSans-Medium', 'normal');
        rowPosition += 0.278;
        hyl(7).textWithLink("Click here to view the order's documents", lineSpacing, rowPosition, {url:appurl});
        rowPosition += 0.278;

        if(rowPosition >= pageHeight - 0.740){
            rowPosition += 0.740;
            quickcheck();
            rowPosition += 0.093;
        }

        th().text('Should you have any questions, please contact Client Care and Success at ', 0.972, rowPosition);
        let len = th().getTextWidth("Should you have any questions, please contact Client Care and Success at ") + 0.972;
        hyl().textWithLink('877.350.8698, ', len, rowPosition, {url: "tel:877.350.8698"});
        len = len + th().getTextWidth('877.350.8698, ');
        th().text('email', len, rowPosition);
        len = len + th().getTextWidth('email ');

        hyl().textWithLink(`clientcare@firstlegal.com`, len, rowPosition, {url:"mailto:clientcare@firstlegal.com"});
        len = len + th().getTextWidth('clientcare@firstlegal.com ');
        th().text('or you can log in and manage your cases and orders in ', len, rowPosition);
        len = len + th().getTextWidth('or you can log in and manage your cases and orders in ');
        hyl().textWithLink(`First Connect.`, len, rowPosition, {url:appurl});

        rowPosition += 0.185;
        th().text(`Thank you for using First Legal.`, column2 - 0.093, rowPosition);
        rowPosition += 0.148;
        // Footer Blue Logos

        doc.setFillColor('#12426A').rect(0.463, rowPosition, 7.87, 0.370, "F");
        rowPosition += 0.185;
        td().text(`© Copyright 2024, First Legal. All rights reserved. Private Investigator Licenses: CA PI: 24171 AZ PI: 1551710 NV PI-PS: 1452`, column1 + 0.555, rowPosition);
        rowPosition += 0.185;
        doc.setFillColor('#eeeeee').rect(0.463, rowPosition, 7.87, pageHeight - rowPosition, "F");
        let filename = "FirstLegal-Order-" + data.service.replace(/[^\w\s]+/ig, '').replace(/\s+/g, '_') + '.pdf';
        console.log("Passing html", doc);
        doc.output(outputType as any, {filename: filename });

    }
}


export interface ReviewData {
    createdAt: string,
    updatedAt: string,
    orderNumber: string,
    service: string,
    serviceType: string,
    serviceCategoryDescription?: string,
    clientMatterNumber: string,
    claimNumber: string,
    insuranceCompany: string,
    insuranceAdjuster: string,
    pickupContact: string,
    pickupCompany: string,
    pickupAddress: string[],
    pickupPhone: string,
    pickupDate: string,
    caseNumber: string,
    caseName: string,
    casePlaintiff?: string,
    caseDefendant?: string,
    documentDescription: string,
    attachedDocuments: string[],
    servee: string[],
    serveCompany: string[],
    serveAddress: string[],
    servePhone: string[],
    serveAttempts: string[][],
    serveAffidavits: string[][],
    pickupHeader: string,
    pickupContactLabel: string,
    deliveryHeader: string,
    deliveryContactLabel: string,
    deliveryCompanyLabel: string,
    deliveryDate: string,
    deliveryTime: string,
    deliverySpecialInstructions: string,
    pickupSpecialInstructions: string,
    orderId: string
    env: string
}

//may add more to this possibly if more fields are needed
export interface OrderPrintData{
    record: Order,
    review: ReviewData
}

export type outputTypes = "arraybuffer" | "blob" | "bloburi" | "datauristring" | "datauri" | "dataurlnewwindow" | "pdfobjectnewwindow" | "pdfjsnewwindow";