<h2 mat-dialog-title>New Password</h2>
<div mat-dialog-content>
    <h4>Enter a new password for your Success Account</h4>
    <mat-form-field>
        <input matInput placeholder="Enter a new password" [type]="passwordVisibility" [(ngModel)]="data.password"/>
        <mat-icon class="clickable" matSuffix (click)="togglePasswordVisibility()">{{visibilityIcon}}</mat-icon>
    </mat-form-field>

    <div *ngIf="badPassword">
        <!-- Password invalid, must be at least 8 characters long, -->
        Password invalid, must be at least {{PASSWORD_MIN_LENGTH}} characters long, less than {{PASSWORD_MAX_LENGTH}} characters long,
        <br>and must contain, 1 lowercase letter, 1 uppercase letter, 1 number.
        <!-- ,<br>and 1 of the following special characters:{{specialSymbolList}} -->
    </div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="submit()">Set New Password</button>
</div>