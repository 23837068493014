<page-notice color="primary" *ngIf="!isLoading && (recentlyFinished | async)">
    <h1>Thank You for your Order Submission</h1>
    <p>Your order has been received and you should receive an email confirmation shortly.</p>
</page-notice>

<page-notice color="accent" *ngIf="!isLoading && (draftSaved | async)">
    <h1>Your Draft has been Saved</h1>
    <p>You may resume working on this order, or cancel it if you no longer need it.</p>
</page-notice>

<resource-list-grid *ngIf="!isLoading && !entity?.id">
    <resource-list-pane>
        <h3>No Order Found</h3>
    </resource-list-pane>
</resource-list-grid>

<resource-list-grid *ngIf="entity && entity.id">
    <resource-list-pane>
        <div class="orderProperties">
            <div class="row">
                <div class="column">
                    <div class="review-header">Requested Service</div>

                    <div class="property" *ngIf="createdByUser | async as account">
                        <label>Requested By</label>
                        <span *ngIf="account.firstName">{{ account.firstName }} {{ account.lastName }}</span>
                        <span *ngIf="!account.firstName">{{entity.name}}</span>
                    </div>

                    <div class="property" *ngIf="vendor | async as vendor">
                        <label>Service</label>
                        <span>{{ vendor.serviceAliasName || 'unknown' }}</span>
                    </div>

                    <div class="property">
                        <label>Category</label>
                        <span>{{ category?.displayName || entity.serviceType || 'undefined' }}</span>
                    </div>

                    <div class="property">
                        <label>Type</label>
                        <span>{{ type?.displayName || entity.serviceType || 'undefined' }}</span>
                    </div>

                    <div class="property" *ngIf="category?.displayName == 'Other'">
                        <label>Service Type Description</label>
                        <span>{{ serviceCategoryDescription || 'undefined' }}</span>
                    </div>

                    <div class="property">
                        <label>Control Number</label>
                        <span>{{entity.orderStatus == "Draft" ? entity.orderStatus : entity.orderNumber}}</span>
                    </div>
                    <div class="property">
                        <label>Status</label>
                        <span>{{entity.orderStatus}}</span>
                    </div>
                </div>
                <div class="column">
                    <div class="review-header">{{ showCourt ? "Order Information" : "Pickup From" }}</div>

                    <div *ngIf="showCourt">
                        <div class="property" *ngIf="createdByUser | async as account">
                            <label>Ordered By</label>
                            <span>{{ account.firstName }} {{ account.lastName }}</span>
                        </div>
                    </div>

                    <div *ngIf="!showCourt">
                        <div class="property" *ngIf="pickupAddress | async as address">
                            <label>Contact</label>
                            <span>{{ pickupAddress.value.attention }}</span>
                        </div>
                    </div>

                    <div class="property" *ngIf="pickupAddress | async as address">
                        <label>Company</label>
                        <span>{{ pickupAddress.value.name }}</span>
                    </div>

                    <div class="property" *ngIf="pickupAddress | async as address">
                        <label>Address</label>
                        <span [innerHTML]="addressBuilderHTML(pickupAddress.value)">
                        </span>
                    </div>
                
                    <div class="property" *ngIf="pickupAddress | async as address">
                        <label>Phone</label>
                        <span [innerHTML]="phoneBuilder(pickupAddress.value)"></span>
                    </div>

                    <div class="review-header">Order Details</div>
                    <div class="property" *ngIf="case | async as $case">
                        <label>Case Number</label>
                        <span>{{ ($case.caseNumber || entity.caseNumber) }}</span>
                    </div>
                    
                    <div class="property" *ngIf="case | async as $case">
                        <label>Case Name</label>
                        <span>{{ (entity.caseName || $case.name) }}</span><span *ngIf="$case.name && entity.caseName && $case.name.trim() != entity.caseName.trim()">( {{ ($case.name) }} )</span>
                    </div>
                </div>

                <div class="column">
                    <div class="review-header">Service Details</div>

                    <div class="property">
                        <label>Client Matter Number</label>
                        <span>{{ entity.clientMatterNumber }}</span>
                    </div>

                    <div class="property">
                        <label>Claim Number</label>
                        <span>{{ entity.claimNumber }}</span>
                    </div>
                    
                    <div class="property" *ngIf="entity.claimNumber">
                        <label>Insurance Company</label>
                        <span>{{ entity.insuranceCompany }}</span>
                    </div>

                    <div class="property" *ngIf="entity.claimNumber">
                        <label>Insurance Adjuster</label>
                        <span>{{ entity.insuranceAdjuster }}</span>
                    </div>

                </div>
                <!----delivery column-->
                <div class="column" *ngIf="showDeliverTo && entity.delivery_ServiceAddress_uuid">
                    <div class="review-header">Deliver To</div>
                    <div class="property" *ngIf="deliveryAddress | async as address">
                        <label>Company</label>
                        <span>{{ deliveryAddress.value.name }}</span>
                    </div>

                    <div class="property" *ngIf="deliveryAddress | async as address">
                        <label>Address</label>
                        <span [innerHTML]="addressBuilderHTML(deliveryAddress.value)"></span>
                    </div>
                
                    <div class="property" *ngIf="deliveryAddress | async as address">
                        <label>Phone</label>
                        <span>{{deliveryAddress.value.phone}}</span>
                    </div>

                    <div class="property" *ngIf="deliveryAddress | async as address">
                        <label>Special Instructions</label>
                        <span [innerHTML]="pickupSpecialInstructionBuilder()"></span>
                        <span [innerHTML]="deliverySpecialInstructionBuilder()"></span>
                        <!-- <span>{{specialInstructions}}</span> -->
                    </div>
                </div>

                <div class="column" *ngIf="showDeliverTo  && !entity.delivery_ServiceAddress_uuid">
                    <div class="review-header">Deliver To</div>
                    <div class="property" *ngIf="courtAddresses$ | async as addresses">
                        <label>Company</label>
                        <span>{{ addresses[0].name }}</span>
                    </div>

                    <div class="property" *ngIf="courtAddresses$ | async as addresses">
                        <label>Address</label>
                        <span [innerHTML]="addressBuilderHTML(addresses[0])"></span>
                    </div>
                
                    <div class="property" *ngIf="courtAddresses$ | async as addresses">
                        <label>Phone</label>
                        <span>{{addresses[0].phone}}</span>
                    </div>

                    <div class="property" *ngIf="deliveryAddress | async as address">
                        <label>Special Instructions</label>
                        <span [innerHTML]="pickupSpecialInstructionBuilder()"></span>
                        <span [innerHTML]="deliverySpecialInstructionBuilder()"></span>
                        <!-- <span>{{specialInstructions}}</span> -->
                    </div>
                </div>

                <div class="column" *ngIf="showCourt && entity.delivery_ServiceAddress_uuid">
                    <div class="review-header">Court</div>
                    <div class="property" *ngIf="deliveryAddress | async as address">
                        <label>Name</label>
                        <span>{{ deliveryAddress.value.name }}</span>
                    </div>
                    <div class="property" *ngIf="deliveryAddress | async as address">
                        <label>Address</label>
                        <span [innerHTML]="addressBuilderHTML(address)"></span>
                    </div>

                    <div class="property" *ngIf="deliveryAddress | async as address">
                        <label>Special Instructions</label>
                        <span [innerHTML]="pickupSpecialInstructionBuilder()"></span>
                        <span [innerHTML]="deliverySpecialInstructionBuilder()"></span>
                        <!-- <span>{{specialInstructions}}</span> -->
                    </div>
                </div>

                <div class="column" *ngIf="showCourt && !entity.delivery_ServiceAddress_uuid">
                    <div class="review-header">Court</div>
                    <div class="property" *ngIf="courtAddresses$ | async as addresses">
                        <label>Name</label>
                        <span>{{ addresses[0].name }}</span>
                    </div>
                    <div class="property" *ngIf="courtAddresses$ | async as addresses">
                        <label>Address</label>
                        <span [innerHTML]="addressBuilderHTML(addresses[0])"></span>
                    </div>
                
                    <div class="property" *ngIf="courtAddresses$ | async as addresses">
                        <label>Special Instructions</label>
                        <span [innerHTML]="pickupSpecialInstructionBuilder()"></span>
                        <span [innerHTML]="deliverySpecialInstructionBuilder()"></span>
                        <!-- <span>{{specialInstructions}}</span> -->
                    </div>
                </div>

                <div class="column" *ngIf="showServedTo">
                    <div *ngFor="let s of servees$ | async; let i = index">
                        <div class="review-header">Served To - Business/Individual #{{i+1}}</div>
                        <div class="property">
                            <label>Person to be Served</label>
                            <span>{{s.firstName}}  {{s.lastName}}</span>
                            <label>Phone</label>
                            <span>{{s.phone}}</span>
                        </div>

                        <div class="property" *ngIf="deliveryAddress$ | async as address">
                            <label>Address</label>
                            <span [innerHTML]="addressBuilderHTML(address[i])"></span>
                        </div>
                    </div>
                    <div class="property" *ngIf="deliveryAddress$ | async as address">
                        <label>Special Instructions</label>
                        <span [innerHTML]="pickupSpecialInstructionBuilder()"></span>
                        <span [innerHTML]="deliverySpecialInstructionBuilder()"></span>
                        <!-- <span>{{specialInstructions}}</span> -->
                    </div>
                </div>
            </div>
        </div>
    </resource-list-pane>

    <resource-list-pane title="Case"  [collapsable]='true' *ngIf="case | async as $case">
        <div class="orderProperties">
            <div class="row">
                <div class="column">

                    <div class="property">
                        <label>Name</label>
                        <span>{{ $case.name }}</span>
                    </div>
                    <div class="property">
                        <label>Number</label>
                        <span>{{ $case.caseNumber }}</span>
                    </div>

                    <button class="bottomPaneButton" [disabled]="!$case.id" mat-flat-button color="primary" [routerLink]="['/case', $case.id]">
                        <mat-icon>open_in_new</mat-icon>
                        View Case
                    </button>
                </div>
            </div>
        </div>
    </resource-list-pane>
</resource-list-grid>

<resource-list-grid *ngIf="orderService">
    <resource-list-pane title="Service Status"  [collapsable]='true' *ngIf="orderService | async as $serve">
        <div class="orderProperties">
            <div class="row">
                <div class="column">

                    <div class="property">
                        <label>Serve Type</label>
                        <span>{{ $serve.serviceType }}</span>
                    </div>
                    <div class="property">
                        <label>Service Description</label>
                        <span>{{ $serve.description }}</span>
                    </div>
                    <div class="property">
                        <label>Job Number</label>
                        <span>{{ $serve.jobNumber }}</span>
                    </div>
                    <div class="property">
                        <label>Service Status</label>
                        <span>{{ $serve.orderServiceStatus }}</span>
                    </div>
                    <div class="property">
                        <label>Latest Update</label>
                        <span>{{entity.updatedAtUTC | reflexTimeAgo }}</span>
                        <span class="smallDetail">{{entity.updatedAtUTC | date : 'medium' }}</span>
                    </div>
                </div>
            </div>
        </div>        
    </resource-list-pane>

    <resource-list-pane title="Attempts">
        <mat-table multiTemplateDataRows [dataSource]="orderServiceAttempts" matSort>
            <ng-container cdkColumnDef="attemptedAt">
                <mat-header-cell mat-sort-header *cdkHeaderCellDef>Attempted At</mat-header-cell>
                <mat-cell *cdkCellDef="let entity"><span>{{entity.attemptedAtUTC | date : 'medium' }}</span></mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="orderServiceAttemptStatus">
                <mat-header-cell mat-sort-header *cdkHeaderCellDef>Status</mat-header-cell>
                <mat-cell *cdkCellDef="let entity"><span>{{entity.orderServiceAttemptStatus}}</span></mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="subStatus">
                <mat-header-cell mat-sort-header *cdkHeaderCellDef>Sub Status</mat-header-cell>
                <mat-cell *cdkCellDef="let entity"><span>{{entity.subStatus}}</span></mat-cell>
            </ng-container>

            <mat-header-row *cdkHeaderRowDef="['attemptedAt', 'orderServiceAttemptStatus', 'subStatus']; sticky: true"></mat-header-row>
            <mat-row *cdkRowDef="let row; columns: ['attemptedAt', 'orderServiceAttemptStatus', 'subStatus']"
            class="entity-row">
            </mat-row>
        </mat-table>
        <br>
    </resource-list-pane>

    <!-- <resource-list-pane title="Affidavits"  [collapsable]='true'>
        <mat-table multiTemplateDataRows [dataSource]="orderServiceAffidavits" matSort>
            <ng-container cdkColumnDef="title">
                <mat-header-cell mat-sort-header *cdkHeaderCellDef>Title</mat-header-cell>
                <mat-cell *cdkCellDef="let entity"><span>{{entity.title}}</span></mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="linkTo">
                <mat-header-cell mat-sort-header *cdkHeaderCellDef>Link To</mat-header-cell>
                <mat-cell *cdkCellDef="let entity">
                    <a mat-icon-button [href]=entity.linkTo target="_blank">
                        <mat-icon>open_in_new</mat-icon>
                    </a>
                </mat-cell>
            </ng-container>

            <mat-header-row *cdkHeaderRowDef="['title', 'linkTo']; sticky: true"></mat-header-row>
            <mat-row *cdkRowDef="let row; columns: ['title', 'linkTo']"
            class="entity-row">
            </mat-row>
        </mat-table>
    </resource-list-pane> -->
</resource-list-grid>


<resource-list-grid *ngIf="entity && entity.id">
    <resource-list-pane title="Documents" >
        <div class="orderProperties">
            <div class="row">
                <div class="column">
                    <div class="property">
                        <label>Description of Documents</label>
                        <span>{{ entity.documentsDescription }}</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <lib-order-documents 
                        [order]="entity" 
                        [showUploadControls]="false"
                        [cc]="false"
                        [documents]="(orderDocuments | async) || []"
                        [affidavits]="(orderServiceAffidavits | async) || []"
                        [allowBatchDownloading]="true">
                    </lib-order-documents>
                </div>
            </div>
        </div>
    </resource-list-pane>

    <resource-list-pane title="Timeline" [collapsable]='true'>
        <div class="orderProperties">
            <div class="row">
                <div class="column">
                    <div class="property">
                        <label>Date Created</label>
                        <span>{{entity.createdAtUTC | reflexTimeAgo }}</span>
                        <span class="smallDetail">{{entity.createdAtUTC | date : 'medium' }}</span>
                    </div>
                    <div class="property">
                        <label>Last Updated</label>
                        <span>{{entity.updatedAtUTC | reflexTimeAgo }}</span>
                        <span class="smallDetail">{{entity.updatedAtUTC | date : 'medium' }}</span>
                    </div>
                </div>
            </div>
        </div>
        
    </resource-list-pane>
</resource-list-grid>

<resource-list-grid *ngIf="entity && entity.id">
    <resource-list-pane title="Notes" *ngIf="this.orderNotes.length==0">
        <p class='center'>No Order Note Found</p>
    </resource-list-pane>

    <resource-list-pane title="Notes" *ngIf="this.orderNotes.length!=0">
        <mat-table [dataSource]="sortedOrderNotes" matSort>
            <ng-container cdkColumnDef="date">
                <mat-header-cell style="flex: 0 1 100px;" mat-sort-header *cdkHeaderCellDef disabled>Entry date</mat-header-cell>
                <mat-cell style="flex: 0 1 100px;" *cdkCellDef="let entity"><span>{{entity.date}}</span></mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="time">
                <mat-header-cell style="flex: 0 1 100px;" mat-sort-header *cdkHeaderCellDef disabled>Entry time</mat-header-cell>
                <mat-cell style="flex: 0 1 100px;" *cdkCellDef="let entity"><span>{{entity.time}}</span></mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="note">
                <mat-header-cell mat-sort-header *cdkHeaderCellDef disabled>Note</mat-header-cell>
                <mat-cell *cdkCellDef="let entity"><span>{{entity.note}}</span></mat-cell>
            </ng-container>

            <mat-header-row *cdkHeaderRowDef="['date', 'time', 'note']; sticky: true" matSortDisabled></mat-header-row>
            <mat-row *cdkRowDef="let row; columns: ['date', 'time', 'note']" class="entity-row"></mat-row>
        </mat-table>
    </resource-list-pane>
</resource-list-grid>

<mat-expansion-panel *ngIf="entity && entity.id">
    <mat-expansion-panel-header>
        <mat-panel-title>Technical Support Details</mat-panel-title>
    </mat-expansion-panel-header>

    <div class="orderProperties">
        <div class="row">
            <div class="column">
                <div class="review-header">Identities</div>            

                <div class="property">
                    <label>Company Number</label>
                    <span class="mono">{{ entity.companyNumber }}</span>
                </div>
                <div class="property">
                    <label>Corp ID</label>
                    <span class="mono">{{ entity.corpId }}</span>
                </div>
                <div class="property">
                    <label>Order UUID</label>
                    <span class="mono">{{ entity.id }}</span>
                </div>
                <div class="property">
                    <label>Filing Service Type</label>
                    <span class="mono">{{ entity.filingServiceType_uuid }}</span>
                </div>
                <div class="property">
                    <label>Account User</label>
                    <span class="mono">{{ entity.successAccount_uuid }}</span>
                </div>
                <div class="property">
                    <label>Pickup Service Address</label>
                    <span class="mono">{{ entity.pickup_ServiceAddress_uuid }}</span>
                </div>
                <div class="property">
                    <label>Delivery Service Address</label>
                    <span class="mono">{{ entity.delivery_ServiceAddress_uuid }}</span>
                </div>
                <div class="property">
                    <label>Case</label>
                    <span class="mono">{{ entity.case_uuid }}</span>
                </div>
                <div class="property">
                    <label>Vendor</label>
                    <span class="mono">{{ entity.vendor_uuid }}</span>
                </div>
            </div>
            <div class="column">
                <div class="review-header">Errors</div>

                <div class="property">
                    <label>Message</label>
                    <span class="mono">{{ entity.errorMessage || 'None' }}</span>
                </div>
            </div>
        </div>
    </div>
</mat-expansion-panel>