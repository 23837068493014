import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ImpersonateService } from '../../../services/impersonate.service';
import { filter, switchMap, take, tap } from 'rxjs/operators';

@Component({
  selector: 'impersonate-frame',
  templateUrl: './frame.component.html',
  styleUrls: ['./frame.component.scss']
})
export class FrameComponent implements OnInit {

  public loading: boolean = true;
  public authenticated: boolean = false;
 
  public subject: string = '';
  public log: string[] = [];

  constructor(
    private route: ActivatedRoute, 
    private router: Router,
    private impersonateService: ImpersonateService) { }

  ngOnInit(): void {
    this.impersonateService.log.subscribe(message => {
      this.log.push(message);
    });
    this.route.queryParams.pipe(
      tap(params => console.debug('params', params)),
      filter(params => 'subject' in params && 'token' in params && 'client_id' in params), 
      tap(_ => console.debug('got the right params')),
      take(1),
      tap(params => {
        this.subject = params.subject;
        console.debug('setting subject', this.subject);
      }),
      switchMap(params => this.impersonateService.doImpersonate(params['client_id'], params['subject'], params['token']))
    )
    .subscribe({
      next: authenticated => {
        this.loading = false;

        this.authenticated = authenticated.isAuthenticated;
        if (authenticated) {
          // go to home/dashboard?
          this.router.navigate(['/']);
        }

      },
      error: e => {
        this.loading = false;
        console.error(e);
        this.log.push(`Something went wrong when trying to impersonate ${this.subject}`);
        if (e.message) {
          this.log.push(e.message);
        }
        if (e.error?.message) {
          this.log.push(e.error.message);
        }
        if (e.error?.error_description) {
          this.log.push(e.error.error_description);
        }
        
      }
    });
  }

}
