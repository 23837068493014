import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { ContactType } from '../models/contactType.model';

@Injectable()
export class ContactType_Service extends ResourceService<ContactType> {
    protected servicePath: string = '/ContactType';

    public ModelType = ContactType;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }
}
