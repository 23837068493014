<div *ngIf="WIP">
	<resource-list-grid>
		<resource-list-pane *ngIf="!noDocuments">
			<div class="loading" *ngIf="isLoading">
				<div class="loading-text">Loading Documents</div>
				<mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
			</div>
	
			<div [hidden]="isLoading">
				<mat-paginator
					(page)="pageChange()"
					[pageSizeOptions]="dataSource.pageSizeOptions"
					[pageIndex]="dataSource.pageIndex"
					[pageSize]="dataSource.pageSize"
					showFirstLastButtons>
				</mat-paginator>
				<mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
		
					<ng-container cdkColumnDef="select">
						<mat-header-cell *cdkHeaderCellDef>
							<mat-checkbox
								color="primary"
								(change)="selectAll()"
								[checked]="selection.hasValue() && allSelected()"
								(click)="selectionChange()">
							</mat-checkbox>
						</mat-header-cell> 
						<mat-cell *cdkCellDef="let entity">
							<mat-checkbox
								color="primary"
								(change)="selection.toggle(entity)"
								[checked]="selection.isSelected(entity)"
								(click)="selectionChange($event)">
							</mat-checkbox>
						</mat-cell>
					</ng-container>
		
					<ng-container matColumnDef="download">
						<mat-header-cell *cdkHeaderCellDef></mat-header-cell>
						<mat-cell *matCellDef="let entity">
							<button mat-icon-button [routerLink]="'/document/'+entity.id">
								<mat-icon>list_alt</mat-icon>
							</button>
							<button mat-icon-button *ngIf='!downloadURLs.has(entity.id)' (click)="getDocumentUrl([entity])">
								<mat-icon>download</mat-icon>
							</button>
							<a mat-icon-button *ngIf='downloadURLs.has(entity.id)' [href]="downloadURLs.get(entity.id)" 
							target="_blank">
								<mat-icon>open_in_new</mat-icon>
							</a>
						</mat-cell>
					</ng-container>
					
					<ng-container matColumnDef="name">
						<mat-header-cell mat-sort-header *cdkHeaderCellDef> Name  </mat-header-cell>
						<mat-cell *matCellDef="let entity"> <span>{{entity.name}}</span> </mat-cell>
					</ng-container>
			
					<ng-container matColumnDef="docStatus">
						<mat-header-cell mat-sort-header *cdkHeaderCellDef> Status </mat-header-cell>
						<mat-cell *matCellDef="let entity"> <span>{{entity.docStatus}}</span> </mat-cell>
					</ng-container>
			
					<ng-container matColumnDef="fileType">
						<mat-header-cell mat-sort-header *cdkHeaderCellDef> File Type </mat-header-cell>
						<mat-cell *matCellDef="let entity"> <span>{{entity.fileType}}</span> </mat-cell>
					</ng-container>
			
					<ng-container matColumnDef="fileName">
						<mat-header-cell mat-sort-header *cdkHeaderCellDef> Filename </mat-header-cell>
						<mat-cell *matCellDef="let entity"> <span>{{entity.fileName}}</span> </mat-cell>
					</ng-container>
			
					<ng-container matColumnDef="documentType">
						<mat-header-cell mat-sort-header *cdkHeaderCellDef> Document Type </mat-header-cell>
						<mat-cell *matCellDef="let entity"> <span>{{entity.documentType}}</span> </mat-cell>
					</ng-container>
			
					<mat-header-row *cdkHeaderRowDef="columnsToDisplay; sticky: true"></mat-header-row>
					<mat-row *cdkRowDef="let row; columns: columnsToDisplay;"></mat-row>
				</mat-table>
				<div *ngIf="isLoading" style="justify-content: center;display: flex;margin: 10px 0;" color="primary">
					<mat-spinner ></mat-spinner>
				</div>
		
				<mat-paginator
					(page)="pageChange()"
					[pageSizeOptions]="dataSource.pageSizeOptions"
					[pageIndex]="dataSource.pageIndex"
					[pageSize]="dataSource.pageSize"
					showFirstLastButtons>
				</mat-paginator>
			</div>
	
		</resource-list-pane>
	
		<resource-list-pane title="Filters" id="filterPaneDocument" #filter="resourceListPane" [isCollapsed]='filterCollapsed' *ngIf="filterFormGroup" [collapsable]='true' >
			<form class="filters" (ngSubmit)="getDocuments()" [formGroup]="filterFormGroup">
					<mat-form-field>
						<input matInput
							formControlName="orderId"
							placeholder="Order ID"/>
					</mat-form-field>
					<mat-form-field>
						<input matInput
							formControlName="name"
							placeholder="Name"/>
					</mat-form-field>
					<mat-form-field>
						<input matInput
							formControlName="status"
							placeholder="Status"/>
					</mat-form-field>
					<mat-form-field>
						<input matInput
							formControlName="fileType"
							placeholder="File Type"/>
					</mat-form-field>
					<mat-form-field>
						<input matInput
							formControlName="fileName"
							placeholder="Filename"/>
					</mat-form-field>
					<mat-form-field>
						<input matInput
							formControlName="documentType"
							placeholder="Document Type"/>
					</mat-form-field>
	
				<div class="filterActions">
					<button mat-flat-button type="button" (click)="getDocuments(true)">
						Clear
					</button>
					<button mat-raised-button color="primary" type="submit">
						Search
					</button>
				</div>
			</form>
		</resource-list-pane>
	
	</resource-list-grid>
	
</div>
