import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { PartnerVendor, Note } from '../models';
import { map } from 'rxjs/operators';

@Injectable()
export class PartnerVendor_Service extends ResourceService<PartnerVendor> {
    protected servicePath: string = '/PartnerVendor';

    public ModelType = PartnerVendor;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }

    public getNotes(uuid: string) {
        return this
            .http
            .post(
                this.serviceUrl + 'notes',
                {
                    uuid
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) =>
                        response.body.map((e: any) => new Note(e))
                )
            );
    }
}
