import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CanLeaveDirective } from './canLeaveDialog/canLeave.dialog';
import { CanLeaveService } from '../services/canLeave.service';
import { DialogComponent } from './dialog/dialog';
import { LegalReflexToolbar } from './toolbar/toolbar';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { NotificationPreferencesComponent } from './notification-preferences/notification-preferences.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input'; 
import { MatSelectModule } from '@angular/material/select';
import { SpecialInstructionPreferencesComponent } from './special-instruction-preferences/special-instruction-preferences.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PagedResourceListComponent } from './paged-resource-list/paged-resource-list.component';
import { OrderDocumentsComponent } from './order-documents/documents.component';
import { MatTableModule } from '@angular/material/table';
import { CdkTableModule } from '@angular/cdk/table';
import { LibEntityActivityComponent } from './lib-entity-activity/lib-entity-activity.component';
import { LookupComponent } from './lookup/lookup.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { InvalidDocumentDialog } from './order-documents/invalidDocument';
import { MatSortModule } from '@angular/material/sort';
import { DocumentNameChangedDialog } from './order-documents/documentNameChanged';
import { FileSizeDialog } from './order-documents/fileSize';
@NgModule({
    imports: [
        MatDialogModule,
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatCardModule,
        MatSnackBarModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatSelectModule,
        MatCheckboxModule,
        MatListModule,
        MatInputModule,
        MatTableModule,
        CdkTableModule,
        MatProgressBarModule,
        MatRadioModule,
        BrowserModule,
        BrowserAnimationsModule,
        MatTooltipModule,
        FormsModule,
        MatSortModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
    ],
    declarations: [
        CanLeaveDirective,
        DialogComponent,
        LegalReflexToolbar,
        UnauthorizedComponent,
        NotificationPreferencesComponent,
        SpecialInstructionPreferencesComponent,
        ChangePasswordComponent,
        ForgotPasswordComponent,
        PagedResourceListComponent,
        OrderDocumentsComponent,
        LibEntityActivityComponent,
        LookupComponent,
        InvalidDocumentDialog,
        DocumentNameChangedDialog,
        FileSizeDialog
    ],
    providers: [
        CanLeaveService
    ],
    exports: [
        MatDialogModule,
        CanLeaveDirective,
        DialogComponent,
        LegalReflexToolbar,
        NotificationPreferencesComponent,
        SpecialInstructionPreferencesComponent,
        ChangePasswordComponent,
        ForgotPasswordComponent,
        OrderDocumentsComponent,
        LibEntityActivityComponent,
        LookupComponent,
    ]
})
export class LegalReflexComponentsModule { }
