

export type JSONValue = null | string | number | boolean | JSONValue[] | { [key: string]: JSONValue }
export type JSONObject = { [key: string]: JSONValue };

/**
 * Renders variables provided in `context` to the provided `template`. 
 * Variables can be accessed in the template using a JSON path as dot notation (i.e. user.name) or by using
 * index notation (i.e. array[0])
 * @param template 
 * @param context 
 * @returns 
 */
export const render = (template: string, context: JSONObject): string => {
    
    // flatten all the paths in context
    let scope = flatten('', {}, context);
    let result: string = template;

    // this regex got gnarly quickly.
    // the template variables can now be surrounded with a single curly { } brackets, double curly {{ }} brackets
    // can't do the variables surrounded with < > (can do it with html escaped entity &lt; &gt;), since template is sometimes html. 
    // if there's a variable name in the context and is the same as a tagname or a tag without attributes, the tag will be replaced 
    const reg = (expr: string) => new RegExp(`(\\{|\\{\\{|&lt;)\\s*${expr}\\s*(\\}\\}|\\}|&gt;)`, "g");

    for (let path in scope) {
        // escape the indexed accessors
        let expr = path.replace(/\[/g, "\\[").replace(/\]/g, "\\]");

        let pattern = reg(expr);
        let value = `${scope[path]}`;
        result = result.replace(pattern, value);
    }

    // replace vars with empty string when they are not in context/scope
    let pattern = reg('[A-Za-z0-9_\\.\\[\\]]*');
    result = result.replace(pattern, '');

    return result;
}

/**
 * flattens subject to a JSONObject, where the keys are all the paths in `subject`
 * 
 * example
 *      flatten("", {}, { a: [1, 2, 3], hello: 'world', user: { name: 'tom' } })
 *      -> { "a[0]": 1, "a[1]": 2, "a[2]": 3, "hello": "world", "user.name": 'tom', "user[name]": 'tom' }
 * @param path 
 * @param acc 
 * @param subject 
 * @returns 
 */
const flatten = (path: string, acc: JSONObject, subject: JSONValue): JSONObject => {
    if (!subject || typeof subject == 'string' || typeof subject === 'number' || typeof subject === 'boolean') {
        acc[path] = subject;
    } 
    else if (Array.isArray(subject)) {
        for (let i = 0; i < subject.length; i++) {
            acc = flatten(`${path}[${i}]`, acc, subject[i]);
        }
    }
    else {
        for (let key in subject) {
            // dot accessor
            acc = flatten(path ? `${path}.${key}` : key, acc, subject[key]);
            // index accessor
            acc = flatten(path ? `${path}[${key}]` : key, acc, subject[key]);
        }
    }
    return acc;
}


