


import { Injectable } from "@angular/core";
import { ResourceService } from "@smartsoftware/reflex-core";
import { OrderNote } from "../models/orderNote.model";
import { HttpClient } from '@angular/common/http'


@Injectable()
export class OrderNote_service extends ResourceService<OrderNote>{
    protected servicePath: string = '/OrderNote';
    public ModelType = OrderNote;

    constructor( protected http: HttpClient ){
        super(http);
    }
}