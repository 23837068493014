export * from './lib.module';
export * from './components/index';
export * from './models/index';
export * from './services/index';
export * from './directives/index';
export * from './routes/index';
export * from './definitions/index';
export * from './abstracts/index';
export * from './modules/index';
export * from './datasources';
export * from './paged.datasource';
export * as validators from './validators';

export const FC3_DATETIME_FORMAT = 'MM/dd/YYYY hh:mm a';// ZZZZ';
export const FC3_DATE_FORMAT  = 'MM/dd/YYYY';