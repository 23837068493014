import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ResourceListComponent, ResourceListDataSource, ResourceListPane } from '@smartsoftware/reflex-core';
import { MatSort } from '@angular/material/sort';

import { SuccessAccount, SuccessAccountFirm_Service, SuccessAccount_Service, SystemConfig_service, UserPermission_Service } from 'legalreflex-lib';
import { BreadCrumbService } from '../../../components/breadcrumb-bar/breadcrumb-service';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
    selector: 'success-account-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class SuccessAccountList extends ResourceListComponent<SuccessAccount, SuccessAccountFirm_Service> {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('filter') filterPane: ResourceListPane = new ResourceListPane();
    public filterCollapsed: boolean = sessionStorage.getItem("filterCollapsed") == 'true';

    public columns: string[] = [
       'View', 'username', 'email', 'firstName', 'lastName', 
    ];

    public entityProps: Array<any> = [];

    constructor(
        protected userPermissionService: UserPermission_Service,
        protected entityService: SuccessAccountFirm_Service,
        protected route: ActivatedRoute,
        protected dialog: MatDialog,
        private breadCrumbService: BreadCrumbService,
        public sysConfigService: SystemConfig_service,
        protected auth: OidcSecurityService,
    ) {
        super(entityService, route, dialog);
        this.sort = new MatSort()

        this.breadCrumbService.breadcrumbs.next([
            { label: 'Users', url: '/users'}
        ]);
        this.breadCrumbService.actions.next([
             { label: 'Refresh', action:this.relistEntries.bind(this, true), icon:'refresh'},
        ]);
    }

    sortData(event: any){
        this.entityListSource.entities = this.entityListSource.sortData(this.entityListSource.entities,this.sort)
    }

    anySelected(){
        this.entityListSource.entities = this.entityListSource._filterData(this.entityListSource.entities)
        return this.selection.selected.length == 0
    }

    relistEntries(reset?:boolean){
        if(reset){
            this.entityListSource.filters.map((filter)=>{
                filter.filterValue = ''
            })
        }
        this.isLoading = true
        this.resourceService
        .list()
        .subscribe(
            entities => {
                if(entities[0]){
                    let temp = Object.getOwnPropertyNames(this.entityListSource.entities[0])
                    this.entityProps = []
                    for(let i = 0; i < temp.length; i++){
                        this.entityProps.push(temp[i])
                    }
                    this.entityListSource.entities = entities
                    this.entityListSource.entities = this.entityListSource._filterData(this.entityListSource.entities)
                }
                this.isLoading = false;
                let filterClick = document.getElementById('filterPaneUser');
                if(filterClick && !filterClick.hasAttribute('filterClickAttached')){
                    filterClick.setAttribute('filterClickAttached','true')
                    filterClick.addEventListener("click",()=>{
                        sessionStorage.setItem("filterCollapsed", this.filterPane.isCollapsed ? "true" : "false");
                    })
                }
        
            },
            (error) => {
                let filterClick = document.getElementById('filterPaneUser');
                if(filterClick && !filterClick.hasAttribute('filterClickAttached')){
                    filterClick.setAttribute('filterClickAttached','true')
                    filterClick.addEventListener("click",()=>{
                        sessionStorage.setItem("filterCollapsed", this.filterPane.isCollapsed ? "true" : "false");
                    })
                }
        
                console.log(error);
            }
        );
    }


    ngOnInit(): void {
        super.ngOnInit();
        if(this.sysConfigService.maintenanceRedirectCheck()){  
            window.localStorage.removeItem("loggedInUserPermissionNodes");
            this.auth.logoff();
        };

        this.relistEntries();
    }

}