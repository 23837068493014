import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SuccessAccount_Service } from '../../services';
import { matchesOtherControl, PASSWORD_RULES, PASSWORD_MIN_LENGTH, PASSWORD_MAX_LENGTH, PASSWORD_HINT } from '../change-password/change-password.component';
import { ErrorStateMatcher } from '@angular/material/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
       return !!(control && control.invalid && (control.dirty || control.touched));
   }
}

@Component({
  selector: 'lib-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  public resetCode: string | undefined;

  public requestForm: FormGroup;
  public resetForm: FormGroup;

  public message: {level: 'error' | 'info', message: string } | undefined;
  public errorStateMatcher = new MyErrorStateMatcher();

  PASSWORD_MIN_LENGTH = PASSWORD_MIN_LENGTH;
  PASSWORD_MAX_LENGTH = PASSWORD_MAX_LENGTH;
  PASSWORD_HINT = PASSWORD_HINT;

  public loading: boolean = false;

  get isRedeemingResetCode() {
    return !!this.resetCode;
  }
  get password() {
    return this.resetForm.get('password');
  }
  get confirmedPassword() {
    return this.resetForm.get('confirmedPassword');
  }

  constructor(
    private successAccountService: SuccessAccount_Service,
    private route: ActivatedRoute,
    private oidcService: OidcSecurityService,
    private fb: FormBuilder) {
    this.requestForm = fb.group({
      email: ['', [Validators.required, Validators.email]]
    }, { updateOn: 'change' });
    this.resetForm = fb.group({
      password: ['', PASSWORD_RULES],
      confirmedPassword: ['']
    }, { updateOn: 'change' });
    let passwordControl = this.resetForm.get('password');
    let confirmedPasswordControl = this.resetForm.get('confirmedPassword');
    confirmedPasswordControl?.setValidators([matchesOtherControl(passwordControl)]);
    passwordControl?.valueChanges.subscribe(() => {
      confirmedPasswordControl?.updateValueAndValidity({onlySelf: true, emitEvent: false});
    })
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if ('code' in params) {
        this.resetCode = params['code'];
      }
    });
  }
  submitRequestForm() {
    this.message = undefined;
    if (this.requestForm.invalid)
      return;
    this.loading = true;
    let email = this.requestForm.get('email')?.value;
    this.successAccountService.sendPasswordResetEmail(email).subscribe({
      next: () => {
        this.message = {level:'info', message:'Password reset email sent'};
        this.loading = false;
       },
      error: err => {
        this.message = {level:'error', message: err.error?.internalError || 'Something went wrong when attempting to send the password reset email'};
        this.loading = false;
      }
    })
  }
  submitResetForm() {
    this.message = undefined;
    if (this.resetForm.invalid || !this.resetCode)
      return;
    this.loading = true;
    let password = this.resetForm.get('password')?.value;
    this.successAccountService.resetPasswordFromCode(this.resetCode, password).subscribe({
      next: () => { 
        this.message = {level:'info', message:'Password was reset'};
        this.loading = false;
      },
      error: err => {
        this.message = {level:'error', message: err.error?.internalError || 'Something went wrong when attempting to reset your password'};
        this.loading = false;
      }
    })
  }

  gotoLogin() {
    this.oidcService.authorize();
  }
}
