import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { OrderServiceAttempt } from '../models/orderServiceAttempt.model';

@Injectable()
export class OrderServiceAttempt_Service extends ResourceService<OrderServiceAttempt> {
    protected servicePath: string = '/orderService';

    public ModelType = OrderServiceAttempt;
    
    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }
}
