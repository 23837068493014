<div [formGroup]="form" class="d-flex v-align">

<mat-form-field *ngIf="!isPasswordChange">
    <mat-label>Password</mat-label>
    <input matInput [type]="passwordVisibility" formControlName="password"/>
    <mat-icon class="clickable" matSuffix (click)="togglePasswordVisibility()">{{visibilityIcon}}</mat-icon>
    <mat-hint>
        {{PASSWORD_HINT}}
    </mat-hint>
    <mat-error *ngIf="password?.errors?.required">
        The password is required
    </mat-error>
    <mat-error *ngIf="password?.errors?.pattern">
        {{PASSWORD_HINT}}
    </mat-error>
</mat-form-field>

<mat-form-field *ngIf="isPasswordChange && kind == 'owner'">
    <mat-label>Current Password</mat-label>
    <input [type]="passwordVisibility" placeholder="Current Password" matInput formControlName="currentPassword" required />
    <mat-icon class="clickable" matSuffix (click)="togglePasswordVisibility()">{{visibilityIcon}}</mat-icon>
    <mat-error *ngIf="currentPassword?.errors?.required">
        Required
    </mat-error>
</mat-form-field>

<mat-form-field *ngIf="isPasswordChange">
    <mat-label>New Password</mat-label>
    <input [type]="passwordVisibility" placeholder="New Password" matInput formControlName="password" required />
    <mat-icon class="clickable" matSuffix (click)="togglePasswordVisibility()">{{visibilityIcon}}</mat-icon>
    <mat-hint>
        {{PASSWORD_HINT}}
    </mat-hint>
    <mat-error *ngIf="password?.errors?.required">
        The new password is required
    </mat-error>
    <mat-error *ngIf="password?.errors?.pattern">
        {{PASSWORD_HINT}}
    </mat-error>
</mat-form-field>
<mat-form-field *ngIf="isPasswordChange && kind == 'owner'">
    <mat-label>Confirm New Password</mat-label>
    <input [type]="passwordVisibility" placeholder="Confirm New Password" matInput formControlName="confirmedPassword" />
    <mat-icon class="clickable" matSuffix (click)="togglePasswordVisibility()">{{visibilityIcon}}</mat-icon>
    <mat-error *ngIf="confirmedPassword?.errors?.matchesOther">
        Password does not match the new password
    </mat-error>
</mat-form-field>

<button mat-flat-button color="primary" *ngIf="isPasswordChange" (click)="doChangePassword($event)" [disabled]="form.invalid">Change Password</button>
<button mat-stroked-button (click)="suggestPassword($event)">Suggest Password</button>

</div>
