import {Component, Inject } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'document-name-changed-dialog',
  templateUrl: 'documentNameChanged.html',
})
export class DocumentNameChangedDialog {
    public changedFiles: string = '';
    public newFiles: string = '';
    constructor(@Inject(MAT_DIALOG_DATA) public data: {changedFileNames: string[], newFileNames: string[]}) {
        this.changedFiles = data.changedFileNames.map(fn => `"${fn}"`).join(',');
        this.newFiles = data.newFileNames.map(fn => `"${fn}"`).join(',');
    }
}
