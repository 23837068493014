import {Component, Inject } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';

// export interface OrderData {
//   eFileControlNo: number,
//   eServiceControlNo: number
// }

@Component({
  selector: 'invalid-document-dialog',
  templateUrl: 'invalidDocument.html',
})
export class InvalidDocumentDialog {
    constructor(@Inject(MAT_DIALOG_DATA) public data: {types: string, invalidType: string}) {}
}
