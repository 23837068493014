import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
@Component({
    templateUrl: 'orderSubmitFailed.html',
    styleUrls: ['./orderSubmitFailed.scss']
})
export class OrderSubmitFailedDialog {
    constructor (
        private dialogRef : MatDialogRef<OrderSubmitFailedDialog>,

        @Inject(MAT_DIALOG_DATA)
        public data: OrderErrorData
    ) {

    }

    close() {
        this.dialogRef.close(false);
    }

    saveAsDraft() {
        this.dialogRef.close('draft');
    }

    retryUpload() {
        this.dialogRef.close('retry');
    }
}

interface OrderErrorData {
    failedDocuments: []
    message?: string 
}