import { Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class OrderCache extends DexResourceModel {

    @Field(String)
	public email: string = "";
    @Field(String)
    public corpId: string = "";
    @Field(String)
    public externalId: string = "";
    @Field(String)
    public externalSource: string = "";
    @Field(Boolean)
    public hasCompositeExternalId: boolean = false;
    @Field(String)
    public division: string = "";
    @Field(String)
    public requestedBy: string | null = null;
    @Field(String)
    public orderNumber: string | null = null;
    @Field(Date)
    public orderDate: Date | null = null;
    @Field(String)
    public reference: string | null = null;
    @Field(String)
    public caseNumber: string | null = null;
    @Field(String)
    public orderStatus: string | null = null;
    @Field(String)
    public serviceType: string | null = null;
    @Field(Date)
    public jobDate: Date | null = null;
    @Field(Date)
    public dueDate: Date | null = null;
    @Field(String)
    public subject: string | null = null;
    @Field(String)
    public deliveryLocation: string | null = null;
    @Field(String)
    public deliveryAddress: string | null = null;
    @Field(String)
    public caseName: string | null = null;
    @Field(String)
    public claimNumber: string | null = null;
    @Field(Date)
	public createdAt: Date = new Date();
	@Field(Date)
	public updatedAt: Date = new Date();
    @Field(Date)
	public deletedAt: Date | null = null;
    
    constructor(data?: any) {
        super();
        this.mapData(data);
    }

    public get data(): any {
        let json = super.data;

        return json;
    }

}
