import { Injectable } from "@angular/core";
import { ResourceService } from "@smartsoftware/reflex-core";
import { SuccessCounty } from "../models/county.model";
import { HttpClient } from '@angular/common/http'


@Injectable()
export class SuccessCounty_Service extends ResourceService<SuccessCounty>{
    protected servicePath: string = '/County';
    public ModelType = SuccessCounty;

    constructor( protected http: HttpClient ){
        super(http);
    }
}