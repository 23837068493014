import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class ProjectStatus extends DexResourceModel {

	@Field(String)
	public name: string = "";

	@Field(String)
	public description: string = "";

	@Field(String)
	public color: string = "";		

	constructor(data?: any) {
		super();
		this.mapData(data);
	}
}
