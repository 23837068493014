import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { ProjectType } from '../models';
import { map } from 'rxjs/operators';
import { Submission } from '../models/submission.model';

@Injectable()
export class Submission_Service extends ResourceService<Submission> {
    protected servicePath: string = '/Submission';

    public ModelType = Submission;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }

    public getSubmissionByOrderId(orderId: string) {
        return this.http.get<Submission[]>(`${this.serviceUrl}Submission/${orderId}`)
            .pipe(map(submissions => submissions.map(s => new Submission(s))));
    }
}
