import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs';
import {
    File_Service,
    FileAction_Service,
} from 'legalreflex-lib';

import { UploaderType } from '../uploader';
import { MatSort } from '@angular/material/sort';

@Component({
    selector: 'uploader-table',
    templateUrl: './uploader-table.html',
    styleUrls: ['./uploader-table.scss']
})
export class UploaderTableComponent implements OnInit {
    @Input('entityType')
    public entityType: UploaderType = 'order';
    @Input('folder')
    public folder: string = "";

    @ViewChild(MatPaginator) paginator: MatPaginator | null = null;

    @ViewChild('uploadSort') uploadSort?: MatSort;

    public files: File[] = [];
    public uploadedFiles$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    public isLoading: boolean = false;
    public showLink: boolean = false;
    public columnDefs: string[] = ['filename', 'filesize', 'createdAt', 'Remove'];

    public dataSource: MatTableDataSource<any[]>;

    public linkFormControl: FormControl = new FormControl({ value: null });
    public deleteFile: boolean = false;

    public get fetchFiles(): BehaviorSubject<boolean> {
        return this.fileService.fetchFiles$;
    }

    constructor(
        protected fileService: File_Service,
        protected fileActionService: FileAction_Service,
    ) {
        this.dataSource = new MatTableDataSource([] as any[]);
    }

    ngOnInit() {
        this
            .fileService
            .fetchFiles$
            .subscribe(fetch => {
                if (fetch) {
                    this.fetch_Files();
                }
            });
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.uploadedFiles$
            .subscribe(files => this.dataSource.data = files);
        if (this.uploadSort) {
            this.dataSource.sort = this.uploadSort;
        }
    }

    public fetch_Files() {
        this.isLoading = true;
    }

    public view_file(file: any) {
        this.fileActionService
            .requestOrderDownloadUrl(file.filename, this.folder)
            .subscribe(
                (response: any) => {
                    if (response.body && 'success' in response.body && response.body.success) {
                        let url = response.body.data['url'];
                        window.open(url);
                    }
                }
            );
    }


    public remove_File(file: File) {

    }

    public update_File(file: File) {

    }
}
