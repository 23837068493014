import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes, UrlSegment, provideRoutes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AestheticsModule, NavigationModule, ResourceModule } from '@smartsoftware/reflex-core';
import { HttpClient } from '@angular/common/http';
import { RedirectService } from 'angular-auth-oidc-client/lib/utils/redirect/redirect.service';
import { OrderRedirect } from './redirect';
import { PageNotFound } from './pageNotFound';
import { ComponentsModule } from '../../components/components.module';
const routes: Routes = [
	{
		path: 'first-connect/dashboard',
		redirectTo: '',
	},
	{
		path: 'first-connect/order-history-search',
		redirectTo: 'order',
	},
    {
		path: 'first-connect/service-type',
		redirectTo: 'order/new',
	},
	{
		path: 'first-connect/legal-connect',
		redirectTo: 'order/direct-efile',
	},
	{
		path: 'first-connect/my-account',
		redirectTo: 'users',
	},
	{
		path: 'first-connect/address-book',
		redirectTo: 'address-book',
	},
	{
		path: 'first-connect/invoices',
		redirectTo: 'invoice',
	},
	{
        matcher: (url) => {
			if(url.length == 3){
				if (url.length == 3 && url[1].path == 'order-detail' && url[2].path.match(/^\d{1,9}$/)) {
					return {consumed: url, posParams: {id: new UrlSegment(url[2].path, {})}};
				} else if  (url.length == 3 && url[1].path == 'continue-order' && url[2].path.match(/^\d{1,9}$/)) {
					return {consumed: url, posParams: {id: new UrlSegment(url[2].path, {})}};
				} 
			}
            return null;
		},
		component: OrderRedirect,
	},

];

@NgModule({
	declarations: [
		OrderRedirect,
		PageNotFound
	],
	imports: [
		CommonModule,
		ResourceModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
        AestheticsModule,
        ComponentsModule,
		RouterModule.forChild(routes)
	],
	exports: [

	],
	bootstrap: [

	],
    providers: [
		
    ]
})
export class LegacyModule { }
