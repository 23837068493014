import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { ProjectBillingRate_Template } from '../models/projectBillingRate_template.model';

@Injectable()
export class ProjectBillingRate_Template_Service extends ResourceService<ProjectBillingRate_Template> {
  protected servicePath: string = '/ProjectBillingRateTemplate';

  public ModelType = ProjectBillingRate_Template;

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }
}
