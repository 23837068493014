import { Component, Inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
	templateUrl: './frame.html',
	styleUrls: ['./frame.scss']
})
export class AuthFrame {

	constructor(
		public pageTitleService : Title
	) {
		this.pageTitleService.setTitle("FirstConnect - Authentication");
	}

}
