
import { HttpClient, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core';
import { ResourceService } from '@smartsoftware/reflex-core';
import { Role } from '../models/role.model';
import { PermissionNode } from '../models/permissionNode.model';
import { BehaviorSubject, Observable } from "rxjs";
import { map, tap } from 'rxjs/operators';
//import { error } from 'console';
import { RolePermissionNode } from '../models/rolePermissionNode.model';

@Injectable()
export class RolePermissionNode_Service extends ResourceService<RolePermissionNode> {

    protected servicePath = '/RolePermissionNode';

    // private _rolePermissionNodes: RolePermissionNode[] = [];
    // get rolePermissionNodes(): RolePermissionNode[] {
    //     return this._rolePermissionNodes;
    // }
    // set rolePermissionNodes(data: RolePermissionNode[]) {
    //     this._rolePermissionNodes = data;
    // }

    // private _isDataSynced = false;
    // get isDataSynced(): boolean {
    //     return this._isDataSynced;
    // }
    // set isDataSynced(status: boolean) {
    //     this._isDataSynced = status;
    //     this.dataSync.next(this._isDataSynced);
    // }

    public ModelType = RolePermissionNode;
    // public dataSync: BehaviorSubject<boolean> = new BehaviorSubject(this._isDataSynced);

    constructor(
        protected http: HttpClient
    ) {
        super(http);
        // this.syncRolePermissionData();
    }
  
    // public syncRolePermissionData(){
    //     this.allRolePermissionNodes()
    //     .then(
    //         (response: any) => {
    //             this.isDataSynced = true;
    //         }
    //     )

    // }

    public allCorpRolePermissionNodes(data :{roles: Array<Role>}): Observable<Array<RolePermissionNode>> {
        return this
            .http
            .post(
                this.serviceUrl + 'getAllRolePermissionNodesForCorp',
                {
                    roles: data.roles
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => 
                        response.body.result.map((e: RolePermissionNode) => new RolePermissionNode(e))
                )
            );
    }

    public push(entry: RolePermissionNode): Observable<RolePermissionNode> {
        
        if(!entry.role_uuid) {
            entry.role_uuid = "";
        }

        if(!entry.permissionNode_uuid){
            entry.permissionNode_uuid = "";
        }

        return super.push(entry);
    }

}
