import { Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class ServiceCategory extends DexResourceModel {
   
    @Field(String)
	public name: string = "";
    @Field(String)
	public displayName: string | null = null;
    @Field(String)
	public filingService_uuid: string = "";
    @Field(Number)
    public sortOrder: number = 0;
    @Field(Boolean)
    public isInternal: boolean = true;
    @Field(Number)
    public groupNumber: number | null = null;
    @Field(String)
    public parentCategory: string | null = null;
    @Field(Date)
    public createdAt: Date = new Date();
	@Field(Date)
	public updatedAt: Date = new Date();
    @Field(Date)
	public deletedAt: Date | null = null;	
	
    constructor(data?: any) {
        super();
        this.mapData(data);
    }

    public get data(): any {
        let json = super.data;

        return json;
    }

}
