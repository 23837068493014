<resource-list-grid>
    <resource-list-pane *ngIf="!noCaseFound">
        <div class="loading" *ngIf="isLoading">
            <div class="loading-text">Loading Cases</div>
            <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
        </div>
        <div [hidden]="isLoading">
            <mat-paginator
                (page)="pageChange($event)"
                [pageSizeOptions]="dataSource.pageSizeOptions"
                [pageIndex]="dataSource.pageIndex"
                [pageSize]="dataSource.pageSize"
                showFirstLastButtons>
            </mat-paginator>
            <mat-table multiTemplateDataRows [dataSource]="dataSource" matSort (matSortChange)="sortDatasource('case')">

                <ng-container cdkColumnDef="select">
                    <mat-header-cell *cdkHeaderCellDef>
                        <mat-checkbox
                            color="primary"
                            (change)="selectAll()"
                            [checked]="selection.hasValue() && allSelected()"
                            (click)="selectionChange()">
                        </mat-checkbox>
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let entity">
                        <mat-checkbox
                            color="primary"
                            (change)="selection.toggle(entity)"
                            [checked]="selection.isSelected(entity)"
                            (click)="selectionChange($event)">
                        </mat-checkbox>
                    </mat-cell>
                </ng-container>

                <ng-container cdkColumnDef="view">
                    <mat-header-cell *cdkHeaderCellDef class="view"></mat-header-cell>
                    <mat-cell *cdkCellDef="let entity" class="view">
                        <mat-icon>{{entity == expandedEntity ? 'expand_less' : 'expand_more'}}</mat-icon>
                        <button mat-icon-button *ngIf="!entity.deletedAt" [routerLink]="'/case/'+entity.id">
                            <mat-icon>list_alt</mat-icon>
                        </button>
                        <button mat-icon-button *ngIf="entity.deletedAt" (click)="restoreEntry(entity)">
                            <mat-icon *ngIf="entity.isDeleted">restore</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <ng-container cdkColumnDef="name">
                    <mat-header-cell mat-sort-header *cdkHeaderCellDef>Case Name</mat-header-cell>
                    <mat-cell *cdkCellDef="let entity">
                        <span>{{entity.name}}</span>
                    </mat-cell>
                </ng-container>

                <ng-container cdkColumnDef="caseNumber">
                    <mat-header-cell mat-sort-header *cdkHeaderCellDef>Case Number</mat-header-cell>
                    <mat-cell *cdkCellDef="let entity">
                        <span>{{entity.caseNumber}}</span>
                    </mat-cell>
                </ng-container> 
    
                <ng-container cdkColumnDef="clientMatterNumber">
                    <mat-header-cell mat-sort-header *cdkHeaderCellDef>Client Matter Number</mat-header-cell>
                    <mat-cell *cdkCellDef="let entity">
                        <span>{{entity.clientMatterNumber}}</span>
                    </mat-cell>
                </ng-container> 

                <ng-container cdkColumnDef="successAccount_uuid">
                    <mat-header-cell mat-sort-header *cdkHeaderCellDef>Primary User</mat-header-cell>
                    <mat-cell *cdkCellDef="let entity">
                        <span>{{corpUsers.get(entity.successAccount_uuid)}}</span>
                    </mat-cell>
                </ng-container>

                <ng-container cdkColumnDef="expandedDetail">
                    <mat-cell class="entity-detail" *cdkCellDef="let entity;" [@detailExpand]="expandedEntity === entity ? 'expanded' : 'collapsed'">
                        <mat-tab-group [animationDuration]="100" *ngIf="entity == expandedEntity">
                            <mat-tab label="Overview">
                                <div class="entity-detail-row">
                                    <div class="entity-detail-column">
                                        <div class="entity-additional case-name">
                                            <label>Case Name</label>
                                            <span>{{entity.name}}&nbsp;</span>
                                        </div>
                                        <div class="entity-additional case-number">
                                            <label>Case Number</label>
                                            <span>{{entity.caseNumber}}&nbsp;</span>
                                        </div>
                                        <div class="entity-additional client-matter-number">
                                            <label>Client Matter Number</label>
                                            <span>{{entity.clientMatterNumber}}&nbsp;</span>
                                        </div>
                                    </div>

                                    <div class="entity-detail-column">
                                        <div class="entity-additional restricted">
                                            <label>Restricted</label>
                                            <span>{{entity.isRestricted ? "Restricted" : "Unrestricted"}}&nbsp;</span>
                                        </div>
                                        <div class="entity-additional successaccount-id">
                                            <label>Primary User</label>
                                            <span>{{corpUsers.get(entity.successAccount_uuid)}}&nbsp;</span>
                                        </div>
                                    </div>
                                </div>
                            </mat-tab>
                            <!-- <mat-tab label="Participants">
                                <div class="entity-detail-row">
                                    <div *ngIf="!contactSource.data[0]" class="entity-additional">
                                        No participants found
                                    </div>
                                    <mat-table class="innerTable" *ngIf="contactSource.data[0]" [dataSource]="contactSource" matSort #contactSort="matSort" (matSortChange)="sortDatasource('contact')">
                                        
                                        <ng-container cdkColumnDef="view">
                                            <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
                                            <mat-cell *cdkCellDef="let entity" >
                                                <button mat-icon-button (click)="deleteParticipant(entity)">
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                                <button mat-icon-button (click)="editParticipant(entity)">
                                                    <mat-icon>edit</mat-icon>
                                                </button>
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container cdkColumnDef="contactType">
                                            <mat-header-cell mat-sort-header *cdkHeaderCellDef>Type</mat-header-cell>
                                            <mat-cell style="text-transform:capitalize;" *cdkCellDef="let entity">
                                                {{entity.contactType ? entity.contactType : 'N/a'}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container cdkColumnDef="firstName">
                                            <mat-header-cell mat-sort-header *cdkHeaderCellDef>Name</mat-header-cell>
                                            <mat-cell *cdkCellDef="let entity">
                                                {{entity.firstName ? entity.firstName : 'N/a'}} {{entity.lastName ? entity.lastName : ''}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container cdkColumnDef="email">
                                            <mat-header-cell mat-sort-header *cdkHeaderCellDef>Email</mat-header-cell>
                                            <mat-cell *cdkCellDef="let entity">
                                                {{entity.email ? entity.email : 'N/a'}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container cdkColumnDef="phone">
                                            <mat-header-cell mat-sort-header *cdkHeaderCellDef>Phone</mat-header-cell>
                                            <mat-cell *cdkCellDef="let entity">
                                                {{entity.phone ? entity.phone : 'N/a'}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container cdkColumnDef="companyName">
                                            <mat-header-cell mat-sort-header *cdkHeaderCellDef>Company</mat-header-cell>
                                            <mat-cell *cdkCellDef="let entity">
                                                {{entity.companyName ? entity.companyName : 'N/a'}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container cdkColumnDef="address">
                                            <mat-header-cell mat-sort-header *cdkHeaderCellDef>Address</mat-header-cell>
                                            <mat-cell *cdkCellDef="let entity">
                                                {{!entity.address && !entity.zip ? 'N/a' : '' }} {{entity.address}} {{entity.zip}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container cdkColumnDef="city">
                                            <mat-header-cell mat-sort-header *cdkHeaderCellDef>City</mat-header-cell>
                                            <mat-cell *cdkCellDef="let entity">
                                                {{entity.city ? entity.city : 'N/a'}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container cdkColumnDef="state">
                                            <mat-header-cell mat-sort-header *cdkHeaderCellDef>State</mat-header-cell>
                                            <mat-cell *cdkCellDef="let entity">
                                                {{entity.state ? entity.state : 'N/a'}}
                                            </mat-cell>
                                        </ng-container>

                                        <mat-header-row *cdkHeaderRowDef="participantColumns; sticky: true"></mat-header-row>
                                        <mat-row *cdkRowDef="let row; columns: participantColumns; let i = index">
                                        </mat-row>
                                    </mat-table>
                                    <button style="width:160px;margin-bottom:10px;" color=primary mat-raised-button (click)="editParticipant()">
                                        <mat-icon>library_add</mat-icon>
                                        Add Participant
                                    </button>
                                </div>
                                <button mat-flat-button  *ngIf="contactSource.data.length >= 10" [routerLink]="'/case/'+entity.id">View More Participants</button>
                            </mat-tab> -->
                            <mat-tab label="Court">
                                <div class="entity-detail-row">
                                    <div class="entity-detail-column" *ngIf="courtAddress">
                                        <div class="entity-additional court" >
                                            <label>Court</label>
                                            <span>{{!courtAddress.name ? 'N/a' : courtAddress.streetAddress1}}</span>
                                        </div>
                                        <div class="entity-additional court-location">
                                            <label>Location</label>
                                            <span>{{courtAddress.state}}, {{courtAddress.city}}, {{courtAddress.postalCode}}</span>
                                        </div>
                                        <div class="entity-additional court-address">
                                            <label>Address</label>
                                            <span>{{!courtAddress.streetAddress1 ? (!courtAddress.streetAddress2 ? 'N/a' : courtAddress.streetAddress2 ) :  courtAddress.streetAddress1}}</span>
                                        </div>
                                        <div class="entity-additional court-phone">
                                            <label>Phone</label>
                                            <span>{{!courtAddress.primaryPhoneNumber ? 'N/a' : courtAddress.primaryPhoneNumber}} {{!courtAddress.primaryPhoneNumber ? "" : courtAddress.phoneExt}}</span>
                                        </div>
                                    </div>
                                    <div class="entity-detail-column"  *ngIf="courtAddress">
                                        <div class="entity-additional court-fax">
                                            <label>Fax</label>
                                            <span>{{!courtAddress.primaryFaxNumber ? 'N/a' : courtAddress.primaryFaxNumber}}</span>
                                        </div>
                                        <div class="entity-additional care-of">
                                            <label>Care Of</label>
                                            <span>{{!courtAddress.careOfLine ? 'N/a' : courtAddress.careOfLine}}</span>
                                        </div>
                                    </div>
                                    <div *ngIf="!courtAddress" class="entity-additional">
                                        No court found
                                    </div>
                                </div>
                            </mat-tab> 
                        </mat-tab-group>
                    </mat-cell>
                </ng-container>

                <mat-header-row *cdkHeaderRowDef="caseColumns; sticky: true"></mat-header-row>
                <mat-row *cdkRowDef="let row; columns: caseColumns"
                    class="entity-row"
                    [class.entity-expanded-row]="expandedEntity === row"
                    (click)="getEntity(row)">
                </mat-row>
                <mat-row *cdkRowDef="let row; columns: ['expandedDetail']" [class.entity-expanded-row]="expandedEntity === row" class="detail-row"></mat-row>
            </mat-table>


            <mat-paginator
                (page)="pageChange($event)"
                [pageSizeOptions]="dataSource.pageSizeOptions"
                [pageIndex]="dataSource.pageIndex"
                [pageSize]="dataSource.pageSize"
                showFirstLastButtons>
            </mat-paginator>
        </div>

    </resource-list-pane>
    
    <resource-list-pane *ngIf="noCaseFound" title="Address Details">
        <h3>No case found</h3>
    </resource-list-pane>   

    <resource-list-pane title="Filters" id="filterPaneCase" #filter="resourceListPane" [isCollapsed]='filterCollapsed' *ngIf="filterFormGroup" [collapsable]='true' class="entity-filters">
        <form class="filters" (ngSubmit)="getCases()" [formGroup]="filterFormGroup">

            <mat-label style="align-self:center;">Deleted</mat-label> 
            <br><hr class="solid" style="width:100%">

            <mat-button-toggle-group 
                style="width:min-content; align-self:center;"
                formControlName='deletedAt'
                color="primary"
                value="either">
                <mat-button-toggle value="deleted">Is</mat-button-toggle>
                <mat-button-toggle value="notDeleted">Not</mat-button-toggle>
                <mat-button-toggle value="either">Either</mat-button-toggle>
            </mat-button-toggle-group>
			<br>
            <mat-label style="align-self:center;">Restricted</mat-label> 
            <br><hr class="solid" style="width:100%">

            <mat-button-toggle-group 
                style="width:min-content; align-self:center;"
                formControlName='isRestricted'
                color="primary"
                value="either">
                <mat-button-toggle value="restricted">Is</mat-button-toggle>
                <mat-button-toggle value="notRestricted">Not</mat-button-toggle>
                <mat-button-toggle value="either">Either</mat-button-toggle>
            </mat-button-toggle-group>

            <mat-form-field>
                <input matInput
                    formControlName="name"
                    placeholder="Name"/>
            </mat-form-field>
            <mat-form-field>
                <input matInput
                    formControlName="user"
                    placeholder="User"/>
            </mat-form-field>
            <mat-form-field>
                <input matInput
                    formControlName="caseNumber"
                    placeholder="Case Number"/>
            </mat-form-field>
            <mat-form-field>
                <input matInput
                    formControlName="clientMatterNumber"
                    placeholder="Client Matter Number"/>
            </mat-form-field>

            <div class="filterActions">
                <button mat-flat-button type="button" (click)="getCases(true)">
                    Clear
                </button>
                <button mat-raised-button color="primary" type="submit">
                    Search
                </button>
            </div>
            
        </form>
    </resource-list-pane>

</resource-list-grid> 
