import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class ClientMatterSet extends DexResourceModel {
    @Field(String)
    public name: string = "";

    @Field(String)
    public ftp_host: string = "";

    @Field(String)
    public ftp_protocol: string = "";

    @Field(String)
    public ftp_matterFilePath: string = "";

    @Field(String)
    public ftp_clientFilePath: string = "";

    @Field(String)
    public ftp_fetchFrequency: string = "";

    @Field(String)
    public ftp_username: string = "";

    @Field(String)
    public ftp_password: string = "";

    @Field(String)
    public customMessage: string = "";

    @Field(String)
    public client_uuid?: string | null = null;

    @Field(String)
    public cName: string = "";

    @Field(Boolean)
    public autoFetch!: boolean;

    @Field(String)
	public color: string = "";
    
    @Field(String)
    public check: string = "false";

    @Field(String)
    public separator: string = ",";
    
    @Field(Number)
    public bucket: number = 2;

    @Field(String)
    public corpId?: string | null = null;

    get active(): boolean {
        return this.deletedAt == null;
    }
    set active(active: boolean) {
        if (active) {
            this.deletedAt = null;
        } else {
            this.deletedAt = new Date();
        }
    }

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}