<!--
<button type="button" mat-raised-button [routerLink]="'/users/client'">
	Add Client User
</button>
-->

<resource-list-grid>


	<resource-list-pane>
		
		<div class="loading" *ngIf="isLoading">
            <div class="loading-text">Loading Users</div>
            <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
        </div>

		<div [hidden]="isLoading">
			<mat-paginator
				[pageSizeOptions]="entityListSource.pageSizeOptions"
				[resource-source]="entityListSource"
				showFirstLastButtons >
			</mat-paginator>

			<mat-table [dataSource]="entityListSource" matSort (matSortChange)="sortData($event)">

				<ng-container cdkColumnDef="select">
					<mat-header-cell *cdkHeaderCellDef>
						<mat-checkbox (change)="selectAll()"
							color="primary"
							[checked]="selection.hasValue() && allSelected()">
						</mat-checkbox>
					</mat-header-cell>
					<mat-cell *cdkCellDef="let entity">
						<mat-checkbox (change)="selection.toggle(entity)"
							color="primary"
							[checked]="selection.isSelected(entity)">
						</mat-checkbox>
					</mat-cell>
				</ng-container>

				<ng-container cdkColumnDef="View">
					<mat-header-cell *cdkHeaderCellDef>View</mat-header-cell>
					<mat-cell *cdkCellDef="let entity" >
						<a mat-flat-button [routerLink]="['/users', entity.id]">
							<mat-icon>library_books</mat-icon>
						</a>
					</mat-cell>
				</ng-container>

				<ng-container cdkColumnDef="firstName">
					<mat-header-cell mat-sort-header *cdkHeaderCellDef>First Name</mat-header-cell>
					<mat-cell *cdkCellDef="let entity">{{entity.firstName}}</mat-cell>
				</ng-container>
				<ng-container cdkColumnDef="lastName">
					<mat-header-cell mat-sort-header *cdkHeaderCellDef>Last Name</mat-header-cell>
					<mat-cell *cdkCellDef="let entity">{{entity.lastName}}</mat-cell>
				</ng-container>
				<ng-container cdkColumnDef="email">
					<mat-header-cell mat-sort-header *cdkHeaderCellDef>Email</mat-header-cell>
					<mat-cell *cdkCellDef="let entity">{{entity.email}}</mat-cell>
				</ng-container>
				<ng-container cdkColumnDef="username">
					<mat-header-cell mat-sort-header *cdkHeaderCellDef>Username</mat-header-cell>
					<mat-cell *cdkCellDef="let entity">{{entity.username}}</mat-cell>
				</ng-container>
				<ng-container cdkColumnDef="corpId">
					<mat-header-cell mat-sort-header *cdkHeaderCellDef>Corp Id</mat-header-cell>
					<mat-cell *cdkCellDef="let entity">{{entity.corpId}}</mat-cell>
				</ng-container>
				<mat-header-row *cdkHeaderRowDef="columns; sticky: true"></mat-header-row>
				<mat-row *cdkRowDef="let row; columns: columns"></mat-row>
			</mat-table>

			<mat-paginator
				[pageSizeOptions]="entityListSource.pageSizeOptions"
				[resource-source]="entityListSource"
				showFirstLastButtons >
			</mat-paginator>
		</div>

	</resource-list-pane>

	<resource-list-pane id="filterPaneUser" #filter="resourceListPane" [isCollapsed]='filterCollapsed' [collapsable]='true' title="Filters">

		<resource-list-filter-form [resource-source]="entityListSource">
			<mat-form-field>
				<input matInput
					[resource-filter-field]="['firstName']"
					placeholder="First Name" />
			</mat-form-field>
			<mat-form-field>
				<input matInput
					[resource-filter-field]="['lastName']"
					placeholder="Last Name" />
			</mat-form-field>
			<mat-form-field>
				<input matInput
					[resource-filter-field]="['email']"
					placeholder="Email" />
			</mat-form-field>
			<mat-form-field>
				<input matInput
					[resource-filter-field]="['username']"
					placeholder="Username" />
			</mat-form-field>

		</resource-list-filter-form>
	</resource-list-pane>
</resource-list-grid>
