import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavigationModule, ResourceModule, AuthGuardService } from '@smartsoftware/reflex-core';
import { AestheticsModule } from '../../aesthetics/aesthetics.module';
import { ComponentsModule } from '../../components/components.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AutoLoginPartialRoutesGuard as AuthGuard } from 'angular-auth-oidc-client';
import { LegalReflexLibModule } from 'legalreflex-lib';
import { NotificationFrame } from './frame/frame';
import { NotificationList } from './list/list';
import { NotificationView } from './view/view';
import { NotificationPopupDialog } from './dialog/notificationPopup';
import { NotificationRestoreDialog } from './restore/notificationRestoreDialog';
const routes: Routes = [
    {
        path: 'notifications',
        component: NotificationFrame,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: NotificationList
            },
            {
                path: ':id',
                component: NotificationView
            },

        ]
    }
];

@NgModule({
    declarations: [
        NotificationFrame,
        NotificationList,
        NotificationView,
        NotificationPopupDialog,
        NotificationRestoreDialog,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AestheticsModule,
        ComponentsModule,
        ResourceModule,
        BrowserModule,
        BrowserAnimationsModule,
        MatTooltipModule,
        RouterModule,
        LegalReflexLibModule,
        MatFormFieldModule,
        MatInputModule,
        RouterModule.forChild(routes)
    ],
    exports: [

    ],
    bootstrap: [

    ]
})
export class NotificationModule { }