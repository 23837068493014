import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class SuccessContact extends DexResourceModel {

    @Field(String)
    public firstName: string = "";
    @Field(String)
    public lastName: string = "";
    @Field(String)
    public phone: string = "";
    @Field(String)
    public email: string = "";
    @Field(String)
    public address: string = "";
    @Field(String)
    public city: string = "";
    @Field(String)
    public state: string = "";
    @Field(String)
    public zip: string = "";
    @Field(String)
    public companyName: string = "";
    @Field(String)
    public barId: string = "";
    @Field(String)
    public isOrganization: boolean = false;


    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}
