import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { UserIdentity } from '../models/userIdentity.model';

@Injectable()
export class UserIdentity_Service extends ResourceService<UserIdentity> {
    protected servicePath: string = '/UserIdentity';

    public ModelType = UserIdentity;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }
}
