import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { NavigationModule, ResourceModule } from '@smartsoftware/reflex-core';
import { AestheticsModule } from '../../aesthetics/aesthetics.module';

//import { AuthFrame } from './frame/frame';
import { UnauthorizedView } from './unauthorized/unauthorized';

const routes: Routes = [
	// {
	// 	path: 'unauthorized',
	// 	component: UnauthorizedView,
	// }
];

@NgModule({
	declarations: [
		UnauthorizedView
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
        AestheticsModule,
		ResourceModule,
		BrowserModule,
		RouterModule,

		RouterModule.forChild(routes)
	],
	exports: [

	],
	bootstrap: [

	]
})
export class AuthModule { }
