import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class Dictionary_Service {
    [key: string]: any;

    public dictionary: BehaviorSubject<Dictionary> = new BehaviorSubject<Dictionary>({tooltips: {}});
    public tooltip : any; // Dynamic proxy for tooltip lookups

    constructor(
        protected http: HttpClient
    ) {
        /**
         * Proxied get handler allows for consumers to simply inject the service and call 'dictionary.tooltip.name' to get the tooltip for 'tooltipName'
         */
        this.tooltip = new Proxy(this, {
            get(target, property, receiver) : string | undefined {
                return target.getTooltip(property.toString());
            }
        });

        this.loadDictionary();
    }

    public loadDictionary() {
        let cache = localStorage.getItem('dictionary');
        if (cache) {
            this.dictionary.next(JSON.parse(cache));
        }

        this.http.get<Dictionary>('/assets/dictionary.json').subscribe(
            (dictionary: Dictionary) => {
                this.dictionary.next(dictionary);
                localStorage.setItem('dictionary', JSON.stringify(dictionary));
            }
        );
    }

    public getTooltip(key: string) : string | undefined {
        return key in this.dictionary.value.tooltips ? this.dictionary.value.tooltips[key] : undefined;
    }

} 

export interface Dictionary {
    tooltips: {[key: string]: any};
}