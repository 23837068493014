import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { OrderContact } from '../models/';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class OrderContact_Service extends ResourceService<OrderContact> {
    protected servicePath: string = '/OrderContact';

    public ModelType = OrderContact;

    public push(entry: OrderContact): Observable<OrderContact> {
               
        return super.push(entry);
    }

    


    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }
}
