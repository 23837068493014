<h2 mat-dialog-title>New Password</h2>
<div mat-dialog-content>
    <h4>Enter a new password for your Success Account</h4>

    <mat-form-field>
        <input matInput placeholder="Username" type="text" [(ngModel)]="data.username" [disabled]="!badUsername">
    </mat-form-field>
    <br>
    
    <!-- <mat-label>Username</mat-label>
    <div mat-form-field>
        <input matInput placeholder="username" type="text" [(ngModel)]="data.username" [disabled]="!badUsername">
    </div>
    <div *ngIf="badUsername">The username you selected is already in use, please select a new one</div>
    <br> -->

    <mat-form-field>
        <input matInput placeholder="New password" [type]="passwordVisibility" [(ngModel)]="data.password"/>
        <mat-icon class="clickable" matSuffix (click)="togglePasswordVisibility()">{{visibilityIcon}}</mat-icon>
    </mat-form-field>
    
    <!-- <mat-label>Password</mat-label>
    <div mat-form-field>
        <input matInput placeholder="Enter a new password" type="password" [(ngModel)]="data.password">
    </div> -->

    <div *ngIf="badPassword">
        {{PASSWORD_HINT}}
    </div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="submit()">Create Account</button>
</div>