import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class NotificationType extends DexResourceModel {

    @Field(String)
    public name: string = "";
 
    @Field(String)
    public subject: string = "";

    @Field(String)
    public globalBCC: string = "";

    @Field(String)
    public templateText: string | null = null;

    @Field(String)
    public templateHtml: string | null = null;

    @Field(Boolean)
    public shouldEmail: boolean = false;
    
    @Field(Boolean)
    public shouldSMS: boolean = false;
   
    @Field(Boolean)
    public canGroupSame: boolean = false;

    @Field(String)
    public stripoTemplateKey: string | null = null;

    @Field(Boolean)
    public shouldSyncStripo: boolean = false;

    @Field(String)
    public headerTemplateId: string | null = null;

    @Field(String)
    public footerTemplateId: string | null = null;

    @Field(String)
    public systemEvent: string | null = null;

    @Field(String)
    public jsonDataFullBody: string | null = null;

    @Field(String)
    public jsonDataExampleBody: string | null = null;

    @Field(String)
    public templateKey: string | null = null;

    @Field(Date)
    public lastSyncedAt: Date | null = null;

    @Field(String)
    public internalName: string | null = null;

    get active(): boolean {
        return this.deletedAt == null;
    }
    set active(active: boolean) {
        if (active) {
            this.deletedAt = null;
        } else {
            this.deletedAt = new Date();
        }
    }

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

    public get data(): any {
        let json = super.data;

        return json;
    }
}