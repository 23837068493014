import { Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class Notification extends DexResourceModel {

    @Field(String)
    public userId: string | null = null;
 
    @Field(String)
    public emailTo: string | null = null;

    @Field(String)
    public emailCC: string | null = null;

    @Field(String)
    public emailBCC: string | null = null;

    /** Replaced with deliveredAt */
    @Field(Date)
    public emailedAt: Date | null = null;

    @Field(Date)
    public viewedAt: Date | null = null;

    @Field(String)
    public notificationTypeId: string = "";

    @Field(String)
    public subject: string | null = null;

    @Field(String)
    public contentText: string | null = null;

    @Field(String)
    public contentHtml: string | null = null;

    @Field(String)
    public jsonData: string | null = null;

    @Field(Boolean)
    public deliveredBrowserPushNotification: boolean = false;

    @Field(String)
    public deliverVia: 'SES' | 'MailGun' = 'MailGun';

    @Field(String)
    public deliveryStatusResponse: string | null = null;

    @Field(Date)
    public deliveredAt: Date | null = null;

    @Field(String)
    public deliveredVia:  'SES' | 'MailGun' | null = null;
    
    constructor(data?: any) {
        super();
        this.mapData(data);
    }

    public get data(): any {
        let json = super.data;

        return json;
    }

}