import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ServicesModule } from './services/services.module';
import { LegalReflexComponentsModule } from './components/components.module';
import { LegalReflexDirectivesModule } from './directives/directives.module';
import { LegalReflexModulesModule } from './modules/modules.module';

import { ImpersonateModule } from './routes';

import {
    AestheticsModule
} from '@smartsoftware/reflex-core';

@NgModule({
    imports: [
        AestheticsModule,
        CommonModule,
        BrowserModule,
        ServicesModule,
        LegalReflexComponentsModule,
        LegalReflexDirectivesModule,
        LegalReflexModulesModule,
        ImpersonateModule
    ],
    exports: [
        ServicesModule,
        LegalReflexComponentsModule,
        LegalReflexDirectivesModule,
        LegalReflexModulesModule,
        ImpersonateModule
    ],
    declarations: [
    ],
    entryComponents: [

    ],
    providers: [
        // Non-singleton services
        HttpClientModule,
        RouterModule,

        // { provide: HTTP_INTERCEPTORS, useClass: HttpAuthorization, multi: true },
    ]
})
export class LegalReflexLibModule { }
