import { Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class FLCompany extends DexResourceModel {
    @Field(String)
    public companyName: string = "";

    @Field(String)
	public color: string = "";

    @Field(String)
    public activeStatus : string = "";
    
    @Field(String)
    public check: string = "false";
       
    public set_Status(){
        if(this.deletedAt == null)
            this.activeStatus = "active";

        else{
            this.activeStatus = "inactive"
        }
            
    }
  

    public get_Status(){
        return this.activeStatus;
    }
    

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}