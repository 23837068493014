import { MatPaginator } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { BehaviorSubject, combineLatest, merge, Observable, of, Subject, Subscription, throwError } from "rxjs";
import { catchError, debounceTime, switchMap, tap } from "rxjs/operators";

// export type Pager<T> = (params: PageParams) => Observable<Page<T>>;
export type Filters = { [key: string]: any };

export interface PageParams {
    page: number;
    pageSize: number;
    sortOrder: 'desc' | 'asc' | '';
    sortColumn: string;
    filters: Filters;  
}

export interface Page<T> {
    items: T[];
    page: number;
    totalItems: number;
    params?: PageParams;
}

// services will implement this interface for use in datasource
// going with this style since only classes appear to be injectable
export interface Pager<T> {
    page: (params: PageParams) => Observable<Page<T>>
}


export class PagedDataSource<T> extends MatTableDataSource<T> {

    pager: Pager<T>;

    protected pageChange: Subject<void> = new Subject();

    pageSizeOptions = [10, 25, 50];

    protected _pageSize: number = 10;
    get pageSize() { return this._pageSize; }
    set pageSize(size: number) {
        this._pageSize = size;
        if (this.paginator && this.paginator.pageSize != size) {
            this.paginator.pageSize = size;
        }
        if (this._otherPaginator && this._otherPaginator.pageSize != size) {
            this._otherPaginator.pageSize = size;
        }
        this.pageChange.next();
    }
    protected _pageIndex: number = 0;
    get pageIndex() { return this._pageIndex; }
    set pageIndex(i: number) {
        this._pageIndex = i;
        if (this.paginator && this.paginator.pageIndex != i) {
            this.paginator.pageIndex = i;
        }
        if (this._otherPaginator && this._otherPaginator.pageSize != i) {
            this._otherPaginator.pageIndex = i;
        }
        this.pageChange.next();
    }
    
    protected _filters: BehaviorSubject<Filters> = new BehaviorSubject<Filters>({});
    get filters() { return this._filters.value; }
    set filters(filters: Filters) {
        this.pageIndex = 0;
        this._filters.next(filters);
    }

    protected pageRenderData: BehaviorSubject<T[]> = new BehaviorSubject([] as T[]);

    protected _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    get loading() {
        return this._loading.asObservable();
    }

    protected _failed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    get failed() {
        return this._failed.asObservable();
    }

    protected _totalItems: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
    get totalItems() {
        return this._totalItems.asObservable();
    }
    
    protected _otherPaginator?: MatPaginator | null;
    protected paginatorSubscription?: Subscription;
    protected otherPaginatorSubscription?: Subscription;

    set otherPaginator(paginator: MatPaginator | null) {
        this._otherPaginator = paginator;
        this.syncPaginators();
    }

    get paginator() { return super.paginator; }
    set paginator(paginator: MatPaginator | null) {
        super.paginator = paginator;
        this.syncPaginators();
    }

    protected syncPaginators() {
        if (this.paginatorSubscription) {
            this.paginatorSubscription.unsubscribe();
        }
        if (this.paginator) {
            this.paginatorSubscription = this.paginator.page.pipe(tap(e => {
                this.pageIndex = e.pageIndex;
                this.pageSize = e.pageSize;
            })).subscribe();
        }
        if (this.otherPaginatorSubscription) {
            this.otherPaginatorSubscription.unsubscribe();
        }
        if (this._otherPaginator) {
            this.otherPaginatorSubscription = this._otherPaginator.page.pipe(tap(e => {
                this.pageIndex = e.pageIndex;
                this.pageSize = e.pageSize;
            })).subscribe();
        }
    }

    constructor(pager: Pager<T>) {
        super();
        this.pager = pager;
    }

    protected loadData(filters: Filters): Observable<Page<T>> {
        this.data = []
        this.pageRenderData.next([]);
        this._failed.next(false);
        this._loading.next(true);
        let page = this.paginator ? this.paginator.pageIndex + 1 : 1;
        let pageSize = this.paginator ? this.paginator.pageSize : 1;
        let sortColumn = this.sort ? this.sort.active : '';
        let sortOrder = this.sort ? this.sort.direction : 'asc';
        let pageParams: PageParams = {
            page,
            sortColumn,
            sortOrder,
            pageSize,
            filters
        }
        return this.pager.page(pageParams).pipe(
            tap(_ => {
                this._totalItems.next(_.totalItems);
                this._loading.next(false);
            }),
            catchError((err: any, caught: Observable<Page<T>>) => {
                this._failed.next(true);
                this._loading.next(false);
                return of(err);
            })
        );
    }

    public reload() {
        this.pageChange.next();
    }

    _updateChangeSubscription() {
        // super class calls this method in the constructor, so the this._filters and this.pageChange
        // properties won't be initialized yet. 
        if (!this._filters || !this.pageChange) {
            return;
        }

        const sortChange: Observable<Sort|null|void> = this.sort ?
            merge(this.sort.sortChange, this.sort.initialized) as Observable<Sort|void> :
            of(null);

        // when the filters, page, or sort changes, we need to go to the server to request a new page
        const paginatedData = combineLatest([this._filters, this.pageChange, sortChange])
            .pipe(
                debounceTime(100),
                switchMap(([filters, p, s]) => {
                    return this.loadData(filters);
                })
            );
        

        this._renderChangesSubscription?.unsubscribe();
        this._renderChangesSubscription = paginatedData.subscribe((page: Page<T>) => {
            if (this.paginator && this.paginator.length != page.totalItems) {
                this.paginator.length = page.totalItems;
            }
            if (this._otherPaginator && this._otherPaginator.length != page.totalItems) {
                this._otherPaginator.length = page.totalItems;
            }
            // emit the new data items to MatTable so that it can re-render the rows
            this.data = page.items;
            this.pageRenderData.next(page.items);
        });
    }

    connect(): BehaviorSubject<T[]> {
        this._updateChangeSubscription();
        return this.pageRenderData;
    }
    
    disconnect(): void {
        super.disconnect();
        if (this.paginatorSubscription) {
            this.paginatorSubscription.unsubscribe();
        }
        if (this.otherPaginatorSubscription) {
            this.otherPaginatorSubscription.unsubscribe();
        }
    }

}