import { Injectable, Optional } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Injectable()
export class ModelLibrary_Service{
  constructor() {}

  // NOTE: Used for fields that are 'undefined' in the model e.g. `*_uuid` fields
  public addEntityFormControl(entityForm: FormGroup, controlsToAdd: {[name: string]: any}): FormGroup {
      for (let key in controlsToAdd) {
        if (key in entityForm.controls) {
          entityForm.controls[key].setValue(controlsToAdd[key]);
        }
        else {
          entityForm.addControl(key, new FormControl(controlsToAdd[key]));
        }
      }
      
      return entityForm;
  }
}