
<mat-tab-group *ngIf="entity && !isLoading">
	<mat-tab label="Profile">
		<resource-list-grid>
			<resource-list-pane title="Contact Information">

				<div class="row">
					<mat-form-field>
						<mat-label>First Name</mat-label>
						<input 
							matInput 
							maxlength="255"
							[(ngModel)]="entity.firstName" />
					</mat-form-field>
				
					<mat-form-field>
						<mat-label>Last Name</mat-label>
						<input matInput 
							maxlength="255"
							[(ngModel)]="entity.lastName" />
					</mat-form-field>
				</div>
		
			
				<mat-form-field>
					<mat-label>Email Address</mat-label>
					<input required 
						matInput 
						readonly
						type="email" 
						[(ngModel)]="entity.email" />
						<mat-hint>Contact support to change your email address</mat-hint>
				</mat-form-field>

				<mat-form-field>
					<mat-label>Mobile Phone</mat-label>
					<input phone matInput type="tel" [(ngModel)]="settings.mobilePhone" />
				</mat-form-field>
				<div class="row">
					<mat-form-field class="grow-3">
						<mat-label>Office Phone</mat-label>
						<input phone matInput type="tel" [(ngModel)]="settings.businessPhone" />
					</mat-form-field>
					<mat-form-field >
						<mat-label>Extension</mat-label>
						<input matInput type="number" maxlength="9" [(ngModel)]="settings.businessPhoneExtension" />
					</mat-form-field>
				</div>
			</resource-list-pane>
			
			<resource-list-pane title="Default Insurance Information">

				<mat-checkbox disabled aria-label="Same as my organization's default insurance" [checked]="true" matTooltip="This is not yet available for you to customize">Same as my organization's default insurance</mat-checkbox>
				
			</resource-list-pane>
		
		</resource-list-grid>
	</mat-tab>

	<mat-tab label="Preferences" *ngIf="settings">
		<resource-list-grid>
			<resource-list-pane title="Notifications">
				<p>These preferences represent your defaults, but can be customized on a case-by-case and job-by-job basis if needed.</p>

				<lib-notification-preferences [user]="entity" [settings]="settings"></lib-notification-preferences>

			</resource-list-pane>
			<resource-list-pane title="Default Special Instructions">
				<p>These can (and are likely to) be overridden on a case-by-case and job-by-job basis, but are the defaults when creating a new job.</p>

				<lib-special-instruction-preferences [user]="entity" [settings]="settings"></lib-special-instruction-preferences>
			</resource-list-pane>
		</resource-list-grid>
	</mat-tab>

	<mat-tab label="Security">
		<resource-list-grid>
			<resource-list-pane title="Account Status">

				<mat-slide-toggle disabled [checked]="entity?.active" #toggle [matTooltip]="dictionary.tooltip.editUser_accountStatus">
					Account {{toggle.checked ? 'Enabled' : 'Disabled'}}
				</mat-slide-toggle>
			</resource-list-pane>
			<resource-list-pane title="Change Password">
				<lib-change-password kind="owner" [user]="entity"></lib-change-password>
			</resource-list-pane>
		</resource-list-grid>

	</mat-tab>
</mat-tab-group>
