import {Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SuccessCase } from 'legalreflex-lib';

@Component({
    selector: 'userDelete-dialog',
    templateUrl: 'userDelete.html',
    styleUrls: ['./userDelete.scss']
})  
export class userDeletePopupDialog {

    constructor(
        private dialogRef : MatDialogRef<string>, 
            
        @Inject(MAT_DIALOG_DATA) 
        public data: {name:string, email:string, isCurrentUser:boolean}
    ) {
    }

    close(confirm:boolean) {
        return this.dialogRef.close(confirm)
    }
}

