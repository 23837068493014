import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class PartnerVendor extends DexResourceModel {

    @Field(String)
    public name: string = "";

    @Field(String)
    public description: string = "";

    @Field(String)
    public vendorType_uuid: string | null = null;
    @Field(String)
    public main_contact_uuid: string | null = null;
    @Field(String)
    public billing_contact_uuid: string | null = null;
    @Field(String)
    public address1: string = "";
    @Field(String)
    public address2: string = "";
    @Field(String)
    public phone: string = "";
    @Field(String)
    public email: string = "";
    @Field(String)
    public city: string = "";
    @Field(String)
    public state: string = "";
    @Field(String)
    public postal: string = "";
    @Field(String)
    public fileFolderName: string = "";

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}
