import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef, ViewChildren, QueryList } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ReflexEnvironment, ResourceEditComponent, ResourceFormService, ResourceListHash } from '@smartsoftware/reflex-core';
import { FormGroup, FormControl, Validators } from '@angular/forms'
import {
    WIP,
    AddressBook,
    AddressBookService,
    Order,
    SuccessCase,
    SuccessCase_Service,
    UserPermission_Service,
    SuccessAccountRoleName,
    SuccessCaseContact_Service,
    SuccessContact_Service,
    SuccessCaseContact,
    SuccessContact,
    SuccessContactWithType,
    Document,
    AdminOrder_Service,
    SuccessCaseAccess_Service,
    SuccessCaseAccess,
    SuccessAccount,
    SuccessAccount_Service,
    Notification,
    Notification_Service,
    NotificationType,
    NotificationType_Service,
    CommonData_Service,
    ClientMatterEntry,
    ClientMatterEntry_Service,
    ClientMatterSet_Service,
    ClientMatterSet,
    PageParams,
    Vendor_Service,
    Vendor,
    SuccessAccountPermissionNodeName,
    ServiceType,
    Service,
    Service_Service,
    Services,
    Document_Service,
    SystemConfig_service
} from 'legalreflex-lib';
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatSort } from '@angular/material/sort';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, Observable, Subscription, combineLatest, concat, forkJoin } from 'rxjs';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { BreadCrumbService, Breadcrumb } from '../../../components/breadcrumb-bar/breadcrumb-service';
import { userDeletePopupDialog } from './userDelete';
import { ParticipantEditPopupDialog } from '../participantEdit/participantEdit';
import { catchError, combineAll, concatAll, concatMap, first, map, mergeMap, startWith, take, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { CaseRestoreDialog } from '../restore/caseRestoreDialog';
import { MissingCasePopupDialog } from './missingCase';
import jsPDF from 'jspdf';
import { merge } from 'angular';

@Component({
    templateUrl: './view.html',
    styleUrls: ['./view.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
        trigger('filterExpand', [
            state('collapsed', style({width: '0px', minWidth: '0'})),
            state('expanded', style({width: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})

export class CaseView extends ResourceEditComponent<SuccessCase, SuccessCase_Service> implements OnInit, AfterViewInit {

    @ViewChild('order') orderSort: MatSort = new MatSort();
    @ViewChild('participant') participantSort: MatSort = new MatSort();
    @ViewChild('user') userSort: MatSort = new MatSort();
    @ViewChild('document') documentOrderSort: MatSort = new MatSort();

    @ViewChild('orderPaginator') orderPage: MatPaginator  = new MatPaginator(new MatPaginatorIntl(), ChangeDetectorRef.prototype);
    @ViewChild('documentPaginator') docPage: MatPaginator  = new MatPaginator(new MatPaginatorIntl(), ChangeDetectorRef.prototype);
    
    public WIP : boolean = WIP();
    
    public userId: string = "";
    public corpId: string = "";
    public orderId: string = "";

    public userManage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public expandedOrder: Order | null = null;
    public clientMatterSet: ClientMatterSet | null = null;
    public clientMatterMap: Map<string, string> = new Map();
    public filteredClientMatters: Observable<string[]> | undefined = undefined;

    public primaryUser: SuccessAccount | undefined = undefined;
    public addressHash$: BehaviorSubject<ResourceListHash<AddressBook>> = new BehaviorSubject<ResourceListHash<AddressBook>>({});
    public courtAddressList: BehaviorSubject<AddressBook[]> = new BehaviorSubject<AddressBook[]>([]);
    public courtAddress: AddressBook | undefined = undefined;
    public lookupAddress: boolean = false;

    public allUsers : Array<SuccessAccount> = [];
    public selectedUser : SuccessAccount | undefined = undefined;
    public nonPermittedUsers: Array<SuccessAccount> = [];

    public addressLookupCtrl = new FormControl(null);
    public sendNotificationCtrl = new FormControl(null);
    public permittedUserCtrl = new FormControl(null);

    public documents: DocumentOrder[] = [];
    public orderSource : MatTableDataSource<ExtendedOrder> = new MatTableDataSource(undefined);
    public documentSource : MatTableDataSource<Document> = new MatTableDataSource(undefined);
    public caseDocumentSource : MatTableDataSource<Document> = new MatTableDataSource(undefined);
    public documentOrderSource : MatTableDataSource<DocumentOrder> = new MatTableDataSource(undefined);
    public downloadURLs: Map<string, string> = new Map();

    public vendors: Map<any, any> = new Map();
    public orderServices: Map<any, any> = new Map();
    public participantSource : MatTableDataSource<SuccessContactWithType> = new MatTableDataSource(undefined);
    public userSource : MatTableDataSource<SuccessAccount> = new MatTableDataSource(undefined);

    public orderColumns = ['view', 'order.orderNumber', 'order.orderDate', 'service', 'serviceType', 'order.orderStatus', 'order.updatedAt'];
    public participantColumns = ['view', 'contactType', 'firstName', 'email', 'phone', 'companyName', 'address', 'city', 'state'];
    public documentOrderColumns = ['view', 'orderNumber', 'document.fileName', 'document.name', 'document.status', 'document.fileType'];
    public userColumns = ['view', 'firstName', 'lastName', 'email', 'username', 'active'];
    public caseDetail: SuccessCase = new SuccessCase();
    public noCaseFound = false;

    public updateCaseNotificationType: BehaviorSubject<NotificationType> = new BehaviorSubject<NotificationType>(new NotificationType());
    public createCaseNotificationType: BehaviorSubject<NotificationType> = new BehaviorSubject<NotificationType>(new NotificationType());

    public get formHasControl(): boolean {
        return this.entityForm?.controls['id']?.value || this.entityForm?.controls['uuid']?.value
    }

    constructor(
        public locationService: CommonData_Service,
        public auth: OidcSecurityService,
        protected addressService: AddressBookService,
        protected entityService: SuccessCase_Service,
        protected orderService: AdminOrder_Service,
        protected route: ActivatedRoute,
        protected dialog: MatDialog,
        protected snackbar: MatSnackBar,
        protected resourceFormService: ResourceFormService<SuccessCase>,
        protected userPermissionService: UserPermission_Service,
        protected caseContactService: SuccessCaseContact_Service,
        protected contactService: SuccessContact_Service,
        protected caseAccessService: SuccessCaseAccess_Service,
        protected successAccountService: SuccessAccount_Service,
        protected notificationService: Notification_Service,
        protected notificationTypeService: NotificationType_Service,
        protected clientMatterEntryService: ClientMatterEntry_Service,
        protected clientMatterSetService: ClientMatterSet_Service,
        protected vendorService: Vendor_Service,
        protected serviceService: Service_Service,
        protected documentService: Document_Service,
        private breadCrumbService: BreadCrumbService,
        public sysConfig: SystemConfig_service
    ) {
        super(entityService, route, dialog, resourceFormService);
        this.entityForm = this.resourceFormService.from(new SuccessCase());
        // Fix issue with mat-sort-header deep access to document
        this.orderSource.sortingDataAccessor = (item, property) => {
            if (property.includes('order.')) 
                return property.split('.').reduce((o,i)=>item.order[i as keyof Order])
            return item[property as keyof ExtendedOrder].toString()
        };

        this.documentOrderSource.sortingDataAccessor = (item, property) => {
            if (property.includes('.')) 
                return property.split('.').reduce((o,i)=>item.document[i as keyof Document])
            return item[property as keyof DocumentOrder].toString()
        };

        this.notificationTypeService.getByInternalName(KnownNotificationTypes.SuccessCaseCreate).subscribe(notificationType => {
            this.createCaseNotificationType.next(notificationType);
        });

        this.notificationTypeService.getByInternalName(KnownNotificationTypes.SuccessCaseUpdate).subscribe(notificationType => {
            this.updateCaseNotificationType.next(notificationType);
        });

        this.entityUpdated.subscribe((res)=>{
            // lookup if case is loaded while being recorded as deleted
            if(!res.id){
                this.isLoading = true;
                this.route.params.subscribe((route)=>{
                    let params : PageParams = {
                        page: 1,
                        pageSize: 1,
                        sortOrder: 'desc',
                        sortColumn: 'name',
                        filters: {deletedAt:'either', source:'success', uuid:route.id}
                    }
                    this.entityService.page(params).subscribe((result)=>{
                        if(result.items[0]){
                            this.isLoading = false;

                            let deletedCase = result.items[0];
                            // deletedCase.deletedAt = null
                            // deletedCase.isDeleted = false
                            this.caseDetail = deletedCase;
                            this.entityForm = this.resourceFormService.from(this.caseDetail);

                            this.getContacts(this.caseDetail.uuid);
                            
                            if(this.caseDetail.court_AddressBook_uuid)
                                this.addressService.get(this.caseDetail.court_AddressBook_uuid).subscribe((res)=>{this.courtAddress=res});
                            this.setBreadCrumbs();
                        }
                        else if(!res.id){
                            this.dialog.open(MissingCasePopupDialog, {})
                        }
                    },(err)=>{this.dialog.open(MissingCasePopupDialog, {})})
                })
            }else{
                this.caseDetail = res;
                this.entityForm = this.resourceFormService.from(this.caseDetail);

                this.getContacts(this.caseDetail.uuid);
                
                if(this.caseDetail.court_AddressBook_uuid)
                    this.addressService.get(this.caseDetail.court_AddressBook_uuid).subscribe((res)=>{this.courtAddress=res});
                this.setBreadCrumbs();
            }
        })
    }

    ngOnInit() {
        if(this.sysConfig.maintenanceRedirectCheck()){  
            window.localStorage.removeItem("loggedInUserPermissionNodes");
            this.auth.logoff();
        };
        this.addressService.getCourtsAddressBook().subscribe((entity)=>{
            this.addressHash$.next(entity);
        })

        this.auth.userData$.subscribe(({userData, allUserData}) => {
			let res = userData;
            this.userId = res.profile.uuid;
            this.corpId = res.profile.corpId;
            this.userPermissionService.userSync.subscribe((res)=>{
                if(this.userPermissionService.canDo(SuccessAccountPermissionNodeName.manage_all_users)){
                    this.userManage.next(true)
                }else if(this.userPermissionService.canDo(SuccessAccountPermissionNodeName.manage_firm_users)){
                    this.userManage.next(true)
                }
            })
        })

        this.successAccountService.search({where:{filters:{corpId:this.corpId}}}).subscribe((accounts)=>{
            this.allUsers = accounts.results;
            this.allUsers.sort((a,b)=> {
                if(a.firstName == b.firstName) return 0
                return a.firstName.toUpperCase() < b.firstName.toUpperCase() ? -1 : 1
            })
            this.nonPermittedUsers = this.allUsers.filter((entity)=>{
                if(this.userSource.data.find((copy)=>{return copy.uuid == entity.id})){
                    return undefined;
                }
                return entity;
            })
            this.nonPermittedUsers.sort((a,b)=> {
                if(a.firstName == b.firstName) return 0
                return a.firstName.toUpperCase() < b.firstName.toUpperCase() ? -1 : 1
            })
            
            let filter = accounts.results.find((account)=> account.uuid == this.userId);
            if(filter?.default_clientMatterSet_uuid){
                this.clientMatterSetService.get(filter.default_clientMatterSet_uuid).subscribe((cms)=>{
                    this.clientMatterSet = cms;
                    this.getClientMatters();
                })
            }
        })
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
        this.setBreadCrumbs();
    }

    serviceName(serviceName:string){
        let service = Object.entries(Services).find((service)=>{
            return service[0] == serviceName;
        })
        return service? service[1] : serviceName;
    }

    sortData(table:string){
        switch(table){
            case 'order':
                this.orderSource.data = this.orderSource.sortData(this.orderSource.data, this.orderSort);
                break
            case 'participant':
                this.participantSource.data = this.participantSource.sortData(this.participantSource.data, this.participantSort);
                break
            case 'user':
                this.userSource.data = this.userSource.sortData(this.userSource.data, this.userSort);
                break
            case 'document':
                this.documentOrderSource.data = this.documentOrderSource.sortData(this.documentOrderSource.data, this.documentOrderSort);
                break
            default:
                break
        }
    }

    setBreadCrumbs(){
        let crumbs = [];
        crumbs.push({ label: 'Case Manager', url:'/case'});
        if(this.caseDetail.uuid) 
            crumbs.push({ label: "Case " + this.entityForm.controls['caseNumber'].value});
        else{
            this.route.params.subscribe((res)=>{
                if(res.id == 'new')
                    crumbs.push({ label: "New Case"});
                else if(res.id != 'undefined')
                    crumbs.push({ label: "Loading"});
                else
                    this.isLoading = false;
            })
        }
        this.breadCrumbService.breadcrumbs.next(crumbs);

        let actions = [];
        actions.push({ label: 'Cancel', routerLink: "/case", icon: 'close'})
        if(this.caseDetail.uuid && !this.caseDetail.deletedAt){
            actions.push({ label: 'Delete', action: this.deleteEntry.bind(this), icon: 'delete'});
        } else if(this.caseDetail.uuid && this.caseDetail.deletedAt){
            actions.push({ label: 'Restore', action: this.restoreEntry.bind(this), icon: 'restore_from_trash'});
        }


        if(this.checkForms()){
            actions.push({ label: 'Save', action: this.save.bind(this), isPrimary: true, icon: ''});
        } else
            actions.push({ label: 'Save', disabled:true, tooltip:"Please fill in all basic case details before saving.", icon: ''});
        this.breadCrumbService.actions.next(actions);
    }

    zIndex(state:any){
        let win = window.document.getElementsByClassName('cdk-overlay-container').item(0) as HTMLElement
        if(win){
            win.style.zIndex = state ? '999' : '1000';
        }
    }

    getOrderService(order:Order){
        let service = this.serviceService.services.value.find((currentService)=>{
            let currentCategory = currentService.categories.find((category)=>{
                let type = category.types.find((type)=>{
                    return type.uuid == order.filingServiceType_uuid;
                })
                return !!type;
            })
            return !!currentCategory;
        })
        return service;
    }

    getContacts(id:string){
        let trac = false;
        // Service types stored in order have to go from
        // Service Type -> Service Category -> Service Line
        this.serviceService.dataSync.subscribe((state)=>{
            if(state && trac != true){
                trac = true;
                
                this.vendorService.list({allowCache:false}).subscribe((vendorList)=>{
                    vendorList.map((vendor:Vendor)=>{
                        this.vendors.set(vendor.uuid, vendor.name);
                    })
                    let obs = [
                        this.documentService.getCaseDocuments(id),
                        this.resourceService.getCaseOrders(id)
                    ]
                    forkJoin(obs).subscribe(([caseDocs,caseOrders])=>{
                        let orders: ExtendedOrder[] = []
                        caseOrders.map((order:any)=>{
                            let vendorName = this.vendors.get(order.vendor_uuid);
                            if(order.orderStatus != "Draft") {
                                if(vendorName == "Datatrac"){
                                    order.orderNumber = order.companyNumber + '-' + order.vendorId;
                                }
                                else if(vendorName == 'Acclaim' || vendorName == 'Tristar FLI'){
                                    order.orderNumber = order.vendorId;
                                }
                                else{
                                    order.orderNumber = order.vendorId + '-' + order.vendorKeyPart;
                                }        
                            }
                            else{
                                order.orderNumber = "Draft";
                            }
                            let orderService = this.getOrderService(order);
                            let serviceName = '';
                            let serviceCategory = '';
                            let serviceType = '';
                            if(orderService){
                                serviceName = orderService.service.name;
                                let cat = orderService.categories.find((c)=>{
                                    let type = c.types.find((type)=> {return type.uuid == order.filingServiceType_uuid});
                                    if(type)
                                        serviceType = (type.displayName || "");

                                    return !!type;
                                })
                                if(cat)
                                    serviceCategory = (cat.category.displayName || "");
                            }
                            orders.push({order:order, service:serviceName, serviceCategory:serviceCategory, serviceType:serviceType});
                            this.orderSource.data = this.orderSource.sortData(orders, this.orderSort);
                            this.orderSource.paginator = this.orderPage;
                        })

                        if(!this.documentOrderSource.paginator)
                            this.documentOrderSource.paginator = this.docPage;
            
                        let docs : DocumentOrder[] = caseDocs.map((docOrderPair:any)=>{
                            let id = docOrderPair.orderId;
                            let foundOrder = this.orderSource.data.find((currentOrder)=> currentOrder.order.uuid == id)
                            delete docOrderPair.orderId;
                            let docOrder: DocumentOrder = {
                                document: docOrderPair,
                                orderNumber: foundOrder && foundOrder.order.orderNumber ? foundOrder.order.orderNumber : "",
                                orderUuid: id
                            }
                            return docOrder;
                        })
                        this.documentOrderSource.data = this.documentOrderSource.data.concat(docs);
                        this.documentOrderSource.data = this.documentOrderSource._filterData(this.documentOrderSource.data);
                        this.caseDocumentSource.data = this.documentOrderSource.data.map(doc => new Document(doc.document));

                    })
                })
            }
        })

        this.resourceService.getCaseContacts(id).subscribe((entity)=>{
            this.participantSort = new MatSort();
            this.participantSource.data = this.participantSource.sortData(entity, this.participantSort);
        })
        
        this.resourceService.getPermittedUsers(id).subscribe((res)=>{
            this.userSource.data = this.userSource._filterData(res);
            this.nonPermittedUsers = this.allUsers.filter((entity)=>{
                if(this.userSource.data.find((copy)=>{return copy.uuid == entity.id})){
                    return undefined;
                }
                return entity;
            })
        })
    }

    getOrderDocuments(entity:ExtendedOrder){
        let order = entity.order;
        this.expandedOrder = this.expandedOrder === order ? null : order;
        if(typeof(this.expandedOrder?.uuid) === 'string'){
            this.orderId = this.expandedOrder.uuid;
            this.orderService.findDocumentsByOrderId(this.orderId).subscribe((res)=>{
                if(res[0]){
                    this.documentSource.data = res;
                }
            })
        }
    }    

    getUrls(docOrder: DocumentOrder) {
        let doc = docOrder.document;

        if (this.downloadURLs.has(doc.uuid)) {
            window.open(this.downloadURLs.get(doc.uuid), '_blank');
            return;
        } else {
            if(!docOrder.orderUuid || !doc.fileName) return;
            this.documentService.getDownloadUrl(docOrder.orderUuid, doc.fileName)
                .subscribe((data)=>{
                    this.downloadURLs.set(doc.uuid, data);
                    window.open(data, '_blank');
                }, (error)=>{
                    let docInfo = `${doc.fileName}`;
                    let message = `Something went wrong getting the file contents for ${docInfo}. `;
                    if (error instanceof HttpErrorResponse || error instanceof Error) {
                        message += error.message;
                    }
                    else if (typeof error === 'string') {
                        message += error;
                    }
                    this.snackbar.open(message, undefined, { duration: 4000, horizontalPosition: 'left'});
                });
        }
    }
  
    getAddress(event:string){
        let filter = Object.values(this.addressHash$.value).find((address)=> address.uuid == event);
        if(filter)
            this.lookupAddress = !this.lookupAddress;
        this.courtAddress = filter ? filter : undefined;
        this.addressLookupCtrl.setValue(event ? event : undefined);
        this.entityForm.controls['court_AddressBook_uuid'].setValue(event ? event: undefined);
        this.setBreadCrumbs();
    }

    getClientMatter(event:string){
        this.entityForm.controls['clientMatterNumber'].setValue(event ? event : null);
        this.setBreadCrumbs();
    }

    async getClientMatters(){

        if(this.clientMatterSet && this.clientMatterSet.uuid != ''){
            this.filteredClientMatters = this.entityForm.controls['clientMatterNumber'].valueChanges.pipe(
                startWith(''),
                map(value => this._clientMatterFilter(value || ''))
            );
            let requests = [];
            let entryCount = await this.clientMatterEntryService.getIdCount(this.clientMatterSet.uuid).pipe(first()).toPromise();
            let batchSize = 5000;
            let entries: Map<string, string> = new Map<string, string>();
            for (let i = 0; i < Math.ceil(entryCount / batchSize); i++) {
                requests.push(this.clientMatterEntryService.getPage(this.clientMatterSet.uuid, i, batchSize).pipe(first()).toPromise().then(matterList => {
                    matterList.forEach(matter => {
                        entries.set(matter.matter, `${matter.matter} | ${matter.name}`);
                    })
                }));
            }
            await Promise.all(requests);
            this.clientMatterMap = entries;
        }
    }

    private _clientMatterFilter(value: string) : any[] {
        const filterValue = value.toLowerCase();

        const result = Array.from(this.clientMatterMap.entries()).filter(([k, v]: [string,string]) => v.toLowerCase().includes(filterValue));
        return result.slice(0, 100);
    }


    filterEntries(listHash:BehaviorSubject<ResourceListHash<any>>, list:BehaviorSubject<any>, column:string, event?:any) {
        let searchString = event ? event : '';
        let filteredOptions: Array<any> = Object
            .values(listHash.value)
            .filter(
                entry => searchString == '' || (entry[column] && entry[column].toLowerCase().includes(searchString.toLowerCase()))
            )
        list.next(filteredOptions);
    }

    filterDisplay(key: string): string{
        let selectedOption;
        if((this as unknown as MatAutocomplete).options != undefined)
            selectedOption = (this as unknown as MatAutocomplete).options.find(o => o.value == key)
        return selectedOption ? selectedOption.viewValue : '';
    }

    setSelectedUser(event:number){
        this.permittedUserCtrl.setValue(event ? event : null);
        this.selectedUser = event ? this.nonPermittedUsers[event] : undefined;
    }

    removePermittedUser(removedUser:SuccessAccount){
        this.dialog.open(userDeletePopupDialog, {
            data: { name:removedUser.fullName, email:removedUser.email, isCurrentUser: removedUser.uuid == this.userId}
        }).afterClosed().subscribe((confirm)=>{
        if(confirm)
            this.caseAccessService.search({where:{filters:{case_uuid:this.caseDetail.uuid, successAccount_uuid:removedUser.uuid}}}).subscribe((res)=>{
                if(this.entityForm.controls['successAccount_uuid'].value == removedUser.uuid){
                    this.entityForm.controls['successAccount_uuid'].reset();
                    this.caseDetail.successAccount_uuid = "";
                    this.primaryUser = undefined;
                    this.resourceService.get(this.caseDetail.uuid).subscribe((res)=>{
                        res.successAccount_uuid = '';
                        this.resourceService.updateCase(res).subscribe(()=>{});
                    })
                }
                res.results[0].deletedAt = new Date();
                this.caseAccessService.push(res.results[0]).subscribe(()=>{
                    let ind = this.userSource.data.findIndex((user)=> user.uuid == removedUser.uuid);
                    this.userSource.data.splice(ind,1);
                    this.userSource.data = this.userSource.sortData(this.userSource.data, this.userSort);
                    this.nonPermittedUsers.push(removedUser);
                    this.nonPermittedUsers.sort((a,b)=> {
                        if(a.firstName == b.firstName) return 0
                        return a.firstName.toUpperCase() < b.firstName.toUpperCase() ? -1 : 1
                    })
                })
            })
        })
    }

    addPermittedUser(){
        if(!this.selectedUser)
            return;
        let added = this.selectedUser;
        let newAccess = new SuccessCaseAccess({case_uuid:this.caseDetail.uuid, successAccount_uuid:added.uuid});
        this.caseAccessService.search({where:{filters:{case_uuid:this.caseDetail.uuid, successAccount_uuid:added.uuid}}}).subscribe((res)=>{
            if(res.results[0] && res.results[0].deletedAt != null){
                newAccess = res.results[0];
                newAccess.deletedAt = null;
            }
            this.caseAccessService.push(newAccess).subscribe(()=>{
                this.nonPermittedUsers.splice(this.permittedUserCtrl.value,1) ;
                this.userSource.data.push(added);
                this.userSource.data = this.userSource.sortData(this.userSource.data, this.userSort);
                this.permittedUserCtrl.reset();
                this.selectedUser = undefined;
            })
        })
    }

    deleteParticipant(entity:SuccessContactWithType){
        let entry = new SuccessCaseContact();
        Object.assign(entry, entity);
        this.caseContactService.search({where:{filters:{uuid:entity.caseContact_uuid}}}).subscribe((res)=>{
            if(res.results[0])
                entry = res.results[0];
            entry.isDeleted = true;
            entry.deletedAt = new Date();
            this.caseContactService.push(entry).subscribe(()=>{
                let data = this.participantSource.data.filter((contact)=> contact.uuid != entry.contact_uuid);
                this.participantSource.data = [];
                this.participantSource.data = this.participantSource._filterData(data);
                this.selectedUser = undefined;
            })
        })
    }

    editParticipant(participant?:SuccessContactWithType){
        let entity : SuccessContactWithType = participant ? participant : {uuid:""};
        this.dialog.open(ParticipantEditPopupDialog, {
            data: entity
        }).afterClosed().subscribe((saved: SuccessContactWithType | undefined)=>{
            if(saved && saved.isOrganization == true){
                saved.firstName = undefined;
                saved.lastName = undefined;
            }
            if(saved && !saved.uuid){
                let contact = new SuccessContact();
                let caseContact = new SuccessCaseContact();
                Object.assign(contact, saved);
                this.contactService.push(contact).subscribe((newContact:SuccessContact)=> {
                    Object.assign(caseContact, {'case_uuid':this.caseDetail.uuid, 'contact_uuid':newContact.id.toString(), 'contactType':saved.contactType });
                    this.caseContactService.push(caseContact).subscribe((res)=>{
                        saved.caseContact_uuid = res.id.toString();
                        this.participantSource.data.push(saved);
                        this.participantSource.data = this.participantSource.sortData(this.participantSource.data, this.participantSort);
                    })        
                })
            }else if(saved && saved.uuid){
                let contact = new SuccessContact();
                Object.assign(contact, saved);
                this.contactService.push(contact).subscribe(()=> {
                    let dataInd = this.participantSource.data.findIndex((participant)=> participant.uuid == saved.uuid);
                    this.participantSource.data[dataInd] = saved;
                    this.participantSource.data = this.participantSource.sortData(this.participantSource.data, this.participantSort);
                })
            }
        })
    }

    checkForms(){
        let pristine = true;
        if(this.entityForm.controls['successAccount_uuid'].value){
            this.primaryUser = this.allUsers.find((entry) => entry.id == this.entityForm.controls['successAccount_uuid'].value);
        } else{
            this.primaryUser = undefined;
        }

        let formErrors = Object.entries(this.entityForm.controls).find((res)=>{
            if(res[1].validator)
                pristine = false;
            return res[1].errors != null;
        })
        if(formErrors || pristine)
            return false;

        return true;
    }

    save(): void {
        if(!this.checkForms()){
            this.setBreadCrumbs();
            return
        }
        this.isLoading = true;

        Object.assign(this.caseDetail, this.entityForm.value);
        if(!this.caseDetail.uuid){
            this.caseDetail.corpId = this.corpId;
            this.entityService.insertCase(this.caseDetail).subscribe((result:SuccessCase)=>{
                this.noCaseFound = false;
                this.caseDetail = result;
                this.entityForm = this.resourceFormService.from(this.caseDetail);
                this.entityForm.controls['uuid'].setValue(this.caseDetail.uuid);

                if(!this.entityForm.controls['id']){
                    this.entityForm.addControl("id", new FormControl(this.caseDetail.uuid));
                }

                if(this.caseDetail.successAccount_uuid){
                    let userPermitted = new SuccessCaseAccess();
                    let userIndex = this.nonPermittedUsers.findIndex((account) => account.uuid == this.caseDetail.successAccount_uuid);
                    let newPrimaryUser = this.nonPermittedUsers.splice(userIndex,1);
                    if(newPrimaryUser[0]){
                        this.userSource.data.push(newPrimaryUser[0]);
                        this.userSort = new MatSort();
                        this.userSource.data = this.userSource.sortData(this.userSource.data, this.userSort);
                        Object.assign(userPermitted, {case_uuid:this.caseDetail.uuid, successAccount_uuid:this.caseDetail.successAccount_uuid});
                        this.caseAccessService.push(userPermitted).subscribe(()=>{});
                    }
                }
                this.createNotice('Create', undefined);
                this.setBreadCrumbs();
                this.isLoading = false;
                this.snackbar.open('Case Saved', undefined, { duration: 2000, verticalPosition: 'top', horizontalPosition: 'right'});
            })
        }
        else{
            if(!this.caseDetail.corpId)
                this.caseDetail.corpId = this.corpId;
            this.entityService.updateCase(this.caseDetail).subscribe((result:SuccessCase)=>{
                this.noCaseFound = false;
                this.caseDetail = result;
                let sharedUsers = new Array<SuccessAccount>();

                if(!this.entityForm.controls['id']){
                    this.entityForm.addControl("id", new FormControl(this.caseDetail.uuid));
                }

                // Access not found, then access deleted, or just not listed properly on view
                let foundUser = this.userSource.data.find((account)=>{account.uuid == this.caseDetail.successAccount_uuid});
                if(!foundUser){
                    this.caseAccessService.search({where:{filters:{case_uuid:this.caseDetail.uuid, successAccount_uuid:this.caseDetail.successAccount_uuid}}}).subscribe((res)=>{
                        if(!res.results[0]){
                            let permittedUser = new SuccessCaseAccess({case_uuid:this.caseDetail.uuid, successAccount_uuid:this.caseDetail.successAccount_uuid});
                            this.caseAccessService.push(permittedUser).subscribe(()=>{});
                        }else if(res.results[0].deletedAt != null){
                            let permittedUser = res.results[0];
                            permittedUser.deletedAt = null;
                            this.caseAccessService.push(permittedUser).subscribe(()=>{});
                        }
                        else{
                            let userIndex = this.nonPermittedUsers.findIndex((account) => account.uuid == this.caseDetail.successAccount_uuid);
                            if(userIndex != -1){
                                let newPrimaryUser = this.nonPermittedUsers.splice(userIndex,1);
                                this.userSource.data.push(newPrimaryUser[0]);
                                this.userSource.data = this.userSource.sortData(this.userSource.data, this.userSort);
                            }
                        }
                    })
                }

                sharedUsers = this.userSource.data.map(user => {return user});
                this.createNotice('Update', sharedUsers);
                this.setBreadCrumbs();
                this.isLoading = false;
                this.snackbar.open('Case Saved', undefined, { duration: 2000, verticalPosition: 'top', horizontalPosition: 'right'});
            })
        }
    }

    deleteEntry(){
        this.openConfirmDeleteDialog()
            .subscribe(
                response => {
                    if (response.confirmed) {
                        this.caseDetail.deletedAt = new Date();
                        this.entityService.updateCase(this.caseDetail).subscribe((res)=>{
                            this.entityForm = this.resourceFormService.from(this.caseDetail);
                            this.entityForm.value.deletedAt = new Date();
                            this.entityForm.value.isDeleted = true;
                            this.setBreadCrumbs();
                        })
                    }
                }
            )
    }

    restoreEntry(){
        this.dialog.open(CaseRestoreDialog, {
            data: this.caseDetail
        }).afterClosed().subscribe(response => {
            if (response) {
                this.caseDetail.deletedAt = null;
                this.entityService.updateCase(this.caseDetail).subscribe(()=>{
                    this.entityForm = this.resourceFormService.from(this.caseDetail);
                    this.entityForm.value.deletedAt = null;
                    this.entityForm.value.isDeleted = false;
                    this.setBreadCrumbs();
                })
            }
        })
    }

    createNotice(type:string, bcc?:SuccessAccount[]){
        let loc = "https://app." + ReflexEnvironment.config['resourcePrefix'] + ".firstlegal.com";
        let url = loc + `/case/` + this.caseDetail.uuid;
        if(!this.sendNotificationCtrl.value)
            return
        let notice = new Notification();
        let pacTime: Date | string = new Date();

        if(bcc){
            bcc = bcc.filter((c)=>{
                return c.uuid != this.userId
            })
            notice.emailBCC = bcc.map((c)=>{return c.email}).join(',');
        }

        pacTime = pacTime.toLocaleString('en-US', {timeZone: 'America/Los_Angeles'});
        let json = {
            CaseLink: loc,
            CaseName: `<a href="` + url + `" target="_blank">` + this.caseDetail.name + `</a>`,
            CaseNumber: this.caseDetail.caseNumber,
            ClientMatter: this.caseDetail.clientMatterNumber,
            CourtName: this.courtAddress?.name,
            PrimaryUser: this.allUsers.find((user)=> this.caseDetail.successAccount_uuid == user.uuid)?.fullName || 'n/a',
            CourtJurisdiction: (this.courtAddress?.jurisdiction ? this.courtAddress.jurisdiction : "n/a"),
            CourtCounty: (this.courtAddress?.county ? this.courtAddress.county : "n/a"),
            CourtAddress: this.courtAddress?.streetAddress1 + (this.courtAddress?.streetAddress2 ?  this.courtAddress.streetAddress2 : ''),
            CourtPhone : this.courtAddress?.primaryPhoneNumber + (this.courtAddress?.phoneExt ? ("+" + this.courtAddress.phoneExt) : ""),
            UpdateDate: (pacTime),
            Comments : 'n/a',
            // Participants currently disabled
            // AttorneyList : '',
            // PlaintiffList: '',
            // DefendantList: '',
            OrderList: ''
        }
        if(type == "Create"){
            this.auth.userData$.subscribe(({userData, allUserData}) => {
                let res = userData;
                let userEmail = res.profile.email;
                notice.notificationTypeId = this.createCaseNotificationType.getValue().uuid;
                notice.emailTo = userEmail;
                notice.userId = this.userId;
                Object.assign(json, {CreateDate: (pacTime)});
                notice.jsonData = JSON.stringify(json);
                this.notificationService.push(notice).subscribe((res)=>{});
            })
            return
        }
        
        if(type == "Update"){
            let userEmail = "";
            this.auth.userData$.subscribe(({userData, allUserData}) => {
                let res = userData;
                userEmail = res.profile.email;
                notice.notificationTypeId = this.updateCaseNotificationType.getValue().uuid;
                notice.emailTo = userEmail;
                notice.userId = this.userId;
                Object.assign(json, {UpdateDate: (pacTime)});
                json.OrderList = this.orderSource.data.map((order)=> {
                    let url = loc + `/order/` + order.order.uuid;                    
                    let string = (order.order.orderNumber ? `<a href="` + url + `" target="_blank">` + order.order.orderNumber + `</a>` + ' ': '');
                    string += (order.order.orderStatus ? "Status: " + order.order.orderStatus + ' ' : 'Status: Unknown');
                    string += (order.order.orderDateUTC ? "<br>Ordered: " + (new Date(order.order.orderDateUTC).toLocaleString('en-US', {timeZone: 'America/Los_Angeles'})) : '');
                    return string;
                }).join('<br><br>');
                
                notice.jsonData = JSON.stringify(json);
                this.notificationService.push(notice).subscribe((res)=>{});
            })
        }
    }

    resetFields(controlGroup:FormGroup, control?:FormControl){
        if(control)
            control.reset();
        controlGroup.reset();
    }

/*
    - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    -
    -   Case and court
    -
    - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
*/
    // spliceString(text:string, separator:string, maxLength:number){
    //     let arg : Array<string> = [];
    //     let q : number | string = Number.parseInt(((text.length/maxLength)).toFixed(0));
    //     if(q == 0)
    //         arg.push(text);
    //     for(let x = 0; x < q ; x++){
    //         arg.push(text.substring(maxLength*x, maxLength*(x+1)));
    //     }
    //     if(q == 0)
    //         q++;
    //     return {text:arg.join(separator), length:q};
    // }

    // quickScale(pos:number, max:number, doc:jsPDF){
    //     if(pos + 20 >= max - 10){
    //         doc.addPage();
    //         pos = 25;
    //     }
    //     return pos;
    // }

    // public saveReviewToPDF(includeOrders?:boolean) {
    //     // let testing = true
    //     // if(!testing){
    //     //     const doc = new jsPDF('p', 'pt', [1200,800])
    //     //     // let res = document.querySelector('#order-pdf') as HTMLElement
    //     //     this.checkForms()
    //     //     let reviewHTML = document.querySelector('#review-pdf') as HTMLElement
    //     //     if(includeOrders)
    //     //         reviewHTML = document.querySelector('#greater-pdf') as HTMLElement

    //     //     if(!reviewHTML) return;
    //     //     doc.html(reviewHTML, {
    //     //         // windowWidth: 800,
    //     //         width:800,
    //     //         autoPaging:'text',
    //     //         margin: [20,40,20,40],
    //     //         callback: (doc) => {
    //     //         doc.save("case-review.pdf")
    //     //         }
    //     //     })
    //     // }else{
    //     // }
    //     // 210 x 297 a4 mm
    //     const doc = new jsPDF();

    //     // doc.addFont('ArialUnicodeMS', 'Arial', 'normal');
    //     let defaultFontColor = "#0F1319";
    //     let fieldFontColor = "#444444";
    //     doc.setTextColor(defaultFontColor);

    //     let rowPosition = 10;
    //     let pageWidth = 200;
    //     let pageHeight = 295;
    //     let margin = 5;
    //     // We'll use a 4 column grid
    //     let columnWidth = pageWidth / 4;
    //     let columnPadding = 4;
    //     let column1 = margin;
    //     let column2 = column1 + columnWidth;
    //     let column3 = column2 + columnWidth;
    //     let column4 = column3 + columnWidth;

    //     // Short hands for formatting
    //     let h1 = (): jsPDF => doc.setFontSize(16);
    //     let h2 = (): jsPDF => doc.setFontSize(12).setTextColor("#FFFFFF").setFillColor("#12426A").rect(margin, rowPosition - 5, pageWidth, 8, "F");
    //     let fl = (): jsPDF => doc.setFontSize(10).setTextColor(defaultFontColor);
    //     let ff = (): jsPDF => doc.setFontSize(10).setTextColor(fieldFontColor).setDrawColor(fieldFontColor);
    //     let th = (): jsPDF => doc.setFontSize(8).setTextColor(defaultFontColor);
    //     let td = (): jsPDF => doc.setFontSize(10).setTextColor(fieldFontColor);

    //     // Title
    //     h1().text("Case Form", column1, rowPosition);

    //     fl().setFontSize(18).text((this.entityForm.value.caseNumber || 0).toString(), pageWidth, rowPosition, { align: "right" });

    //     //  Title
    //     rowPosition += 15;
    //     h2().text("Case Information", pageWidth / 2, rowPosition + 1, { align: "center" });

    //     // Case Name
    //     rowPosition += 10;
    //     let res = this.spliceString(this.entityForm.value.name, '\n', 50)
    //     fl().text("Case Name", column1 + columnPadding, rowPosition);
    //     ff().text(res.text || '', column2 + columnPadding, rowPosition);
    //     rowPosition += 4*res.length - 4
    //     doc.line(column2, rowPosition + 1, column4, rowPosition + 1);

    //     // Case Number
    //     rowPosition += 8;
    //     fl().text("Case Number", column1 + columnPadding, rowPosition);
    //     ff().text(this.entityForm.value.caseNumber || '', column2 + columnPadding, rowPosition);
    //     doc.line(column2, rowPosition + 1, column4, rowPosition + 1);

    //     // Client Matter
    //     rowPosition += 8;
    //     fl().text("Client Matter ", column1 + columnPadding, rowPosition);
    //     ff().text(this.entityForm.value.clientMatterNumber || '', column2 + columnPadding, rowPosition);
    //     doc.line(column2, rowPosition + 1, column4, rowPosition + 1);

    //     // Case Owner
    //     let selectedUser : any = this.allUsers.find((user)=> user.uuid == this.entityForm.value.successAccount_uuid)
    //     if(selectedUser)
    //         selectedUser = selectedUser.firstName + ' ' + selectedUser.lastName
    //     rowPosition += 8;
    //     fl().text("Case Owner ", column1 + columnPadding, rowPosition);
    //     ff().text(selectedUser || '', column2 + columnPadding, rowPosition);
    //     doc.line(column2, rowPosition + 1, column4, rowPosition + 1);
    //     rowPosition += 8;


    //     // Court Address
    //     if(this.courtAddress){
    //         let address = this.courtAddress.name + '\n' + this.courtAddress.streetAddress1 + '\n' + this.courtAddress.city + ', ' + this.courtAddress.state + ' ' + this.courtAddress.postalCode
    //         fl().text("Court Address", column1 + columnPadding, rowPosition);
    //         ff().text(address  || '', column2 + columnPadding, rowPosition);
    //         doc.line(column2, rowPosition + 1, column4, rowPosition + 1);
    //         rowPosition += 4;
    //         doc.line(column2, rowPosition + 1, column4, rowPosition + 1);
    //         rowPosition += 4;
    //         doc.line(column2, rowPosition + 1, column4, rowPosition + 1);
    //         rowPosition += 6;

    //         // fl().text("Court", column1 + columnPadding, rowPosition);
    //         // if (this.courtAddress.name) {
    //         //     rowPosition += 6;
    //         //     fl().text("Court Name", column1 + columnPadding*4, rowPosition);
    //         //     ff().text(this.courtAddress.name || '', column2 + columnPadding, rowPosition);
    //         //     doc.line(column2, rowPosition + 1, column4, rowPosition + 1);
    //         // }
    
    //         // if (this.courtAddress.streetAddress1) {
    //         //     rowPosition += 6;
    //         //     fl().text("Court Address", column1 + columnPadding*4, rowPosition);
    //         //     ff().text(this.courtAddress.streetAddress1 || this.courtAddress.streetAddress2 || '', column2 + columnPadding, rowPosition);
    //         //     doc.line(column2, rowPosition + 1, column4, rowPosition + 1);
    //         // }
    
    //         // if (this.courtAddress.city) {
    //         //     rowPosition += 6;
    //         //     fl().text("Court Location", column1 + columnPadding*4, rowPosition);
    //         //     ff().text([this.courtAddress.city + ", "  + this.courtAddress.state + (this.courtAddress.county || '') + (this.courtAddress.jurisdiction || '') + ', ' + (this.courtAddress.postalCode || '')] || '', column2 + columnPadding, rowPosition);
    //         //     doc.line(column2, rowPosition + 1, column4, rowPosition + 1);
    //         // }
    //         // rowPosition += 8;
    //     }
    //     let op = true;
    //     // // Case Parties
    //     // Participants currently disabled
    //     // rowPosition += 14;
    //     // h2().text("Parties Information", pageWidth / 2, rowPosition, { align: "center" });

    //     // rowPosition += 10;
    //     // fl().text("Attorneys", column1 + columnPadding, rowPosition);
    //     // rowPosition += 6;
    //     // this.caseAttorneys.map((attorney)=>{
    //     //     rowPosition = this.quickScale(rowPosition, pageHeight, doc)           
    //     //     op = !op
    //     //     if(op){
    //     //         doc.setFillColor(0,0,0,0.2).rect(columnPadding, rowPosition-4, pageWidth, 18, "F")
    //     //     }
    //     //     fl().text("Attorney #" + attorney.barId, column1 + columnPadding, rowPosition);
    //     //     rowPosition += 6;

    //     //     fl().text("Name", column1 + columnPadding*4, rowPosition);
    //     //     ff().text(attorney.firstName  || '' + attorney.lastName || '', column2 + columnPadding, rowPosition);
    //     //     rowPosition += 6;

    //     //     fl().text("Email", column1 + columnPadding*4, rowPosition);
    //     //     ff().text(attorney.email || attorney.email || '', column2 + columnPadding, rowPosition);
    //     //     rowPosition += 7;

    //     //     rowPosition = this.quickScale(rowPosition, pageHeight, doc)           
    //     // })
    //     // rowPosition += 6;

    //     // fl().text("Plaintiffs", column1 + columnPadding, rowPosition);
    //     // rowPosition += 6;
    //     // op = true
    //     // this.casePlaintiffs.map((plaintiff)=>{
    //     //     rowPosition = this.quickScale(rowPosition, pageHeight, doc)           
    //     //     op = !op
    //     //     if(op){
    //     //         doc.setFillColor(0,0,0,0.2).rect(columnPadding, rowPosition-4, pageWidth, 18, "F")
    //     //     }
    //     //     fl().text("Plaintiff: " + plaintiff.firstName  || '' + plaintiff.lastName || '', column1 + columnPadding, rowPosition);
    //     //     rowPosition += 6;

    //     //     fl().text("Address", column1 + columnPadding*4, rowPosition);
    //     //     ff().text(plaintiff.address  || '', column2 + columnPadding, rowPosition);
    //     //     rowPosition += 6;

    //     //     fl().text("Email", column1 + columnPadding*4, rowPosition);
    //     //     ff().text(plaintiff.email || plaintiff.email || '', column2 + columnPadding, rowPosition);
    //     //     rowPosition += 7;

    //     //     rowPosition = this.quickScale(rowPosition, pageHeight, doc)           
    //     // })
    //     // rowPosition += 6;

    //     // fl().text("Defendants", column1 + columnPadding, rowPosition);
    //     // rowPosition += 6;
    //     // op = true
    //     // this.caseDefendants.map((defendant)=>{
    //     //     rowPosition = this.quickScale(rowPosition, pageHeight, doc)           
    //     //     op = !op
    //     //     if(op){
    //     //         doc.setFillColor(0,0,0,0.2).rect(columnPadding, rowPosition-4, pageWidth, 18, "F")
    //     //     }
    //     //     fl().text("Defendant: " + defendant.firstName  || '' + defendant.lastName || '', column1 + columnPadding, rowPosition);
    //     //     rowPosition += 6;

    //     //     fl().text("Address", column1 + columnPadding*4, rowPosition);
    //     //     ff().text(defendant.address  || '', column2 + columnPadding, rowPosition);
    //     //     rowPosition += 6;

    //     //     fl().text("Email", column1 + columnPadding*4, rowPosition);
    //     //     ff().text(defendant.email || defendant.email || '', column2 + columnPadding, rowPosition);
    //     //     rowPosition += 7;       
            
    //     //     rowPosition = this.quickScale(rowPosition, pageHeight, doc)           
    //     // })


    //     if(!includeOrders || this.orderSource.data.length == 0){
    //         // Output PDF (default is in new tab)

    //         let ca = new Image();
    //         let src = "../../../../assets/FLN-logo-Corp-cm-ww 1.png";
    //         ca.src = src;
    //         let totalPages = doc.getNumberOfPages();
    //         for (let i = 1; i <= totalPages; i++) {
    //             doc.setPage(i);
    //             doc.setFillColor('#12426A').rect(153, 3, 52, 14, "F");
    //             doc.addImage(ca, 'PNG', 154, 4, 50, 12);
    //             rowPosition = 285;
    //             h2().text("Private Investigator Licenses: CA PI: 24171 AZ PI: 1551710 NV PI-PS: 1452" + (totalPages > 1 ? "  Page " + i + " of " + totalPages : ""), column1 + columnPadding, rowPosition);
    //         }
    
    //         let filename = "FirstLegal-Client-" + this.entityForm.value.name.replace(/[^\w\s]+/ig, '').replace(/\s+/g, '_') + '.pdf';
    //         doc.output('pdfobjectnewwindow', { filename: filename });
    //         return
    //     }

    //     rowPosition += 6;

    //     h2().text("Order Information", pageWidth / 2, rowPosition + 1, { align: "center" });
    //     rowPosition += 10;
    //     op = true;
    //     this.orderSource.data.map((extendedOrder)=>{
    //         let x = new Order();
    //         let order = extendedOrder.order;
    //         rowPosition = this.quickScale(rowPosition, pageHeight, doc);
    //         op = !op;
    //         let rectLength = 21;
    //         if(op){
    //             if(order.courtName)
    //                 rectLength += 12;
    //             doc.setFillColor(0,0,0,0.2).rect(columnPadding, rowPosition-4, pageWidth, rectLength, "F");
    //         }
    //         // fl().text("Order #" + order.orderNumber || '' + ": " + order.name || '', column1 + columnPadding, rowPosition);
    //         let loc = "https://app." + ReflexEnvironment.config['resourcePrefix'] + ".firstlegal.com" + "/order/" + order.uuid;
    //         fl().textWithLink("Order #" + (order.orderNumber || '' + ": " + order.name || ''), column1 + columnPadding, rowPosition, {url : loc});
    //         rowPosition += 6;
    //         fl().text("Service Line", column1 + columnPadding*4, rowPosition);
    //         ff().text(extendedOrder.serviceType || '', column2 + columnPadding, rowPosition);
    //         doc.line(column2, rowPosition + 1, column4, rowPosition + 1);
    //         rowPosition += 7;
    //         rowPosition = this.quickScale(rowPosition, pageHeight, doc);

    //         if(order.courtName){
    //             let address = order.courtName + '\n' + order.courtAddress + '\n' + order.courtCity + ', ' + order.courtState + ' ' + order.courtZip;
    //             fl().text("Court Address", column1 + columnPadding*4, rowPosition);
    //             ff().text(address  || '', column2 + columnPadding, rowPosition);
    //             doc.line(column2, rowPosition + 1, column4, rowPosition + 1);
    //             rowPosition += 4;
    //             doc.line(column2, rowPosition + 1, column4, rowPosition + 1);
    //             rowPosition += 4;
    //             doc.line(column2, rowPosition + 1, column4, rowPosition + 1);
    //             rowPosition += 6;
    //         }

    //         fl().text("Email", column1 + columnPadding*4, rowPosition);
    //         ff().text(order.placedByEmail || order.placedByEmail || '', column2 + columnPadding, rowPosition);
    //         doc.line(column2, rowPosition + 1, column4, rowPosition + 1);
    //         rowPosition += 7;
    //         rowPosition = this.quickScale(rowPosition, pageHeight, doc);
    //     })

    //     let ca = new Image();
    //     let src = "../../../../assets/FLN-logo-Corp-cm-ww 1.png";
    //     ca.src = src;
    //     let totalPages = doc.getNumberOfPages();
    //     for (let i = 1; i <= totalPages; i++) {
    //         doc.setPage(i);
    //         doc.setFillColor('#12426A').rect(153, 3, 52, 14, "F");
    //         doc.addImage(ca, 'PNG', 154, 4, 50, 12);
    //         rowPosition = 285;
    //         h2().text("Private Investigator Licenses: CA PI: 24171 AZ PI: 1551710 NV PI-PS: 1452" + (totalPages > 1 ? "  Page " + i + " of " + totalPages : "")
    //         , column1 + columnPadding, rowPosition);
    //     }


    //     let filename = "FirstLegal-Client-" + this.entityForm.value.name.replace(/[^\w\s]+/ig, '').replace(/\s+/g, '_') + '.pdf';
    //     doc.output('pdfobjectnewwindow', { filename: filename });
    //     return

    //  }
}

enum KnownNotificationTypes {
    SuccessCaseCreate = "SUCCESS_CASE_CREATE",
    SuccessCaseUpdate = "SUCCESS_CASE_UPDATE"
}

interface DocumentOrder {
    document: Document,
    orderUuid: string,
    orderNumber: string
}

interface ExtendedOrder {
    order: Order,
    service: string,
    serviceType: string,
    serviceCategory: string
}
