import { Injectable } from "@angular/core";
import { ResourceService } from "@smartsoftware/reflex-core";
import { SuccessState } from "../models/state.model";
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";


@Injectable()
export class SuccessState_Service extends ResourceService<SuccessState>{
    protected servicePath: string = '/State';
    public ModelType = SuccessState;

    constructor( protected http: HttpClient ){
        super(http);
    }

    public getAll() {
        return this.http.get<SuccessState[]>(`${this.serviceUrl}getAll`)
            .pipe(map(states => states.map(doc => new SuccessState(doc))));
    }
}