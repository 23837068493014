import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { SuccessAccount_Service, UserPermission_Service, SuccessAccountSettingsService } from '../../services';
import { Day, ContactTrigger, ContactFrequency, ContactPreference, SuccessAccountSettings,SuccessAccount} from '../../models';
const { convertMinutesToTimeString, convertTimeStringToMinutes } = SuccessAccountSettings;

@Component({
  selector: 'lib-special-instruction-preferences',
  templateUrl: './special-instruction-preferences.component.html',
  styleUrls: ['./special-instruction-preferences.component.scss']
})
export class SpecialInstructionPreferencesComponent implements OnInit {
    @Input()
    public user: SuccessAccount | null | undefined;
    @Input()
    public settings: SuccessAccountSettings = new SuccessAccountSettings();
    @Output()
    public onSave = new EventEmitter<SuccessAccountSettings>();
  
    Day = Day;
    ContactPreference = ContactPreference;
    ContactFrequency = ContactFrequency;
    ContactTrigger = ContactTrigger;

    form: FormGroup;

    get invalid() {
        return this.form.invalid;
    }

    constructor(
        // indirectly call syncUser by injecting the service, lol
        private userPermissionservice: UserPermission_Service,
        private successAccountService: SuccessAccount_Service,
        private successAccountSettingsService: SuccessAccountSettingsService,
        private fb: FormBuilder,
        private snackBar: MatSnackBar,
    ) {
        // set defaults from model
        let settings = this.settings;

        this.form = this.fb.group({
            casePreferenceAdvanceFees: [settings.casePreferenceAdvanceFees],
            casePreferenceAlwaysDeliverCourtesyFeesBack: [settings.casePreferenceAlwaysDeliverCourtesyFeesBack]
        });

    }
    ngOnInit(): void {
        this.initForm();
    }

    async initForm() {
        if (!this.user) return;
        let settings = this.settings;
        //specialInstructions
        this.form.get('casePreferenceAdvanceFees')?.setValue(settings.casePreferenceAdvanceFees);
        this.form.get('casePreferenceAlwaysDeliverCourtesyFeesBack')?.setValue(settings.casePreferenceAlwaysDeliverCourtesyFeesBack);

    }


    get hasNoContactHours() {
        return this.form.get('hasNoContactHours');
    }

    bindFormValuesToSettings() {
        console.log('form', this.form);
        
        let settings = this.settings;

        //specialInstructions
        settings.casePreferenceAdvanceFees = this.form.get('casePreferenceAdvanceFees')?.value;
        settings.casePreferenceAlwaysDeliverCourtesyFeesBack = this.form.get('casePreferenceAlwaysDeliverCourtesyFeesBack')?.value;

        // noContactFromTime: [],
        // noContactToTime: []
        // console.debug('settings', settings);
        // this.successAccountService.saveSettings(settings)
        //     .subscribe({
        //         next: () => {
        //             this.onSave.emit(settings);
        //             this.snackBar.open('Preferences Saved', undefined, {duration: 4000, horizontalPosition: 'left', panelClass: 'snack-error'})
        //         },
        //         error: err => {
        //             let message = err.error?.error || 'Something went wrong saving the preferences';
        //             this.snackBar.open(message, undefined, {duration: 4000, horizontalPosition: 'left', panelClass: 'snack-error'})
        //             console.error(err);
        //         }
        // });
    }
}
