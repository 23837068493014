import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavigationModule, ResourceModule, AuthGuardService } from '@smartsoftware/reflex-core';
import { AestheticsModule } from '../../aesthetics/aesthetics.module';
import { ComponentsModule } from '../../components/components.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { MatInputModule } from '@angular/material/input';
import { AutoLoginPartialRoutesGuard as AuthGuard } from 'angular-auth-oidc-client';
import { ClientBranch_Service, ClientCompany_Service, Client_Service, LegalReflexLibModule } from 'legalreflex-lib';
import { OrderFrame } from './frame/frame';
import { OrderList } from './list/list';
import { OrderView } from './view/view';
import { OrderCreate } from './create/create';
import { OrderCreatedDialog } from './create/dialog/orderCreated';
import { DirectEFile } from './directEFile/directEFile';
import { OrderPrint_Service } from './orderPrint.service';
import { CanLeaveService } from 'legalreflex-lib';
import { InvalidDocumentDialog } from './create/dialog/invalidDocument';
import { DocumentNameChangedDialog } from './create/dialog/documentNameChanged';
const routes: Routes = [
    {
        path: 'order',
        component: OrderFrame,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: OrderList
            },
            {
                path: 'new',
                component: OrderCreate,
                canDeactivate: [CanLeaveService]
            },
            {
                path: 'direct-efile',
                component: DirectEFile,
            },
            {
                path: 'clone/:id',
                component: OrderCreate,
                canDeactivate: [CanLeaveService]
            },
            {
                path: 'resume/:id',
                component: OrderCreate,
                canDeactivate: [CanLeaveService]
            },
            {
                path: 'cancel/:id',
                component: OrderView,
            },
            {
                path: ':id',
                component: OrderView,
            }
        ]
    }
];

@NgModule({
    declarations: [
        OrderFrame,
        OrderList,
        OrderView,
        OrderCreate,
        OrderCreatedDialog,
        InvalidDocumentDialog,
        DirectEFile,
        DocumentNameChangedDialog
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AestheticsModule,
        ComponentsModule,
        ResourceModule,
        BrowserModule,
        BrowserAnimationsModule,
        MatTooltipModule,
        RouterModule,
        LegalReflexLibModule,
        MatFormFieldModule,
        MatInputModule,
        MatStepperModule,

        RouterModule.forChild(routes)
    ],
    providers: [
        ClientBranch_Service,
        Client_Service,
        OrderPrint_Service,
        CanLeaveService,
    ],
    exports: [

    ],
    bootstrap: [

    ]
})
export class OrderModule { }
