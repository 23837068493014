import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { ClientPartner } from '../models/clientPartner.model';
import { ClientPartnerVendor } from '../models/clientPartnerVendor.model';
import { map } from 'rxjs/operators';

@Injectable()
export class ClientPartner_Service extends ResourceService<ClientPartner> {
    protected servicePath: string = '/ClientPartner';

    public ModelType = ClientPartner;
    

    public getClientPartnerVendorByUUID(id: string) {
        let params = new HttpParams().set('uuid', id);

        return this.http.get<ClientPartnerVendor[]>(`${this.serviceUrl}getClientPartnerVendorByUUID`,{params})
            .pipe(map(docs => docs.map(doc => new ClientPartnerVendor(doc))));
    }


    public getClientPartnersByClientUUID(id: string) {
        let params = new HttpParams().set('uuid', id);

        return this.http.get<ClientPartner[]>(`${this.serviceUrl}getClientPartnersByClientUUID`,{params})
            .pipe(map(docs => docs.map(doc => new ClientPartner(doc))));
    }

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }
}
