
<div class="app-lookup-container"
    (focusin)="onFocusIn($event)"
    (focusout)="onFocusOut($event)">
    <input
        type="text"
        placeholder="Pick one"
        aria-label="autoComplete"
        matInput
        (keyup)="checkIfEmpty()"
        [formControl]="control"
        [matAutocomplete]="auto"
        [attr.disabled]="disabled || null"
        [readonly]="readonly"
        (focusout)="onInputBoxFocusOut($event)"/>
    <mat-autocomplete #auto="matAutocomplete" 
        [displayWith]="displayWith" 
        (optionSelected)="selected = $event.option.value">
        <mat-option *ngFor="let option of suggestions$ | async" [value]="option">
            {{displayWith(option)}}
        </mat-option>
    </mat-autocomplete>
</div>
