import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { SuccessContact } from '../models/successContact.model';
import { Observable } from 'rxjs';
import { Page, PageParams } from '../paged.datasource';
import { map } from 'rxjs/operators';

@Injectable()
export class SuccessContact_Service extends ResourceService<SuccessContact> {
    protected servicePath: string = '/SuccessContact';

    public ModelType = SuccessContact;

    page(pageParams: PageParams): Observable<Page<SuccessContact>> {
        let params = new HttpParams()
            .append('params', btoa(JSON.stringify(pageParams)));
    
        return this.http.get<Page<SuccessContact>>(`${this.serviceUrl}Page`, { params })
            .pipe(map(page => {
                page.items = page.items.map(i => new SuccessContact(i));
                return page;
            }));
    }

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }
}
