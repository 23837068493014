import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class ClientMatterFtpLog extends DexResourceModel {

    @Field(String)
    public clientMatterSet_uuid: string | null = null;
    
    @Field(String)
    public fullUri: string = "";
    
    @Field(Boolean)
    public wasSuccessful: number = 0;

    @Field(String)
    public statusMessage: string = "";

    //public statusMessageShort: string = "";

	public set StatusMessageShort(msg : string){
		if(this.statusMessage.length > 400)
		this.StatusMessageShort = this.statusMessage.slice(0,399);
	else
		this.StatusMessageShort = this.statusMessage;
	}

    public get StatusMessageShort(){
        if(this.statusMessage.length > 400)
		return this.statusMessage.slice(0,399);
	else
		return this.statusMessage;
    }


    public date: string | undefined = this.createdAtUTC?.toString();

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}
