import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ResourceService } from '@smartsoftware/reflex-core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

export type EmailUtilityError = {
    message: string;
    salesAccountId: number;

    didEmail: boolean;
    didNote: boolean;
}

export type EmailUtilityResponse = {
    success: boolean;

    error?: string;
    errors?: EmailUtilityError[];
}

@Injectable()
export class Mailing_Service extends ResourceService<any> {
    protected servicePath: string = '/mailing';

    constructor(
        protected http: HttpClient,
        protected auth: OidcSecurityService,
    ) { 
        super(http);
    }

    public sendFLDS(userData: any, requestData: any): Observable<Object> {
        return this
            .http
            .post(
                this.serviceUrl + 'sendFLDS',
                {
                    userData: userData,
                    requestData: requestData        
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => 
                        response.body.result.map((e: Object) => new Object(e))
                )
            );
    }

    public sendFLRR(userData: any): Observable<Object> {
        return this
            .http
            .post(
                this.serviceUrl + 'sendFLRR',
                {
                    userData: userData,
                    // requestData: requestData        
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => 
                        response.body.result.map((e: Object) => new Object(e))
                )
            );
    }

    public sendFreshworksEmail(spreadsheet: any, emailTemplateId: string, chunkStart: number): Observable<EmailUtilityResponse> {
        return this
            .http
            .post(
                this.serviceUrl + 'sendFreshworksEmail',
                {
                    spreadsheet: spreadsheet,
                    emailTemplateId: emailTemplateId,
                    chunkStart: chunkStart
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => {
                        //console.log("sendFreshworksEmail response", response);
                        return response.body.result as EmailUtilityResponse;

                    }
                )
            );
    }

    // public sendEmail(ToAddresses:string[], Message:string, Subject:string, FromAddress:string): any {                
    //     return this
    //         .http
    //         .post(
    //             this.serviceUrl + 'sendEmail',
    //             {
    //                 ToAddresses: ToAddresses,
    //                 Message: Message,
    //                 Subject: Subject,
    //                 FromAddress: FromAddress
    //             },
    //             {
    //                 observe: 'response'
    //             }
    //         )
    //         .pipe(
    //             map(
    //                 (response: HttpResponse<any>) => 
    //                     response.body.result.map((e: Object) => new Object(e))
    //             )
    //         );
    // }
}
