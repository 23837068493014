import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ReflexEnvironment as env } from '@smartsoftware/reflex-core';

@Component({
  selector: 'page-notice',
  templateUrl: './page-notice.html',
  styleUrls: ['./page-notice.scss']
})
export class PageNoticeComponent implements OnInit {

    @Input('allowDismiss') 
    public allowDismiss: boolean = true;

    @Input('isDismissed') 
    @HostBinding('class.dismissed')
    public isDismissed: boolean = false;

    @Input('color') 
    public color: "primary" | "accent" | "warn" | "default" = "default";

    constructor() { }

    ngOnInit(): void {

    }

}
