import { Component, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms'
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ResourceEditComponent, ResourceFormService, ResourceListPane } from '@smartsoftware/reflex-core';
import { BehaviorSubject } from 'rxjs';

import{
  SuccessAccount,
  SuccessAccount_Service,
  SuccessAccountFirm_Service,
  ClientUser,
  ClientUser_Service,
  Client_Service,
  Client,
  UserPermission_Service
} from 'legalreflex-lib';
import { userDialog } from './userDialog';
@Component({
  templateUrl: './create.html',
  styleUrls: ['./create.scss']
})
export class SuccessAccountCreate implements OnInit, AfterViewInit {
  
  public disableCreate: boolean = false;
  public isFormComplete: boolean = false;
  public fileInfos: { complete: boolean, name: string }[] = [];
  public privateSwitchCtrl = new FormControl(true,null);
  public clientUsers$: BehaviorSubject<ClientUser[]> = new BehaviorSubject<ClientUser[]>([]);
  public firmAccounts$: BehaviorSubject<SuccessAccount[]> = new BehaviorSubject<SuccessAccount[]>([]);
  public clients$: BehaviorSubject<Client[]> = new BehaviorSubject<Client[]>([]);
  public accountId: string = 'NULL';
  protected corp_id: string = 'NULL';
  public selectedAccount: string = '';
  public client_uuid: string = '';

  public companyMap = new Map();
  isLoading: boolean = false;

  entityForm?: FormGroup;
  
  constructor(
    protected auth: OidcSecurityService,
        protected entityService: SuccessAccountFirm_Service,
        protected accountService: SuccessAccount_Service,
        protected route: ActivatedRoute,
        protected dialog: MatDialog,
        protected resourceFormService: ResourceFormService<SuccessAccount>,
        protected clientUserService: ClientUser_Service,
        protected ClientService: Client_Service,
        protected userPermissionService: UserPermission_Service,
        protected formBuilder: FormBuilder
  ) { 
        this.auth.userData$.subscribe(({userData, allUserData})=>{
          let response = userData;
          this.accountId = response.profile.uuid,
          this.corp_id = response.profile.corpId
        })

    }


    public get_ID(){
      return this.accountId;
    }
    public get_CorpID(){
      return this.corp_id
    }


    public add_ClientUser(){
      let client_uuid = this.clients$.value[0].uuid;  
      
      let itemData ={
          successAccount_uuid: this.selectedAccount,
          client_uuid : client_uuid
        };
        let item = new ClientUser(itemData);
        

        this.update_ClientUsers(item);

    }

    public update_ClientUsers(item: ClientUser){
      this
          .clientUserService
          .push(item)
          .subscribe(
            (item) => {
              if(!this.clientUsers$.value.find(c=>item.uuid == c.uuid)){
                this.clientUsers$.next([...this.clientUsers$.value, item]);
              }
            },
          );
          this.dialog.open(userDialog);
    }

    public get_ClientUUID(entity : SuccessAccount){
      if(!entity || (entity && !entity.uuid)){
        this.clients$.next([]);
        return;
      }
      this
        .ClientService
        .list({
          reloadFresh:true,
          where: {
            filters: {
                corp_id: {
                    value: entity.corpId,
                    operator: "="
                },
                deletedAt: {
                    value: null,
                    operator: "IS"
                }
            }
        }  
        })

        .subscribe((items) => {
          this.clients$.next(items);
          //maps accounts uuid to client companys
          for(let i=0; i<items.length; i++)
            this.companyMap.set(items[i].uuid, entity.uuid);
        });
    }

    public fetch_ClientUsers(entity: SuccessAccount){
      if(!entity || (entity && !entity.uuid)){
        this.clientUsers$.next([]);
        return;
      }
    
      this
          .clientUserService
          .list({
            reloadFresh: true,
            where: {
              filters: {
                  successAccount_uuid: {
                      value: entity.uuid,
                      operator: "="
                  },
                      
              }
          }
          })
          .subscribe(
            (items) => {
                this.clientUsers$.next(items);
            }
        )
    
    }

    public fetch_FirmAccounts(){
      this
          .entityService
          .list({
            reloadFresh: true,
            where: {
              includeDeleted:true,
              filters: {
                  corp_id: {
                      value:  this.corp_id,
                      operator: "="
                  },
                      
              }
          }
          })
          .subscribe(
            (items) => {
                this.firmAccounts$.next(items);
            }
        )
    
    }

    public fetch_RelatedData(entity: SuccessAccount){
      this.fetch_ClientUsers(entity);
      this.fetch_FirmAccounts();
    }
    
    //fetch client users
  ngOnInit(): void {

    this.entityForm = this.formBuilder.group({})
    

    this
        .ClientService
        .list({
          reloadFresh: true
        })
        .subscribe((records) => {
          this.clients$.next(records);
        });

      this
        .clientUserService
        .list({
          reloadFresh: true
        })
        .subscribe((records) => {
          this.clientUsers$.next(records);
        });    

    this.fetch_FirmAccounts();
    
  }

  ngAfterViewInit() {

}

  public save(){
    this.isLoading = true;
    this.disableCreate = true;

    if(this.accountId == "NULL")
      return;

        this.dialog.open(userDialog);
        

        this.isLoading = false;
        this.disableCreate = false;
        return
  }

}
