import {Component, Inject, Input } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { 
    SuccessAccount_Service,
    PASSWORD_MAX_LENGTH,
    PASSWORD_MIN_LENGTH,
    PASSWORD_PATTERN,
    PASSWORD_HINT
} from 'legalreflex-lib';

// const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}/gm;
// const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#\$%\^&\*\(\)_+-=<>,\.\?/\{\}\[\]])[A-Za-z\d~!@#\$%\^&\*\(\)_+-=<>,\.\?/\{\}\[\]]{8,}/gm;
// const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\d\D]{12,72}/gm;
const passwordRegex = PASSWORD_PATTERN // new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[\\d\\D]{" + PASSWORD_MIN_LENGTH + "," + PASSWORD_MAX_LENGTH + "}");

@Component({
  selector: 'password-prompt-dialog',
  templateUrl: 'passwordPrompt.html',
})
export class PasswordPromptDialog {

    @Input('badPassword')
    public badPassword: boolean = false;
    public badUsername: boolean = false;
    public passwordVisibility: 'password' | 'text' = 'password';
    public specialSymbolList =` ~!@#$%^&*()_-+=<>,.?/{}[]`;
    PASSWORD_MAX_LENGTH = PASSWORD_MAX_LENGTH;
    PASSWORD_MIN_LENGTH = PASSWORD_MIN_LENGTH;
    PASSWORD_HINT = PASSWORD_HINT;
    
    constructor(
        private dialogRef : MatDialogRef<PasswordPromptDialog>,
        private SuccessAccountService: SuccessAccount_Service,
        @Inject(MAT_DIALOG_DATA) public data: UserData
    ) {
        // console.log('TFTEST - user data', data);

        this.badUsername = data.editUsername;
    }

    togglePasswordVisibility() {
        this.passwordVisibility = this.passwordVisibility == 'password' ? 'text' : 'password';
    }

    get visibilityIcon() {
        return this.passwordVisibility == 'password' ? 'visibility_off' : 'visibility';
    }
    
    submit() {
        // console.log('TFTEST request access', this.data.username);
        
        // check the new username 
        this.SuccessAccountService.checkUsername({username: this.data.username}).subscribe(
            (response:any) => { 
                // console.log('TFTEST modal check username', response); 

                this.badUsername = (response[0] || this.data.username == '');
                // console.log('TFTEST raw response', response[0]);

                // this.badPassword = this.data.password.search(passwordRegex) == -1;
                this.badPassword = !passwordRegex.test(this.data.password);

                if(!this.badPassword && !this.badUsername) {
                    // console.log('TFTEST closing');
                    this.dialogRef.close(this.data);
                } 

                // console.log('TFTEST pass is bad', this.badPassword);
                // console.log('TFTEST user is bad', this.badUsername);
            },
            (error:any) => { 
                console.log('ERROR: checkUsername encountered a problem', error); 

                this.dialogRef.close(false);
            }
        )
    }
}

export interface UserData {
    username: string,
    password: string,
    editUsername: boolean
}