import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class User extends DexResourceModel {

    @Field(String)
    public warehouse_uuid: string | null = null;

    @Field(String)
    public o365_id: string = "";

    @Field(String)
    public email: string = "";

    @Field(String)
    public displayName: string = "";

    @Field(String)
    public pictureUrl: string = "";

    @Field(Date)
    public lastLoginAt: Date | null = null;

    @Field(Boolean)
    public isAdmin: boolean = false;

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}
