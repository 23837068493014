<mat-progress-spinner *ngIf="!(fetchFiles | async)" style="margin:0 auto;" color="primary" mode="indeterminate"></mat-progress-spinner>
<mat-paginator *ngIf="fetchFiles | async" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
<mat-table *ngIf="fetchFiles | async" #uploadSort="matSort" [dataSource]="dataSource" matSort >

    <ng-container cdkColumnDef="View">
        <mat-header-cell *cdkHeaderCellDef>View</mat-header-cell>
        <mat-cell *cdkCellDef="let file" >
            <button type="button" mat-flat-button (click)="view_file(file)">
                <mat-icon>library_books</mat-icon>
            </button>
        </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="filename">
        <mat-header-cell mat-sort-header *cdkHeaderCellDef>Name</mat-header-cell>
        <mat-cell *cdkCellDef="let file">{{file.filename}}</mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="filesize">
        <mat-header-cell mat-sort-header *cdkHeaderCellDef>Size</mat-header-cell>
        <mat-cell *cdkCellDef="let file">{{file.filesize}}</mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="createdAt">
        <mat-header-cell mat-sort-header *cdkHeaderCellDef>Uploaded At</mat-header-cell>
        <mat-cell *cdkCellDef="let file">{{file.createdAt | date}}</mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="Remove">
        <mat-header-cell *cdkHeaderCellDef>Remove</mat-header-cell>
        <mat-cell *cdkCellDef="let file" >
            <a mat-flat-button (click)="remove_File(file)">
                <mat-icon *ngIf="!file.isDeleted" matTooltip="Delete">remove_circle</mat-icon>
                <mat-icon *ngIf="file.isDeleted" matTooltip="Undelete">remove_circle_outline</mat-icon>
            </a>
        </mat-cell>
    </ng-container>

    <mat-header-row *cdkHeaderRowDef="columnDefs; sticky: true"></mat-header-row>
    <mat-row *cdkRowDef="let row; columns: columnDefs">
    </mat-row>
</mat-table>
