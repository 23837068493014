import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class Project_BillingRateItem extends DexResourceModel {

	@Field(String)
	public source_rate_uuid : string | null = null;

	@Field(String)
	public project_uuid : string | null = null;

	@Field(String)
	public name: string = "";

	@Field(String)
	public timeKeeper: string = "";

	@Field(Number)
	public rate: number = 0;

	constructor(data?: any) {
		super();
		this.mapData(data);
	}
}
