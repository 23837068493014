import { Component, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms'
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ConfirmDeleteDialog, ResourceEditComponent, ResourceFormService, ResourceListPane } from '@smartsoftware/reflex-core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
    AddressBook,
    AddressBookService,
    CommonData_Service,
    SuccessAccountRoleName,
    UserPermission_Service,
    SystemConfig_service
} from 'legalreflex-lib';
import { AddressRestoreDialog } from '../restore/addressRestoreDialog';
import { BreadCrumbService } from '../../../components/breadcrumb-bar/breadcrumb-service';

@Component({
    templateUrl: './create.html',
    styleUrls: ['./create.scss']
})
export class AddressCreate extends ResourceEditComponent<AddressBook, AddressBookService> implements OnInit, AfterViewInit {
    
    public privateCtrl = new FormControl(null);
    public stateCtrl = new FormControl(null);

    public corpId: string = '';
    public accountId: string = '';

    constructor(
        public locationService: CommonData_Service,
        protected auth: OidcSecurityService,
        protected entityService: AddressBookService,
        protected route: ActivatedRoute,
        protected dialog: MatDialog,
        protected snackbar: MatSnackBar,
        protected resourceFormService: ResourceFormService<AddressBook>,
        private breadCrumbService: BreadCrumbService,
        public sysConfig: SystemConfig_service
    ) {
        super(entityService, route, dialog, resourceFormService);

        this.entity = new AddressBook()
        this.entityForm = this.resourceFormService.from(new AddressBook())

        this.auth.userData$.subscribe(({userData, allUserData}) => {
			let response = userData;
            this.accountId = response.profile.uuid
            this.corpId = response.profile.corpId;
        })

        this.entityStatus.subscribe((res) => { 
            if(res){
                this.entityForm = this.resourceFormService.from(res)
                if(res.isPrivate)
                    this.privateCtrl.setValue("private")
                else
                    this.privateCtrl.setValue("shared")                
                this.setBreadCrumbs()
            }
        })
    }

    ngOnInit() {
        super.ngOnInit();
        if(this.sysConfig.maintenanceRedirectCheck()){  
            window.localStorage.removeItem("loggedInUserPermissionNodes");
            this.auth.logoff();
        };
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
    }

    setBreadCrumbs(){
        let crumbs = []
        crumbs.push({ label: 'Address Book', url:'/address-book'})
        if(this.entityForm.value.id) 
            crumbs.push({ label: "Address " + this.entityForm.value.name})
        else{
            this.route.params.subscribe((res)=>{
                if(res.id == 'new')
                    crumbs.push({ label: "New Address"})
                else
                    crumbs.push({ label: "Loading"})
            })
        }
        this.breadCrumbService.breadcrumbs.next(crumbs)

        let actions = []
        actions.push({ label: 'Cancel', routerLink: "/address-book", isPrimary: false, icon: 'close'})
        if(this.entityForm.value.id && (this.entityForm.value.deletedAt == null || this.entityForm.value.deletedAt == '')){
            actions.push({ label: 'Delete', action:this.deleteEntry.bind(this), icon: 'delete'})
        }else if(this.entityForm.value.id &&  (this.entityForm.value.deletedAt != null || this.entityForm.value.deletedAt != '')){
            actions.push({ label: 'Restore', action:this.restoreEntry.bind(this), icon: 'restore_from_trash'})
        }
        if(this.checkFields())
            actions.push({ label: 'Save', action:this.save.bind(this), isPrimary: true, icon: ''})
        else
            actions.push({ label: 'Save', disabled:true, tooltip:"Please fill in all required(*) address details before saving", icon: ''})
        this.breadCrumbService.actions.next(actions)
    }


    public checkFields() {
        let pristine = true
        let requiredControl = Object.entries(this.entityForm.controls).find((res)=>{
            if(res[0] == "state"){
                if(this.entityForm.controls["country"].value != "United States")
                    this.entityForm.controls["state"].reset();
                return this.entityForm.controls["country"].value != "United States" ? undefined : (this.entityForm.controls["state"].value != undefined ? undefined : "state")
            }
            if(res[1].validator != null)
                pristine = false
            return res[1].errors != null
        })

        if(requiredControl != undefined || this.accountId == "" || pristine){
            return false
        }

        if(this.privateCtrl.value == "private"){
            this.entityForm.controls['owner_uuid'].setValue(this.accountId)
            this.entityForm.controls['isPrivate'].setValue(true)
        }
        else{
            this.entityForm.controls['owner_uuid'].setValue('')
            this.entityForm.controls['isPrivate'].setValue(false)
        }
        this.entityForm.controls['jurisdiction'].setValue(null)
        this.entityForm.controls['county'].setValue(null)
        this.entityForm.controls['isDefault'].setValue(false)
        this.entityForm.controls['corp_id'].setValue(this.corpId)
        this.entityForm.controls['streetAddress2'].setValue(null)

        return true
    }

    public restoreEntry(){
        this.dialog.open(AddressRestoreDialog, {
            data: this.entity
        }).afterClosed().subscribe((res)=>{
            if(res){
                this.entityForm.controls['deletedAt'].setValue(null)
                this.save()
            }        
        })
    }

    public deleteEntry(){
        this.openConfirmDeleteDialog().subscribe((res)=>{
            if(res){
                let date = new Date()
                this.entityForm.controls['deletedAt'].setValue(date)
                this.save()
            }        
        })
    }

    public save() {
        Object.assign(this.entity, this.entityForm.value)
        if(!this.checkFields()){
            this.setBreadCrumbs()
            return
        }
        this.entity.deletedAt = this.entityForm.value.deletedAt
        this.resourceService.push(this.entity).subscribe(() => {        
            this.snackbar.open('Address Saved', undefined, { duration: 2000, verticalPosition: 'top', horizontalPosition: 'right'})
        });

        this.setBreadCrumbs()

        return 
    }
}
