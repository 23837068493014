import { Component, Inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BreadCrumbService } from '../../../components/breadcrumb-bar/breadcrumb-service';

@Component({
	templateUrl: './frame.html',
	styleUrls: ['./frame.scss']
})
export class LandingFrame {

	constructor(
		public pageTitleService : Title,
		private breadCrumbService: BreadCrumbService
	) {
		this.pageTitleService.setTitle("FirstConnect - File Through Trial");
		this.breadCrumbService.breadcrumbs.next([
			{ label: 'Dashboard', url: '/' }
		]);
		this.breadCrumbService.actions.next([ ]);
	}


}
