import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse,HttpParams } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { Client } from '../models/client.model';
import { ClientPartner }  from '../models/clientPartner.model';
import { Observable, of } from 'rxjs';
import { map } from "rxjs/operators";
import { Page, PageParams } from '../paged.datasource';


@Injectable()
export class Client_Service extends ResourceService<Client> {
  protected servicePath: string = '/Client';

  public ModelType = Client;

  public getClientPartnerByUUID(id: string) {
    let params = new HttpParams().set('uuid', id);

    return this.http.get<ClientPartner[]>(`${this.serviceUrl}getClientPartnerByUUID`,{params})
      .pipe(map(docs => docs.map(doc => new ClientPartner(doc))));
  }

  public push(entry: Client): Observable<Client> {

    if(!entry.default_clientMatterSet_uuid)
        entry.default_clientMatterSet_uuid = null

    return super.push(entry);
  }

  public history(pageParams: PageParams) {
        
    let params = new HttpParams().append('params',  btoa(JSON.stringify(pageParams)));
    return this.http.get<Page<Client>>(`${this.serviceUrl}Page`, {
        params
    }).pipe(map(page => {
        page.items = page.items.map(client => new Client(client));
        return page;
    }));
    
}

  page(pageParams: PageParams): Observable<Page<Client>> {
    let params = new HttpParams()
        .append('params', btoa(JSON.stringify(pageParams)));

    return this.http.get<Page<Client>>(`${this.serviceUrl}Page`, { params })
        .pipe(map(page => {
            page.items = page.items.map(i => new Client(i));
            return page;
        }));
}

public makeLookupConfig() {
  const service = this;
  let identifier = (type: Client) => type && type.uuid;
  let lookup = (id: string) => service.get(id);
  let display = (type: Client) => {
      if (!type) 
          return '';
      let words = [type.corpId].filter(w => !!w);
      return words.join('');    
  };
  let suggest = (term: string, limit?: number) => {
      if (!term) 
          return of([]);

      return service.history({
          page: 1, 
          pageSize: limit || 50, 
          sortColumn: 'corpId', 
          sortOrder: 'asc',
          filters: {
              corpId: term
          }
      }).pipe(
          map(page => {
              return page.items;
          })
      );
  }
  return { identifier, lookup, display, suggest };
}

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }
}