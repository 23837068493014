import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class Billing extends DexResourceModel {
    @Field(String)
    public contactName: string = "";
    @Field(String)
    public department: string = "";
    @Field(String)
    public phone: string = "";
    @Field(String)
    public salesRepName: string = "";
    @Field(String)
    public salesRepEmail: string = "";
    @Field(String)
    public hourlyRates: string = "";

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}