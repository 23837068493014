import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { ReflexEnvironment as ENV } from '@smartsoftware/reflex-core';

@Injectable()
export class CheckForUpdateService {

  constructor(appRef: ApplicationRef, public updates: SwUpdate) {
    
    if(updates.isEnabled) {
      const updateInterval$ = interval(ENV.config["updateInterval"]);

      updateInterval$.subscribe(() => {
        updates.checkForUpdate().then(() => console.log('checking for updates'))
      });
    }
  }

  public checkForUpdates(): void {
    this.updates.available.subscribe(event => {      
      this.promptUser();
    });
  }

  private promptUser(): void {
    this.updates.activateUpdate().then(() => document.location.reload()); 
  }
}