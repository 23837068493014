import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'missingCase-dialog',
    templateUrl: 'missingCase.html',
    styleUrls: ['./missingCase.scss']
})  
export class MissingCasePopupDialog {

    constructor(
        private dialogRef : MatDialogRef<string>, 
    ) {
    }

    close(){
        return this.dialogRef.close()
    }
}

