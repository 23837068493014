import {
    NgModule,
//    ModuleWithProviders
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import {
    ReflexDownloadComponent
} from './components';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,

        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        MatProgressBarModule,
    ],
    exports: [
        ReflexDownloadComponent
    ],
    declarations: [
        ReflexDownloadComponent
    ],
    entryComponents: [
    ],
    providers: []
})
export class ReflexDownloaderModule {
//    static forRoot(): ModuleWithProviders<AuthModule> {
//        return {
//            ngModule: AuthModule,
//            providers: [
//                AuthResourceService,
//                Auth
//            ]
//        };
//    }
}
