import { Component, OnInit } from '@angular/core';
import { ReflexEnvironment as env } from '@smartsoftware/reflex-core';

@Component({
  selector: 'info-banner',
  templateUrl: './info-banner.component.html',
  styleUrls: ['./info-banner.component.scss']
})
export class InfoBannerComponent implements OnInit {


  public message: string = `This is a ${env.config['resourcePrefix']} release`;

  constructor() { }

  ngOnInit(): void {
  }

}
