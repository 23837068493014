import { Component, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import userflow from 'userflow.js';
import { ReflexEnvironment as environment } from '@smartsoftware/reflex-core';
import {  User, User_Service } from 'legalreflex-lib';
import * as CryptoJS from 'crypto-js';
//const CryptoJS = require("crypto-js");

@Component({
	selector: 'user-flow',
	templateUrl: './userFlow.html',
	styleUrls: ['./userFlow.scss']
})
export class UserFlowComponent implements OnInit {

	constructor(
		public auth: OidcSecurityService,
		private userService: User_Service
	) {
        let userflowToken = environment.config['userflowToken'];

		userflow.init(userflowToken);


		// Attach listenter for auto-resume of session
		this.auth.userData$.subscribe(({userData, allUserData}) => {
			let response = this.auth.getUserData();
			// not yet, use cached identity if available
			if(!response?.sub || !response?.email) {
				this.identify();
			}
			if(response && response?.sub){
				this.userService.getUserInfo(response.sub).subscribe(
					(userInfo) => {
						if(!userInfo?.success || !userInfo?.result) {
							return;
						}

						return this.identify({
							userId: userInfo.result.UserID.toString(), 
							name: userInfo.result.FirstName + ' ' + userInfo.result.LastName, 
							email: response.email, 
							signed_up_at: userInfo.result.CreatedOn
						});
					},
					(error: any) => {
						console.log('ERROR: Could not get UserInfo', error);
					}
				);
			}
        });
		
	}



	protected identify(identity ?: UserAuthIdentity) : Promise<any> {
		// Use an identity if passed, otherwise fall back on last identity
		let id = identity || this.lastIdentity;

		if(!id) {
			return userflow.identifyAnonymous();
		}

		let hmac = CryptoJS.HmacSHA256(id.userId, environment.config['userflowSecretKey']);
		let signature = hmac.toString(CryptoJS.enc.Hex);

		this.lastIdentity = id;

		return userflow.identify(
			id.userId,
			{
				name: id.name,
				email: id.email,
				signed_up_at: id.signed_up_at
			},
			{
				signature: signature
			}
		);
	}

	protected get lastIdentity() : UserAuthIdentity | undefined {
		let cache = localStorage.getItem('lastUserFlowIdentity');
		return cache ? JSON.parse(cache) : undefined;
	}

	protected set lastIdentity(identity : UserAuthIdentity | undefined) {
		if(identity) localStorage.setItem('lastUserFlowIdentity', JSON.stringify(identity));
		else localStorage.remove('lastUserFlowIdentity');
	}

	ngOnInit() {
        // console.log('Userflow Service');
    }

}

interface UserAuthIdentity {
	userId: string;
	name: string;
	email: string;
	signed_up_at: string;
}