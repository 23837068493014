import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class BaseFile extends DexResourceModel {

    public downloadUrl: string = '';

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}
