import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import {
    Notification,
    CaseUser,
    // CaseOwner,
    Note,
    Case
} from '../models';
import { Page, PageParams, Pager } from '../paged.datasource';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class Notification_Service extends ResourceService<Notification> implements Pager<Notification>{
    protected servicePath: string = '/Notification';

    public ModelType = Notification;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }

    public push(entry: Notification): Observable<Notification> {
        return super.push(entry)
    }

    page(pageParams: PageParams): Observable<Page<Notification>> {
        
        let params = new HttpParams()
        .append('params', btoa(JSON.stringify(pageParams)));

        return this.http.get<Page<Notification>>(`${this.serviceUrl}successPage`, { params })
            .pipe(map(page => {
                page.items = page.items.map(i => new Notification(i));
                return page;
            }));
    }

    // public getCasesByOwner(): Observable<CaseOwner[]> {
    //     return this
    //         .http
    //         .get(
    //             this.serviceUrl + 'owner',
    //             {
    //                 observe: 'response'
    //             }
    //         )
    //         .pipe(
    //             map(
    //                 (response: HttpResponse<any>) =>
    //                     response.body.map((e: any) => new CaseOwner(e))
    //             )
    //         );
    // }

    // public getNotes(uuid: string) {
    //     return this
    //         .http
    //         .post(
    //             this.serviceUrl + 'notes',
    //             {
    //                 uuid
    //             },
    //             {
    //                 observe: 'response'
    //             }
    //         )
    //         .pipe(
    //             map(
    //                 (response: HttpResponse<any>) =>
    //                     response.body.map((e: any) => new Note(e))
    //             )
    //         );
    // }

    // public getAlerts(uuid: string) {
    //     return this
    //         .http
    //         .post(
    //             this.serviceUrl + 'alerts',
    //             {
    //                 uuid
    //             },
    //             {
    //                 observe: 'response'
    //             }
    //         )
    //         .pipe(
    //             map(
    //                 (response: HttpResponse<any>) =>
    //                     response.body.map((e: any) => new Note(e))
    //             )
    //         );
    // }

    // public getContacts(uuid: string) {
    //     return this
    //         .http
    //         .post(
    //             this.serviceUrl + 'contacts',
    //             {
    //                 uuid
    //             },
    //             {
    //                 observe: 'response'
    //             }
    //         )
    //         .pipe(
    //             map(
    //                 (response: HttpResponse<any>) =>
    //                     response.body.map((e: any) => new Contact(e))
    //             )
    //         );
    // }

    // public getUsers(uuid: string) {
    //     return this
    //         .http
    //         .post(
    //             this.serviceUrl + 'users',
    //             {
    //                 uuid
    //             },
    //             {
    //                 observe: 'response'
    //             }
    //         )
    //         .pipe(
    //             map(
    //                 (response: HttpResponse<any>) =>
    //                     response.body.map((e: any) => new CaseUser(e))
    //             )
    //         );
    // }

    getRecentNotifications(body:any): Observable<any>{
        return this
            .http
            .post(
                this.serviceUrl + 'getRecentNotifications',
                {
                    createdAt:body.createdAt,
                    includeViewed:body.includeViewed,
                    notificationLimit:body.notificationLimit
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) =>{
                        return new Object ({notifications: response.body.notifications.map((e: any) => new Notification(e)), length: response.body.length})
                    }
                )
            )
    }

    getRecentNotificationsIgnorePush(body:any): Observable<any>{
        return this
            .http
            .post(
                this.serviceUrl + 'getRecentNotificationsIgnorePush',
                {
                    createdAt:body.createdAt,
                    includeViewed:body.includeViewed,
                    notificationLimit:body.notificationLimit
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) =>{
                        return new Object ({notifications: response.body.notifications.map((e: any) => new Notification(e)), length: response.body.length})
                    }
                )
            )
    }
}
