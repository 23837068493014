import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class Client extends DexResourceModel {

    @Field(String)
    public corpId: string = "";

    @Field(String)
    public clientName: string = "";

    @Field(String)
    public clientCode: string = "";

    @Field(String)
    public default_clientMatterSet_uuid: string | null = null;

    constructor(data?: any) {
        super();
        //super.status = "inactive";
        this.mapData(data);
    }


}