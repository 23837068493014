import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { ProjectContact } from '../models/projectContact.model';

@Injectable()
export class ProjectContact_Service extends ResourceService<ProjectContact> {
    protected servicePath: string = '/ProjectContact';

    public ModelType = ProjectContact;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }
}
