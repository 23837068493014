import {Component, Inject, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SuccessCase } from 'legalreflex-lib';


@Component({
  selector: 'caseRestore-dialog',
  templateUrl: 'caseRestoreDialog.html',
  styleUrls: ['./caseRestoreDialog.scss']

})
export class CaseRestoreDialog {

  public decided: boolean = false;
  constructor(
    private dialogRef : MatDialogRef<CaseRestoreDialog>,

    @Inject(MAT_DIALOG_DATA) 
    public data: SuccessCase
  ) {
  }

  close(result:boolean){
    this.dialogRef.close(result)
  }
}
