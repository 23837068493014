import { Component, Input, Output, OnInit, OnDestroy, EventEmitter,  ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import {
    File_Service,
    FileAction_Service
} from 'legalreflex-lib';
import { mergeMap } from 'rxjs/operators';

export type UploaderType = 'order';

@Component({
    selector: 'uploader',
    templateUrl: './uploader.html',
    styleUrls: ['./uploader.scss']
})
export class UploaderComponent implements OnInit, OnDestroy {
    @Input('entityType')
    public entityType: UploaderType = 'order';
    @Input()
    clearFiles: Observable<void> = new Observable<void>();
    @Input('files')
    public files: File[] = [];
    @Input('fileInfos')
    public fileInfos: { complete: boolean, name: string }[] = [];
    @Input('hide')
    public hide: boolean = false;

    @Output()
    public folderChange = new EventEmitter<string>();
    @Output()
    public filesChange = new EventEmitter<File[]>();
    @Output()
    public fileInfosChange = new EventEmitter<{ complete: boolean, name: string }[]>();

    @ViewChild('UploadFileInput') uploadFileInput?: ElementRef;

    public fileControl = new FormControl();
    public isComplete$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public uploadedFiles$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    public isLoading: boolean = false;

    private clearFilesSubscription: Subscription = new Subscription();

    constructor(
        protected fileService: File_Service,
        protected fileActionService: FileAction_Service
    ) { }

    ngOnInit() {
        this.fileService.fetchFiles$.next(true);
        this.clearFilesSubscription = this.clearFiles.subscribe((event) => this.clearFilesCallback(event));
    }

    ngOnDestroy() {
        this.clearFilesSubscription.unsubscribe();
    }

    private clearFilesCallback(event: any): void {
        if (this.uploadFileInput) {
            this.uploadFileInput.nativeElement.value = null;
        }
    }

    public onFileChange(event: any): void {
        let files: Array<File> = Array.from(event.target.files);
        files.forEach((file: File) => {
            this.fileInfos.push({ complete: true, name: file.name });
            this.files.push(file);
            this.fileInfosChange.emit(this.fileInfos);
            this.filesChange.emit(this.files);
        });
    }

    public remove(fileName: string, fileInfoIndex: number) {
        let filesIndex = this.files.findIndex(f => f.name === fileName);
        this.files.splice(filesIndex, 1);
        this.fileInfos.splice(fileInfoIndex, 1);
        this.filesChange.emit(this.files);
        this.fileInfosChange.emit(this.fileInfos);
    }
}
