import { enableProdMode, NgModuleRef} from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { ConfigService, ReflexEnvironment as environment } from '@smartsoftware/reflex-core';
import {environment as environmentAngular } from './environments/environment'
import * as configFile from './assets/config.json';

ConfigService.DefaultConfig = (configFile as any).default;

if (environmentAngular.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(
    (appModule: NgModuleRef<AppModule>) => {
      environment.serviceInjector = appModule.injector;

      if('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/ngsw-worker.js')
        .then((registration) => {console.log('service worker registered'); registration.update(); });
      }
    }
  )
  .catch(err => console.error(err));
