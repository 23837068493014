import { ActivatedRoute, Router } from "@angular/router";
import { BreadCrumbService } from "../../components/breadcrumb-bar/breadcrumb-service";
import { Order_Service, UserPermission_Service } from "legalreflex-lib";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { filter } from "rxjs/operators";
import { Component } from "@angular/core";

@Component({
    templateUrl: './redirect.html',
})
export class OrderRedirect {
    public typeUrl: string = ''
    constructor(
        protected auth: OidcSecurityService,
        private userPermissionService: UserPermission_Service,
		private breadCrumbService: BreadCrumbService,
        public route: ActivatedRoute, 
        public router: Router,
        public entityService: Order_Service
    ) {}

    ngOnInit(){
        this.route.url.subscribe((url)=>{
            if(url[1].path){
                this.typeUrl = url[1].path;
            }
            if(url.length == 3 && url[2].path.match(/^\d{1,9}$/)){
                this.entityService.getByLegacyId(url[2].path)
                .subscribe((entity:any)=> {
                    console.debug('entity', entity);
                    if(entity) {
                        if(url[1].path == 'continue-order'){
                            this.router.navigate(['/order/resume/' + entity.uuid]);
                        } else if (url[1].path == 'order-detail'){
                            this.router.navigate(['/order/' + entity.uuid]);
                        }
                    }else{
                        this.router.navigate(['/notFound']);
                    }
                },((err)=>{this.router.navigate(['/notFound']);}))
            }
        })
    }
}