
import { Injectable } from '@angular/core';
import { ResourceService } from '@smartsoftware/reflex-core';
import { ServiceType } from '../models';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Page, PageParams } from '../paged.datasource';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ServiceType_Service extends ResourceService<ServiceType> {

    protected servicePath = '/ServiceType';

    public ModelType = ServiceType;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }

    page(pageParams: PageParams): Observable<Page<ServiceType>> {
        let params = new HttpParams()
            .append('params', btoa(JSON.stringify(pageParams)));
        return this.http.get<Page<ServiceType>>(`${this.serviceUrl}Page`, { params })
            .pipe(map(page => {
                page.items = page.items.map(i => new ServiceType(i));
                return page;
            }));
    }
}