import { Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export enum Division {
    DATATRAC = "Datatrac",
    ACCLAIM = "Acclaim",
    TRISTAR = "Tristar",
    TRISTAR_FDS = "Tristar FDS",
    TRISTAR_FLI = "Tristar FLI",
    TRISTAR_FLI_CG = "Tristar FLI CG",
    EDISCOVERY = "eDiscovery"
}

export class Vendor extends DexResourceModel {
	@Field(String)
    public name: string = "";
    @Field(Date)
	public createdAt: Date = new Date();
	@Field(Date)
	public updatedAt: Date = new Date();
    @Field(Date)
	public deletedAt: Date = new Date();
    @Field(String)
    public serviceAliasName: string = "";

	constructor(data?: any) {
        super();
        this.mapData(data);
    }

    public get data(): any {
        let json = super.data;
        return json;
    }
}