import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { Project_BillingRateItem } from '../models/project_billingRateItem.model';

@Injectable()
export class Project_BillingRateItem_Service extends ResourceService<Project_BillingRateItem> {
  protected servicePath: string = '/ProjectBillingRateItem';

  public ModelType = Project_BillingRateItem;

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }
}
