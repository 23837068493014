import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ResourceEditComponent, ResourceFormService } from '@smartsoftware/reflex-core';
import {
    Notification as Notice,
    Notification_Service,
    NotificationType_Service,
    SystemConfig_service
} from 'legalreflex-lib';
import { NotificationPopupDialog } from '../dialog/notificationPopup';
import { BreadCrumbService } from '../../../components/breadcrumb-bar/breadcrumb-service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { NotificationRestoreDialog } from '../restore/notificationRestoreDialog';
import { JSONObject, render } from '../../../../../../../../src/Runtime/Logics/template.logic';

@Component({
    templateUrl: './view.html',
    styleUrls: ['./view.scss']
})
export class NotificationView extends ResourceEditComponent<Notice, Notification_Service> implements OnInit, AfterViewInit {

    public noticeTypes: Map<string,string> =  new Map()
    public isUser = false
    public notificationHtml : string = ''
    public notificationSubject : string = ''
    constructor(
        protected auth: OidcSecurityService,
        protected entityService: Notification_Service,
        protected route: ActivatedRoute,
        protected dialog: MatDialog,
        protected resourceFormService: ResourceFormService<Notice>,
        protected notificationTypeService: NotificationType_Service,
        protected snackbar: MatSnackBar,
        private breadCrumbService: BreadCrumbService,
        public sysConfig: SystemConfig_service
    ) {
        super(entityService, route, dialog, resourceFormService);

        this.entityForm = this.resourceFormService.from(new Notice());
        this.entityStatus.subscribe((res)=>{
            if(this.entity && this.entity.viewedAt == null){
                this.entity.viewedAt = new Date()
                this.entityService.push(this.entity).subscribe(()=>{})
                this.setBreadCrumbs()
            }

            if(this.entity){
                this.auth.userData$.subscribe(({userData, allUserData}) => {
                    let prof = userData;
                    if(this.entity.userId == prof.profile.uuid)
                        this.isUser = true
                })
            }
        })
    }

    ngOnInit() {
        super.ngOnInit();
        if(this.sysConfig.maintenanceRedirectCheck()){  
            window.localStorage.removeItem("loggedInUserPermissionNodes");
            this.auth.logoff();
        };
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
        this.loading.subscribe(() => {
            this.notificationTypeService.list().subscribe((res)=>{
                res.map((type)=>{this.noticeTypes.set(type.uuid, type.name)})
            })
            this.setBreadCrumbs()
        })
        this.entityStatus.subscribe((status)=>{
            if (this.entity) {
                if(this.entity.subject)
                    this.notificationSubject = this.entity.subject
                this.notificationTypeService.get(this.entity.notificationTypeId).subscribe((res)=>{
                    if (res.jsonDataExampleBody != undefined && this.entity.jsonData != undefined) {
                        const viewCtx = JSON.parse(this.entity.jsonData)
                        this.notificationHtml = render(res.templateHtml? res.templateHtml: "Missing Template Html", viewCtx)
                        if(!this.notificationSubject)
                            this.notificationSubject = render(res.subject? res.subject : "Missing Template subject", viewCtx)
                        const viewDiv = document.getElementById('viewcontent') as HTMLDivElement;
                        viewDiv.innerHTML = this.notificationHtml? this.notificationHtml : ''
                        const viewParent = document.getElementById('viewcontent-parent') as HTMLDivElement;
                        viewParent.scrollTo(0,0)
                    }      
                })
            }
        })
    }

    setBreadCrumbs(){
        let crumbs = []
        crumbs.push({ label: 'Notifications', url:'/notifications'})
        if(this.entity){
            crumbs.push({ label: this.entity.subject ? "Notification: " + this.entity.subject : "Notification: N/a"})
        }else
            crumbs.push({ label: "Loading"})
        this.breadCrumbService.breadcrumbs.next(crumbs)

        let actions = []
        if(this.entity && !this.entity.deletedAt){
            actions.push({label: 'Delete', action: this.deleteNotification.bind(this), icon: 'delete'})
        }else if(this.entity && this.entity.deletedAt){
            actions.push({label: 'Restore', action: this.restoreNotification.bind(this), icon: 'restore_from_trash'})
        }
        actions.push(
            {label: 'Open Notice', isPrimary:true, action: this.openNotification.bind(this), icon: ''},
            {label: 'Resend Notification', action: this.resend.bind(this), icon:'restart_alt'}
        )
        this.breadCrumbService.actions.next(actions)
    }

    resend(){
        this.entity.deletedAt = null
        this.entity.isDeleted = false
        this.entity.deliveredAt = null
        this.entity.deliveryStatusResponse = null
        this.entity.viewedAt = null
        this.entityService.push(this.entity).subscribe(()=>{
            this.snackbar.open('Notification resent', undefined, { duration: 2000, verticalPosition: 'top', horizontalPosition: 'right'})
            this.setBreadCrumbs()
        })
    }

    openNotification(){
        let dialogRef: MatDialogRef<NotificationPopupDialog>;
        dialogRef = this.dialog.open(NotificationPopupDialog, {
            data: this.entity
        })
        dialogRef.afterClosed().subscribe((res)=>{
            if(res){
                Object.assign(this.entity, res.data)
                this.entityService.push(this.entity).subscribe(()=>{})
            }
        })
    }

    deleteNotification(){
        this.openConfirmDeleteDialog()
            .subscribe(
                response => {
                    if (response.confirmed) {
                        this.entity.deletedAt = new Date()
                        this.entity.isDeleted = true
                        this.resourceService.push((this.entity)).subscribe(()=>{
                            this.setBreadCrumbs()
                        })
                    }
                }
            )
    }

    restoreNotification(){
        this.dialog.open(NotificationRestoreDialog, {
            data: this.entity
        }).afterClosed().subscribe((res)=>{
            if(res){
                this.entity.deletedAt = null
                this.entity.isDeleted = false
                this.resourceService.push((this.entity)).subscribe(()=>{
                    this.setBreadCrumbs()
                })
            }
        })
    }

    public get formHasControl(): boolean {
        return this.entityForm?.controls['orderId']?.value;
    }

}
