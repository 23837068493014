import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { SuccessAccountRoleName, UserPermission_Service, SuccessAccountPermissionNodeName } from 'legalreflex-lib';
import { switchMap } from 'rxjs/operators';

export type ActivateCondition = (userPermissionService: UserPermission_Service) => Observable<boolean>;

@Injectable({
  providedIn: 'root'
})
export class IsFirmAdmin implements CanActivateChild, CanActivate {

  constructor(
    private router: Router,
    private userPermissionService: UserPermission_Service) {}

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    // let hasRole = this.userPermissionService.hasRole(SuccessAccountRoleName.client_firm_admin);
    let hasFirmPermission = this.userPermissionService.canDo(SuccessAccountPermissionNodeName.manage_firm_users);
    //this exception allows a non-firm admin user to reach their own profile settings 
    if(!hasFirmPermission && (route.url[0].path == this.userPermissionService.loggedInUser?.uuid))
      return true;

    if (!hasFirmPermission) 
      return this.router.parseUrl('/');
    return hasFirmPermission;
  }
}

@Injectable({
  providedIn: 'root'
})
export class hasSysConfigAccess implements CanActivate 
{
  constructor( private userPermissionService: UserPermission_Service ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> 
  {
    return this.userPermissionService.canDo(SuccessAccountPermissionNodeName.manage_system_config);
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthGuard extends AutoLoginPartialRoutesGuard implements CanActivateChild { 

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    let userPermissionService: UserPermission_Service = inject(UserPermission_Service);
    let authenticated$ = super.canActivate(route, state);
    if(!authenticated$)
      return authenticated$;

    let activate = false;
    let activateCondition: ActivateCondition | undefined = route.data.activateCondition;
    if (activateCondition){
      activateCondition(userPermissionService).subscribe(result=>{
        activate = result;
      });
      return activate;
    }
  
    let activatePermission: string | undefined = route.data.activatePermission;
    if (activatePermission)
      return userPermissionService.canDo(activatePermission);

    return authenticated$;
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    return this.canActivate(childRoute, state);
  }
  
}
