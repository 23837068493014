import { Component, Input, OnInit } from '@angular/core';
import { SuccessAccount } from '../../models/successAccount.model';
import { FC3_DATETIME_FORMAT } from '../..';
import { DexResourceModel } from '../../abstracts/dexResource.model';

@Component({
  selector: 'lib-entity-activity',
  templateUrl: './lib-entity-activity.component.html',
  styleUrls: ['./lib-entity-activity.component.scss']
})
export class LibEntityActivityComponent implements OnInit {

  FC3_DATETIME_FORMAT = FC3_DATETIME_FORMAT;

  @Input()
  public entity: DexResourceModel | undefined;

  constructor() { }

  ngOnInit(): void {
  }

  get is_success_account() {
    return this.entity instanceof SuccessAccount;
  }

  get success_account() {
    return this.entity as SuccessAccount;
  }

}
