import { Component, Inject, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Router } from '@angular/router';

@Component({
    templateUrl: './unauthorized.html',
    styleUrls: ['./unauthorized.scss']
})
export class UnauthorizedView {

    constructor(
        public auth: OidcSecurityService,
		private router: Router
    ) {

    }


    login() {
        this.auth.authorize();
    }

	ngOnInit() {
		// Temporary hack till we fix the user-data endpoints
		setTimeout(
			() => {
				this.router.navigate(["/"]);
			},
			100
		)
	}

}
