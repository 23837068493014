import { Field } from "@smartsoftware/reflex-core";
import { DexResourceModel } from "../abstracts/dexResource.model";


export class SystemConfig extends DexResourceModel
{
    @Field(String)
    public key: string | undefined; 
    @Field(String)
    public value: string | null = null;
    @Field(String)
    public valueType: string | null | undefined;
    @Field(String)
    public description: string | null = null;

    public createdAt: any | null = null;
	public deletedAt: any | null = null;
	public updatedAt: any | null = null;    

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

    public get data(): any {
        let json = super.data;

        return json;
    }
    
}