import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class PartnerVendorClient extends DexResourceModel {

    @Field(String)
    public partnerVendor_uuid: string | null = null;
    @Field(String)
    public application_type: string = "";
    @Field(String)
    public client_id: string = "";
    @Field(String)
    public client_name: string = "";
    @Field(String)
    public client_secret: string = "";
    @Field(String)
    public client_uri: string = "";
    @Field(String)
    public contacts: string = "";
    @Field(String)
    public default_acr_values: string = "";
    @Field(String)
    public default_max_age: string = "";
    @Field(String)
    public grant_types: string = "";
    @Field(String)
    public id_token_signed_response_alg: string = "";
    @Field(String)
    public initiate_login_uri: string = "";
    @Field(String)
    public jwks: string = "";
    @Field(String)
    public jwks_uri: string = "";
    @Field(String)
    public logo_uri: string = "";
    @Field(String)
    public policy_uri: string = "";
    @Field(String)
    public post_logout_redirect_uris: string = "";
    @Field(String)
    public redirect_uris: string = "";
    @Field(String)
    public require_auth_time: string = "";
    @Field(String)
    public response_types: string = "";
    @Field(String)
    public scope: string = "";
    @Field(String)
    public sector_identifier_uri: string = "";
    @Field(String)
    public subject_type: string = "";
    @Field(String)
    public token_endpoint_auth_method: string = "";
    @Field(String)
    public tos_uri: string = "";
    @Field(String)
    public userinfo_signed_response_alg: string = "";

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}
