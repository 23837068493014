import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { CaseFile } from '../models/caseFile.model';

@Injectable()
export class CaseFile_Service extends ResourceService<CaseFile> {
    protected servicePath: string = '/CaseFile';

    public ModelType = CaseFile;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }
}
