import { DexResourceModel } from '../abstracts/dexResource.model';

// Does not correspond to table, used for /Case/users endpoint
export class CaseUser extends DexResourceModel {
    public email: string = "";
    public displayName: string = "";
    public pictureUrl: string = "";
    public isOwner: boolean = false;

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}
