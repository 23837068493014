import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { VendorType } from '../models/vendorType.model';

@Injectable()
export class VendorType_Service extends ResourceService<VendorType> {
    protected servicePath: string = '/VendorType';

    public ModelType = VendorType;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }
}
