import {Component, Inject, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AddressBook } from 'legalreflex-lib';


@Component({
  selector: 'addressRestore-dialog',
  templateUrl: 'addressRestoreDialog.html',
  styleUrls: ['./addressRestoreDialog.scss']

})
export class AddressRestoreDialog {

  public decided: boolean = false;
  constructor(
    private dialogRef : MatDialogRef<AddressRestoreDialog>,

    @Inject(MAT_DIALOG_DATA) 
    public data: AddressBook
  ) {
  }

  close(result:boolean){
    this.dialogRef.close(result)
  }
}
