import { HttpClient, HttpResponse, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ResourceService } from "@smartsoftware/reflex-core";
import { Vendor } from "../models/vendor.model";
import { BehaviorSubject, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

@Injectable()
export class Vendor_Service extends ResourceService<Vendor> {
    protected servicePath =  '/Vendor';

    public ModelType = Vendor;

    private _isDataSynced = false;
    get isDataSynced(): boolean {
        return this._isDataSynced;
    }
    set isDataSynced(status: boolean) {
        // console.log('TFTEST- set is data synced', status);
        this._isDataSynced = status;
        this.dataSync.next(this._isDataSynced);
    }

    private _vendors: Vendor[] = [];
    get vendors(): Vendor[] {
        return this._vendors;
    }
    set vendors(data: Vendor[]) {
        this._vendors = data;
    }

    
    public dataSync: BehaviorSubject<boolean> = new BehaviorSubject(this._isDataSynced);

    constructor(
        protected http: HttpClient
    ){
        super(http);

        this.syncVendorData();
    }

    public push(entry: Vendor): Observable<Vendor> {
        
        if(!entry.name) {
            entry.name = "";
        }

       
        return super.push(entry);
    }

    public getServiceUrl(): string {
        return this.serviceUrl
    }

    // TFTEST add later if needed
    // public createAccount(data: Vendor): Observable<Array<Vendor>> {}
        
    public syncVendorData() : void {
        this.allVendors()
        .then(
            (response: any) => {
                this.isDataSynced = true;
                // console.log('TFTEST all vendors synced on service', this.isDataSynced);
            }
        )
    }

    public allVendors(): any {
        return this
            .http
            .get(
                this.serviceUrl,
                {
                    observe: 'response'
                }
            )
            .pipe(
                tap((response: any) => {
                    this.vendors = response.body;
                    // console.log('TFTEST - vendor service response', response);
                })
            )   
            .toPromise();
    }

    public getVendorByName(data: {name: string}): Observable<Array<Vendor>> {
        // console.log('TFTEST get vendor by name', data);
        return this
            .http
            .post(
                this.serviceUrl + 'get',
                {
                    name: data.name,
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => 
                        response.body.map((e:Vendor) => new Vendor(e))
                )
            );
    }

    public getVendorByClientUUID(id: string) {
        let params = new HttpParams().set('uuid', id);

        return this.http.get<Vendor[]>(`${this.serviceUrl}getVendorByClientUUID`,{params})
            .pipe(map(docs => docs.map(doc => new Vendor(doc))));
    }

    public makeLookupConfig() {
        const service = this;
        let identifier = (vendor: Vendor) => vendor && vendor.uuid;
        let lookup = (id: string) => service.get(id);
        let display = (vendor: Vendor) => vendor && vendor.name;
        let suggest = (term: string) => {
            return service.list({reloadFresh:true}).pipe(
                map(vendors => {
                    term = term ? term.toLowerCase() : '';
                    return vendors.filter(vendor => {
                        return !term || (vendor.name && vendor.name.toLowerCase().includes(term));
                    });
                })
            );
        }
        return { identifier, lookup, display, suggest };
    }

}