import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { OrderService } from '../models/orderService.model';
import { map } from 'rxjs/operators';
import { OrderServiceAffidavit, OrderServiceAttempt } from '../models';

@Injectable()
export class OrderService_Service extends ResourceService<OrderService> {
    protected servicePath: string = '/OrderService';

    public ModelType = OrderService;
    
    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }

    public getAttemptsAndAffidavits(uuid: string) {
        return this
            .http
            .post(
                this.serviceUrl + 'getAttemptsAndAffidavits',
                {
                    uuid
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) =>{
                        response.body.attempts = (response.body.attempts as OrderServiceAttempt[]).map((attempt)=> {return new OrderServiceAttempt(attempt)});
                        response.body.affidavits = (response.body.affidavits as OrderServiceAffidavit[]).map((affidavit)=> {return new OrderServiceAffidavit(affidavit)});
                        return response.body
                    }
                )
            );
    }
}
