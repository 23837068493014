import { HttpClient, HttpParams, HttpResponse, HttpXhrBackend } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ResourceListOptions, ResourceService } from "@smartsoftware/reflex-core";
import { SuccessAccount } from "../models/successAccount.model";
import { BehaviorSubject, Observable, of } from "rxjs";
import { filter, map, pairwise, switchMap, take, tap } from "rxjs/operators";
import { Role_Service } from "./role.service";
import { SuccessAccountSettingsService } from "./success-account-settings.service";
import { SuccessAccountSettings } from "../models/successAccountSettings.model";
import { Role } from "../models";
import { Page, PageParams } from "../paged.datasource";

export const SUCCESS_GUEST_USER: SuccessAccount = new SuccessAccount({
    firstName: 'Guest',
    username: 'Guest',
});

export enum SuccessAccountRoleName {
    // old roles
    // first_legal_admin = 'FirstLegal Administrator',
    // first_legal_staff = 'FirstLegal Staff',
    // firm_admin = 'Firm Administrator',
    // office_admin = 'Office Administrator',
    // office_staff = 'Office Staff',
    // new roles
    client_office_staff = 'Client Office Staff',
	client_beta_access = 'Client Beta Access',
	client_account_admin = 'Client Account Administrator',
	client_office_admin = 'Client Office Administrator',
	client_firm_admin = 'Client Firm Administrator',
	
	fl_beta_access = 'FL Beta Access',
	fl_staff = 'FL Staff', 
	fl_power_user = 'FL Power User',
	fl_admin = 'FL Administrator'
}

export enum SuccessAccountPermissionNodeName {
    view_orders_for_specific_accounts = 'VIEWSPECIFIC',
    view_orders_for_own_account = 'VIEWOWN',
    view_orders_for_office_accounts = 'VIEWOFFICE',
    view_orders_for_firm_accounts = 'VIEWFIRM',
    view_all_orders = 'VIEWALL',

    place_orders_in_specific_accounts = 'ORDERSPECIFIC',
    place_orders_in_office_accounts = 'ORDEROFFICE',
    place_orders_in_firm_accounts = 'ORDERFIRM',
    place_orders_in_any_accounts = 'ORDERALL',

    manage_specific_users = 'MANAGESPECIFIC',
    manage_office_users = 'MANAGEOFFICE',
    manage_firm_users = 'MANAGEFIRM',
    manage_all_users = 'MANAGEALL',

    manage_system_config = 'SYSTEMCONFIG',
    allow_login_to_beta_legalreflex = 'LEGALREFLEXBETA',
    allow_login_to_beta_firstconnect = 'FIRSTCONNECTBETA',
    impersonate_users = 'Impersonate:User',

    manage_firm_cases = 'CASEFIRM',
    manage_all_cases = 'CASEALL',

    manage_client_users = 'ADMINUSERROLE',

    manage_roles_and_associated_permissions = 'ADMINUSERPERMISSION',
    manage_collection_email_utility = 'ACCTCOLLECTEMAIL',
    manage_firstlegal_staff_users = 'ADMINUSERCLIENT',
    manage_all_cases_in_system = 'CASEALL',
    manage_notification_templates = 'ADMINNOTIFICATIONTYPE',
    manage_all_notifications_in_system = 'ADMINNOTIFICATION',
    access_legalreflex_admin_site = 'Access:LegalReflexAdmin',
    access_all_invoices_in_system = 'ADMININVOICE',
    access_global_address_book_entries = 'ADMINGLOBALADDRESS',
    manage_client_matter_sets = 'ADMINCLIENTMATTER',
    manage_clients = 'ADMINCLIENT',
    manage_firstlegal_locations = 'ADMINFIRM',
    manage_customer_address_book_entries = 'ADMINCUSTOMERADDRESS',
}

@Injectable()
export class SuccessAccount_Service extends ResourceService<SuccessAccount> {
    protected servicePath =  '/SuccessAccount';

    private user$ = new BehaviorSubject<SuccessAccount>(SUCCESS_GUEST_USER);
    private userRoles$ = new BehaviorSubject<Role[]>([]);

    // flow for when the user changes or i.e. is set with currentUser
    // update the user's roles
    private rolePipe: Observable<Role[]> = this.user$.pipe(
        pairwise<SuccessAccount>(),
        switchMap(([previous, current]) => {
            if (current == SUCCESS_GUEST_USER) // guest has no roles
                return of([]);
            // if (previous.uuid != current.uuid)
            if(current.uuid)
                return this.roleService.getRolesBySuccessAccount({successAccount_uuid: current.uuid as string});
            return of(this.userRoles$.value);
        }),
        tap(roles => this.userRoles$.next(roles))
    )
    
    public ModelType = SuccessAccount;
    /**
     * The current user
     */
    public get user(): Observable<SuccessAccount> {
        return this.user$.asObservable();
    }

    public set currentUser(user: SuccessAccount) {
        this.user$.next(user);
    }


    page(pageParams: PageParams): Observable<Page<SuccessAccount>> {
        let params = new HttpParams()
            .append('params', btoa(JSON.stringify(pageParams)));
    
        return this.http.get<Page<SuccessAccount>>(`${this.serviceUrl}Page`, { params })
            .pipe(map(page => {
                page.items = page.items.map(i => new SuccessAccount(i));
                return page;
            }));
    }
    

    constructor(
        protected http: HttpClient,
        // There is a bug in reflex-core
        // the http instance being passed down to ResourceService gets reflex-core's HTTP_INTERCEPTORS
        // and one of those interceptors is transforming the parameters passed to the error handler
        // it passes a string instance instead of an HttpErrorResponse instance. this breaks angular's HttpClient API
        // that is why we are injecting a new client that belongs to this class and doesn't get passed to ResourceService
        protected myhttp: HttpClient,
        protected roleService: Role_Service,
        protected settingsService: SuccessAccountSettingsService
    ){
        super(http);
        this.rolePipe.subscribe();
    }

    getSettingsByAccountUUID(uuid: string) {
        return this.settingsService.getByAccountUUID(uuid);
    }

    settings(): Observable<SuccessAccountSettings> {
        return this.user.pipe(
            filter(user => user && user !== SUCCESS_GUEST_USER),
            switchMap(user => {
                return this.getSettingsByAccountUUID(user.uuid);
            })
        );
    }

    saveSettings(settings: SuccessAccountSettings) {
        return this.settingsService.push(settings);
    }

    changePassword(request: { password: string, successAccount_uuid: string}): Observable<void> {
        return this.http.post<void>(`${this.serviceUrl}Change-Password`, request);
    }

    resetPassword(request: { password: string, successAccount_uuid: string}) {
        return this.http.post<void>(`${this.serviceUrl}Reset-Password`, request);
    }

    sendPasswordResetEmail(email: string): Observable<void> {
        return this.myhttp.post<void>(`${this.serviceUrl}Request-Password-Reset`, {
            email
        });
    }
    resetPasswordFromCode(code: string, password: string): Observable<void> {
        return this.myhttp.post<void>(`${this.serviceUrl}Password-Reset`, {
            password, token: code
        })
    }

    /**
     * 
     * @param action 
     * @returns 
     */
    hasPermission(action: string): Observable<boolean> {
        // TODO
        return of(false);
    }

    /**
     * 
     * @param role 
     */
    hasRole(roleName: string): Observable<boolean>  {
        return this.userRoles$.pipe(
            map(roles => {
                let match = !!roles.find(role => role.internalName == roleName);
                
                return match;
            })
        );
    }

    public push(entry: SuccessAccount): Observable<SuccessAccount> {
        if(!entry.firstName) {
            entry.firstName = "";
        }

        if(!entry.lastName) {
            entry.lastName = "";
        }

        if(!entry.email) {
            entry.email = "";
        }

        if(!entry.username) {
            entry.username = "";
        } 

        if(!entry.password) {
            entry.password = "";
        }

        if(!entry.corpId) {
            entry.corpId = "";
        }

        if(!entry.customerNumber) {
            entry.customerNumber = null;
        }

        if(!entry.companyNumber) {
            entry.companyNumber = null;
        }
        return super.push(entry);
    }

    public getServiceUrl(): string {
        return this.serviceUrl
    }

    public sendAccountCreateEmail(request: {name:string, email: string, username:string, password:string}): Observable<void> {
        return this.http.post(
                this.serviceUrl + 'AccountCreateNotification',
                request,
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => {
                        console.log('AccountCreateNotification: response:', response)
                    }
                )
            );
    }

    public updateLastLogin(request: { password?: string, lastLogin: Date, successAccount_uuid: string}): Observable<void> {
        return this.http.post(
                this.serviceUrl + 'UpdateLastLogin',
                request,
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => {
                        // console.log('updateLatLogin: response:', response)
                    }
                )
            );
    }

    public createAccount(data: SuccessAccount): Observable<Array<{uuid: string}>> {
        // console.log('New SuccessAccount data', data);
        
        return this
            .http
            .post(
                this.serviceUrl + 'createAccount',
                {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                    username: data.username,
                    password: data.password,
                    corpId: data.corpId,
                    customerNumber: data.customerNumber,
                    companyNumber: data.companyNumber
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => 
                        response.body.map((e: {uuid: string}) => e)
                )
            );

    }

    public verifyUser(data: {username:string, password:string}): Observable<boolean> {
        //console.log('verifyUser data', data);
        return this
            .http
            .post(
                this.serviceUrl + 'verifyUser',
                {
                    username: data.username,
                    password: data.password
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) =>
                        response.body.map((e: boolean) => e)
                )
            );
    }

    public checkUsername(data: {username:string}): Observable<Array<boolean>> {
        //console.log('checkUsername username', data);
        return this
            .http
            .post(
                this.serviceUrl + 'checkUsername',
                {
                    username: data.username
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) =>
                        response.body.map((e: boolean) => e)
                )
            );
    }

    public deleteSuccessAccount(data: {uuid:string}): Observable<boolean> {
        return this
            .http
            .post(
                this.serviceUrl + 'deleteSuccessAccount',
                {
                    uuid: data.uuid
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) =>
                        response.body.result
                )
            );
    }

    setAuthenticatedUserByEmail(email: string): Observable<SuccessAccount> {
        return this.getByEmail(email).pipe(  
            tap((user: SuccessAccount) => {
                this.user$.next(user);
            })
        );
    }

    getByEmail(email: string): Observable<SuccessAccount> {
        return this.list({
            allowCache: false,
            where: {
                filters: {
                    email: {
                        value: email,
                        operator: '='
                    }
                }
            }
        }).pipe(map(users => {
            if (!users)
                return SUCCESS_GUEST_USER;
            if (users.length != 1) {
                console.warn(`Found ${users.length} users with email ${email}`);
                return SUCCESS_GUEST_USER;
            }
            return users[0];
        }))
    }
    public getSuccessAccountByUsername(data: {username: string}): Observable<SuccessAccount> {
        return this
            .http
            .post(
                this.serviceUrl + 'getSuccessAccountByUsernameOrEmail',
                {
                    username: data.username
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => {
                        if(response && response.body)
                            return new SuccessAccount(response.body.result);
                        return SUCCESS_GUEST_USER;
                    }
                )
            );
    }

    public getSuccessAccountsByCorpId(corpId: string): Observable<SuccessAccount[]> {
        return this.list({
            where: {
                filters: {
                    corpId
                }
            }
        })
    }

    public makeLookupConfig() {
        const service = this;
        let lookup = (id: string) => service.get(id);
        let display = (account: SuccessAccount) => {
            if (!account) 
                return '';
            let name = `${account.firstName || ''} ${account.lastName || ''}`.trim();
            let words = [name, account.username].filter(word => !!word);
            return words.join(' - ');
        };
        let suggest = (term: string) => {
            return service
            .list({
                reloadFresh: true
            })
            .pipe(
                map(
                    response => response
                        .filter(option => {
                            return term === '' || option.firstName.toLowerCase().trim().includes(term)
                                                || option.lastName.toLowerCase().trim().includes(term)
                                                || option.username.toLowerCase().trim().includes(term)
                        })
                        .sort(
                            (a, b) => a.username.toLowerCase() <= b.username.toLowerCase() ? -1 : 1
                        )
                )
            );
        };
        let identifier = (item: SuccessAccount) => item && item.uuid;
        return { suggest, display, lookup, identifier };
    }

    public makeLookupConfigEmail() {
        const service = this;
        let lookup = (id: string) => service.get(id);
        let display = (account: SuccessAccount) => {
            if (!account) 
                return '';
            let email = `${account.email || ''}`.trim();
            let words = [email, account.username].filter(word => !!word);
            return words.join(' - ');
        };
        let suggest = (term: string) => {
            return service
            .list({
                reloadFresh: true
            })
            .pipe(
                map(
                    response => response
                        .filter(option => {
                            return term === '' || option.email.toLowerCase().trim().includes(term)
                                   
                        })
                        .sort(
                            (a, b) => a.username.toLowerCase() <= b.username.toLowerCase() ? -1 : 1
                        )
                )
            );
        };
        let identifier = (item: SuccessAccount) => item && item.uuid;
        return { suggest, display, lookup, identifier };
    }

    public getByDefaultClientMatterSetId(uuid: string)  {
        return this.http.get<SuccessAccount[]>(`${this.serviceUrl}getByDefaultClientMatterSetId?uuid=${uuid}`);
    }


    list(options?: ResourceListOptions | undefined): Observable<SuccessAccount[]> {
        if (!options)
            options = {};
        if (!options.where)
            options.where = {};
        if (!options.where.filters)
            options.where.filters = {};

        return this.http.post<SuccessAccount[]>(`${this.serviceUrl}search`, options).pipe(
                map((response:SuccessAccount[])=>{
                    return response.map((entity:SuccessAccount)=>{
                        return new SuccessAccount(entity);
                    })
                })
            );
    }
}

/**
 * Making this new service so we can use reflex's ResourceListComponent<SuccessAccount, SuccessAccountFirm_Service>
 * Class only exists to override the list method, so that the list only includes success accounts that belong
 * to the current user's corpId/firm.
 */
@Injectable()
export class SuccessAccountFirm_Service extends SuccessAccount_Service {

    constructor(
        protected http: HttpClient,
        protected myhttp: HttpClient,
        protected roleService: Role_Service,
        protected settingsService: SuccessAccountSettingsService,
        // this.user is always guest. never gets set on SuccessAccountFirm_Service, that's why we are injecting SuccessAccount_Service
        protected successAccountService: SuccessAccount_Service,
    ){
        super(http, myhttp, roleService, settingsService);
    }

    list(options?: ResourceListOptions | undefined): Observable<SuccessAccount[]> {
        // endpoints should return different results depending on who is making the call,
        // but that isn't implemented. this logic mimics access control rules and belongs on the backend, but is here for now.
        // this gives the user the impression/illusion they can only view/edit accounts belonging to their corp.
        // so we have to do something janky here, like requesting for accounts that have the 
        // current user's corpId.
        return this.successAccountService.user.pipe(
            tap(user => console.debug('got user in list method', user)),
            filter(user => user != SUCCESS_GUEST_USER),
            switchMap(user => {
                if (!options)
                    options = {};
                if (!options.where)
                    options.where = {};
                if (!options.where.filters)
                    options.where.filters = {};
                options.where.includeDeleted = true;
                    return super.list(options);
            })
        )

    }
}
