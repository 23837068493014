import { Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class SuccessAccount extends DexResourceModel {
   
    @Field(String)
	public firstName: string = "";
    @Field(String)
	public lastName: string = "";
    @Field(String)
	public email: string = "";
    @Field(String)
	public username: string = "";
    @Field(String)
	public password: string = "";
    @Field(String)
	public corpId: string = "";
    @Field(String)
	public customerNumber: string | null = null;
	@Field(Number)
	public companyNumber: number | null = null;
    @Field(Date)
	public createdAt: Date = new Date();
	@Field(Date)
	public updatedAt: Date = new Date();
    @Field(Date)
	public deletedAt: Date | null = null;	
	@Field(Boolean)
	public active: boolean = true;
	@Field(Date)
	public activeChangedOnDate: Date = new Date();
	@Field(Date)
	public lastLogin: Date | null = null;
	@Field(String)
	public default_clientMatterSet_uuid: string | null = null;
	@Field(Boolean)
	public internalUser: boolean = false;
	
	get fullName() {
		let max = 30;
		let name = `${this.firstName} ${this.lastName}`;
		if (name.length > max)
			return name.slice(0, max-3) + '...';
		return name;
	}
	
    constructor(data?: any) {
        super();
        this.mapData(data);
    }

    public get data(): any {
        let json = super.data;

        return json;
    }

}
