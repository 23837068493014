import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { ProjectFile } from '../models/projectFile.model';

@Injectable()
export class ProjectFile_Service extends ResourceService<ProjectFile> {
    protected servicePath: string = '/ProjectFile';

    public ModelType = ProjectFile;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }
}
