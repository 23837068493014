import { Directive, ElementRef } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
    selector: 'input[uuid]',
    host: {
      '(ngModelChange)': 'onInputChange($event)',
      '(keyup.backspace)': 'onInputChange($event.target.value, true)',
    }
  })
  export class UuidMask {
    private previousValue: string = '';
    constructor(public model: NgControl, public host: ElementRef<HTMLInputElement>) {
      this.previousValue = this.host.nativeElement.value;
    }
  
    logPosition() {
      let cursorPosition = this.getCaretPosition();
      console.debug('keyup cursorPosition', cursorPosition);
    }

    dropCharAt(value: string, position: number) {
      if (position < 0)
        return value;
      return value.substring(0, position) + value.substring(position+1);
    }

    onInputChange(value: string, backspace: boolean) {
      
      // is the cursor position with the added characters \s ( ) -
      let cursorPosition = this.getCaretPosition();

      
      var next = value.replace(/[^a-fA-F0-9]/g, '');
      var prev = this.previousValue.replace(/[^a-fA-F0-9]/g, '');
      console.log('next', next, 'prev', prev);
      //backspace = next < prev;
      console.debug('onInputChange cursorPosition', cursorPosition, backspace);

      if (next.length > 32) {
        // cap to sql 32 chars
        next = next.substring(0, 32);

      }

      if (next.length <= 8) {
        if (backspace)
          //next = this.dropCharAt(next, next.length - 1);
        if (next.length)
          next = next.replace(/^([a-fA-f0-9]{0,8})/, '$1');
          console.log(next)
      } else if (next.length <= 12) {
        next = next.replace(/^([a-fA-f0-9]{0,8})([a-fA-f0-9]{0,4})/, '$1-$2');
        console.log(next)
      } else if (next.length <= 16){
        next = next.replace(/^([a-fA-f0-9]{0,8})([a-fA-f0-9]{0,4})([a-fA-f0-9]{0,4})/, '$1-$2-$3');
      } else if (next.length <= 20){
        next = next.replace(/^([a-fA-f0-9]{0,8})([a-fA-f0-9]{0,4})([a-fA-f0-9]{0,4})([a-fA-f0-9]{0,4})/, '$1-$2-$3-$4');
      } else {
        next = next.replace(/^([a-fA-f0-9]{0,8})([a-fA-f0-9]{0,4})([a-fA-f0-9]{0,4})([a-fA-f0-9]{0,4})([a-fA-f0-9]{0,12})/, '$1-$2-$3-$4-$5');
      }
      

      // set the new value
      this.model.valueAccessor?.writeValue(next);       
      this.previousValue = next;

      if (backspace) {
        if (cursorPosition < next.length) {
          cursorPosition = cursorPosition;
        }
      } 
      else {
        cursorPosition = cursorPosition + 1;
      }
      this.setCaretPosition(cursorPosition);
      
    }

    getCaretPosition() {
      let input = this.host.nativeElement;
      // Initialize
      var iCaretPos = (input.value || '').length;
    
      if (input.selectionStart || input.selectionStart == 0) {
        switch(input.selectionDirection) {
          case 'forward':
            iCaretPos = input.selectionEnd != null ? input.selectionEnd : iCaretPos;
            break;
          default:
              iCaretPos = input.selectionStart;
              break;
        }
      }
      // Return results
      return iCaretPos;
    }

    setCaretPosition(caretPos: number) {
      let input = this.host.nativeElement;
      console.debug('setting cursorPosition', caretPos);
      input.setSelectionRange(caretPos, caretPos);
    }
}