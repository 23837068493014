<form #editForm="ngForm" (ngSubmit)="save()" [formGroup]="entityForm" *ngIf="entityForm !== undefined">
    <lib-legalreflex-toolbar
        mode="edit"
        [isLoading]="isLoading"
        [isLookup]="false"
        [isEmpty]="!isFormComplete"
        [ngForm]="entityForm"
        >
    </lib-legalreflex-toolbar>
    <mat-progress-spinner *ngIf="isLoading" style="margin:0 auto;" color="primary" mode="indeterminate"></mat-progress-spinner>

    <h3>Select a SuccessAccount to set as a Client User</h3>
  
    <resource-list-pane title="New Client User">
        <mat-form-field>
            <mat-label>Client User</mat-label>
            <mat-select [(value)] = "selectedAccount">
            <mat-option *ngFor="let type of firmAccounts$ | async" [value]="type.uuid">{{type.username}}</mat-option>
            </mat-select>
        </mat-form-field>

    <p> You Selected: {{selectedAccount}}</p>

    <div class="table-controls">
        <div class="span"></div>
        <button mat-button type="button" color="primary" (click)="add_ClientUser()">
            <mat-icon>add</mat-icon>
            <span>Set as Client User</span>
        </button>
    </div>
        </resource-list-pane>
   

        


</form>