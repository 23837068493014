import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ReflexEnvironment as environment } from '@smartsoftware/reflex-core';
import { filter, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

interface TokenResult {
  "access_token": string;
  "expires_in": number;
  "id_token": string;
  "scope": string;
  "token_type": string;
}

@Injectable({
  providedIn: 'root'
})
export class ImpersonateService {

  private oidcURL: string = environment.config['stsServer'];
  private messages = new BehaviorSubject<string>("");

  public get log() {
    // filter out the seed value
    return this.messages.asObservable().pipe(filter(value => !!value));
  }

  constructor(
    private oidcService: OidcSecurityService, 
    private http: HttpClient) { }

    /**
     * Super hacky and brittle, but seems to work. Might break when upgrading the angular-auth-oidc-client package
     * @param client_id 
     * @param subject 
     * @param actor_id_token 
     * @returns 
     */
  doImpersonate(client_id: string, subject: string, actor_id_token: string) {

    // console.debug('doImpersonate', client_id, subject, actor_id_token);
    this.messages.next(`Attempting to impersonate ${subject}`);

    const body = new HttpParams()
      .set('client_id', client_id)
      .set('subject', subject)
      .set('actor_id_token', actor_id_token);
    return this.http.post<string>(`${this.oidcURL}/impersonate`, 
      body.toString(), 
      {headers: {'Content-Type': 'application/x-www-form-urlencoded'}})
      .pipe(
      switchMap(grant_id => {

        // console.debug('grant_id', grant_id);
        this.messages.next("Obtained impersonation grant");
        // let config = this.oidcService.configuration.configuration;
        // if (!config.customTokenParams) 
        //   config.customTokenParams = {};
        // config.customTokenParams['grant_type'] = 'impersonate';
        // config.customTokenParams['grant_id'] = grant_id;

        let tokenParams = new HttpParams()
          .set('grant_id', grant_id)
          .set('grant_type', 'impersonate')
          .set('client_id', client_id);
        let configuration = this.oidcService.getConfiguration();
        let tokenEndpoint = configuration?.authWellknownEndpoints?.tokenEndpoint || `${this.oidcURL}/token`;
        this.messages.next('Requesting access token with grant');
        return this.http.post<TokenResult>(tokenEndpoint, 
          tokenParams.toString(), 
          {headers: {'Content-Type': 'application/x-www-form-urlencoded', Accept: 'application/json'}})
        
      }),
      switchMap(tokenResult => {
        // console.debug('/token result', tokenResult);
        // set id token and access token in sessionStorage. the oidcService expects this
        let tmp = window.sessionStorage.getItem(`0-${client_id}`) ;
        // console.log('impersonate: tmp:', tmp);
        let stored : any;
        if(tmp) {
          stored = JSON.parse(tmp);
          let authnResult = tokenResult;
          let authzData = tokenResult.access_token;
          // console.log('impersonate: stored:', stored);
          stored.authnResult = authnResult;
          stored.authzData = authzData;
          // console.log('impersonate: stored:', stored);
        }

        window.sessionStorage.removeItem(`0-${client_id}`);
        window.sessionStorage.setItem(`0-${client_id}`, JSON.stringify(stored));
        
        // window.sessionStorage.setItem(`${client_id}_authnResult`, JSON.stringify(tokenResult));
        // window.sessionStorage.setItem(`${client_id}_authzData`, JSON.stringify(tokenResult.access_token));
        this.messages.next('Obtained access token. Requesting user data');
        let configuration = this.oidcService.getConfiguration();
        let userinfoEndpoint = configuration?.authWellknownEndpoints?.userInfoEndpoint || `${this.oidcURL}/me`;
        return this.http.get<object>(userinfoEndpoint, { headers: { Authorization: `Bearer ${tokenResult.access_token}`}})
      }),
      switchMap(userData => {
        // console.log('impersonate: userData:', userData);
        let tmp = window.sessionStorage.getItem(`0-${client_id}`) ;
        // console.log('impersonate: tmp:', tmp);
        let stored : any;
        if(tmp) {
          stored = JSON.parse(tmp);
          // console.log('impersonate: stored:', stored);
          stored.userData = userData;
          // console.log('impersonate: stored:', stored);
        }

        window.sessionStorage.removeItem(`0-${client_id}`);
        window.sessionStorage.setItem(`0-${client_id}`, JSON.stringify(stored));

        // console.debug('/me result', userData);
        // set the userData in sessionStorage. the oidcService expects this
        // window.sessionStorage.setItem(`${client_id}_userData`, JSON.stringify(userData));
        // console.log('impersonate: sessionStorage:', window.sessionStorage);
        this.messages.next(`Obtained user info for ${subject}`);
        // call to checkAuth should now succeed, since we have set all the expected sessionStorage data.
        // this seems to notify subscribers of oidcService.userData$ with the subject's user info
        return this.oidcService.checkAuth(window.location.origin);
      }),
      tap(authenticated => {
        // console.log('impersonate: authenticated:', authenticated);
        if (authenticated.isAuthenticated) {
          // should be the impersonated user's (subject) token
          //console.debug('id token', this.oidcService.getIdToken);
          //console.debug('token', this.oidcService.getToken);
          this.messages.next(`Authenticated as ${subject}`);
        } else {
          this.messages.next(`For some reason the OIDC client is not authenticated for ${subject}. Try copying the impersonate URL into the location bar of a new private browser window.`);
        }
      })
    );
  }

}
