<div class="crumbs">
    <div *ngFor="let crumb of breadcrumbs | async; first as isFirst" >
        <a *ngIf="crumb.url" [routerLink]="crumb.url">{{crumb.label}}</a>
        <span *ngIf="!crumb.url">{{crumb.label}}</span>
    </div>
</div>
<div class="actions">
    <button *ngFor="let action of actions | async" mat-flat-button [disabled]="action.disabled" [color]="action.isPrimary ? 'primary' : ''" [routerLink]="action.routerLink" (click)="invokeAction(action)" [matTooltip]="action.tooltip || ''" [matTooltipDisabled]="!action.tooltip">
        <mat-icon *ngIf="action.icon">{{action.icon}}</mat-icon>
        <span *ngIf="action.label">{{action.label}}</span>
    </button>
</div>