import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { ResourceListOptions, ResourceService } from '@smartsoftware/reflex-core';
import { Invoice } from '../models/invoice.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Page, PageParams, Pager } from '../paged.datasource';

@Injectable()
export class Invoice_Service extends ResourceService<Invoice> implements Pager<Invoice> {
  protected servicePath: string = '/Invoice';

  public ModelType = Invoice;

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  page(params: PageParams): Observable<Page<Invoice>> {
    return this.filterListInvoice(params);
  }

  public filterListInvoice(pageParams: PageParams) {
    if (!pageParams.sortColumn)
      pageParams.sortColumn = 'invoiceNumber';

    if (!pageParams.sortOrder)
      pageParams.sortOrder = 'desc';

    let params = new HttpParams().append('params', btoa(JSON.stringify(pageParams)));

    return this.http.get<Page<Invoice>>(`${this.serviceUrl}`, {
      params
    }).pipe(map(page => {
      page.items = page.items.map(invoice => new Invoice(invoice));
      return page;
    }));
  }

  public totalCount() {
    return this
      .http
      .get(
        this.serviceUrl + 'totalCount',
        {
          observe: 'response'
        }
      )
      .pipe(
        map(
          (response: HttpResponse<any>) => {
            return response.body;
          }
        )
      );
  }
}

@Injectable()
export class AdminInvoice_Service extends ResourceService<Invoice> implements Pager<Invoice> {
  protected servicePath: string = '/Invoice';

  public ModelType = Invoice;

  constructor(
    protected http: HttpClient,
  ) {
    super(http);
  }

  page(params: PageParams): Observable<Page<Invoice>> {
    return this.history(params);
  }

  public history(pageParams: PageParams) {
    let params = new HttpParams().append('params', btoa(JSON.stringify(pageParams)));
    return this.http.get<Page<Invoice>>(`${this.serviceUrl}`, {
      params
    }).pipe(map(page => {
      page.items = page.items.map(invoice => new Invoice(invoice));
      return page;
    }));
  }
}