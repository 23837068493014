import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { ProjectNote } from '../models/projectNote.model';

@Injectable()
export class ProjectNote_Service extends ResourceService<ProjectNote> {
    protected servicePath: string = '/ProjectNote';

    public ModelType = ProjectNote;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }
}
