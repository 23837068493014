import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import {
    Contact,
    CaseUser,
    Note,
    Case
} from '../models';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class Case_Service extends ResourceService<Case> {
    protected servicePath: string = '/Case';

    public ModelType = Case;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }

    public push(entry: Case): Observable<Case> {
        if (!entry.caseNumber) {
            entry.caseNumber = null;
        }

        if (!entry.vendor_uuid) {
            entry.vendor_uuid = null;
        }        
        
        if (!entry.successAccount_uuid) {
            entry.successAccount_uuid = null;
        }        
        
        if (!entry.corpId) {
            entry.corpId = null;
        }        
        
        if (!entry.clientMatterNumber) {
            entry.clientMatterNumber = null;
        }        
        
        if (!entry.addressBook_uuid) {
            entry.addressBook_uuid = null;
        }
        
        //set to inactive
        if(entry.check == "true" && entry.deletedAt == null){
            entry.deletedAt = new Date();
            }
            //set to active
        else if(entry.check == "true" && entry.deletedAt != null){
                entry.deletedAt = null;
            }

        return super.push(entry);
    }

    public getNotes(uuid: string) {
        return this
            .http
            .post(
                this.serviceUrl + 'notes',
                {
                    uuid
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) =>
                        response.body.map((e: any) => new Note(e))
                )
            );
    }

    public getAlerts(uuid: string) {
        return this
            .http
            .post(
                this.serviceUrl + 'alerts',
                {
                    uuid
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) =>
                        response.body.map((e: any) => new Note(e))
                )
            );
    }

    public getContacts(uuid: string) {
        return this
            .http
            .post(
                this.serviceUrl + 'contacts',
                {
                    uuid
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) =>
                        response.body.map((e: any) => new Contact(e))
                )
            );
    }

    public getUsers(uuid: string) {
        return this
            .http
            .post(
                this.serviceUrl + 'users',
                {
                    uuid
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) =>
                        response.body.map((e: any) => new CaseUser(e))
                )
            );
    }
}
